import React, { Component, useEffect, useState } from "react"
import PropTypes from "prop-types"
import { Modal, Button, Header, Container, Icon, Select, Message } from "semantic-ui-react"
import BraintreeWebDropIn from "braintree-web-drop-in"
import { selectOptions } from "../../helpers/userHelpers"
import {
  BRAINTREE_EN_US_LOCALE,
  BRAINTREE_FR_CANADA_LOCALE,
  FR_LOCALE
} from "../../constants/users"
import LoadingThrobber from "./LoadingThrobber"
import { getSubdomain } from "./StylesheetInjector"
import { withTranslation } from "react-i18next"
import {
  isFleetMaintenanceHubCanada,
  isFleetMaintenanceHub,
  isFuelman,
  isFleetAdvise
} from "../../helpers/affiliationHelpers"
import Checkbox from "../../elements/Checkbox"
import { Link } from "react-router-dom"
import ReactSVG from "react-svg"
import PrivacyPolicyPage from "../../views/sessions/PrivacyPolicyPage"
import info_icon from "../../assets/images/info_icon.svg"

const BRAINTREE_CONTAINER = "payment-form"

function BraintreeDropIn(props) {
  const { clientToken, onPaymentCompleted, t } = props

  const [braintreeInstance, setBraintreeInstance] = useState(undefined)
  const [acceptedTermsAndConditions, setAcceptedTermsAndConditions] = useState(false)
  const updateAcceptedTermsAndConditions = () => setAcceptedTermsAndConditions((prev) => !prev)
  const [promotionalCheckboxChecked, setPromotionalCheckboxChecked] = useState(false)
  const updatePromotionalCheckboxChecked = () => setPromotionalCheckboxChecked((prev) => !prev)
  const [communicationPreferencesInFrench, setCommunicationPreferencesInFrench] = useState(false)
  const [validate, setValidate] = useState(false)
  const [termsValidate, setTermsValidate] = useState(false)
  const [token, setToken] = useState(undefined)
  const updateCommunicationPreferencesInFrench = () =>
    setCommunicationPreferencesInFrench((prev) => !prev)
  const isDomainShellCanada = isFleetMaintenanceHubCanada()
  const fuelman = isFuelman()
  const fleetAdvise = isFleetAdvise()

  useEffect(() => {
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" })
    if (clientToken) {
      const initializeBraintree = () =>
        BraintreeWebDropIn.create(
          {
            authorization: clientToken, // insert your tokenization key or client token here
            container: `#${BRAINTREE_CONTAINER}`,
            translations: {
              payWithCard: "Credit card"
            },
            locale:
              props.userSelectedLanguage == FR_LOCALE
                ? BRAINTREE_FR_CANADA_LOCALE
                : BRAINTREE_EN_US_LOCALE,
            card: {
              overrides: {
                fields: {
                  postalCode: {
                    placeholder: "Postal Code" // Update the postalCode field placeholder
                  }
                }
              },
              cardholderName: {
                required: true
              }
            }
          },
          function (error, instance) {
            if (error) return test(error)
            else setBraintreeInstance(instance)

            instance.on("paymentMethodRequestable", function (event) {
              setValidate(true)
            })

            instance.on("noPaymentMethodRequestable", function () {
              setValidate(false)
            })
          }
        )

      if (braintreeInstance) {
        braintreeInstance.teardown().then(() => {
          initializeBraintree()
        })
      } else {
        initializeBraintree()
      }
    }
  }, [clientToken, props.userSelectedLanguage])

  function test(error) {}
  const svgStyle = {
    width: 30,
    height: 30,
    backgroundColor: "#fff",
    borderRadius: "50%",
    marginRight: 18
  }

  return (
    <div style={{ width: "500px", margin: "auto", display: "block" }}>
      {!braintreeInstance ? (
        <div style={{ float: "center" }}>
          <LoadingThrobber visible noMargin={true} />
        </div>
      ) : (
        <Message icon className={fleetAdvise ? "info-message" : ""}>
          {fuelman ? (
            <>
              <Icon name="exclamation circle" className={"icon-color"} />
              <Message.Content>
                <p align="justify">{t("needCardDetailsFuelmanLabel")}</p>
              </Message.Content>
            </>
          ) : fleetAdvise ? (
            <>
              <ReactSVG svgStyle={svgStyle} src={info_icon} />
              <Message.Content>
                <p align="justify">{t("needCardDetailsLabel")}</p>
              </Message.Content>
            </>
          ) : (
            <>
              <Icon name="exclamation circle" className={"icon-color"} />
              <Message.Content>
                <p align="justify">{t("needCardDetailsLabel")}</p>
              </Message.Content>
            </>
          )}
        </Message>
      )}
      <div id={BRAINTREE_CONTAINER} />
      {braintreeInstance ? (
        <>
          <div className={"checkbox-signupbutton signup-payment"}>
            <div>
              <div className={"terms-and-conditions"}>
                <Checkbox
                  className={"checkbox t-and-c-checkbox"}
                  onChange={updateAcceptedTermsAndConditions}
                  checked={acceptedTermsAndConditions}
                />
                <PrivacyPolicyPage t={t} />
              </div>
              {isDomainShellCanada && (
                <div className={"terms-and-conditions"}>
                  <Checkbox
                    className={"checkbox t-and-c-checkbox"}
                    onChange={updatePromotionalCheckboxChecked}
                    checked={promotionalCheckboxChecked}
                    style={{ marginRight: "3px" }}
                  ></Checkbox>
                  <div className={"t-and-c-container"} style={{ display: "contents" }}>
                    <p style={{}}>
                      {t("readAcceptTermLabel")}
                      <Link
                        to={{
                          pathname: t("promotionalTermsLink")
                        }}
                        target="_blank"
                        aria-label="Terms and Conditions open in new window"
                        style={{
                          textDecoration: "underline",
                          color: "unset",
                          marginRight: "20px"
                        }}
                      >
                        <u>{t("promotionalTermsConditionsLabel")}</u>
                      </Link>
                    </p>
                  </div>
                </div>
              )}
              {isDomainShellCanada && (
                <div className={"language"} style={{ justifyContent: "flex-start" }}>
                  <Checkbox
                    className={"checkbox"}
                    onChange={updateCommunicationPreferencesInFrench}
                    checked={communicationPreferencesInFrench}
                  />
                  <div className={"language-french"} style={{ width: "510px" }}>
                    <p style={{}}>{t("communicationPreferencesInFrench")}</p>
                  </div>
                </div>
              )}
              {isDomainShellCanada && (
                <div className="canadian-partners-logo">
                  <ReactSVG src={`images/canadian_partners.svg`} style={{ width: "50%" }} />
                </div>
              )}
            </div>
          </div>
          <Button
            className={"braintreePayButton full-length"}
            type="primary"
            disabled={
              !validate ||
              (isDomainShellCanada
                ? !(acceptedTermsAndConditions && promotionalCheckboxChecked)
                : !acceptedTermsAndConditions)
            }
            onClick={() => {
              return new Promise((resolve, reject) => {
                if (braintreeInstance) {
                  braintreeInstance.requestPaymentMethod(async (error, payload) => {
                    if (error && error.message) {
                      resolve(false)
                    } else {
                      props.onPaymentCompleted(payload.nonce, communicationPreferencesInFrench)
                      setToken(payload.nonce)
                      resolve(true)
                    }
                  })
                }
              })
            }}
          >
            {t("getStartedLabel")}
          </Button>
        </>
      ) : (
        <></>
      )}
    </div>
  )
}

// TODO: this class can be extracted as a common component, if it is refactored to accept props
// for the "Drivers" dropdown data.  this.props.users and the formData attr name would become props.
// Otherwise, it's a generic overlay modal that is reusable.
export default class PaymentMethodScreen extends Component {
  static propTypes = {
    modalOpen: PropTypes.bool.isRequired,
    closeModal: PropTypes.func.isRequired,
    saveCallback: PropTypes.func.isRequired,
    users: PropTypes.array.isRequired,
    clientToken: PropTypes.string
  }

  handleSave = async () => {
    return new Promise((resolve, reject) => {
      this.state.btInstance.requestPaymentMethod(async (error, payload) => {
        if (error && error.message) {
          window.alert(`${this.props.t("payment:braintreePaymentErrorLabel")}`)
          resolve(false)
        } else {
          await this.props.saveCallback(payload.nonce, this.state.formData)
          this.setState({ formData: { customer_ids: [] } })
          resolve(true)
        }
      })
    })
  }

  onPaymentCompleted = (token, communicationPreferencesInFrench) => {
    this.props.onPaymentCompleted(token, communicationPreferencesInFrench)
  }

  render() {
    const { modalOpen, closeModal, users, t } = this.props
    return (
      <>
        <BraintreeDropIn
          clientToken={this.props.clientToken}
          onPaymentCompleted={this.onPaymentCompleted}
          t={t}
          userSelectedLanguage={this.props.userSelectedLanguage}
        />
      </>
    )
  }
} // class BraintreeModal
