import React, { useEffect, useState } from "react"
import { withTranslation } from "react-i18next"
import { Image } from "semantic-ui-react"
import { getSubdomain } from "../../components/shared/StylesheetInjector"
import { BRAND_LOGO, DISCOUNT_TIRE_BRAND_LOGO, FLEET_ADVISE } from "../../constants/membershipCard"
import ReactSVG from "react-svg"
import { isDTCAFleet, isFuelman, isNTWTBC } from "../../helpers/affiliationHelpers"
import DiscountTireLogo from "../../assets/images/discountTire_logo.svg"
import { DISCOUNT_TIRE } from "../../constants/application"
import * as userHelpers from "../../helpers/userHelpers"
import { connect } from "react-redux"
import { EXCLUDED_ROLES, isFMCUser } from "../../helpers/userRoleHelpers"
import { FLEET_MANAGER } from "../../constants/roles"
import Select from "../../elements/Select"
import { isEmpty } from "lodash"

const FleetCard = ({
  driverName,
  membershipLogo,
  membershipAccount,
  year,
  make,
  model,
  vin,
  membershipBarcode,
  membershipIntegrate,
  worldwideStores,
  MembershipShops,
  phone,
  helpCenterName,
  t,
  cardRef,
  basicUsers,
  currentUser,
  fleet
}) => {
  const [selectedUser, setSelectedUser] = useState(driverName)
  let sub = getSubdomain()
  let size = 90
  if (isFuelman()) size = 100
  const extraStyles = { backgroundColor: "#e3eae6", borderRadius: "0 0 0 6px", height: "9vh" }

  useEffect(() => {
    setSelectedUser(driverName)
  }, [driverName])

  return (
    <div ref={cardRef} className="membership">
      <div className="membership_header">
        <div className="membership_header__details">
          {!isEmpty(fleet.name) && <p className="membership_name">{fleet.name}</p>}
          {currentUser.roles.includes(FLEET_MANAGER) || isFMCUser(currentUser) ? (
            <Select
              onChange={(event) => setSelectedUser(event.target.value)}
              options={userHelpers.selectOptions(basicUsers, { nameAsValue: true })}
              placeholder={t("selectUserLabel")}
              value={selectedUser}
              className="basic-users-select"
            ></Select>
          ) : (
            <p className="membership_driver">{driverName}</p>
          )}
        </div>
        {sub === FLEET_ADVISE ? (
          <Image src={membershipLogo} className="fleetadvise_logo" />
        ) : (
          <ReactSVG
            src={sub === DISCOUNT_TIRE ? DiscountTireLogo : `/images/${sub}_logo.svg`}
            svgStyle={{
              ...extraStyles,
              width: size
            }}
          />
        )}
      </div>

      <div className="membership_vehicle">
        <p>{membershipAccount}</p>
        <p>
          {year} {make} {model}
        </p>
        <p>
          {t("vin")}: <span>{vin}</span>
        </p>
      </div>
      <div className="membership_code">
        <p className="shop-instruction-label">{t("shopInstrcutions")}</p>
        <div className="membership_code__details">
          {t("process")} <span>{membershipAccount}</span> {t("through")}{" "}
          <span>{membershipIntegrate}</span>, {t("call")} {helpCenterName} {t("at")}{" "}
          <span>{phone}</span> {t("forApproval")}
        </div>
      </div>
      {!isNTWTBC() && (
        <div className="membership_brand">
          <p className="mebership_brand__store">
            {t("over")} {worldwideStores} {t("storeNationwide")}
          </p>
          <div className="membership_shops">
            {(isDTCAFleet() ? DISCOUNT_TIRE_BRAND_LOGO : BRAND_LOGO).map((brandLogo) => (
              <Image src={brandLogo} />
            ))}
          </div>
        </div>
      )}
    </div>
  )
}

const mapStateToProps = (state) => {
  return {
    currentUser: state.application.userSessionData || state.users.currentUser,
    basicUsers: state.users.basicUsers.filter(
      (user) => !EXCLUDED_ROLES.some((excludedRole) => user.roles.includes(excludedRole))
    ),
    fleet: state.application.fleetSessionData || state.fleets.fleet
  }
}
export default connect(mapStateToProps)(withTranslation("membershipFleetCard")(FleetCard))
