import React from "react"
import { useTranslation } from "react-i18next"

const MaintenanceServiceDisplay = ({ service }) => {
  const { t } = useTranslation("maintenanceHistory")

  return (
    <div className="service-container">
      <p className="service-name">{service.name}</p>
      {service.notes && (
        <p className="service-notes">
          {t("notes")} <span>{service.notes}</span>
        </p>
      )}
    </div>
  )
}

export default MaintenanceServiceDisplay
