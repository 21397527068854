import React from "react"
import { Link } from "react-router-dom"
import { navigationPaths } from "../../constants/paths"
import { formattedSelectedServices, checkForActiveOrder } from "../../helpers/serviceHelpers"
import { Button } from "semantic-ui-react"
import { useTranslation } from "react-i18next"
import { faCalendar } from "@fortawesome/pro-light-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { isFleetAdvise } from "../../helpers/affiliationHelpers"

export default function ScheduleServiceButton({ text, maintenance, vehicle, floated, secondary }) {
  const services = maintenance ? formattedSelectedServices({ services: maintenance, vehicle }) : []
  const { t } = useTranslation("common")

  return (
    <Link
      onClick={(event) => checkForActiveOrder({ vehicle, event, t })}
      to={{
        pathname: navigationPaths.serviceRequestNew(),
        state: {
          previousFormData: {
            order_services: services,
            vehicle_id: vehicle && vehicle.id,
            vehicle: vehicle
          }
        }
      }}
    >
      <Button className={secondary && "white-button"} floated={floated}>
        {isFleetAdvise() ? (
          <FontAwesomeIcon icon={faCalendar} className="button-icon-color" />
        ) : null}
        {text ? text : t("scheduleServiceLabel")}
      </Button>
    </Link>
  )
}
