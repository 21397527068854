import { mapKeys, omitBy, isNil } from "lodash"
import { getDeviceProp, getEventNameByKey } from "../helpers/segmentHelpers"
import { segmentProps } from "../constants/segmentProps"
import store from "../actions/configureStore"
import { COUNTRY_CODE } from "./stringHelpers"
import { getSubdomain } from "../components/shared/StylesheetInjector"
import { FLEET_MAINTENANCE_HUB } from "../constants/application"

export const trackEvent = async (eventNameKey, props = {}) => {
  // device information gets added with every track event
  props = {
    ...props,
    ...getDeviceProp()
  }

  if (props) {
    props = JSON.parse(JSON.stringify(props, (_, v) => (v === undefined ? null : v)))
  }

  // mapping props key to constant file
  if (props) props = mapKeys(props, (v, k) => segmentProps[k])

  if (props) props = omitBy(props, isNil)

  // fetching event name which is stored in redux state by its key
  const eventName = getEventNameByKey(eventNameKey)
  if (eventName && getSubdomain() !== FLEET_MAINTENANCE_HUB) {
    const response = await window.analytics.track(eventName, props)
    return response
  }
  return null
}

export const identifyEvent = async ({
  traits = {},
  userId,
  onSuccess = () => {},
  replaceUndefinedOrNullValues = true
}) => {
  const { application } = store.getState()
  const user = application && application.userSessionData

  // device information gets added with every track event
  traits = {
    ...traits,
    ...getDeviceProp()
  }

  if (traits) {
    if (user && user.email) traits.email = user.email
    if (user && user.cellPhone) traits.phone = COUNTRY_CODE.concat(user.cellPhone)
    if (user && user.firstName && user.lastName)
      traits.name = (user.firstName + " " + user.lastName).trim()
    if (user && user.firstName) traits.firstNameWithSpace = user.firstName.trim()
    if (user && user.lastName) traits.lastNameWithSpace = user.lastName.trim()
    if (replaceUndefinedOrNullValues) {
      traits = JSON.parse(
        JSON.stringify(traits, (_, v) => (v === undefined || v === null ? "" : v))
      )
    }
  }

  // mapping props key to constant file
  traits = mapKeys(traits, (v, k) => segmentProps[k])

  const response = await window.analytics.identify(userId, traits, null, onSuccess)
  return response
}
