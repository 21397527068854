import { getSubdomain } from "../components/shared/StylesheetInjector"

export const DOWNLOAD_APPS_LINKS = Object.freeze({
  fleetadvise: {
    playstoreLink: "https://play.google.com/store/apps/details?id=com.caradvise_fleetadvise",
    appstoreLink: "https://apps.apple.com/us/app/caradvise-for-business/id1448524292"
  }
})
export const checkSubDomain = () => {
  const subDomain = getSubdomain()
  if (DOWNLOAD_APPS_LINKS.hasOwnProperty(subDomain)) {
    return {
      found: true,
      result: DOWNLOAD_APPS_LINKS[subDomain]
    }
  } else {
    return {
      found: false,
      result: null
    }
  }
}
