// eslint-disable-next-line
import React, { Component } from "react"
import { connect } from "react-redux"
import { isFleetMaintenanceHub, isShell } from "../../helpers/affiliationHelpers"
import { deleteApplicationCookies, userSignedIn } from "../../helpers/sessionHelpers"
import { navigationPaths } from "../../constants/paths"
import ShellLogoutUser from "./ShellLogoutUser"
import { withTranslation } from "react-i18next"
import { IMPERSONATED_BY } from "../../constants/users"
import { CURRENT_ACCOUNT_ID, CURRENT_FLEET_ID } from "../../constants/application"
// NOTE: This allows for a user to sign out, without each view/component that renders the nav link having to worry about the presence of this.props.location
class SessionDestroy extends Component {
  state = {
    signoutComplete: false
  }

  componentDidMount() {
    const { dispatch, history, t, currentUser } = this.props

    if (userSignedIn()) {
      dispatch({
        type: "SIGN_OUT_SAGA",
        payload: { currentUser: currentUser },
        callback: this.onSignOutSuccess,
        t: t
      })
    } else {
      history.push({ pathname: navigationPaths.signIn() })
    }
  }

  onSignOutSuccess = (affiliation = {}) => {
    localStorage.removeItem(IMPERSONATED_BY)
    sessionStorage.removeItem(deleteApplicationCookies([CURRENT_FLEET_ID, CURRENT_ACCOUNT_ID]))

    if (isFleetMaintenanceHub() && isShell(affiliation)) {
      this.setState({ signoutComplete: true })
    } else {
      this.props.history.push({ pathname: navigationPaths.signIn() })
    }
  }

  render() {
    if (isFleetMaintenanceHub() && this.state.signoutComplete) {
      return <ShellLogoutUser />
    } else {
      return null
    }
  }
} // class SessionDestroy

const mapStateToProps = (state) => ({
  currentUser: state.application.userSessionData || state.users.currentUser
})

export default connect(mapStateToProps)(withTranslation("common")(SessionDestroy))
