import * as stringHelpers from "./stringHelpers"
import { isFleetcor } from "./affiliationHelpers"
import { MD_HD_VEHICLE_TYPE_ID } from "../constants/application"
import i18n from "../utilities/i18n"
import React from "react"
import store from "../actions/configureStore"
import { formatCurrency } from "../utilities/currency"

export function selectOptions(services, opts = {}) {
  let options = []
  let vehicle = opts.vehicle

  let showTirePromotion =
    opts.showTirePromotionBannerConfig &&
    opts.showTirePromotionBannerConfig.hasOwnProperty("service_search_banner")
  if (showTirePromotion && opts.showTirePromotionPlaceholder)
    options.push({
      label: (
        <div>
          <img
            src={opts.showTirePromotionBannerConfig.service_search_banner}
            className="placeholder-image"
          />
        </div>
      ),
      value: "placeholder",
      isDisabled: true
    })

  if (vehicle && vehicle.vehicle_type_id === MD_HD_VEHICLE_TYPE_ID) {
    options = opts.includeBlank
      ? [
          {
            value: null,
            label: i18n.t("common:mdHdServicesLabel")
          }
        ]
      : []
  }

  if (opts.semantic)
    services.forEach((service) => {
      options.push({
        value: service.id,
        text: service.name,
        key: `service-${service.id}`
      })
    })
  else
    services.forEach((service) => {
      options.push({
        label: service.name,
        value: service.id
      })
    })

  return options
}
export const getServiceNameForMap = (obj) => {
  return `${obj ? obj.name || obj.name : ""}${obj && obj.position ? " - " + obj.position : ""}`
}

export function preselectOilType(vehicle, options) {
  let selection
  if (!vehicle) return selection

  const vehicleOilType = vehicle.oil_type_name

  if (vehicleOilType) {
    const formattedOilType = stringHelpers.capitalCase(vehicleOilType)
    const oilTypeIndex = options.indexOf(formattedOilType)

    selection = options[oilTypeIndex]
  }

  return selection
}

export function checkForActiveOrder({ event, vehicle, t }) {
  const { active_order, is_active, fleetcor_enrolled } = vehicle || {}

  if (active_order) {
    event.preventDefault()
    alert(t("orderExistsAlert"))
  } else if (is_active == false) {
    event.preventDefault()
    alert(t("vehicleDeactivatedLabel"))
  } else if (isFleetcor() && fleetcor_enrolled == false) {
    event.preventDefault()
    alert(t("fleetcorVehicleDeactivateLabel"))
  }
}

export function formattedSelectedServices({ services, vehicle }) {
  return services.map((service) => formattedSelectedService({ service, vehicle }))
}

export function formattedSelectedService({ service, vehicle }) {
  // `service_id` is the id of the associated service definition.  alternatively can be just `id`
  let formattedService = {
    id: service.service_id || service.id,
    name: service.name || service.service_name,
    positionOptions: service.positions || [],
    support_medium_and_heavy_duty_vehicles: service.support_medium_and_heavy_duty_vehicles
  }

  // positions can come via the `service_options` or directly on the service
  const serviceOption = (service.service_options || [])[0]
  const positions = (serviceOption || service).positions || []

  if (positions.length > 0) {
    let preSelectedPosition

    if (service.name === "Oil Change") {
      preSelectedPosition = preselectOilType(vehicle, positions)
    }

    formattedService["positionOptions"] = positions
    formattedService["position"] = preSelectedPosition || ""
  }

  return formattedService
}

export const getServicesWithPrice = (order_services, vehicle) => {
  const servicePrices = store.getState().services.servicePrices || []

  const serviceWithPrice =
    order_services &&
    order_services.map((service) => {
      let servicePrice = 0
      if (vehicle && vehicle.vehicle_type_id === MD_HD_VEHICLE_TYPE_ID) {
        servicePrice = i18n.t("common:priceAtShopLbl")
      } else {
        const matchingPrice =
          servicePrices && servicePrices.find((price) => price.id === service.id)
        if (matchingPrice && matchingPrice.price_estimates) {
          if (service.position) {
            servicePrice = (
              (matchingPrice &&
                matchingPrice.price_estimates.find((pe) => pe.position === service.position)) ||
              {}
            ).shop_price_estimate
          } else if (service.positionOptions && !service.positionOptions[0]) {
            servicePrice = (matchingPrice.price_estimates[0] || {}).shop_price_estimate
          }
          servicePrice = !servicePrice
            ? i18n.t("common:priceAtShopLbl")
            : formatCurrency(servicePrice)
        }
      }
      return {
        ...service,
        servicePrice: servicePrice
      }
    })

  return serviceWithPrice
}
