import React, { PureComponent } from "react"
import { isAndroid, isIOS, isMobileOnly } from "react-device-detect"
import { Modal } from "semantic-ui-react"
import PlaystoreIcon from "../../assets/images/playstore_color.png"
import AppstoreIcon from "../../assets/images/appstore.png"
import { withTranslation } from "react-i18next"
import { checkSubDomain } from "../../constants/downloadApp"
import { EVENT_LISTENERS } from "../../constants/application"

const OPEN_DOWNLOAD_APP_MODAL = "openDownloadAppModal"
const { found, result } = checkSubDomain()

class DownloadAppModal extends PureComponent {
  state = {
    open: true,
    windowWidth: window.innerWidth
  }
  handleWindowResize = () => {
    this.setState({ windowWidth: window.innerWidth })
  }

  componentDidMount() {
    const isOpen = sessionStorage.getItem(OPEN_DOWNLOAD_APP_MODAL)
    if (isOpen === "true") {
      this.setState({ open: false })
    } else if (isMobileOnly) {
      this.setState({ open: true })
      sessionStorage.setItem(OPEN_DOWNLOAD_APP_MODAL, "true")
    }
    window.addEventListener(EVENT_LISTENERS.RESIZE, this.handleWindowResize)
  }

  componentWillUnmount() {
    window.removeEventListener(EVENT_LISTENERS.RESIZE, this.handleWindowResize)
  }

  handleIconClick() {
    if (isMobileOnly && found) {
      if (isAndroid) {
        window.location.href = result.playstoreLink
      } else {
        window.location.href = result.appstoreLink
      }
    } else {
      window.location = "https://www.caradvise.com/mobile"
    }
  }

  render() {
    const { t } = this.props
    const { windowWidth } = this.state
    return (
      <React.Fragment>
        {windowWidth <= 500 && (
          <Modal
            closeIcon
            close
            onClose={() => this.setState({ open: false })}
            onOpen={() => this.setState({ open: true })}
            open={this.state.open}
            className="downloadModal"
          >
            <Modal.Actions className="downloadModal-main-section">
              <h5 className="downloadModal-text">{t("letsMakeThingsText")}</h5>
              <h4 style={isAndroid ? { margin: "12px" } : {}}>{t("downloadTheCarAdviseText")}</h4>
              {isAndroid ? (
                <img
                  alt="playstore"
                  width={170}
                  height={70}
                  src={PlaystoreIcon}
                  onClick={this.handleIconClick}
                />
              ) : null}
              {isIOS ? (
                <img
                  alt="appstore"
                  width={150}
                  height={50}
                  src={AppstoreIcon}
                  onClick={this.handleIconClick}
                />
              ) : null}
              <h5
                className="downloadModal-continue-btn"
                onClick={() => this.setState({ open: false })}
              >
                {t("orContinuwWithBrowsertext")}
              </h5>
            </Modal.Actions>
          </Modal>
        )}
      </React.Fragment>
    )
  }
}

export default withTranslation("dashboard")(DownloadAppModal)
