import React, { Component } from "react"
import { connect } from "react-redux"

import { navigationPaths } from "../../constants/paths"

import Alert from "../../components/shared/Alert"
import ApplicationLayout from "../../layouts/ApplicationLayout"
import PaymentForm from "../../components/payments/PaymentForm"
import { logFirebaseAnalyticsScreenName } from "../../helpers/googleAnalyticsHelpers"
import { getRouteNameFromUrl } from "../../helpers/segmentHelpers"

class PaymentNew extends Component {
  constructor(props) {
    super(props)

    this.state = {
      error: "",
      isSubmitting: false
    }
  }

  afterFormSubmit(status, data) {
    this.setState({ isSubmitting: false })

    if (status === "success") {
      this.onFormSubmitSuccess(data)
    } else {
      this.onFormSubmitFailure(data)
    }
  }

  onFormSubmit(formData) {
    this.setState({ isSubmitting: true })

    // this.props.dispatch({
    //   type: 'PAYMENT_CREATE_SAGA',
    //   payload: { formData: formData },
    //   callback: this.afterFormSubmit.bind(this)
    // })
  }

  onFormSubmitFailure(data) {
    this.setState({ error: data.error })
  }

  onFormSubmitSuccess(data) {
    this.props.history.push({
      pathname: navigationPaths.account(),
      state: data
    })
  }

  componentDidMount() {
    logFirebaseAnalyticsScreenName(getRouteNameFromUrl())
  }

  render() {
    const { error, isSubmitting } = this.state

    return (
      <ApplicationLayout>
        <Alert message={error} type={"error"} visible={error !== ""} />

        <div>
          <div className={"dash-hd"}>NEW PAYMENT</div>

          <PaymentForm isSubmitting={isSubmitting} onSubmit={this.onFormSubmit.bind(this)} />
        </div>
      </ApplicationLayout>
    )
  }
} // class PaymentNew

export default connect()(PaymentNew)
