const rootPath = ""

export const navigationPaths = {
  root: () => `/`,

  account: () => `${rootPath}/account`,
  dashboard: () => `/dashboard`,
  fmcDashboard: () => `${rootPath}/fmc_dashboard`,
  fmcVehicles: () => `${rootPath}/fmc_vehicles`,
  fmcUsers: () => `${rootPath}/fmc_users`,
  fmcActiveOrders: () => `${rootPath}/fmc_activeorders`,
  fmcTransactions: () => `${rootPath}/fmc_transactions`,
  fleetTransactions: () => `${rootPath}/fleet_transactions`,
  transferVehicles: () => `${rootPath}/transfer_vehicles`,
  dashboardActiveMaintenances: () => `${rootPath}/active_maintenances`,
  dashboardRecalls: () => `${rootPath}/recalls`,
  dashboardUpcomingMaintenances: () => `${rootPath}/upcoming_maintenances`,
  forgotPassword: () => `${rootPath}/forgot_password`,
  paymentsNew: () => `${rootPath}/payments/new`,
  paymentsEdit: (id) => `${rootPath}/payments/${id}/edit`,
  resetPassword: () => `${rootPath}/reset_password`,
  servicesIndex: () => `${rootPath}/services`,
  serviceRequestNew: () => `${rootPath}/service_request/new`,
  MaintenanceHistoryNew: () => `${rootPath}/maintenance_history/new`,
  serviceRequestSchedule: () => `${rootPath}/service_request/schedule`,
  shellCustomerEdit: () => `${rootPath}/shell_sign_up`,
  shopIndex: () => `${rootPath}/shops`,
  signIn: () => `${rootPath}/sign_in`,
  signUp: () => `${rootPath}/sign_up`,
  signOut: () => `${rootPath}/sign_out`,
  vehicleEdit: (id) => `${rootPath}/vehicles/${id}/edit`,
  vehicleIndex: () => `${rootPath}/vehicles`,
  vehicleNew: () => `${rootPath}/vehicles/new`,
  vehicleShow: (id) => `${rootPath}/vehicles/${id}/overview`,
  settingsIndex: () => `${rootPath}/settings`,
  approvalsShow: (id) => `${rootPath}/orders/${id}/approvals`,
  userPreferences: () => `${rootPath}/user_preferences`,
  membershipCard: () => `${rootPath}/membership_card`,
  fleetEdit: (id) => `${rootPath}/fleets/${id}/edit`,
  fleetNew: () => `${rootPath}/fleets/new`,
  fleetShow: (id) => `${rootPath}/fleets/${id}/overview`,
  privacyPolicy: () => `${rootPath}/privacy_policy`,
  maintenanceHistoryEdit: (id) => `${rootPath}/maintenance_history/${id}`,
  vehicleShowMaintenanceHistory: (id) => `${rootPath}/vehicles/${id}/maintenance_history`,
  vehicleShowMaintenanceSchedule: (id) => `${rootPath}/vehicles/${id}/maintenance_schedule`,
  vehicleShowMaintenanceScheduleDetails: (id, interval) =>
    `${rootPath}/vehicles/${id}/maintenance_schedule/${interval}`,
  vehicleShowMaintenanceScheduleHistory: (id) =>
    `${rootPath}/vehicles/${id}/maintenance_schedule/history`,
  vehicleShowPolicy: (id) => `${rootPath}/vehicles/${id}/policies`,
  suggestedMaintenanceDetails: (id) => `${rootPath}/maintenance_schedule_details/${id}`,
  maintenanceHistoryDetails: (id) => `${rootPath}/vehicles/${id}/history`,
  magicLink: () => `${rootPath}/magic_link`,
  fmcReporting: () => `${rootPath}/fmc_reporting`,
  fleetReporting: () => `${rootPath}/fleet_reporting`
}

export const routerPaths = {
  root: `/`,

  account: `${rootPath}/account`,
  dashboard: `${rootPath}/dashboard`,
  fmcDashboard: `${rootPath}/fmc_dashboard`,
  fmcVehicles: `${rootPath}/fmc_vehicles`,
  fmcUsers: `${rootPath}/fmc_users`,
  fmcActiveOrders: `${rootPath}/fmc_activeorders`,
  fmcTransactions: `${rootPath}/fmc_transactions`,
  fleetTransactions: `${rootPath}/fleet_transactions`,
  transferVehicles: `${rootPath}/transfer_vehicles`,
  dashboardActiveMaintenances: `${rootPath}/active_maintenances`,
  dashboardRecalls: `${rootPath}/recalls`,
  dashboardUpcomingMaintenances: `${rootPath}/upcoming_maintenances`,
  forgotPassword: `${rootPath}/forgot_password`,
  paymentsNew: `${rootPath}/payments/new`,
  paymentsEdit: `${rootPath}/payments/:id/edit`,
  resetPassword: `${rootPath}/reset_password`,
  serviceIndex: `${rootPath}/services`,
  serviceRequestNew: `${rootPath}/service_request/new`,
  MaintenanceHistoryNew: `${rootPath}/maintenance_history/new`,
  serviceRequestSchedule: `${rootPath}/service_request/schedule`,
  shellCustomerEdit: `${rootPath}/shell_sign_up`,
  shopIndex: `${rootPath}/shops`,
  signIn: `${rootPath}/sign_in`,
  signUp: `${rootPath}/sign_up`,
  signOut: `${rootPath}/sign_out`,
  vehicleEdit: `${rootPath}/vehicles/:id/edit`,
  vehicleIndex: `${rootPath}/vehicles`,
  vehicleNew: `${rootPath}/vehicles/new`,
  vehicleShow: `${rootPath}/vehicles/:id/overview`,
  settingsIndex: `${rootPath}/settings`,
  approvalsShow: `${rootPath}/orders/:id/approvals`,
  userPreferences: `${rootPath}/user_preferences`,
  membershipCard: `${rootPath}/membership_card`,
  fleetEdit: `${rootPath}/fleets/:id/edit`,
  fleetNew: `${rootPath}/fleets/new`,
  privacyPolicy: `${rootPath}/privacy_policy`,
  maintenanceHistoryEdit: `${rootPath}/maintenance_history/:id`,
  vehicleShowMaintenanceHistory: `${rootPath}/vehicles/:id/maintenance_history`,
  vehicleShowMaintenanceSchedule: `${rootPath}/vehicles/:id/maintenance_schedule`,
  vehicleShowMaintenanceScheduleDetails: `${rootPath}/vehicles/:id/maintenance_schedule/:interval`,
  vehicleShowMaintenanceScheduleHistory: `${rootPath}/vehicles/:id/maintenance_schedule/history`,
  vehicleShowPolicy: `${rootPath}/vehicles/:id/policies`,
  suggestedMaintenanceDetails: `${rootPath}/maintenance_schedule_details/:id`,
  maintenanceHistoryDetails: `${rootPath}/vehicles/:id/history`,
  magicLink: `${rootPath}/magic_link`,
  reminders: `${rootPath}/reminders`,
  fmcReporting: `${rootPath}/fmc_reporting`,
  fleetReporting: `${rootPath}/fleet_reporting`
}
