import React from "react"
import { Button } from "semantic-ui-react"
import { useTranslation } from "react-i18next"
import { getVehicleClassfiedData } from "../../helpers/vehicleHelpers"
import { ACTIVE, DEACTIVE } from "../../constants/vehicles"

const BulkActivateDeactivate = ({ vehicles, handleVehicleActivation, isBulkApiInProgress }) => {
  const { t } = useTranslation("vehicle")

  const { selectedVehicles, activeVehicle, deactiveVehicle } = getVehicleClassfiedData(vehicles)

  return (
    <div className="bulk__active-deactive">
      <p className="vehicle__selected">
        {selectedVehicles.length} {t("selected")}
      </p>
      <Button
        onClick={() => handleVehicleActivation(ACTIVE)}
        disabled={deactiveVehicle.length === 0 || isBulkApiInProgress}
        className="vehicle__active"
      >
        {t("active")} ({deactiveVehicle.length})
      </Button>
      <Button
        onClick={() => handleVehicleActivation(DEACTIVE)}
        disabled={activeVehicle.length === 0 || isBulkApiInProgress}
        className="vehicle__deactive"
      >
        {t("deactivate")} ({activeVehicle.length})
      </Button>
    </div>
  )
}

export default BulkActivateDeactivate
