import React, { useState } from "react"
import { useTranslation, withTranslation } from "react-i18next"
import ReactSVG from "react-svg"
import CrossIcon from "../../assets/images/cross_icon.svg"
import PlayReport from "../../assets/images/play_report.svg"
import SquareEdit from "../../assets/images/edit_square.svg"
import SquareDelete from "../../assets/images/delete_square.svg"
import VectorSvg from "../../assets/images/vector.svg"
import TrashReportModal from "../../components/shared/TrashReportModal"
import moment from "moment"
import MiniLoader from "../../components/shared/MiniLoader"
import { Date_Format } from "../../constants/years"

const ReportHistoryModal = ({
  closeConfigureModal,
  filters,
  fetchData,
  reportEdit,
  loadingFilterRecords,
  clearFilters
}) => {
  const [modalOpen, setModalOpen] = useState(false)
  const [toRemoveFilter, setToRemoveFilter] = useState(null)
  const { t } = useTranslation("reports")

  const handleClose = () => {
    setModalOpen(false)
  }

  const openTraceModal = (filter) => {
    setModalOpen(true)
    setToRemoveFilter(filter)
  }

  return (
    <React.Fragment>
      <div className="reporting-history-section">
        <div className="reporting-header-section">
          <div className="reporting-header-content">
            <div className="reporting-header-frame">
              <span className="report-text">{t("savedHistoryHeaderLabel")}</span>
              <span className="note">
                {filters.length} {t("resultsLabel")}
              </span>
            </div>
          </div>
          <div className="cross-icon-section" onClick={() => closeConfigureModal()}>
            <ReactSVG src={CrossIcon} svgClassName="cross-icon" />
          </div>
        </div>
        {loadingFilterRecords && <MiniLoader inverted={false} />}
        {filters.map((filter) => (
          <>
            <div className="report-history-section">
              <div className="history-details">
                <span className="report-name">{filter.filter_name}</span>
                <div className="reports-other-details">
                  <span className="details-text">
                    {t("lastViewedLabel")}
                    {moment(filter.filters.viewed_at).format(Date_Format.MM_DD_YY)}
                  </span>
                  <span className="details-text">
                    {t("lastModifiedLabel")}
                    {moment(filter.filters.updated_at).format(Date_Format.MM_DD_YY)}
                  </span>
                </div>
              </div>
              <div className="history-actions">
                <div
                  className="icons hand-icon"
                  onClick={() => fetchData(1, filter.filters, filter)}
                >
                  <ReactSVG src={PlayReport} />
                </div>
                <div className="icons hand-icon" onClick={() => reportEdit(filter)}>
                  <ReactSVG src={SquareEdit} />
                </div>
                <div className="icons hand-icon" onClick={() => openTraceModal(filter)}>
                  <ReactSVG src={SquareDelete} />
                </div>
              </div>
            </div>
            <ReactSVG src={VectorSvg} />
          </>
        ))}
        <TrashReportModal
          modalOpen={modalOpen}
          handleClose={handleClose}
          filter={toRemoveFilter}
          clearFilters={clearFilters}
        />
      </div>
    </React.Fragment>
  )
}

export default withTranslation("reports")(ReportHistoryModal)
