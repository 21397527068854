export const initialState = {
  services: [],
  priceRanges: []
}

export default function (state = initialState, action) {
  // eslint-disable-next-line
  const { payload, type } = action

  switch (type) {
    case "PAST_SHOP_ORDERS_LOAD_SUCCEEDED":
      return {
        ...state,
        shopOrders: action.payload.shopOrders
      }
    case "ACTIVE_SHOP_ORDERS_LOAD_SUCCEEDED":
      return {
        ...state,
        activeShopOrders: action.payload.activeShopOrders
      }
    case "SHOP_ORDER_LOAD_SUCCEEDED":
      return {
        ...state,
        shopOrder: { ...action.payload.shopOrder, ...state.shopOrderDetails }
      }
    case "SHOP_ORDER_UNLOAD_SUCCEEDED":
      return {
        ...state,
        shopOrder: {}
      }
    case "SHOP_ORDER_LOAD_DETAILS_SUCCEEDED":
      return {
        ...state,
        shopOrder: { ...state.shopOrder, ...payload.shopOrder },
        shopOrderDetails: payload.shopOrder
      }
    case "SHOP_ORDER_PRICE_RANGES_LOAD_SUCCEEDED":
      return {
        ...state,
        priceRanges: action.payload.priceRanges
      }
    case "MAINTENANCE_UPDATE_SUCCEEDED":
      return {
        ...state,
        shopOrder: { ...state.shopOrder, ...payload.order }
      }
    case "ACTIVE_SHOP_ORDERS_UNLOAD_DATA":
      return {
        ...state,
        activeShopOrders: []
      }
    case "PAST_SHOP_ORDERS_UNLOAD_DATA":
      return {
        ...state,
        shopOrders: []
      }
    case "VANITY_LABEL_CREATE_OR_UPDATE_SUCCEEDED":
      const { order_service_id, vanity_label } = action.payload.vanity
      const orderServices =
        state.shopOrder &&
        state.shopOrder.order_services.map((os) =>
          os.id === order_service_id ? { ...os, vanity_label: vanity_label } : { ...os }
        )
      return {
        ...state,
        shopOrder: { ...state.shopOrder, order_services: orderServices }
      }

    default:
      return state
  }
}
