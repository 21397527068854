import React, { Component } from "react"
import { connect } from "react-redux"
import MagicLinkLockError from "../../../assets/images/magic-link-lock-error.svg"
import { Image } from "semantic-ui-react"
import { withTranslation } from "react-i18next"

class RenderOptions extends Component {
  constructor(props) {
    super(props)
    const { options } = this.props
    this.state = {
      activeOption: options && options.length === 1 ? options[0].name : options[0].name
    }
  }

  componentDidMount() {
    const { options, onOptionChange } = this.props

    if (options && options.length > 0) {
      const firstOption = options[0].name
      onOptionChange(firstOption)
    }
  }

  handleOptionChange = async (option) => {
    this.props.onOptionChange(option)
    await this.setState({ activeOption: option })
  }

  render() {
    const { options, buttonsContainer, onSubmit, t } = this.props
    const { activeOption } = this.state
    return (
      <div className="magic-link-options-container">
        <Image src={MagicLinkLockError} />
        <div className="magic-link-description">
          <div className="magic-link-header-text">{t("magicLinkHeaderText")}</div>
          <div className="magic-link-meta-text">{t("magicLinkMetaText")}</div>
        </div>
        <RenderMagicLinkOptions
          key="magic-link-option"
          activeOption={activeOption}
          onChange={this.handleOptionChange}
          options={options}
        />
        {buttonsContainer(onSubmit)}
      </div>
    )
  }
}

const RenderMagicLinkOptions = (props) => {
  const { options, activeOption, onChange } = props
  return (
    <div className="options-render-grid">
      {options &&
        options.map((option) => {
          return (
            <label
              key={option.name}
              className={`option-card ${option.name === activeOption ? "selected" : ""}`}
              onClick={() => option.name !== activeOption && onChange(option.name)}
            >
              <div className="options-item">
                <span className="options-details">
                  <Image src={option.icon} className="option-icon" />
                  <span className="option-label">{option.label}</span>
                </span>
                <input
                  name="name"
                  className="option-radio"
                  type="radio"
                  checked={option.name === activeOption}
                  readOnly
                />
              </div>
              {activeOption === option.name && option.component()}
            </label>
          )
        })}
    </div>
  )
}

function mapStateToProps(state) {
  let user = state.user || {}
  return {
    user,
    isLoggedIn: !!user.authentication_token
  }
}

export default connect(mapStateToProps, {})(withTranslation("magicLink")(RenderOptions))
