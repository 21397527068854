export const SUCCESS = "success"
export const DANGER = "danger"
export const WARNING = "warning"
export const DEFAULT = "default"
export const INFO = "info"
export const AWEWSOM = "awesome"
export const ERROR = "error"
export const FAILURE = "failure"
export const NOTICE = "notice"

export const NOTIFICATION_TYPE = {
  SUCCESS,
  DANGER,
  WARNING,
  DEFAULT,
  INFO,
  AWEWSOM,
  ERROR
}
