import React from "react"
import { isFleetAdvise } from "../../helpers/affiliationHelpers"
import { Popup } from "semantic-ui-react"
import NavbarItem from "./NavbarItem"

function NavbarContent({ item, activeColor, inactiveColor }) {
  return isFleetAdvise() ? (
    <Popup
      trigger={
        <div className={`${item.activeFunc ? "active-navbar-tab" : ""}`}>
          <NavbarItem item={item} activeColor={activeColor} inactiveColor={inactiveColor} />
        </div>
      }
      content={item.content}
      position="right center"
      className="navbar-popup"
    />
  ) : (
    <NavbarItem item={item} activeColor={activeColor} inactiveColor={inactiveColor} />
  )
}

export default NavbarContent
