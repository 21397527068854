import { takeLatest, call, put } from "redux-saga/effects"
import Frisbee from "frisbee"

export const GET_EVENT_NAMES = "GET_EVENT_NAMES"
export const SET_EVENT_NAMES = "SET_EVENT_NAMES"

const S3_PATH = "https://s3.amazonaws.com/assets.caradvise.com/"

const api = new Frisbee({
  baseURI: "https://s3.amazonaws.com/assets.caradvise.com/"
})

export function getEventNames() {
  return {
    type: GET_EVENT_NAMES
  }
}

function setEventName(payload) {
  return {
    type: SET_EVENT_NAMES,
    payload
  }
}

// Saga Worker

function* fetchEventNames() {
  const FILE_LOCATION = `segment_events/segment-events-combined.json`

  try {
    // Use call effect to invoke the async function and handle promises correctly
    const response = yield call(api.get, FILE_LOCATION)
    if (response && response.body) {
      yield put(setEventName(response.body))
    }
  } catch (error) {
    // Handle error if necessary
    console.error("Error fetching event names:", error)
  }
}

// Saga Watcher

export default function* segment() {
  yield takeLatest(GET_EVENT_NAMES, fetchEventNames)
}
