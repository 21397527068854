import React from "react"
import { withTranslation } from "react-i18next"
import { Image } from "semantic-ui-react"
import NoVehicleImage from "../../assets/images/no-vehicle-img.png"

const FleetVehicles = ({ vehicle, t }) => {
  const { vin, year, make, model, image_url_mobile } = vehicle

  return (
    <div className="fleet_vehicle">
      <div>
        <p className="fleet_vehicle_YMM">
          {year} {make} {model}
        </p>
        <p className="fleet_vehicle_vin">
          <span>{t("vin")}:</span> {vin || t("noVinRecorded")}
        </p>
      </div>
      <Image className="fleet_vehicle_img" src={image_url_mobile || NoVehicleImage} />
    </div>
  )
}

export default withTranslation("membershipFleetCard")(FleetVehicles)
