import React from "react"
import { Country, State } from "country-state-city"

const useCountryStateCity = () => {
  const COUNTRIES = Country.getAllCountries()

  const getContries = () => {
    return COUNTRIES
  }

  const getStateByCountry = (countryCode) => {
    return State.getStatesOfCountry(countryCode)
  }

  const getCountriesDropdown = () => {
    return COUNTRIES && COUNTRIES.length > 0
      ? COUNTRIES.map((country) => {
          return {
            key: country.name,
            value: country.isoCode,
            text: country.name
          }
        })
      : []
  }

  const getStateByCountryDropdown = (country) => {
    const STATES = getStateByCountry(country)

    return STATES && STATES.length > 0
      ? STATES.map((state) => {
          return {
            key: state.name,
            value: state.name,
            text: state.name
          }
        })
      : []
  }

  return {
    getContries,
    getStateByCountryDropdown,
    getStateByCountry,
    getCountriesDropdown,
    COUNTRIES
  }
}

export default useCountryStateCity
