import React from "react"
import { store } from "react-notifications-component"
import ReactSVG from "react-svg"
import { NOTIFICATION_TYPE } from "../../constants/notification"
import { isFleetAdvise } from "../../helpers/affiliationHelpers"
import exclamation_circle from "../../assets/images/exclamation_circle.svg"
import check_circle from "../../assets/images/check_circle.svg"
import delete_circle from "../../assets/images/delete_circle.svg"
import warning_exclamation_circle from "../../assets/images/warning_exclamation_circle.svg"
import info_icon from "../../assets/images/info_icon.svg"

export default function CustomNotification(type = "", title = "", message = "", t = {}) {
  if (type) {
    store.addNotification(
      Object.assign({
        insert: "top",
        container: "top-right", //do not change container
        animationIn: ["animate__animated", "animate__fadeIn"],
        animationOut: ["animate__animated", "animate__fadeOut"],
        dismiss: {
          duration: 3000,
          onScreen: false,
          showIcon: true
        },
        content: CustomContentRenderer
      })
    )
  }

  function CustomContentRenderer() {
    let icon
    let color
    let backgroundColor
    let borderRadius
    let logoSize = fleetAdvise ? 40 : 50
    const fleetAdvise = isFleetAdvise()
    if (type === NOTIFICATION_TYPE.DEFAULT || type === NOTIFICATION_TYPE.SUCCESS) {
      icon = check_circle
      if (fleetAdvise) {
        color = "#fff"
        backgroundColor = "#047857"
        borderRadius = "50%"
      } else {
        color = type === NOTIFICATION_TYPE.DEFAULT ? "#5458F7" : "#84D65A"
      }
    }
    if (type === NOTIFICATION_TYPE.DANGER || type === NOTIFICATION_TYPE.ERROR) {
      icon = title === "Error" || title === "Erreur" ? exclamation_circle : delete_circle
      if (fleetAdvise) {
        color = "#fff"
        backgroundColor = icon === exclamation_circle ? "#ffff" : "#DC2626"
        borderRadius = "50%"
      } else {
        color = "#EF665B"
      }
    }
    if (type === NOTIFICATION_TYPE.WARNING && fleetAdvise) {
      icon = warning_exclamation_circle
      backgroundColor = "#fff"
      borderRadius = "50%"
    }
    if (type === NOTIFICATION_TYPE.INFO && fleetAdvise) {
      icon = info_icon
    }
    const svgStyle = {
      width: logoSize,
      height: logoSize,
      color: color,
      backgroundColor: backgroundColor && backgroundColor,
      borderRadius: borderRadius && borderRadius
    }
    return (
      <div
        className={`notification__custom--${type} ${
          isFleetAdvise ? `notification-border--${type}` : ""
        }`}
      >
        <div className={`notification__custom-icon ${fleetAdvise ? "border-unset" : ""}`}>
          <ReactSVG svgStyle={svgStyle} src={icon} />
        </div>
        <div className="notification__custom">
          <div className="rnc__notification-close-mark"></div>
          <p className="rnc__notification-title">{title && title}</p>
          <p className="rnc__notification-message">{message}</p>
        </div>
      </div>
    )
  }
}
