import React, { useState } from "react"
import { Button, Image, Input, Label, Modal } from "semantic-ui-react"
import AchModalCloseIcon from "../../assets/images/achModalClose.svg"
import { withTranslation } from "react-i18next"
import AchVerificationIcon from "../../assets/images/ach-verification.svg"
import AchVerificationSuccessIcon from "../../assets/images/ach-verification-success.svg"

const ACHVerificationModal = ({ isACHVerificationModalOpen, closeModal, t }) => {
  const [verificationForm, setVerificationForm] = useState({
    depositeAmount1: {
      type: "text",
      label: t("depositAmount1"),
      placeholder: t("depositeAmountPlaceholder"),
      value: "",
      isError: false,
      error: t("depositeAmount1Error")
    },
    depositeAmount2: {
      type: "text",
      label: t("depositeAmount2"),
      placeholder: t("depositeAmount2Placeholder"),
      value: "",
      isError: false,
      error: t("depositeAmount2Error")
    }
  })
  const [isInProgress, setIsInProgress] = useState(false)
  const [isError, setIsError] = useState(false)
  const [isSuccess, setIsSuccess] = useState(false)
  const [isVerificatinoExpire, setIsVerificationExpire] = useState(true)

  const handleVerificationForm = (e, field) => {
    const localForm = { ...verificationForm }

    localForm[field] = {
      ...localForm[field],
      value: e.target.value,
      isError: !e.target.value
    }

    setVerificationForm(localForm)
  }

  const submitVerificationForm = () => {
    const updatedForm = {}
    let hasError = false

    Object.keys(verificationForm).forEach((fieldName) => {
      updatedForm[fieldName] = { ...verificationForm[fieldName] }
      if (!updatedForm[fieldName].value.trim()) {
        updatedForm[fieldName].isError = true
        hasError = true
      } else {
        updatedForm[fieldName].isError = false
      }
    })

    setVerificationForm(updatedForm)

    if (hasError) return
    else {
      setIsInProgress(true)
      setTimeout(() => {
        setIsInProgress(false)
        setIsSuccess(true)
      }, 2500)
    }
  }

  return (
    <Modal className="ach_verification" size="tiny" open={isACHVerificationModalOpen}>
      {!isInProgress ? (
        <>
          {" "}
          <div className="ach_verification__header">
            <p className="verification_subheader">{t("verificationNeeded")}</p>
            <Image className="closeLogo" onClick={closeModal} src={AchModalCloseIcon} />
          </div>
          <p className="ach_verification__note">{t("secureAccount")} </p>
          {isError && (
            <div className="verification_failed">
              <Image className="verificationLogo" src={AchVerificationIcon} />
              <div>
                <p className="ach_failed__title">{t("verificationFailed")}</p>
                <p className="ach_failed__note">{t("amountMatchDeposit")}</p>
              </div>
            </div>
          )}
          {isSuccess && (
            <div className="verification_success">
              <Image className="verificationLogo" src={AchVerificationSuccessIcon} />
              <p className="verification_success__note">{t("newVerificationSent")}</p>
            </div>
          )}
          {isVerificatinoExpire && (
            <div className="verification_expires">
              <Image className="verificationLogo" src={AchVerificationIcon} />
              <div>
                <p className="verification_expired__header">{t("expiredVerification")}</p>
                <p className="verification_expired__note">{t("depositeReturnToAccount")}</p>
              </div>
            </div>
          )}
          {!isVerificatinoExpire &&
            Object.entries(verificationForm).map(([field, fieldData]) => (
              <div className="ach_verification__form">
                <p className="ach_verification__label">{fieldData.label}</p>
                <Input
                  className="ach_verification__input"
                  placeholder={fieldData.placeholder}
                  value={fieldData.value}
                  onChange={(e) => handleVerificationForm(e, field)}
                />
                {fieldData.isError && (
                  <Label className="ach_verification__error" pointing>
                    {fieldData.error}
                  </Label>
                )}
              </div>
            ))}
          <Button
            disabled={isInProgress}
            onClick={() => (isVerificatinoExpire ? () => {} : submitVerificationForm())}
            className="ach_verification__button"
          >
            {isVerificatinoExpire ? t("retryVerification") : t("verifyAccount")}
          </Button>
        </>
      ) : (
        <div className="ach_loader">
          <div className="loader"></div>
          <p className="please_hold">{t("holdWhileVerify")}</p>
        </div>
      )}
    </Modal>
  )
}

export default withTranslation("achPayment")(ACHVerificationModal)
