import fleetAdviseStyles from "../../assets/stylesheets/fleetadvise.scss"
import fleetAmericaStyles from "../../assets/stylesheets/fleetamerica.scss"
import azugaStyles from "../../assets/stylesheets/azuga.scss"
import zubieStyles from "../../assets/stylesheets/zubie.scss"
import fleetMaintenanceHubStyles from "../../assets/stylesheets/shell.scss"
import pepboysStyles from "../../assets/stylesheets/pepboys.scss"
import carvanaStyles from "../../assets/stylesheets/carvana.scss"
import shellCanadaStyles from "../../assets/stylesheets/shellcanada.scss"
import fleetcorStyles from "../../assets/stylesheets/fleetcor.scss"
import ntwTBCStyles from "../../assets/stylesheets/ntw.scss"
import discountTireStyles from "../../assets/stylesheets/discounttire.scss"

import {
  DISCOUNT_TIRE,
  FLEET_MAINTENANCE_HUB,
  FLEET_MAINTENANCE_HUB_CANADA,
  FLEETCOR,
  NTWTBC
} from "../../constants/application"

const styles = {
  fleetadvise: fleetAdviseStyles,
  fleetamerica: fleetAmericaStyles,
  azuga: azugaStyles,
  zubie: zubieStyles,
  shell: fleetMaintenanceHubStyles,
  shellcanada: shellCanadaStyles,
  carvana: carvanaStyles,
  pepboys: pepboysStyles,
  fleetcor: fleetcorStyles,
  fuelman: fleetcorStyles,
  ntw: ntwTBCStyles,
  discounttire: discountTireStyles
}

const loadStylesheet = (url, callback) => {
  let sheet = document.createElement("link")
  sheet.rel = "stylesheet"
  sheet.href = url
  sheet.type = "text/css"
  if (callback) sheet.onload = () => callback()
  document.head.appendChild(sheet)
}

export function getSubdomain(host) {
  if (!host) {
    host = window.location.hostname
  }
  if (host.match(/fleetmainhub/) || host.match(/fleetmaintenancehubca/)) {
    return FLEET_MAINTENANCE_HUB_CANADA
  } else if (host.match(/fleetmaintenancehub/)) {
    return FLEET_MAINTENANCE_HUB
  } else if (host.match(/fuelmanmaintenance/)) {
    return FLEETCOR
  } else if (host.match(/ntwfleet/)) {
    return NTWTBC
  } else if (host.match(/dtcafleet/)) {
    return DISCOUNT_TIRE
  }

  let firstPeriod = host.indexOf(".")
  let subdomain

  if (firstPeriod !== -1) subdomain = host.substring(0, firstPeriod).toLowerCase()
  else subdomain = host

  if (subdomain === "www" || subdomain === "localhost" || subdomain === "app") {
    subdomain = "fleetadvise"
  }
  return subdomain
}

export default function StylesheetInjector() {
  let subdomain = getSubdomain()

  let styleSheet = `${process.env.PUBLIC_URL}/semantic.min.css`
  const styles = getStyles()

  loadStylesheet(styleSheet, () =>
    loadStylesheet(`${process.env.PUBLIC_URL}/${subdomain}.css`, () => {
      document.querySelector("#splash").style.display = "none"
      let root = document.querySelector("#root")
      root.style.display = "block"
      root.style.fontFamily = styles.fontFamily
    })
  )
}

export function getStyles() {
  let subdomain = getSubdomain()
  return styles[subdomain]
}
