import React, { useRef, useState, useEffect } from "react"
import { withTranslation } from "react-i18next"
import { ShimmerThumbnail } from "react-shimmer-effects"
import { Grid, Image, Input, Segment } from "semantic-ui-react"
import MembershipDownload from "../../assets/images/membership-download.svg"
import MembershipInfoLogo from "../../assets/images/membership-info.svg"
import MembershipShops from "../../assets/images/membership_shops.svg"
import Button from "../../elements/Button"
import FleetCard from "./FleetCard"
import FleetVehicles from "./FleetVehicles"
import NoVehicleLogo from "../../assets/images/no_vehicles.svg"
import { FLEET_ADVISE, PAGE_ORIANTATION, PRINT, TEXT_CSS } from "../../constants/membershipCard"
import { getSubdomain } from "../../components/shared/StylesheetInjector"
import { isFuelman } from "../../helpers/affiliationHelpers"
import ReactSVG from "react-svg"
import { logClickEvent } from "../../helpers/googleAnalyticsHelpers"
import { DISCOUNT_TIRE, VIN } from "../../constants/application"
import { debounce } from "lodash"
import * as arrayHelpers from "../../helpers/arrayHelpers"
// import DiscountTireLogo from "../../assets/images/discountTire_logo.svg"
import { connect } from "react-redux"
import { NOTIFICATION_TYPE } from "../../constants/notification"
import CustomNotification from "../../components/shared/CustomNotification"

const MembershipCard = ({
  membershipName,
  driverName,
  membershipLogo,
  membershipAccount,
  membershipBarcode,
  helpCenterName,
  worldwideStores,
  phone,
  membershipIntegrate,
  sectionSubnote,
  fleetVehicles,
  selectedVehicle,
  getSelectedVehicle,
  isLoading,
  t,
  isFMCMode,
  isFleetMode,
  dispatch,
  fmcVehicles
}) => {
  const { vin, year, make, model } = selectedVehicle
  const cardRef = useRef(null)
  const [isPdfLoading, setIsPdfLoading] = useState(false)
  const [quickFilterText, setQuickFilterText] = useState(undefined)
  const [filteredVehicles, setFilteredVehicles] = useState(fleetVehicles)
  const [showFmcCard, setshowFmcCard] = useState(false)
  const [selectedOption, setSelectedOption] = useState(VIN)
  const [search_text, setSearch_text] = useState("")
  const isVehicleAvailable = isFMCMode ? false : fleetVehicles && fleetVehicles.length > 0
  const debounceSearch = useRef(debounce((search_text) => searchVehicle(search_text), 1000)).current

  useEffect(() => {
    setFilteredVehicles(fleetVehicles)
    if (isFMCMode) debounceSearch(search_text)
  }, [search_text, fleetVehicles, debounceSearch])

  const filterVehicles = (value) => {
    const regexp = new RegExp(value, "i")
    if (!value) {
      return fleetVehicles
    }

    const filteredVehicles = fleetVehicles.flatMap((vehicle) => {
      const vehicleMatches = Object.values(vehicle || {}).some(
        (value) => value && value.match && value.match(regexp)
      )
      return vehicleMatches ? [vehicle] : []
    })
    return arrayHelpers.uniq(filteredVehicles)
  }

  const downloadCard = () => {
    let css = PAGE_ORIANTATION,
      head = document.head || document.getElementsByTagName("head")[0],
      style = document.createElement("style")

    style.type = TEXT_CSS
    style.media = PRINT

    if (style.styleSheet) {
      style.styleSheet.cssText = css
    } else {
      style.appendChild(document.createTextNode(css))
    }

    head.appendChild(style)
    logClickEvent(window.location.pathname)
    window.print()
  }

  let sub = getSubdomain()

  let size = 90
  if (isFuelman()) size = 100
  const extraStyles = { backgroundColor: "#e3eae6", borderRadius: "0 0 0 6px", height: "9vh" }

  const updateQuickFilterText = (event) => {
    setQuickFilterText((event.target.value || [])[0])
    setFilteredVehicles(filterVehicles(event.target.value))
  }

  const onTextChange = (event) => {
    setSearch_text(event.target.value)
  }

  const handleOptionChange = (event) => {
    setSelectedOption(event.target.value)
  }

  const loadAllVehicles = async (payload) => {
    await dispatch({
      type: "VEHICLES_LOAD_SAGA",
      payload: payload,
      callback: afterFmcSearchVehicle
    })
  }

  const afterFmcSearchVehicle = (response) => {
    if (response.vehicles && response.vehicles.length > 0) {
      setshowFmcCard(true)
      getSelectedVehicle(response.vehicles[0])
      setFilteredVehicles(response.vehicles)
    } else {
      setshowFmcCard(false)
    }
  }

  const searchVehicle = (search_text) => {
    if (search_text.trim().length == 17) {
      loadAllVehicles({ page: 1, query: search_text, search_type: VIN, fmc: true })
    } else if (search_text == "") {
      setFilteredVehicles([])
      setshowFmcCard(false)
    } else {
      setshowFmcCard(false)
      return CustomNotification(NOTIFICATION_TYPE.DANGER, "", t("fleets:invalidSearchValue"), t)
    }
  }

  return (
    <div className="membership_parent">
      <div className="membership_container">
        <div className="membership_cars">
          <p className="membership_box_title">{t("membershipCard")}</p>
          {isVehicleAvailable && (
            <p className="membership_box_subheader">
              {!isFMCMode ? (
                <>
                  <span>{membershipName}</span> {sectionSubnote}{" "}
                </>
              ) : (
                <></>
              )}
            </p>
          )}
          {isLoading ? (
            <Grid.Column>
              <ShimmerThumbnail className="shimmer_squere" rounded height={90} />
            </Grid.Column>
          ) : isVehicleAvailable || isFMCMode ? (
            <>
              <div className="membership_info">
                <Image src={MembershipInfoLogo} />
                <p className="membership_help">
                  {t("identifyWithCard")} {phone}.
                </p>
              </div>
              {isFMCMode ? (
                <Grid.Column width={7}>
                  <Grid columns="equal" stackable>
                    <Grid.Column width={7}>
                      <div className="search-input-parent">
                        <div className="search-input-box-vin">
                          <Input
                            className="search search-fleet"
                            icon="search"
                            onChange={onTextChange}
                            placeholder={t("searchVehiclesByVINLabel")}
                            value={search_text}
                          />
                        </div>
                      </div>
                    </Grid.Column>
                  </Grid>
                </Grid.Column>
              ) : (
                <Input
                  className="search search-fleet"
                  icon="search"
                  onChange={updateQuickFilterText}
                  placeholder={t("searchVehiclesLabel")}
                />
              )}
            </>
          ) : (
            <>
              <div className="fleet_no_vehicle_img">
                <Image src={NoVehicleLogo} className="" />
              </div>
              <p className="fleet_no_vehicle_text">{t("addVehicleToGenerateCard")}</p>
            </>
          )}
          {isLoading ? (
            <>
              {[...new Array(5)].map(() => (
                <Grid.Column>
                  <ShimmerThumbnail className="shimmer_squere" rounded height={70} />
                </Grid.Column>
              ))}
            </>
          ) : (
            (isVehicleAvailable || showFmcCard) && (
              <div className="fleet_vehicles">
                <div className="fleet_vehicle_container">
                  {(isVehicleAvailable || showFmcCard) &&
                    filteredVehicles &&
                    filteredVehicles.map((vehicle) => (
                      <div
                        key={vehicle.id}
                        className={selectedVehicle.id === vehicle.id ? "bg_grey" : ""}
                        onClick={() => getSelectedVehicle(vehicle)}
                      >
                        <FleetVehicles vehicle={vehicle} />
                      </div>
                    ))}
                </div>
              </div>
            )
          )}
        </div>
        <div
          className={`${
            !(isVehicleAvailable || (isFMCMode && showFmcCard)) && !isLoading ? "custom_bg" : ""
          } membership_box`}
        >
          {isLoading ? (
            <Grid.Column>
              <ShimmerThumbnail className="card_shimmer_squere" rounded height={550} />
            </Grid.Column>
          ) : isVehicleAvailable || showFmcCard ? (
            <FleetCard
              cardRef={cardRef}
              membershipName={membershipName}
              driverName={driverName}
              membershipLogo={membershipLogo}
              membershipAccount={membershipAccount}
              year={year}
              make={make}
              model={model}
              vin={vin}
              membershipBarcode={membershipBarcode}
              membershipIntegrate={membershipIntegrate}
              worldwideStores={worldwideStores}
              MembershipShops={MembershipShops}
              phone={phone}
              helpCenterName={helpCenterName}
            />
          ) : !isVehicleAvailable || (isFMCMode && !showFmcCard) ? (
            <>
              <div className="fleet_no_vehicle_box">
                {sub === FLEET_ADVISE ? (
                  <Image src={membershipLogo} className="membership_logo" />
                ) : (
                  <ReactSVG
                    src={sub === DISCOUNT_TIRE ? "DiscountTireLogo" : `/images/${sub}_logo.svg`}
                    svgStyle={{
                      ...extraStyles,
                      width: size
                    }}
                  />
                )}
              </div>
              <p className="no_membership_info">{t("noMembershipInfo")}</p>
            </>
          ) : (
            <></>
          )}
          {isLoading ? (
            <Grid.Column>
              <ShimmerThumbnail className="shimmer_squere" rounded height={40} />
            </Grid.Column>
          ) : (
            (isVehicleAvailable || (isFMCMode && showFmcCard)) && (
              <Button
                className="membership_download"
                onClick={downloadCard}
                loading={isPdfLoading}
                disabled={isPdfLoading}
                label={
                  <div className="btn_label">
                    <Image src={MembershipDownload} />
                    <p>{t("download")}</p>
                  </div>
                }
              />
            )
          )}
        </div>
      </div>
    </div>
  )
}

const mapStateToProps = (state) => {
  return {
    fmcVehicles: state.vehicles.vehicles.vehicles
  }
}

export default connect(mapStateToProps)(withTranslation("membershipFleetCard")(MembershipCard))
