import { takeLatest, call } from "redux-saga/effects"
import api from "../../utilities/api"
import * as apiErrorHelpers from "../../helpers/apiErrorHelpers"
import * as sessionHelpers from "../../helpers/sessionHelpers"
import { AUTHENTICATION_TOKEN } from "../../constants/application"
import { SUCCESS } from "../../constants/notification"

export const FECTH_SFO_TRACK = "FETCH_SFO_TRACK"
export const IS_SFO_SKIPPED = "IS_SFO_SKIPPED"
export const ONBOARDIN_RESOURCES = "/onboarding_resources"
export const FLEETS = "/fleets"

export function* fetchSfoTrackingStep(action) {
  try {
    api.setAuthApi({
      Authorization: sessionHelpers.getApplicationCookie(AUTHENTICATION_TOKEN)
    })

    const request = {
      url: ONBOARDIN_RESOURCES
    }

    const shopOrdersResponse = yield call(api.utility.get, api.path(request.url))
    if (action.callback) yield call(action.callback, SUCCESS, shopOrdersResponse.body)

    apiErrorHelpers.catchErrors(shopOrdersResponse.body)

    return shopOrdersResponse.body
  } catch (errors) {
    // eslint-disable-next-line
    errors = apiErrorHelpers.formatInternalErrors(errors)
    apiErrorHelpers.consoleLog(errors)
  }
}

export function* isSfoOnboardingSkipped(action) {
  try {
    api.setAuthApi({
      Authorization: sessionHelpers.getApplicationCookie(AUTHENTICATION_TOKEN)
    })

    const request = {
      url: `${FLEETS}/${action.payload.fleetId}`
    }

    const sfoBoardingResponse = yield call(api.utility.get, api.pathV6(request.url))
    if (action.callback) yield call(action.callback, SUCCESS, sfoBoardingResponse.body)

    apiErrorHelpers.catchErrors(sfoBoardingResponse.body)

    return sfoBoardingResponse.body
  } catch (errors) {
    // eslint-disable-next-line
    errors = apiErrorHelpers.formatInternalErrors(errors)
    apiErrorHelpers.consoleLog(errors)
  }
}

// Saga Watcher
export default function* sfo() {
  yield takeLatest(FECTH_SFO_TRACK, fetchSfoTrackingStep)
  yield takeLatest(IS_SFO_SKIPPED, isSfoOnboardingSkipped)
}
