import React, { Component } from "react"
import PropTypes from "prop-types"
import { connect } from "react-redux"
import Alert from "../../components/shared/Alert"
import ApplicationLayout from "../../layouts/ApplicationLayout"
import { Grid, Image } from "semantic-ui-react"
import OrderStatusWidget from "../../components/dashboard/OrderStatusWidget"
import MaintenanceWidget from "../../components/dashboard/MaintenanceWidget"
import VehiclesWidget from "../../components/dashboard/VehiclesWidget"
import ServiceCostsWidget from "../../components/dashboard/ServiceCostsWidget"
import MileageCostsWidget from "../../components/dashboard/MileageCostsWidget"
import ScheduleServiceButton from "../../components/shared/ScheduleServiceButton"
import { navigationPaths } from "../../constants/paths"
import { withTranslation } from "react-i18next"
import { getSubdomain } from "../../components/shared/StylesheetInjector"
import { isFmcPath } from "../../helpers/pathHelpers"
import { isFMCUser } from "../../helpers/userRoleHelpers"
import { ShimmerThumbnail } from "react-shimmer-effects"
import { FMC, FMC_FLEET_ADMIN, FMC_FLEET_MANAGER } from "../../constants/roles"
import { FLEET } from "../../constants/application"
import { logFirebaseAnalyticsScreenName } from "../../helpers/googleAnalyticsHelpers"
import { getRouteNameFromUrl } from "../../helpers/segmentHelpers"
import { setupSplitFlags } from "../../components/config/SplitClient"
import { isEmpty } from "lodash"
import DownloadAppModal from "./DownloadAppModal"
import { checkSubDomain } from "../../constants/downloadApp"
import SfoTracking from "../SFO/SfoTracking"
import { isFleetcor } from "../../helpers/affiliationHelpers"

class Dashboard extends Component {
  // NOTE: The submission/cancelation logic for "Active Maintenance" approvals in this component is mirrored in /views/dashboard/active_maintenance_index.js (The "View All")

  static propTypes = {
    isLoading: PropTypes.bool.isRequired,
    isLoadingError: PropTypes.bool.isRequired,
    users: PropTypes.array.isRequired,
    vehicles: PropTypes.array.isRequired
  }

  constructor(props) {
    super(props)

    // NOTE: locationStateData is instantiated as a variable here rather than referencing this.props.location.state.someObj directly because the state key may not be present when navigating to this page from a <Link> that does not explicitly set it.
    const locationStateData = this.props.location.state || {}

    this.state = {
      alert: locationStateData.alert || "",
      alertType: locationStateData.alertType || "default",
      isSubmitting: false
    }

    const params = window.location.href.match(/key=([\w\d-.]+)?&?/)
    const key = (params && params[1]) || locationStateData.key
    const { dispatch, currentUser, t } = this.props

    if (key) {
      dispatch({
        type: "SIGN_OUT_SAGA",
        payload: { currentUser: currentUser },
        callback: this.onSignOutSuccess(key),
        t: t
      })
    }
  }

  onSignOutSuccess = (key) => {
    this.state.isSubmitting = true
    this.onFormSubmit({ key })
  }

  onFormSubmit(formData) {
    const { dispatch } = this.props
    this.setState({ isSubmitting: true })
    dispatch({
      type: "SIGN_IN_SAGA",
      payload: { formData, t: this.props.t, subdomain: getSubdomain() },
      callback: this.afterFormSubmit.bind(this)
    })
  }

  async afterFormSubmit(data) {
    const params =
      this.props.location.state &&
      this.props.location.state.url.match(/error_message=([\w\d-]+)?&?/)
    if (params) {
      window.location.href = this.props.location.state.url
    } else {
      await this.props.dispatch({
        type: "CURRENT_USER_LOAD_SAGA",
        callback: this.onFormSubmitSuccess.bind(this)
      })
    }
  }

  onFormSubmitSuccess(status, data) {
    this.props.dispatch({
      type: "FLEET_LOAD_SAGA",
      payload: { fleetId: this.props.currentUser.fleet_id }
    })
    if ([FMC, FMC_FLEET_ADMIN, FMC_FLEET_MANAGER].some((role) => data.roles.includes(role))) {
      if (this.props.isFleetMode) {
        this.props.dispatch({
          type: "FMC_FLIP_SAGA",
          callback: null
        })
      }
      this.props.history.push({ pathname: navigationPaths.fmcDashboard() })
    } else {
      if (!this.props.isFleetMode) {
        this.props.dispatch({
          type: "FMC_FLIP_SAGA",
          callback: null
        })
      }
      this.fetchDashboardData()
      this.props.history.push({ pathname: navigationPaths.dashboard() })
    }
    this.setState({ isSubmitting: false })
  }

  async componentDidMount() {
    await setupSplitFlags.bind(this)({ sub_domain: getSubdomain() })
    logFirebaseAnalyticsScreenName(getRouteNameFromUrl())
    // TODO figure out a better way to do this, for some reason hitting back
    // react-router lets the thing render before set loading event fires in Dashboard saga
    // and explosions happen
    const { isFleetMode } = this.props
    this.loading = true
    if (isFleetMode) this.fetchDashboardData()
    this.loading = false
  }

  afterSubmit(status, data) {
    this.setState({ isSubmitting: false })

    if (status === "success") {
      this.onSubmitSuccess(data)
    } else {
      this.onSubmitFailure(data)
    }
  }

  fetchDashboardData() {
    this.getVehicleData()
    this.getOrderSummary()
    this.getServicesData()
    this.getBasicVehicles()
  }

  getVehicleData() {
    this.props.dispatch({
      type: "LOAD_VEHICLE_STATE_SUMMARY"
    })
  }

  getServicesData() {
    this.props.dispatch({
      type: "SERVICE_REQUEST_ORDERS_AGGREGATE"
    })
  }

  getOrderSummary() {
    this.props.dispatch({
      type: "LOAD_ORDER_SUMMARY"
    })
  }

  getBasicVehicles() {
    this.props.dispatch({
      type: "VEHICLES_BASIC_LOAD_SAGA"
    })
  }

  locationStateData() {
    return this.props.location.state || {}
  }

  onSubmitFailure(data) {
    this.setState({
      alert: data.alert,
      alertType: data.alertType
    })
  }

  onSubmitSuccess(data) {
    this.setState({
      alert: data.alert,
      alertType: data.alertType
    })

    // NOTE: Refreshes "Active Maintenance" data.
    this.fetchDashboardData()
  }

  navigateToVehicle = (vehicle) => {
    this.props.history.push({
      pathname: navigationPaths.vehicleShow(vehicle.id)
    })
  }

  navigateToServicesByMonth = (data) => {
    this.props.history.push({
      pathname: navigationPaths.servicesIndex(),
      state: {
        startingPage: "past",
        startingDate: data.date
      }
    })
  }

  shouldRenderContent() {
    const { isLoading, isLoadingError, vehicles, users, serviceCosts } = this.props

    return (
      !isLoading &&
      !isLoadingError &&
      !this.anyArrayNotPresent([vehicles, users, serviceCosts]) &&
      !this.loading
    )
  }

  anyArrayNotPresent = (arrs) => arrs.filter((arr) => arr === undefined || arr === null).length > 0

  renderContent() {
    const {
      vehicles,
      serviceCosts,
      basicVehicles,
      language,
      orderSummary,
      pastDueVehicleIds,
      dueSoonVehicleIds,
      currentUser
    } = this.props

    return (
      <>
        {!isEmpty(currentUser) && isFleetcor() && (
          <Grid stackable className="sfo-track">
            <Grid.Row stretched verticalAlign="">
              <Grid.Column>
                <SfoTracking />
              </Grid.Column>
            </Grid.Row>
          </Grid>
        )}
        <Grid columns={2} stackable className="dashboard">
          <Grid.Row
            verticalAlign="center"
            style={{
              marginBottom: "0",
              paddingBottom: "0",
              flexDirection: "row-reverse"
            }}
          >
            <Grid.Column>
              <ScheduleServiceButton floated="right" />
            </Grid.Column>
          </Grid.Row>
          <Grid.Row style={{ paddingTop: "0" }}>
            <Grid.Column width={10}>
              <Grid columns="equal" stackable style={{ flexDirection: "column" }}>
                <Grid.Row stretched>
                  <Grid.Column className="dashboard-cols">
                    {orderSummary ? (
                      <OrderStatusWidget
                        active={orderSummary.active_orders}
                        awaiting={orderSummary.need_approval}
                        approved={orderSummary.approved}
                      />
                    ) : (
                      <ShimmerThumbnail />
                    )}
                  </Grid.Column>
                  <Grid.Column className="dashboard-cols">
                    <MaintenanceWidget
                      totalVehicle={basicVehicles.length}
                      pastDueVehicleIds={pastDueVehicleIds}
                      dueSoonVehicleIds={dueSoonVehicleIds}
                    />
                  </Grid.Column>
                </Grid.Row>
                <Grid.Row stretched>
                  <Grid.Column className="dashboard-cols">
                    {serviceCosts ? (
                      <ServiceCostsWidget
                        costs={serviceCosts}
                        navigateToServicesByMonth={this.navigateToServicesByMonth}
                        language={language}
                      />
                    ) : (
                      <ShimmerThumbnail />
                    )}
                  </Grid.Column>
                  <Grid.Column className="dashboard-cols">
                    {basicVehicles ? (
                      <MileageCostsWidget
                        vehicles={basicVehicles}
                        navigateTo={this.navigateToVehicle}
                        language={language}
                      />
                    ) : (
                      <ShimmerThumbnail />
                    )}
                  </Grid.Column>
                </Grid.Row>
              </Grid>
            </Grid.Column>
            <Grid.Column width={6}>
              {vehicles ? (
                <VehiclesWidget
                  vehicles={vehicles}
                  pastDueVehicleIds={pastDueVehicleIds}
                  dueSoonVehicleIds={dueSoonVehicleIds}
                />
              ) : (
                <ShimmerThumbnail />
              )}
            </Grid.Column>
          </Grid.Row>
        </Grid>
      </>
    )
  }

  render() {
    const {
      alert,
      alertType,
      showTirePromotionBannerFlag,
      showTirePromotionBannerConfig
    } = this.state
    const { found } = checkSubDomain()
    return (
      <ApplicationLayout>
        <Alert message={alert} type={alertType} visible={alert !== ""} />
        {!isEmpty(showTirePromotionBannerConfig) &&
          showTirePromotionBannerFlag == getSubdomain() &&
          showTirePromotionBannerConfig.hasOwnProperty("dashboard_banner") && (
            <div>
              <Image
                src={showTirePromotionBannerConfig.dashboard_banner}
                size={20}
                className="margin-bottom-15"
              />
            </div>
          )}
        {this.renderContent()}
        {found && <DownloadAppModal />}
      </ApplicationLayout>
    )
  }
} // class Dashboard

const mapStateToProps = (state) => {
  const user = state.application.userSessionData || (state.users && state.users.currentUser)
  const language = state.users.currentUser ? state.users.currentUser.language : ""
  const isFMC = isFMCUser(user)
  const isPathFMC = isFmcPath()
  return {
    isLoading: state.application.isLoading,
    isLoadingError: state.application.isLoadingError,
    users: state.users.users,
    vehicles: state.vehicles.dashboardVehicles,
    serviceCosts: state.services.serviceCosts,
    maintenanceIntervals: state.maintenanceIntervals,
    language: language,
    isFleetMode: state.application.mode === FLEET || state.application.mode === undefined,
    orderSummary: state.fleets.order_summary,
    pastDueVehicleIds: state.vehicles.pastDueVehicleIds,
    dueSoonVehicleIds: state.vehicles.dueSoonVehicleIds,
    basicVehicles: state.vehicles.basicVehicles,
    currentUser: user
  }
}

export default connect(mapStateToProps)(withTranslation("common")(Dashboard))
