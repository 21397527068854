import React, { Component } from "react"
import { Provider } from "react-redux"
import "semantic-ui-css/semantic.min.css"
import Router from "./Router"
import Store from "./actions/configureStore"
import { I18nextProvider } from "react-i18next"
import i18n from "./utilities/i18n"
import ReactNotifications from "react-notifications-component"
import "react-notifications-component/dist/theme.css"
import FlutterInit from "./FlutterInit"

class App extends Component {
  render() {
    return (
      <Provider store={Store}>
        <I18nextProvider i18n={i18n}>
          <ReactNotifications />
          <FlutterInit />
          <Router />
        </I18nextProvider>
      </Provider>
    )
  }
}

export default App
