import * as arrayHelpers from "../../helpers/arrayHelpers"

export const initialState = {
  loading: true,
  service_schedules_by_vehicle: {},
  executedForFleetId: null,
  upComingIntervalServices: {}
}

export default function (state = initialState, action) {
  // eslint-disable-next-line
  const { payload, type } = action

  switch (type) {
    case "MAINTENANCE_INTERVAL_SUCCEEDED":
      return {
        ...state,
        loading: payload.loading,
        service_schedules_by_vehicle: {
          ...state.service_schedules_by_vehicle,
          ...payload.service_schedules_by_vehicle
        },
        executedForFleetId: payload.fleetId
      }

    case "UPCOMING_INTERVAL_SERVICES_SUCCEEDED":
      return {
        ...state,
        loading: payload.loading,
        upComingIntervalServices: payload.upcoming_interval_services
      }
    case "UPCOMING_INTERVAL_SERVICES_UNLOAD_DATA":
      return {
        ...state,
        upComingIntervalServices: []
      }
    case "IS_COMPLETE_SERVICE_SUCCEEDED":
      const vehicle_id = payload.vehicleService.vehicle_service.vehicle_id
      let newObject = arrayHelpers.replaceObjectById(
        state.service_schedules_by_vehicle[vehicle_id],
        payload.vehicleService.vehicle_service,
        payload.vehicleService.vehicle_service.id
      )
      return {
        ...state,
        service_schedules_by_vehicle: newObject
      }

    default:
      return state
  }
}
