import { call, put, takeEvery, all } from "redux-saga/effects"

import api from "../../utilities/api"
import * as apiErrorHelpers from "../../helpers/apiErrorHelpers"
import * as sessionHelpers from "../../helpers/sessionHelpers"
import { AUTHENTICATION_TOKEN, POST, PUT } from "../../constants/application"
import { ERROR, FAILURE, SUCCESS } from "../../constants/notification"
import i18n from "../../utilities/i18n"

const MANUAL_MAINTENANCE_HISTORY = "/manual_maintenance_histories"

export function* loadManualMaitenanceHistories(action) {
  try {
    const payload = action.payload || {}
    const { startDate, endDate } = payload

    api.setAuthApi({
      Authorization: sessionHelpers.getApplicationCookie(AUTHENTICATION_TOKEN)
    })

    yield put({
      type: "MAINTENANCE_HISTORY_CREATE_REQUESTED"
    })
    let body = {}
    if (startDate) {
      body.appointment_datetime_from = startDate
      body.appointment_datetime_to = endDate
    }
    const maintenanceHistoryRequest = {
      url: MANUAL_MAINTENANCE_HISTORY,
      body: body
    }

    const maintenancePoliciesResponse = yield call(
      api.utility.get,
      api.pathV6(maintenanceHistoryRequest.url),
      { body: maintenanceHistoryRequest.body }
    )

    apiErrorHelpers.catchErrors(maintenancePoliciesResponse.body)

    yield put({
      type: "MAINTENANCE_HISTORIES_LOAD_SUCCEEDED",
      payload: { maintenanceHistories: maintenancePoliciesResponse.body }
    })
  } catch (errors) {
    // eslint-disable-next-line
    errors = apiErrorHelpers.formatInternalErrors(errors)

    yield put({ type: "LOADING_FAILED" })
    yield put({ type: "SET_LOADING_ERROR" })
    yield put({ type: "UNSET_LOADING" })

    apiErrorHelpers.consoleLog(errors)
  }
}

export function* loadMaintenanceHistories(action) {
  try {
    const { payload, callback } = action
    const { skipLoading, startDate, endDate, vehicleId, pageMMH, pageMH } = payload

    api.setAuthApi({
      Authorization: sessionHelpers.getApplicationCookie(AUTHENTICATION_TOKEN)
    })

    const url = `/manual_maintenance_histories`
    const url_1 = `/vehicles/vehicle_maintenance_history`
    let body = {}
    let body_1 = {}
    if (startDate) {
      body.appointment_datetime_from = startDate
      body.appointment_datetime_to = endDate
    }
    if (vehicleId) {
      body.vehicle_id = vehicleId
      body_1.vehicle_id = vehicleId
    }
    if (pageMMH) body.page = pageMMH
    if (pageMH) body_1.page = pageMH
    let response = {},
      response_1 = {}
    if (pageMH && pageMMH) {
      ;[response, response_1] = yield all([
        call(api.utility.get, api.path(url), { body: body }),
        call(api.utility.get, api.path(url_1), { body: body_1 })
      ])
    } else if (pageMH) {
      response_1 = yield call(api.utility.get, api.path(url_1), { body: body_1 })
    } else {
      response = yield call(api.utility.get, api.path(url), { body: body })
    }

    apiErrorHelpers.catchErrors(response.body)
    apiErrorHelpers.catchErrors(response_1.body)
    const { manual_histories, total_pages: manualHistoriesTotalPages } = pageMMH
      ? response.body
      : {}
    const { maintenance_history, total_pages: maintenanceHistoryTotalPage } = pageMH
      ? response_1.body
      : {}

    if (vehicleId) {
      yield put({
        type: "VEHICLE_MAINTENANCE_HISTORIES_LOAD_SUCCEEDED",
        payload: {
          maintenanceHistories: manual_histories,
          maintenance_history,
          manualHistoriesTotalPages,
          maintenanceHistoryTotalPage
        }
      })
      if (callback) {
        callback(manualHistoriesTotalPages, maintenanceHistoryTotalPage)
      }
    } else {
      yield put({
        type: "MAINTENANCE_HISTORIES_LOAD_SUCCEEDED",
        payload: { maintenanceHistories: response.body }
      })
    }
  } catch (errors) {
    // eslint-disable-next-line
    errors = apiErrorHelpers.formatInternalErrors(errors)

    yield put({ type: "LOADING_FAILED" })
    yield put({ type: "SET_LOADING_ERROR" })
    yield put({ type: "UNSET_LOADING" })

    apiErrorHelpers.consoleLog(errors)
  }
} // function* loadMaintenanceHistories

export function* createOrUpdateMaintenanceHistory(action) {
  const payload = action.payload || {}
  const maintenance_history_id = action.maintenance_history_id
  const { vehicle_id, formData } = payload

  try {
    yield put({
      type: "MAINTENANCE_HISTORY_CREATE_REQUESTED"
    })

    api.setAuthApi({
      Authorization: sessionHelpers.getApplicationCookie(AUTHENTICATION_TOKEN)
    })

    let baseUri = ""
    if (process.env.NODE_ENV === "production") {
      baseUri = window.location.origin
    } else {
      baseUri = api.apiUrl
    }
    let url, method
    if (maintenance_history_id) {
      url = `${MANUAL_MAINTENANCE_HISTORY}/${maintenance_history_id}`
      method = PUT
    } else {
      url = MANUAL_MAINTENANCE_HISTORY
      method = POST
    }

    const request = {
      url: url,
      body: {
        formData
      }
    }
    const response = yield call(fetch, `${baseUri}${api.basePathV6}${request.url}`, {
      body: formData,
      method: method,
      headers: {
        Authorization: sessionHelpers.getApplicationCookie(AUTHENTICATION_TOKEN)
      }
    })

    if (response.body.status && response.body.status != 200)
      throw [
        maintenance_history_id
          ? i18n.t("notificationsReminder:unableToUpdateMaintenanceHistory")
          : i18n.t("notificationsReminder:unableToCreateMaintenanceHistory")
      ]

    yield put({
      type: `MAINTENANCE_HISTORY_CREATE_SUCCEEDED`,
      payload: {
        maintenanceHistory: response.body
      }
    })

    const afterRequestData = {
      alertMessage: maintenance_history_id
        ? i18n.t("notificationsReminder:maintenanceHistoryUpdated")
        : i18n.t("notificationsReminder:maintenanceHistoryCreated"),
      alertType: SUCCESS
    }

    yield call(action.callback, SUCCESS, afterRequestData)
  } catch (errors) {
    // eslint-disable-next-line
    errors = apiErrorHelpers.formatInternalErrors(errors)

    yield put({ type: "MAINTENANCE_HISTORY_CREATE_FAILED" })

    const afterRequestData = {
      alertMessage: apiErrorHelpers.toString(errors),
      alertType: ERROR
    }

    yield call(action.callback, FAILURE, afterRequestData)

    apiErrorHelpers.consoleLog(errors)
  }
} // function* createMaintenanceHistory

export function* deleteMaintenanceHistory(action) {
  try {
    yield put({ type: "VEHICLE_MAINTENANCE_HISTORY_DELETE_REQUESTED" })

    api.setAuthApi({
      Authorization: sessionHelpers.getApplicationCookie(AUTHENTICATION_TOKEN)
    })

    const recordId = action.payload.recordId

    const request = {
      url: `/manual_maintenance_histories/${recordId}`,
      body: {}
    }

    const response = yield call(api.utility.del, api.path(request.url), { body: request.body })

    apiErrorHelpers.catchErrors(response.body)

    yield put({
      type: "VEHICLE_MAINTENANCE_HISTORY_DELETE_SUCCEEDED",
      payload: {
        manualMaintenanceHistory: response.body
      }
    })

    const afterRequestData = {
      alertMessage: i18n.t("notificationsReminder:maintenanceHistoryDeleted"),
      alertType: SUCCESS
    }

    yield call(action.callback, SUCCESS, afterRequestData)
  } catch (errors) {
    // eslint-disable-next-line
    errors = apiErrorHelpers.formatInternalErrors(errors)

    yield put({ type: "VEHICLE_MAINTENANCE_HISTORY_DELETE_FAILED" })

    const afterRequestData = {
      alertMessage: apiErrorHelpers.toString(errors),
      alertType: ERROR
    }

    yield call(action.callback, FAILURE, afterRequestData)

    apiErrorHelpers.consoleLog(errors)
  }
} // function* deleteMaintenanceHistory

export function* loadMaintenanceHistory(action) {
  try {
    api.setAuthApi({
      Authorization: sessionHelpers.getApplicationCookie(AUTHENTICATION_TOKEN)
    })

    const id = action.payload.id

    const request = {
      url: `${MANUAL_MAINTENANCE_HISTORY}/${id}`,
      body: {}
    }

    const response = yield call(api.utility.get, api.pathV6(request.url))

    apiErrorHelpers.catchErrors(response.body)

    yield put({
      type: `MAINTENANCE_HISTORY_LOAD_SUCCEEDED`,
      payload: {
        maintenanceHistory: response.body
      }
    })

    if (action.callback) yield call(action.callback, SUCCESS, response.body)
  } catch (errors) {
    errors = apiErrorHelpers.formatInternalErrors(errors)
    const afterRequestData = {
      alertMessage: apiErrorHelpers.toString(errors),
      alertType: ERROR
    }
    yield call(action.callback, FAILURE, afterRequestData)
    apiErrorHelpers.consoleLog(errors)
  }
} // function* loadMaintenanceHistory

export default function* maintenanceHistorySaga() {
  yield takeEvery("MAINTENANCE_HISTORIES_LOAD_SAGA", loadMaintenanceHistories)
  yield takeEvery("MAINTENANCE_HISTORY_CREATE_SAGA", createOrUpdateMaintenanceHistory)
  yield takeEvery("MAINTENANCE_HISTORY_DELETE_SAGA", deleteMaintenanceHistory)
  yield takeEvery("MAINTENANCE_HISTORY_LOAD_SAGA", loadMaintenanceHistory)
  yield takeEvery("MANUAL_MAINTENANCE_HISTORIES_LOAD_SAGA", loadManualMaitenanceHistories)
}
