import React, { Component } from "react"
import PropTypes from "prop-types"

import Button from "../../elements/Button"
import { withTranslation } from "react-i18next"
import { isFleetAdvise } from "../../helpers/affiliationHelpers"

class FleetFormButtons extends Component {
  static propTypes = {
    onCancel: PropTypes.func.isRequired,
    onDelete: PropTypes.func.isRequired,
    onEdit: PropTypes.func.isRequired,
    onSubmit: PropTypes.func.isRequired,
    fleetId: PropTypes.number.isRequired,

    allowSubmit: PropTypes.bool,
    isEditing: PropTypes.bool,
    visible: PropTypes.bool
  }

  static defaultProps = {
    allowSubmit: false,
    isEditing: false,
    visible: true
  }

  showDeleteButton() {
    const { fleetId } = this.props

    return fleetId !== null
  }

  render() {
    const {
      allowSubmit,
      fleetId,
      isEditing,
      onCancel,
      onDelete,
      onEdit,
      onSubmit,
      visible,
      t
    } = this.props

    if (visible) {
      if (isEditing) {
        return (
          <div className={"fleet_form_buttons"}>
            <div className="edit-buttons">
              <Button
                disabled={!allowSubmit}
                label={t("common:saveLabel")}
                onClick={onSubmit}
                className={isFleetAdvise() ? "btn-margin-right" : ""}
              />

              {/* NOTE: Only show cancel button for existing users */}
              {fleetId && (
                <Button
                  className={`white-button ${isFleetAdvise() ? "reset-filters-btn" : ""}`}
                  label={t("cancelLabel")}
                  onClick={() => onCancel(fleetId)}
                />
              )}
            </div>

            {this.showDeleteButton() && (
              <span className="link">
                <a className="link" onClick={onDelete}>
                  {t("deleteLabel")}
                </a>
              </span>
            )}
          </div>
        )
      } else {
        return (
          <div className={"fleet_form_buttons"}>
            <div className="edit-buttons">
              <Button label={t("editLabel")} onClick={() => onEdit(fleetId)} />
            </div>

            {this.showDeleteButton() && (
              <span className="link">
                <a className="link" onClick={onDelete}>
                  {t("deleteLabel")}
                </a>
              </span>
            )}
          </div>
        )
      }
    } else {
      return <div className={"fleet_form_buttons"}></div>
    }
  }
} // class FleetFormButtons

export default withTranslation("fleetImpersonation")(FleetFormButtons)
