import React, { Component } from "react"
import { connect } from "react-redux"
import { withRouter } from "react-router"
import PropTypes from "prop-types"
import { Grid, Modal, Header, Divider, Container } from "semantic-ui-react"
import FileUploadButton from "./FileUploadButton"
import FileDownloadButton from "./FileDownloadButton"
import Alert from "../../components/shared/Alert"
import LoadingThrobber from "../../components/shared/LoadingThrobber"
import LoadingError from "../../components/shared/LoadingError"

import withWebSocketConnection from "./withWebSocketConnection"
import { withTranslation } from "react-i18next"
import { isFleetMaintenanceHubCanada } from "../../helpers/affiliationHelpers"
import { EN_LOCALE } from "../../constants/users"

class BulkUploadModal extends Component {
  constructor(props) {
    super(props)
    this.state = {
      ...this.defaultState()
    }
  }

  static propTypes = {
    saga: PropTypes.string.isRequired
  }

  defaultState = () => {
    return {
      file: null,
      open: false,
      alertMessage: this.props.t("readyForUploadLabel"),
      alertType: "default",
      disableSubmit: false,
      awaitingUploadCompletion: false
    }
  }

  loadAllVehciles() {
    this.props.dispatch({
      type: "VEHICLES_LOAD_SAGA"
    })
  }

  open = () => this.setState({ open: true, alertMessage: this.props.t("readyForUploadLabel") })

  close = () => {
    this.setState(!this.state.awaitingUploadCompletion ? this.defaultState() : { open: false })
    this.loadAllVehciles()
  }

  onSubmitSuccess(data) {
    this.setState({
      awaitingUploadCompletion: false,
      alertMessage: data.alertMessage,
      alertType: data.alertType,
      isSubmitting: false,
      submitted: true,
      disableSubmit: true
    })
  }

  onSubmitFailure(data) {
    this.setState({
      awaitingUploadCompletion: false,
      alertMessage: data.alertMessage,
      alertType: data.alertType,
      isSubmitting: false,
      submitted: true
    })
  }

  afterSubmit = (status, data) => {
    if (status === "success") {
      this.onSubmitSuccess(data)
    } else {
      this.onSubmitFailure(data)
    }
  }

  handleSave = async () => {
    this.setState({ loading: true })

    let afterRequestData = await this.props.dispatch({
      type: "VEHICLE_BULK_UPSERT_SAGA",
      payload: { file: this.state.file, t: this.props.t },
      callback: this.afterSubmit
    })
  }

  onChangeFile = (e) => {
    var reader = new FileReader()

    const files = e.target.files
    const file = files ? files[0] : null

    reader.readAsDataURL(file)

    reader.onload = () => {
      this.setState({ file: reader.result }, this.handleSave)
    }
    this.setState({ awaitingUploadCompletion: true })
  }

  onDataReceived = (uploaderRecordData) => {
    const { t } = this.props
    const map = {
      completed: { msg: t("uploadCompletedLabel"), alertType: "success" },
      completed_with_errors: {
        msg: t("uploadCompletedErrorsLabels"),
        alertType: "error"
      },
      errored: {
        msg: t("uploadCompletedErrorsLabels"),
        alertType: "error"
      }
    }

    this.setState({
      awaitingUploadCompletion: false,
      alertMessage: `${map[uploaderRecordData].msg}. Check your email for a report`,
      alertType: map[uploaderRecordData].alertType
    })
  }

  render() {
    const { open, alertMessage, alertType, awaitingUploadCompletion } = this.state
    const { isLoading, isLoadingError, children, t } = this.props
    const { language } = this.props.i18n

    return (
      <React.Fragment>
        <LoadingError visible={!isLoading && isLoadingError} />

        <Modal
          size="mini"
          trigger={children}
          closeIcon
          open={open}
          onOpen={this.open}
          onClose={this.close}
        >
          <Modal.Content>
            <Header textAlign="center" className="primary-color">
              {" "}
              {t("bulkUploadVehiclesLabel")}
            </Header>
            <Alert message={alertMessage} type={alertType} />
            <LoadingThrobber
              visible={awaitingUploadCompletion}
              loadingMessage={t("uploadingVehicleDataLabel")}
            />

            <Modal.Description>
              {!isLoading && !isLoadingError && !awaitingUploadCompletion && (
                <Container centered>
                  <p>{t("downloadVehiclesTemplateLabel")}</p>
                  <p>{t("inputVehicleInformationLabel")}</p>
                  <p>{t("vehicleLineLabel")}</p>
                  <p>{t("vehiclesUploadTemplate")}</p>
                  <p>
                    5. <strong>{t("vinRequired")}</strong>, {t("vinsUploadedLabel")}
                  </p>
                  <p>
                    <small>
                      <sup>*</sup>
                      {t("vehicleNoteLabel")}
                    </small>
                  </p>
                </Container>
              )}

              <Divider hidden></Divider>

              <Grid columns="equal">
                <Grid.Row>
                  <Grid.Column>
                    <FileDownloadButton
                      content={t("downloadTemplateLabel")}
                      downloadLink={
                        isFleetMaintenanceHubCanada() && language == EN_LOCALE
                          ? "https://fleetmaintenancehubca.com/vehicle_upload_template_en_example.csv"
                          : t("downloadLink")
                      }
                    />
                  </Grid.Column>
                  <Grid.Column>
                    <FileUploadButton
                      content={t("uploadVehiclesCSVLabel")}
                      onChangeFile={this.onChangeFile}
                    />
                  </Grid.Column>
                </Grid.Row>
              </Grid>
            </Modal.Description>
          </Modal.Content>
        </Modal>
      </React.Fragment>
    )
  }
} // class BulkUploadModal

const mapStateToProps = (state) => {}

export default connect(mapStateToProps)(
  withRouter(
    withTranslation("vehicle")(
      withWebSocketConnection(BulkUploadModal, "VehicleBulkUpsertionChannel")
    )
  )
)
