import moment from "moment"
import { isFleetMaintenanceHubCanada } from "../helpers/affiliationHelpers"
import * as numberHelpers from "../helpers/numberHelpers"
import { Date_Format } from "./years"

export const newFleetTemplate = {
  id: null,
  name: "",
  otherId: "",
  paymentType: "",
  address: {}
}

export function agGridColumnDefs(defaultCellRenderer, t) {
  const widths = {
    verySmall: 125,
    small: 150,
    medium: 175,
    large: 200,
    veryLarge: 225
  }

  return [
    {
      headerName: t("fleetLabel"),
      field: "name",
      width: widths.veryLarge,
      cellRendererFramework: defaultCellRenderer
    },
    {
      headerName: t("repLabel"),
      field: "rep",
      width: widths.veryLarge,
      cellRendererFramework: defaultCellRenderer
    },
    {
      headerName: t("userLabel"),
      field: "user_total",
      width: widths.verySmall,
      cellRendererFramework: defaultCellRenderer
    },
    {
      headerName: t("createdLabel"),
      field: "created_at_pretty",
      width: widths.small,
      cellRendererFramework: defaultCellRenderer
    },
    {
      headerName: t("vehiclesLabel"),
      field: "vehicles_total",
      width: widths.small,
      cellRendererFramework: defaultCellRenderer
    },
    {
      headerName: t("awaitingApprovalLabel"),
      field: "awaiting_approval_count",
      width: widths.small,
      cellRendererFramework: defaultCellRenderer,
      sort: "desc"
    },
    {
      headerName: t("transactionsLabel"),
      field: "total_order_count",
      width: widths.medium,
      cellRendererFramework: defaultCellRenderer
    },
    {
      headerName: t("totalSpendLabel"),
      field: "total_maintenance_cost",
      width: widths.small,
      cellRendererFramework: defaultCellRenderer,
      comparator: (valueA, valueB, nodeA, nodeB, isInverted) => {
        return nodeA.data.total_maintenance_cost_base - nodeB.data.total_maintenance_cost_base
      }
    },
    {
      headerName: t("averageTicketLabel"),
      field: "average_ticket_spend",
      width: widths.small,
      cellRendererFramework: defaultCellRenderer,
      comparator: (valueA, valueB, nodeA, nodeB, isInverted) => {
        return nodeA.data.average_ticket_spend_base - nodeB.data.average_ticket_spend_base
      }
    },
    {
      headerName: t("regionLabel"),
      field: "region",
      width: widths.verySmall,
      cellRendererFramework: defaultCellRenderer
    },
    {
      headerName: t("activeLabel"),
      field: "active",
      width: widths.verySmall,
      cellRendererFramework: defaultCellRenderer
    }
  ]
}

export function agGridRowData(fleets) {
  return fleets.map((fleet) => {
    return {
      name: fleet.name,
      rep: fleet.rep,
      active: fleet.is_active.toString(),
      user_total: fleet.user_total,
      created_at_pretty: isFleetMaintenanceHubCanada()
        ? moment(fleet.created_at_pretty).format(Date_Format.YYYY_MM_DD)
        : fleet.created_at_pretty,
      vehicles_total: fleet.vehicles_total,
      awaiting_approval_count: fleet.awaiting_approval_count,
      total_order_count: fleet.total_order_count,
      total_maintenance_cost: numberHelpers.formattedPrice(fleet.total_maintenance_cost),
      average_ticket_spend: numberHelpers.formattedPrice(fleet.average_ticket_spend),
      total_maintenance_cost_base: numberHelpers.formattedPrice(fleet.total_maintenance_cost),
      average_ticket_spend_base: numberHelpers.formattedPrice(fleet.average_ticket_spend),
      region: fleet.region,
      // NOTE: The ID column does not get displayed because there is no mapping for it in the columnDefs, however, providing here makes it available to the row's data attribute.
      id: fleet.id
    }
  })
}

export const FUELMAN_FAQ = "https://fuelmanmaintenance.com/faq/"
