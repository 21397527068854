import React, { useEffect, useState } from "react"
import { useTranslation, withTranslation } from "react-i18next"
import { connect } from "react-redux"
import ReactSVG from "react-svg"
import CrossIcon from "../../assets/images/cross_icon.svg"
import VectorSvg from "../../assets/images/vector.svg"
import Select from "../../elements/Select"
import MultiSelect from "../../elements/MultiSelect"
import { FILTER_OPTIONS, convertDateToString } from "../../constants/reports"
import DatePicker from "react-datepicker"
import { isFleetMaintenanceHubCanada } from "../../helpers/affiliationHelpers"
import { Date_Format } from "../../constants/years"
import { Input } from "semantic-ui-react"
import store from "../../actions/configureStore"
import moment from "moment"
import CustomNotification from "../../components/shared/CustomNotification"
import { SUCCESS } from "../../constants/notification"
import { FLEET, FMC } from "../../constants/application"
import { getFmcAndFleetId } from "../../actions/sagas/reportingSaga"

export const COST_FILTER_OPTION = "cost_filter_option"
export const AMOUNT = "amount"
export const PRICE_GREATER_THAN = "price_greater_than"
export const PRICE_LESS_THAN = "price_less_than"
export const TIME_PERIOD = "time_period"
export const APPOINMENT_DATETIME_TO = "appointment_datetime_to"
export const APPOINMENT_DATETIME_FROM = "appointment_datetime_from"

const ReportSideModal = ({
  filtersData,
  filterReportData,
  closeConfigureModal,
  fleets,
  companies,
  services,
  years,
  makes,
  models,
  filter,
  handleClose,
  mode,
  currentFleet
}) => {
  const [filters, setFilters] = useState(() => {
    const initialFilters = {
      fleet_id: [],
      service_name: [],
      company_id: [],
      year: [],
      make: [],
      model: [],
      appointment_datetime_to: "",
      appointment_datetime_from: "",
      price_less_than: null,
      price_greater_than: null,
      cost_filter_option: "",
      time_period: "",
      amount: null,
      vin: "",
      filter_name: ""
    }
    if (filter) {
      return {
        ...initialFilters,
        price_less_than: !filter.filters.price_less_than && null,
        price_greater_than: !filter.filters.price_greater_than && null,
        amount: filter.filters.price_less_than || filter.filters.price_greater_than || null
      }
    }

    return initialFilters
  })

  const { t } = useTranslation("reports")
  useEffect(() => {
    let updatedFilters = {}
    if (filter) {
      updatedFilters = {
        ...filtersData, // Spread the current state into the new state
        amount: Number(filter.filters.price_less_than || filter.filters.price_greater_than) || null,
        cost_filter_option: filter.filters.price_greater_than
          ? PRICE_GREATER_THAN
          : filter.filters.price_less_than
          ? PRICE_LESS_THAN
          : "",
        filter_name: filter.filter_name
      }
    }
    loadConfigureFilters()
    setFilters(filter ? updatedFilters : filtersData)
  }, [filtersData])

  const loadConfigureFilters = () => {
    const { isFMC, fleetId } = getFmcAndFleetId()

    store.dispatch({
      type: "LOAD_CONFIGURE_REPORT_FILTERS",
      payload: { is_fmc: isFMC, ...(isFMC ? {} : { filters: { fleet_id: [fleetId] } }) }
    })
  }

  const checkFiltersChanged = () => {
    // Check if any of the values have been updated
    for (const key in filters) {
      if (key != "filter_name" && key != "updated_at" && key != "viewed_at") {
        if (Array.isArray(filters[key])) {
          if (filters[key].length > 0) {
            return true
          }
        } else {
          if (filters[key] !== null && filters[key] !== "") {
            return true
          }
        }
      }
    }
    return false
  }

  const costOptions = [
    {
      key: "1",
      label: t("reports:constFilterGreaterThanOptionLabel"),
      value: PRICE_GREATER_THAN
    },
    { key: "2", label: t("reports:constFilterLessThanOptionLabel"), value: PRICE_LESS_THAN }
  ]

  const handleFilterChange = (selectedOptions, name) => {
    setFilters({
      ...filters,
      [name]: selectedOptions.map((option) => option.value)
    })
  }

  const handleInputChange = (value, name) => {
    switch (name) {
      case COST_FILTER_OPTION:
        if (value) {
          setFilters({
            ...filters,
            price_less_than: null,
            price_greater_than: null,
            [name]: value,
            [value]: filters.amount
          })
        } else {
          setFilters({
            ...filters,
            price_less_than: null,
            price_greater_than: null,
            amount: null,
            [name]: value
          })
        }
        break
      case AMOUNT:
        if (!filters.cost_filter_option) {
          setFilters({
            ...filters,
            [name]: value,
            [filters.cost_filter_option || PRICE_GREATER_THAN]: value,
            cost_filter_option: PRICE_GREATER_THAN
          })
        } else {
          setFilters({
            ...filters,
            [name]: value,
            [filters.cost_filter_option || PRICE_GREATER_THAN]: value
          })
        }
        break
      case TIME_PERIOD:
        setFilters({
          ...filters,
          [name]: value,
          appointment_datetime_from: "",
          appointment_datetime_to: ""
        })
        break
      case APPOINMENT_DATETIME_FROM:
      case APPOINMENT_DATETIME_TO:
        setFilters({
          ...filters,
          [name]: value,
          time_period: ""
        })
        break
      default:
        setFilters({
          ...filters,
          [name]: value
        })
        break
    }
  }

  const handleUpdateReport = () => {
    const { isFMC, fleetId } = getFmcAndFleetId()

    store.dispatch({
      type: "CREATE_UPDATE_FILTER",
      payload: {
        filters: {
          ...convertDateToString(filters),
          ...(isFMC ? {} : { fleet_id: [fleetId] })
        },
        isFMC: isFMC,
        t: t,
        reportEditEnabled: true,
        filter_key: filter.filter_key,
        filter_name: filters.filter_name
      },
      callback: afterFilterUpdated
    })
  }

  const afterFilterUpdated = (status, data) => {
    const { type, title, message, filter } = data
    handleClose(filter)
    if (status == SUCCESS) filterReportData(filters, filter)
    return CustomNotification(type, title, message, t)
  }

  return (
    <div className="reporting-side-modal-section">
      <div className="reporting-header-section">
        <div className="reporting-header-content">
          <div className="reporting-header-frame">
            <span className="report-text">
              {filter
                ? `${t("reports:editReportLabel")} ${filter.filter_name}`
                : t("reports:createReportLabel")}
            </span>
            <span className="note">{t("reports:filterReportNoteLabel")}</span>
          </div>
        </div>
        <div className="cross-icon-section" onClick={() => closeConfigureModal()}>
          <ReactSVG src={CrossIcon} svgClassName="cross-icon" />
        </div>
      </div>
      <div className={`reporting-main-content-section ${filter ? "edit-filter" : ""}`}>
        <div className="reporting-basic-filter-section">
          {filter && (
            <>
              <div className="report-name-section select-box">
                <span className="enter-report-text"> {t("nameYourReportLabel")} </span>
              </div>
              <div className="select-box">
                <Input
                  type="text"
                  value={filters.filter_name}
                  name={t("filterNameLabel")}
                  style={{ width: "100%" }}
                  onChange={(e) => handleInputChange(e.target.value, "filter_name")}
                />
              </div>
            </>
          )}

          <div className="select-box">
            <MultiSelect
              options={fleets || []}
              placeholder={t("reports:allFleetsLabel")}
              onChange={(selected) => handleFilterChange(selected, "fleet_id")}
              value={mode === FLEET ? [currentFleet.id] : filters.fleet_id}
              disabled={mode === FLEET}
            />
          </div>
          <div className="select-box">
            <MultiSelect
              options={services || []}
              placeholder={t("reports:allServicesLabel")}
              onChange={(selected) => handleFilterChange(selected, "service_name")}
              value={filters.service_name}
            />
          </div>
          <div className="select-box">
            <MultiSelect
              options={companies || []}
              placeholder={t("reports:allCompaniesLabel")}
              onChange={(selected) => handleFilterChange(selected, "company_id")}
              value={filters.company_id}
            />
          </div>
          <div className="select-box">
            <MultiSelect
              options={years || []}
              placeholder={t("reports:yearsLabel")}
              onChange={(selected) => handleFilterChange(selected, "year")}
              value={filters.year}
            />
          </div>
          <div className="select-box">
            <MultiSelect
              options={makes || []}
              placeholder={t("reports:makesLabel")}
              onChange={(selected) => handleFilterChange(selected, "make")}
              value={filters.make}
            />
          </div>
          <div className="select-box">
            <MultiSelect
              options={models || []}
              placeholder={t("reports:modelsLabel")}
              onChange={(selected) => handleFilterChange(selected, "model")}
              value={filters.model}
            />
          </div>
          <div className="select-box">
            <Input
              type="text"
              placeholder={t("reports:vinLabel")}
              value={filters.vin}
              name="vin"
              style={{ width: "100%" }}
              onChange={(e) => handleInputChange(e.target.value, "vin")}
            />
          </div>
        </div>
        <ReactSVG src={VectorSvg} />
        <span className="report-date-text">{t("reports:reportsDateFilterLabel")} </span>
        <div className="reporting-date-filter-radio-section">
          {FILTER_OPTIONS.map((option) => (
            <label key={option.value} style={{ display: "flex" }}>
              <input
                type="radio"
                value={option.value}
                name="reportsFilter"
                checked={filters.time_period === option.value}
                onChange={(e) => handleInputChange(e.target.value, "time_period")}
              />
              <span style={{ paddingLeft: "5px" }}>{option.label}</span>
              <br />
            </label>
          ))}
        </div>
        <span className="report-date-text">{t("reports:reportsCustomDateFilterLabel")} </span>
        <div className="reporting-date-inputs-section ">
          <DatePicker
            selected={
              filters.appointment_datetime_from
                ? moment(filters.appointment_datetime_from)
                : filters.appointment_datetime_from
            }
            onChange={(date) => handleInputChange(date, "appointment_datetime_from")}
            dateFormat={
              isFleetMaintenanceHubCanada() ? Date_Format.YYYY_MM_DD : Date_Format.MM_DD_YYYY
            }
            placeholderText={t("reports:selectDatePlaceHolderLabel")}
            customInput={<Input />}
          />
          <span className="hyphen">-</span>
          <DatePicker
            selected={
              filters.appointment_datetime_to
                ? moment(filters.appointment_datetime_to)
                : filters.appointment_datetime_to
            }
            onChange={(date) => handleInputChange(date, "appointment_datetime_to")}
            dateFormat={
              isFleetMaintenanceHubCanada() ? Date_Format.YYYY_MM_DD : Date_Format.MM_DD_YYYY
            }
            placeholderText={t("reports:selectDatePlaceHolderLabel")}
            customInput={<Input />}
          />
        </div>
        <ReactSVG src={VectorSvg} />
        <div className="reporting-cost-input-section">
          <span className="report-date-text">{t("reports:costLabel")}</span>
          <div className="cost-section-wrap">
            <Select
              options={costOptions}
              placeholder={t("reports:selectOptionPlaceholderLabel")}
              onChange={(e) => handleInputChange(e.target.value, "cost_filter_option")}
              value={filters.cost_filter_option}
            />
            <Input
              type="text"
              placeholder={t("reports:enterAmountPlaceholderLabel")}
              value={filters.amount}
              name="amount"
              onChange={(e) => handleInputChange(e.target.value, "amount")}
            />
          </div>
        </div>
      </div>
      {filter ? (
        <div className="reporting-side-modal-button-frame">
          <div
            className={
              !checkFiltersChanged()
                ? "run-data-button-section"
                : "run-data-button-section run-data-enable-button"
            }
            onClick={() => checkFiltersChanged() && handleUpdateReport(filters)}
          >
            <div className="run-data-button-wrap ">
              <span className="text">{t("saveChangesLabel")} </span>
            </div>
          </div>
          <div
            className="run-data-button-section"
            style={{ cursor: "pointer", background: "#D1E0D8" }}
            onClick={() => closeConfigureModal()}
          >
            <div className="run-data-button-wrap ">
              <span className="text" style={{ color: "black" }}>
                {t("cancelLabel")}{" "}
              </span>
            </div>
          </div>
        </div>
      ) : (
        <div
          className="reporting-side-modal-button-frame"
          onClick={() => checkFiltersChanged() && filterReportData(filters)}
        >
          <div
            className={
              !checkFiltersChanged()
                ? "run-data-button-section"
                : "run-data-button-section run-data-enable-button"
            }
          >
            <div className="run-data-button-wrap ">
              <span className="text">{t("reports:runDataLabel")} </span>
            </div>
          </div>
        </div>
      )}
    </div>
  )
}

const mapStateToProps = (state) => {
  const { reports } = state

  return {
    fleets:
      reports.configureFilters &&
      reports.configureFilters.fleets.map((item) => {
        return { value: item.id, label: item.name }
      }),
    companies:
      reports.configureFilters &&
      reports.configureFilters.companies.map((item) => {
        return { value: item.id, label: item.name }
      }),
    services:
      reports.configureFilters &&
      reports.configureFilters.services.map((item) => {
        return { value: item.name, label: item.name }
      }),
    years:
      reports.configureFilters &&
      reports.configureFilters.vehicles.years.map((item) => {
        return { value: item, label: item }
      }),
    makes:
      reports.configureFilters &&
      reports.configureFilters.vehicles.makes.map((item) => {
        return { value: item, label: item }
      }),
    models:
      reports.configureFilters &&
      reports.configureFilters.vehicles.models.map((item) => {
        return { value: item, label: item }
      }),
    mode: state.application.mode,
    currentFleet: state.application.fleetSessionData
  }
}

export default connect(mapStateToProps)(ReportSideModal)
