import React from "react"
import { useTranslation } from "react-i18next"
import ReactSVG from "react-svg"
import {
  FLEET_MAINTENANCE_HUB,
  FLEET_AMERICA,
  FLEET_MAINTENANCE_HUB_CANADA,
  FLEETCOR,
  FUELMAN,
  NTWTBC,
  DISCOUNT_TIRE
} from "../constants/application"

const DiscountTireSignUpDetails = ({ t }) => {
  return (
    <>
      <div className="fleet-info-container">
        <div className="number-circle">01</div>
        <div className="fleet-info">
          <p className="fleet-info-text-header" style={{ color: "white" }}>
            <b>{t("trustedExprtLabel")}</b>
          </p>
          <p className="fleet-info-text" style={{ color: "white" }}>
            {t("trustedExprtDescription")}
          </p>
        </div>
      </div>
      <div className="fleet-info-container">
        <div className="number-circle">02</div>
        <div className="fleet-info">
          <p className="fleet-info-text-header" style={{ color: "white" }}>
            <b>{t("canDoAttitudeLabel")}</b>
          </p>
          <p className="fleet-info-text" style={{ color: "white" }}>
            {t("canDoAttitudeDescription")}
          </p>
        </div>
      </div>
      <div className="fleet-info-container">
        <div className="number-circle">03</div>
        <div className="fleet-info">
          <p className="fleet-info-text-header" style={{ color: "white" }}>
            <b>{t("unexpectedExperienceLabel")}</b>
          </p>
          <p className="fleet-info-text" style={{ color: "white" }}>
            {t("unexpectedExperienceDescription")}
          </p>
        </div>
      </div>
      <div className="fleet-info-container">
        <div className="number-circle">04</div>
        <div className="fleet-info">
          <p className="fleet-info-text-header" style={{ color: "white" }}>
            <b>{t("lowPriceLabel")}</b>
          </p>
          <p className="fleet-info-text" style={{ color: "white" }}>
            {t("lowPriceDescription")}
          </p>
        </div>
      </div>
    </>
  )
}
export default function SignupPageDesign(props) {
  const { t } = useTranslation("common")
  const headerLogo = props.headerLogo
  const subdomain = props.subdomain
  const iconColor = headerLogo === "fleetamerica_logo.svg" ? "#005ca5" : "#ffffff"
  return (
    <div className="no-session-header-displayFleetInfo">
      {subdomain !== DISCOUNT_TIRE ? (
        <>
          <div className="fleet-info-container">
            <div
              className="fleet-info-image-container"
              style={{
                backgroundColor: `${
                  headerLogo === "fleetadvise_logo.svg"
                    ? "#f8991d"
                    : headerLogo === "shell_logo.svg" || headerLogo === "shell_no_session_logo.svg"
                    ? "#003C88"
                    : headerLogo === "ntw_logo.svg"
                    ? "#005ca9"
                    : "#FFC429"
                }`
              }}
            >
              <ReactSVG
                src={`images/Convenience.svg`}
                onInjected={(svg) => {
                  ;[...svg.querySelectorAll("g")][1].setAttribute("fill", iconColor)
                }}
              />
            </div>

            <div className="fleet-info">
              <p
                className="fleet-info-text-header"
                style={{
                  color: `${
                    headerLogo === "shell_logo.svg" || headerLogo === "shell_no_session_logo.svg"
                      ? "#414447"
                      : subdomain === NTWTBC
                      ? "#005ca9"
                      : "white"
                  }`
                }}
              >
                <b>{t("convenienceLabel")}</b>
              </p>
              <p
                className="fleet-info-text"
                style={{
                  color: `${
                    headerLogo === "shell_logo.svg" ||
                    headerLogo === "shell_no_session_logo.svg" ||
                    headerLogo === "ntw_logo.svg"
                      ? "#414447"
                      : "white"
                  }`
                }}
              >
                {t("convenienceDesciptionLabel")}
              </p>
            </div>
          </div>
          <div className="fleet-info-container">
            <div
              className="fleet-info-image-container"
              style={{
                backgroundColor: `${
                  headerLogo === "fleetadvise_logo.svg"
                    ? "#f8991d"
                    : headerLogo === "shell_logo.svg" || headerLogo === "shell_no_session_logo.svg"
                    ? "#003C88"
                    : headerLogo === "ntw_logo.svg"
                    ? "#005ca9"
                    : "#FFC429"
                }`
              }}
            >
              <ReactSVG
                src={`images/Trust.svg`}
                onInjected={(svg) => {
                  var elements = [...svg.querySelectorAll("g")]
                  elements[1].setAttribute("fill", iconColor)
                  elements[1].setAttribute("stroke", iconColor)
                }}
              />
            </div>
            <div className="fleet-info">
              <p
                className="fleet-info-text-header"
                style={{
                  color: `${
                    headerLogo === "shell_logo.svg" || headerLogo === "shell_no_session_logo.svg"
                      ? "#414447"
                      : subdomain === NTWTBC
                      ? "#005ca9"
                      : "white"
                  }`
                }}
              >
                <b>{t("trustLabel")}</b>
              </p>
              <p
                className="fleet-info-text"
                style={{
                  color: `${
                    headerLogo === "shell_logo.svg" ||
                    headerLogo === "shell_no_session_logo.svg" ||
                    headerLogo === "ntw_logo.svg"
                      ? "#414447"
                      : "white"
                  }`
                }}
              >
                {t("trustDescriptionLabel")}
              </p>
            </div>
          </div>
          <div className="fleet-info-container">
            <div
              className="fleet-info-image-container"
              style={{
                backgroundColor: `${
                  headerLogo === "fleetadvise_logo.svg"
                    ? "#f8991d"
                    : headerLogo === "shell_logo.svg" || headerLogo === "shell_no_session_logo.svg"
                    ? "#003C88"
                    : headerLogo === "ntw_logo.svg"
                    ? "#005ca9"
                    : "#FFC429"
                }`
              }}
            >
              <ReactSVG
                src={`images/Savings.svg`}
                onInjected={(svg) => {
                  var elements = [...svg.querySelectorAll("path")]
                  for (var i = 0; i <= elements.length - 1; i++) {
                    elements[i].setAttribute("stroke", iconColor)
                  }
                }}
              />
            </div>
            <div className="fleet-info">
              <p
                className="fleet-info-text-header"
                style={{
                  color: `${
                    headerLogo === "shell_logo.svg" || headerLogo === "shell_no_session_logo.svg"
                      ? "#414447"
                      : subdomain === NTWTBC
                      ? "#005ca9"
                      : "white"
                  }`
                }}
              >
                <b>{t("affordableLabel")}</b>
              </p>
              <p
                className="fleet-info-text"
                style={{
                  color: `${
                    headerLogo === "shell_logo.svg" ||
                    headerLogo === "shell_no_session_logo.svg" ||
                    headerLogo === "ntw_logo.svg"
                      ? "#414447"
                      : "white"
                  }`
                }}
              >
                {t("affordableDescriptionLabel1")}
                {subdomain === FLEET_MAINTENANCE_HUB || subdomain === FLEET_MAINTENANCE_HUB_CANADA
                  ? " Shell Fleet Maintenance Hub"
                  : subdomain == FLEET_AMERICA
                  ? "Fleet America"
                  : subdomain === FLEETCOR || subdomain === FUELMAN
                  ? "Fuelman Maintenance"
                  : subdomain === NTWTBC
                  ? "NTW Fleet"
                  : "FleetAdvise"}
                , {t("affordableDescriptionLabel2")}
              </p>
            </div>
          </div>
        </>
      ) : (
        <DiscountTireSignUpDetails t={t} />
      )}
      <div className="disclaimer-container">
        <p
          className="disc-text"
          style={{
            color: `${
              headerLogo === "shell_logo.svg" ||
              headerLogo === "shell_no_session_logo.svg" ||
              headerLogo === "ntw_logo.svg"
                ? "#414447"
                : "white"
            }`
          }}
        >
          {subdomain === FLEET_MAINTENANCE_HUB || subdomain === FLEET_MAINTENANCE_HUB_CANADA
            ? t("disclaimerDescriptionLableShellMaintenance")
            : subdomain === FLEETCOR || subdomain === FUELMAN
            ? t("disclaimerDescriptionLableFuelmanMaintenance")
            : subdomain === FLEET_AMERICA
            ? t("disclaimerDescriptionLableFleetAmerica")
            : subdomain === NTWTBC
            ? t("disclaimerDescriptionLableNTWFleet")
            : t("disclaimerDescriptionLableFleetAdvise")}
        </p>
      </div>
    </div>
  )
}
