import { call, put, takeEvery, select, takeLatest } from "redux-saga/effects"

import api from "../../utilities/api"
import * as apiErrorHelpers from "../../helpers/apiErrorHelpers"
import * as sessionHelpers from "../../helpers/sessionHelpers"
import { AUTHENTICATION_TOKEN, FMC } from "../../constants/application"
import { ERROR, FAILURE, SUCCESS } from "../../constants/notification"
import i18n from "../../utilities/i18n"

const VEHICLES_UPADTE_COMPLETED_URL = "/vehicles/update_is_completed"
const UPCOMING_INTERVAL_SERVICES_URL = "/fleets/upcoming_interval_services"

export function* maintenanceInterval(action) {
  try {
    const fleetId = yield select((state) => state.users.currentUser.fleet_id)
    const executedForFleetId = yield select(
      (state) => state.maintenanceIntervals.executedForFleetId
    )
    if (executedForFleetId === fleetId) return

    yield call(fetchMaintenanceInterval, action, fleetId)

    const afterRequestData = {
      alertMessage: i18n.t("notificationsReminder:maintenanceIntervalSucceeded"),
      alertType: SUCCESS
    }

    if (action.callback) yield call(action.callback, SUCCESS, afterRequestData)
  } catch (errors) {
    // eslint-disable-next-line
    errors = apiErrorHelpers.formatInternalErrors(errors)

    yield put({
      type: "MAINTENANCE_INTERVAL_FAILED"
    })

    const afterRequestData = {
      alertMessage: apiErrorHelpers.toString(errors),
      alertType: ERROR
    }

    if (action.callback) yield call(action.callback, FAILURE, afterRequestData)

    apiErrorHelpers.consoleLog(errors)
  }
} // maintenanceInterval()

function* fetchMaintenanceInterval(action, fleetId) {
  try {
    const { payload } = action

    api.setAuthApi({
      Authorization: sessionHelpers.getApplicationCookie(AUTHENTICATION_TOKEN)
    })

    const page = (action.payload && action.payload.page) || 1

    const request = {
      url: `/fleets/me/vehicle_services?page=${page}`
    }

    if (action.payload && action.payload.vehicleIds)
      request.url += `?vehicle_ids=[${action.payload.vehicleIds.join(",")}]`

    const response = yield call(api.utility.get, api.path(request.url))

    apiErrorHelpers.catchErrors(response.body)

    const fleetOrFmcMode = yield select((state) => state.application.mode)
    // The product has two "modes"- FLEET and FMC. If the user is an Fmc and has gone back to the
    // Fmc dashboard (i.e. FMC mode), we don't need to continue loading this data.
    const isFmcMode = fleetOrFmcMode === FMC

    const stillNeedsLoading =
      Object.keys(response.body).length > 0 && !(payload && payload.vehicleIds) && !isFmcMode

    if (stillNeedsLoading) {
      yield call(fetchMaintenanceInterval, { payload: { page: page + 1 } })
    }

    yield put({
      type: "MAINTENANCE_INTERVAL_SUCCEEDED",
      payload: {
        service_schedules_by_vehicle: response.body,
        loading: stillNeedsLoading,
        fleetId: fleetId
      }
    })
  } catch (errors) {
    // eslint-disable-next-line
    errors = apiErrorHelpers.formatInternalErrors(errors)

    yield put({
      type: "MAINTENANCE_INTERVAL_FAILED"
    })

    const afterRequestData = {
      alertMessage: apiErrorHelpers.toString(errors),
      alertType: ERROR
    }

    if (action.callback) yield call(action.callback, FAILURE, afterRequestData)

    apiErrorHelpers.consoleLog(errors)
  }
}

export function* nextMaintenanceInterval(action) {
  try {
    const fleetId = yield select((state) => state.users.currentUser.fleet_id)

    yield call(fetchMaintenanceInterval, action, fleetId)

    const afterRequestData = {
      alertMessage: i18n.t("notificationsReminder:maintenanceIntervalSucceeded"),
      alertType: SUCCESS
    }

    if (action.callback) yield call(action.callback, SUCCESS, afterRequestData)
  } catch (errors) {
    // eslint-disable-next-line
    errors = apiErrorHelpers.formatInternalErrors(errors)

    yield put({
      type: "MAINTENANCE_INTERVAL_FAILED"
    })

    const afterRequestData = {
      alertMessage: apiErrorHelpers.toString(errors),
      alertType: ERROR
    }

    if (action.callback) yield call(action.callback, FAILURE, afterRequestData)

    apiErrorHelpers.consoleLog(errors)
  }
} // maintenanceInterval()

export function* updateIsCompleteVehicleService(action) {
  try {
    const authToken = sessionHelpers.getApplicationCookie(AUTHENTICATION_TOKEN)
    const payload = action.payload || {}
    const payloadOptions = payload.options || {}
    api.setAuthApi({
      Authorization: authToken
    })

    const vehicleServiceRequest = {
      url: VEHICLES_UPADTE_COMPLETED_URL,

      body: {
        service_id: action.payload.service_id,
        is_completed: action.payload.is_completed
      }
    }

    yield put({ type: "SET_LOADING" })

    const vehicleServiceResponse = yield call(
      api.utility.put,
      api.path(vehicleServiceRequest.url),
      {
        body: vehicleServiceRequest.body
      }
    )

    apiErrorHelpers.catchErrors(vehicleServiceResponse.body)

    yield put({
      type: "IS_COMPLETE_SERVICE_SUCCEEDED",
      payload: {
        vehicleService: vehicleServiceResponse.body
      }
    })

    if (action.callback) {
      yield call(action.callback, vehicleServiceResponse.body)
    }

    yield put({ type: "UNSET_LOADING" })
  } catch (errors) {
    // eslint-disable-next-line
    errors = apiErrorHelpers.formatInternalErrors(errors)

    yield put({ type: "LOADING_FAILED" })
    yield put({ type: "SET_LOADING_ERROR" })
    yield put({ type: "UNSET_LOADING" })

    apiErrorHelpers.consoleLog(errors)
  }
} // function* updateIsCompleteVehicleService

function* upComingIntervalServices(action, fleetId) {
  try {
    yield put({
      type: "UPCOMING_INTERVAL_SERVICES_STARTED"
    })

    api.setAuthApi({
      Authorization: sessionHelpers.getApplicationCookie(AUTHENTICATION_TOKEN)
    })

    const response = yield call(api.utility.get, api.pathV6(UPCOMING_INTERVAL_SERVICES_URL))

    apiErrorHelpers.catchErrors(response.body)

    yield put({
      type: "UPCOMING_INTERVAL_SERVICES_SUCCEEDED",
      payload: {
        loading: false,
        upcoming_interval_services: response.body
      }
    })

    if (action.callback) yield call(action.callback, SUCCESS, response.body)
  } catch (errors) {
    // eslint-disable-next-line
    errors = apiErrorHelpers.formatInternalErrors(errors)

    yield put({
      type: "UPCOMING_INTERVAL_SERVICES_FAILED"
    })

    const afterRequestData = {
      alertMessage: apiErrorHelpers.toString(errors),
      alertType: ERROR
    }

    if (action.callback) yield call(action.callback, FAILURE, afterRequestData)

    apiErrorHelpers.consoleLog(errors)
  }
}

export default function* maintenanceIntervalSaga() {
  yield takeEvery("MAINTENANCE_INTERVAL_SAGA", maintenanceInterval)
  yield takeLatest("NEXT_MAINTENANCE_INTERVAL_SAGA", nextMaintenanceInterval)
  yield takeEvery("IS_SERVICE_COMPLETE_SAGA", updateIsCompleteVehicleService)
  yield takeEvery("UPCOMING_INTERVAL_SERVICES", upComingIntervalServices)
}
