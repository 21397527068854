import React from "react"
import ReactSVG from "react-svg"

const HeaderMenu = ({ label, logo, onClick }) => {
  return (
    <div onClick={onClick} className="membership-card-menu">
      <ReactSVG className="menu-logo" src={logo} />
      <p className="menu-label">{label}</p>
    </div>
  )
}

export default HeaderMenu
