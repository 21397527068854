import { put, takeEvery } from "redux-saga/effects"

export function* toggleMobileMenu(action) {
  yield put({ type: "TOGGLE_MOBILE_MENU" })
} // function* toggleMobileMenu

export function* fmcFlip(action) {
  const { payload, callback } = action
  yield put({ type: "FMC_FLIP", payload: payload || {} })
  if (callback) {
    callback()
  }
} // function* fmcFlip

export default function* applicationSaga() {
  yield takeEvery("TOGGLE_MOBILE_MENU_SAGA", toggleMobileMenu)
  yield takeEvery("FMC_FLIP_SAGA", fmcFlip)
  yield takeEvery("SET_FLUTTER_APP_SAGA", setFlutterApp)
}

export function* setFlutterApp(action) {
  const { payload } = action
  yield put({ type: "SET_FLUTTER_APP", payload: payload || null })
} // function* setFlutterApp
