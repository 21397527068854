import React, { useState } from "react"
import { Checkbox, Dropdown, Image, Input, Label, Radio } from "semantic-ui-react"
import MerchantAccountIcon from "../../assets/images/merchant_account.svg"
import { withTranslation } from "react-i18next"
import {
  ACCOUNT_TYPE_OPTIONS,
  DEFAULT_COUNTRY,
  ONLY_NUMBER_REGEX,
  ROUTING_NUMBER_REGEX,
  ACCOUNT_NUMBER_REGEX,
  OWNERSHIP_TYPE_OPTIONS,
  RADIO,
  STRING,
  businessName,
  confirmBankAccountNumber,
  firstName,
  lastName,
  locality,
  number,
  ownershipType,
  personal,
  State,
  text,
  region,
  ZIPCODE_REGEX,
  ACCOUNT_NUMBER
} from "../../constants/achPayment"
import useCountryStateCity from "../../helpers/useCountryStateCity"
import { ZIP_CODE_REGEX } from "../../utilities/stringHelpers"

const ACHForm = ({ t, handleACHValidation, achFormFields, achErrorMessage, setIsConsent }) => {
  const { getCountriesDropdown, getStateByCountry } = useCountryStateCity()
  const [isConsentChecked, setIsConsentChecked] = useState(true)
  const [achForm, setAchForm] = useState({
    routingNumber: {
      type: "number",
      label: t("routingNumber"),
      placeholder: t("routingNumberPlaceholder"),
      value: "",
      isError: false,
      error: t("routingNumberError"),
      isRequired: true,
      validateFunc: (routingNumber, formData) => {
        return !ROUTING_NUMBER_REGEX.test(routingNumber)
      },
      minLength: 9
    },
    accountNumber: {
      type: "number",
      label: t("bankAccountNumber"),
      placeholder: t("banckAccountNumberPlaceholder"),
      value: "",
      isError: false,
      error: t("bankAccountNumberError"),
      preventCopyPaste: true,
      isRequired: true,
      validateFunc: (accountNumber, formData) => {
        return !ACCOUNT_NUMBER_REGEX.test(accountNumber)
      }
    },
    confirmBankAccountNumber: {
      type: "number",
      label: t("confirmAccount"),
      placeholder: t("confirmAccountPlaceholder"),
      value: "",
      isError: false,
      error: t("confirmAccountError"),
      preventCopyPaste: true,
      isRequired: true,
      validateFunc: (confirmBankAccountNumber, formData) => {
        return !(
          formData.accountNumber.value &&
          confirmBankAccountNumber &&
          formData.accountNumber.value === confirmBankAccountNumber
        )
      }
    },
    accountType: {
      type: "radio",
      label: t("accountType"),
      placeholder: t("accountTypePlaceholder"),
      value: "",
      isError: false,
      error: t("accountTypeError"),
      options: ACCOUNT_TYPE_OPTIONS,
      isRequired: true,
      radioName: "accountTypeRadio"
    },
    ownershipType: {
      type: "radio",
      label: t("ownershipType"),
      placeholder: t("ownershipTypePlaceholder"),
      value: "",
      isError: false,
      error: t("ownershipTypeError"),
      options: OWNERSHIP_TYPE_OPTIONS,
      isRequired: true,
      radioName: "ownershipTypeRadio"
    },
    streetAddress: {
      type: "text",
      label: t("streetAddress"),
      placeholder: t("streetAddressPlaceholder"),
      value: "",
      isError: false,
      error: t("streetAddressError"),
      isRequired: true
    },
    extendedAddress: {
      type: "text",
      label: t("extendedAddress"),
      placeholder: t("extendedAddressPlaceholder"),
      value: "",
      isError: false
    },
    region: {
      type: "select",
      label: t("state"),
      placeholder: t("statePlaceholder"),
      value: "",
      isError: false,
      error: t("stateError"),
      options:
        getStateByCountry(DEFAULT_COUNTRY).map((state) => {
          return {
            key: state.isoCode,
            value: state.isoCode,
            text: state.name
          }
        }) || [],
      isRequired: true
    },
    locality: {
      type: "select",
      label: t("country"),
      placeholder: t("selectCountry"),
      value: getCountriesDropdown().find((country) => country.value === DEFAULT_COUNTRY).value,
      isError: false,
      error: t("selectCountryError"),
      options: getCountriesDropdown(),
      isRequired: true,
      disabled: true
    },
    postalCode: {
      type: "number",
      label: t("zipCode"),
      placeholder: t("zipCodePlaceholder"),
      value: "",
      isError: false,
      error: t("zipCodeError"),
      isRequired: true,
      minLength: 5,
      validateFunc: (routingNumber, formData) => {
        return !ZIPCODE_REGEX.test(routingNumber)
      }
    }
  })

  const optionalForm = {
    firstName: {
      type: "text",
      label: t("firstName"),
      placeholder: t("firstNamePlaceholder"),
      value: "",
      isError: false,
      error: t("firstNameError"),
      isRequired: true
    },
    lastName: {
      type: "text",
      label: t("lastName"),
      placeholder: t("lastNamePlaceholder"),
      value: "",
      isError: false,
      error: t("lastNameError"),
      isRequired: true
    },
    businessName: {
      type: "text",
      label: t("businessName"),
      placeholder: t("businessPlaceholder"),
      value: "",
      isError: false,
      error: t("businessError"),
      isRequired: true
    }
  }

  const handleACHForm = (value, fieldName, fieldType) => {
    if (
      (fieldType === number && !ONLY_NUMBER_REGEX.test(value)) ||
      (achForm[fieldName].minLength && value.length > achForm[fieldName].minLength)
    ) {
      return
    }
    let formData = { ...achForm }
    formData[fieldName] = {
      ...formData[fieldName],
      value: value,
      isError: formData[fieldName].validateFunc
        ? formData[fieldName].validateFunc(value, formData)
        : false
    }

    if (fieldName == ACCOUNT_NUMBER) {
      formData[confirmBankAccountNumber] = {
        ...formData[confirmBankAccountNumber],
        isError: formData[confirmBankAccountNumber].validateFunc(
          formData.confirmBankAccountNumber.value,
          formData
        )
      }
    }

    if (fieldName === ownershipType && value) {
      const ownershipTypeIndex = Object.keys(formData).indexOf(ownershipType)

      const beforeOwnership = Object.entries(formData).slice(0, ownershipTypeIndex + 1)
      const afterOwnership = Object.entries(formData).slice(ownershipTypeIndex + 1)

      if (value === personal) {
        formData = {
          ...Object.fromEntries(beforeOwnership),
          firstName: optionalForm.firstName,
          lastName: optionalForm.lastName,
          ...Object.fromEntries(afterOwnership)
        }
        delete formData[businessName]
      } else {
        formData = {
          ...Object.fromEntries(beforeOwnership),
          businessName: optionalForm.businessName,
          ...Object.fromEntries(afterOwnership)
        }
        delete formData[firstName]
        delete formData[lastName]
      }
    }
    if (fieldName === locality && value) {
      const STATES = getStateByCountry(value)
      formData[region] = {
        ...formData.locality,
        options:
          STATES && STATES.length > 0
            ? STATES.map((state) => {
                return {
                  key: state.isoCode,
                  value: state.isoCode,
                  text: state.name
                }
              })
            : []
      }
    }

    const formPayload = Object.entries(formData)
      .map(([field, fieldData]) => {
        return {
          [field]: fieldData.value
        }
      })
      .reduce((acc, current) => {
        return { ...acc, ...current }
      }, {})

    setAchForm(formData)
    achFormFields(formData)
    handleACHValidation(
      Object.values(formData).every(
        (field) =>
          !field.isError &&
          (typeof field.value === STRING
            ? field.value.trim() !== "" || field.label == t("extendedAddress")
            : Array.isArray(field.value) && field.value.length > 0)
      ),
      formPayload
    )
  }

  const setIsConsentValue = (value) => {
    setIsConsentChecked(value)
    setIsConsent(value)
  }
  return (
    <div className="ach_modal ach_modal__bg">
      <div className="ach_modal__title">
        <Image src={MerchantAccountIcon} />
        <p className="ach_title">{t("achMethod")}</p>
      </div>
      <div className="ach_modal__container">
        {achErrorMessage && (
          <Label className="ach_modal__error" pointing>
            {achErrorMessage}
          </Label>
        )}
        {Object.entries(achForm).map(([field, fieldData], index) => (
          <div className="ach_modal__form" key={field}>
            {field !== firstName && field !== lastName ? (
              <>
                {fieldData.type === text || fieldData.type === number ? (
                  <>
                    <p className="ach_modal__label">{fieldData.label}</p>
                    <Input
                      className="ach_modal__input"
                      placeholder={fieldData.placeholder}
                      value={fieldData.value}
                      name={field}
                      type="text"
                      onChange={(e) => handleACHForm(e.target.value, field, fieldData.type)}
                      onPaste={(e) => fieldData.preventCopyPaste && e.preventDefault()}
                    />
                  </>
                ) : fieldData.type === RADIO ? (
                  <div className="ach_form__radio">
                    <p className="radio_label__width">{fieldData.label}</p>
                    {fieldData.options.map((radioBtn) => (
                      <Radio
                        label={radioBtn.text}
                        name={fieldData.radioName}
                        key={radioBtn.key}
                        value={radioBtn.value}
                        className="ach_account__type"
                        checked={fieldData.value === radioBtn.value}
                        onChange={(e, { value }) => handleACHForm(value, field)}
                      />
                    ))}
                  </div>
                ) : (
                  <>
                    <p className="ach_modal__label">{fieldData.label}</p>
                    <Dropdown
                      className="ach_modal__select"
                      options={fieldData.options}
                      placeholder={fieldData.placeholder}
                      value={fieldData.value}
                      search
                      selection
                      fluid
                      disabled={fieldData.disabled}
                      onChange={(e, { value }) => handleACHForm(value, field)}
                    />
                  </>
                )}
                {fieldData.isError && (
                  <Label className="ach_modal__error" pointing>
                    {fieldData.error}
                  </Label>
                )}
              </>
            ) : (
              field === firstName && (
                <div className="ach_modal__userform" key={field}>
                  {Object.entries(achForm).map(
                    ([field, fieldData]) =>
                      (field === firstName || field === lastName) && (
                        <React.Fragment key={field}>
                          <div className="user_form__container">
                            <p className="ach_modal__label">{fieldData.label}</p>
                            <Input
                              className="ach_modal__input"
                              placeholder={fieldData.placeholder}
                              value={fieldData.value}
                              name={field}
                              onChange={(e) => handleACHForm(e.target.value, field)}
                              onPaste={(e) => fieldData.preventCopyPaste && e.preventDefault()}
                            />
                            {fieldData.isError && (
                              <Label className="ach_modal__error" pointing>
                                {fieldData.error}
                              </Label>
                            )}
                          </div>
                        </React.Fragment>
                      )
                  )}
                </div>
              )
            )}
          </div>
        ))}

        <div className="default_consent">
          <Checkbox
            checked={isConsentChecked}
            onChange={(e, data) => setIsConsentValue(data.checked)}
            className="consent_text"
            label={t("setAsDefault")}
          />
        </div>
      </div>
    </div>
  )
}

export default withTranslation("achPayment")(ACHForm)
