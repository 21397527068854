import React, { Component } from "react"
import { connect } from "react-redux"
import PropTypes from "prop-types"
import moment from "moment"

import "react-datepicker/dist/react-datepicker.css"

import Button from "../../elements/Button"
import DatePicker from "react-datepicker"
import Select from "../../elements/Select"

import { UnavailableCompanyMessage } from "../../helpers/shopHelpers"
import { Date_Format } from "../../constants/years"
import { withTranslation } from "react-i18next"
import { ON } from "../config/SplitClient"
import { isEmpty } from "lodash"
import { FAULT_CODE_INSPECTION } from "../../constants/services"
import { trackEvent } from "../../utilities/segmentAnalytics"
import { getServiceNameForMap, getServicesWithPrice } from "../../helpers/serviceHelpers"
import {
  getAppointmentDateTime,
  getBookingAppointmentDateTime,
  getBookingAppointmentTime
} from "../../helpers/segmentHelpers"
import { appointmentDateTimeSelectedEvents } from "../../helpers/googleAnalyticsHelpers"

class ServiceRequestScheduleForm extends Component {
  static propTypes = {
    onSubmit: PropTypes.func.isRequired,
    displayError: PropTypes.func.isRequired,
    previousFormData: PropTypes.object.isRequired,
    isSubmitting: PropTypes.bool
  }

  static defaultProps = {
    isSubmitting: false
  }

  constructor(props) {
    super(props)

    const date = moment()

    this.state = {
      date: date,
      time: "",
      isLoaded: false
    }
    this.findAvailableTimes(date)
  }

  componentDidMount() {
    const { isGeotabSectionFlag, previousFormData } = this.props
    if (
      isGeotabSectionFlag === ON &&
      previousFormData.vehicle &&
      previousFormData.vehicle.geotab_device_id !== null
    ) {
      this.fetchGeotabFaultCodesData(this.props.dispatch, previousFormData.vehicle_id)
    }
  }

  fetchGeotabFaultCodesData = (dispatch, vehicleId) => {
    dispatch({
      type: "VEHICLE_GEOTAB_FAULT_CODES__INFO_LOAD_SAGA",
      payload: {
        vehicleId: vehicleId
      }
    })
  }

  allowSubmit() {
    const { isSubmitting } = this.props

    const { date, time } = this.state

    return !isSubmitting && date && time
  }

  handleSubmit() {
    const { onSubmit, previousFormData, faultCodesData } = this.props

    const { date, time } = this.state

    const selectedDateTimeString = `${date.format(Date_Format.YYYY_MM_DD)}T${time}`

    const formData = { ...previousFormData }
    formData["appointment_datetime"] = selectedDateTimeString
    formData[
      "payment_method_availability"
    ] = this.props.availableTimesData[0].payment_method_availability
    if (
      previousFormData.order_services.every((service) => service.name === FAULT_CODE_INSPECTION) &&
      previousFormData.vehicle.geotab_device_id !== null
    ) {
      formData["fault_codes"] = faultCodesData
    }
    let eventData = {}

    if (formData) {
      eventData.appointmentDate = getBookingAppointmentDateTime(formData.appointment_datetime)
      eventData.appointmentTime = getBookingAppointmentTime(formData.appointment_datetime)
      if (formData.shop) {
        const { name, address_line1, city, state, zip } = formData.shop
        eventData.shopName = name
        eventData.shopAddress = `${address_line1} ${city}, ${state} ${zip}`
      }

      eventData.servicesSelected =
        formData.order_services &&
        formData.order_services.length > 0 &&
        formData.order_services.map(getServiceNameForMap)
      if (formData.vehicle) {
        const { year, make, model, vin } = formData.vehicle
        eventData.vehicleYear = year
        eventData.vehicleMake = make
        eventData.vehicleModel = model
        eventData.vin = vin
      }
    }

    trackEvent("request-appointment-clicked", eventData)

    onSubmit(formData)
  }

  // NOTE: The onChange functions are split because the argument expectations of the DatePicker library component

  onDateChange = (value) => {
    // NOTE: value is a momentJS object.
    this.setState({ date: value, time: "" })
    this.findAvailableTimes(value)
  }

  findAvailableTimes = (date) => {
    const { dispatch, previousFormData, displayError } = this.props
    const { order_services, shop_id, vehicle_id } = previousFormData || {}

    this.setState({ isLoaded: false })
    dispatch({
      type: "SERVICE_REQUEST_GET_AVAILABLE_TIMES_SAGA",
      payload: {
        date: this.formattedDate(date),
        shopId: shop_id,
        vehicleId: vehicle_id,
        services: order_services,
        callback: (errorMessage = "") => {
          this.setState({ isLoaded: true })
          // Always call this to clear old errors
          displayError({ error: errorMessage })
        }
      }
    })
  }

  formattedDate = (date) => {
    return moment.isMoment(date) ? date.format(Date_Format.YYYY_MM_DD) : date
  }

  onTimeChange = (event) => {
    this.setState({ time: event.target.value }, () => {
      this.handleGADateAndTimeObj()
    })
    let formData = this.props.previousFormData
    let eventData = {}

    if (formData && formData.shop) {
      eventData.appointmentDate = getAppointmentDateTime(this.state.date, event.target.value)
      eventData.appointmentTime = event.target.value
      const { name, address_line1, city, state, zip } = formData.shop
      eventData.shopName = name
      eventData.shopAddress = `${address_line1} ${city}, ${state} ${zip}`
    }

    if (formData && formData.order_services && formData.order_services.length > 0) {
      eventData.servicesSelected = formData.order_services.map(getServiceNameForMap)
    } else {
      eventData.servicesSelected = []
    }

    if (formData && formData.vehicle) {
      const { year, make, model, vin } = formData.vehicle
      eventData.vehicleYear = year
      eventData.vehicleMake = make
      eventData.vehicleModel = model
      eventData.vin = vin
    }

    trackEvent("click-continue", eventData)
  }

  timeSelectOptions() {
    const { availableTimesData } = this.props
    const dateString = this.formattedDate(this.state.date)

    const dateData = (availableTimesData || []).find((data) => data.date === dateString) || {}

    return this.formattedAvailableTimes(dateData.times)
  }

  formattedAvailableTimes = (data) => {
    // NOTE: Label converts hours from military to standard time. Adds AM/PM.
    return (data || []).map((time) => {
      return { label: moment(time, "HH:mm").format("hh:mm A"), value: time }
    })
  }

  centerPTagStyle() {
    return { height: "38px", display: "flex", flexDirection: "column", justifyContent: "center" }
  }

  handleGADateAndTimeObj = () => {
    const { previousFormData } = this.props

    appointmentDateTimeSelectedEvents({
      shop: previousFormData.shop,
      vehicle: previousFormData.vehicle,
      order_services: getServicesWithPrice(
        previousFormData.order_services,
        previousFormData.vehicle
      ),
      appointment_datetime: getAppointmentDateTime(this.state.date, this.state.time),
      booking_appointment_time: this.state.time
    })
  }

  render() {
    const { date, time, isLoaded } = this.state
    const timeOptions = this.timeSelectOptions()
    const { t } = this.props

    return (
      <div>
        <div className={"row"}>
          <div className={"column--12 content-center"}>
            {UnavailableCompanyMessage(this.props.previousFormData.shop.company, t)}
            <DatePicker
              inline
              selected={date}
              onChange={this.onDateChange}
              minDate={moment()}
              maxDate={moment().add(30, "days")}
            />
          </div>
        </div>

        <div className={"row"}>
          <div className={"column--12 content-center"}>
            {isLoaded ? (
              timeOptions.length > 0 ? (
                <Select
                  className={"select--small calendar__select"}
                  onChange={this.onTimeChange}
                  options={timeOptions}
                  placeholder={t("selectTimeLabel")}
                  value={time}
                />
              ) : (
                <p style={this.centerPTagStyle()}>{t("noTimesAvailableLabel")}</p>
              )
            ) : (
              <p style={this.centerPTagStyle()}>{t("common:loadingLabel")}</p>
            )}
          </div>
        </div>

        <div className={"row"}>
          <div className={"column--12 content-center"}>
            <Button
              disabled={!this.allowSubmit()}
              label={t("submitServiceRequest")}
              onClick={this.handleSubmit.bind(this)}
            />
          </div>
        </div>
      </div>
    )
  }
} // ServiceRequestScheduleForm

const mapStateToProps = (state) => {
  const { geotabFaultCodesInfo } = state.vehicles
  let faultCodesData = {}
  if (!isEmpty(geotabFaultCodesInfo)) {
    faultCodesData =
      geotabFaultCodesInfo &&
      geotabFaultCodesInfo.faultCodesData.map(({ id, diagnostic }) => ({
        id: id,
        name: diagnostic.name
      }))
  }
  return {
    availableTimesData: state.serviceRequests.availableTimesData,
    faultCodesData: faultCodesData
  }
}

export default connect(mapStateToProps)(
  withTranslation("scheduleRequest")(ServiceRequestScheduleForm)
)
