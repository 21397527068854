import React, { Component } from "react"
import PropTypes from "prop-types"

import * as sessionHelpers from "../../helpers/sessionHelpers"

import Button from "../../elements/Button"
import LabeledCheckbox from "../../elements/LabeledCheckbox"
import PasswordField from "../../elements/PasswordField"
import { CURRENT_USER_EMAIL } from "../../constants/application"

class ChangePasswordForm extends Component {
  static propTypes = {
    onSubmit: PropTypes.func.isRequired,

    isSubmitting: PropTypes.bool
  }

  static defaultProps = {
    isSubmitting: false
  }

  state = {
    formData: {
      currentPassword: "",
      email: sessionHelpers.getApplicationCookie(CURRENT_USER_EMAIL),
      newPassword: "",
      newPasswordConfirmation: ""
    },
    obfuscateFields: true
  }

  allowSubmit() {
    const { isSubmitting } = this.props

    const { currentPassword, newPassword, newPasswordConfirmation } = this.state.formData

    return (
      !isSubmitting &&
      currentPassword.length > 0 &&
      newPassword.length > 0 &&
      newPasswordConfirmation.length > 0
    )
  }

  onFieldChange(field, value) {
    let updatedFormData = { ...this.state.formData }
    updatedFormData[field] = value
    this.setState({ formData: updatedFormData })
  }

  onObfuscationChange() {
    const { obfuscateFields } = this.state
    this.setState({ obfuscateFields: !obfuscateFields })
  }

  render() {
    const { onSubmit } = this.props

    const { formData, obfuscateFields } = this.state

    const { currentPassword, newPassword, newPasswordConfirmation } = formData

    return (
      <div className={"change_password_form"}>
        <div className={"row"}>
          <div className={"column--4"}>
            <PasswordField
              className={"password_field--small change_password_form__field"}
              obfuscated={obfuscateFields}
              onChange={(event) => this.onFieldChange("currentPassword", event.target.value)}
              placeholder={"Current Password"}
              value={currentPassword}
            />
          </div>

          <div className={"column--4"}>
            <PasswordField
              className={"password_field--small change_password_form__field"}
              obfuscated={obfuscateFields}
              onChange={(event) => this.onFieldChange("newPassword", event.target.value)}
              placeholder={"New Password"}
              value={newPassword}
            />
          </div>

          <div className={"column--4"}>
            <PasswordField
              className={"password_field--small change_password_form__field"}
              obfuscated={obfuscateFields}
              onChange={(event) =>
                this.onFieldChange("newPasswordConfirmation", event.target.value)
              }
              placeholder={"New Password Confirmation"}
              value={newPasswordConfirmation}
            />
          </div>

          <div className={"column--4"}>
            <Button
              className={"button--primary change_password_form__button"}
              disabled={!this.allowSubmit()}
              label={"SUBMIT"}
              onClick={() => onSubmit(this.state.formData)}
            />
          </div>
        </div>

        <div className={"row"}>
          <div className={"column--12"}>
            <LabeledCheckbox
              label={"Show Passwords"}
              onChange={() => this.onObfuscationChange()}
              checked={!obfuscateFields}
            />
          </div>
        </div>
      </div>
    )
  }
} // class ChangePasswordForm

export default ChangePasswordForm
