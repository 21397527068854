import React, { useState } from "react"
import { Modal } from "semantic-ui-react"
import { useTranslation, withTranslation } from "react-i18next"
import TextField from "../../elements/TextField"
import store from "../../actions/configureStore"
import CustomNotification from "./CustomNotification"
import { convertDateToString } from "../../constants/reports"
import { getFmcAndFleetId } from "../../actions/sagas/reportingSaga"

const SaveReportModal = ({ modalOpen, service, handleClose, filters, setFilterData }) => {
  const [reportName, setReportName] = useState("")
  const [save_disabled, setSaveDisabled] = useState(true)
  const { t } = useTranslation("reports")

  const onChange = (e) => {
    const newValue = e.target.value
    if (reportName !== newValue) setSaveDisabled(false)
    setReportName(newValue)
    if (!newValue) setSaveDisabled(true)
  }

  const handleSaveReport = () => {
    const { isFMC, fleetId } = getFmcAndFleetId()

    setSaveDisabled(true)
    store.dispatch({
      type: "CREATE_UPDATE_FILTER",
      payload: {
        filter_name: reportName,
        filters: {
          ...convertDateToString(filters),
          ...(isFMC ? {} : { fleet_id: [fleetId] })
        },
        isFMC: isFMC,
        t: t
      },
      callback: afterFilterSaved
    })
  }

  const afterFilterSaved = (status, data) => {
    const { type, title, message, filter } = data
    setFilterData(filter)
    closeSaveReportModal()
    return CustomNotification(type, title, message, t)
  }

  const closeSaveReportModal = () => {
    handleClose()
    setReportName("")
  }

  const renderView = () => (
    <React.Fragment>
      <div className="add_model_section">
        <div className="header_section">
          <span className="header_section_label">{t("chooseReportNameLabel")}</span>
          <span>{service && service.name}</span>
        </div>
        <div className="input_field_section">
          <TextField
            value={reportName}
            className="input_field"
            onChange={onChange}
            placeholder={t("placeholderReportNameLabel")}
          />
        </div>
        <div className="buttons_section">
          <div className="button_section hand-icon">
            <div className="button_cover">
              <div className="button">
                <span className="button_text" onClick={() => closeSaveReportModal()}>
                  {t("services:cancelText")}
                </span>
              </div>
            </div>
          </div>
          {save_disabled ? (
            <div className="disabled_save_button_section hand-icon">
              <div className="disable_button_cover">
                <span className="save_text">{t("services:saveText")}</span>
              </div>
            </div>
          ) : (
            <div
              className="enabled_button_section hand-icon orange-button"
              onClick={handleSaveReport}
            >
              <div className="button_cover">
                <div className="button">
                  <span className="button_text">{t("services:saveText")}</span>
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
    </React.Fragment>
  )

  return (
    <Modal
      size="mini"
      className="custom-modal"
      open={modalOpen}
      closeIcon
      onClose={closeSaveReportModal}
    >
      <Modal.Content>{renderView()}</Modal.Content>
    </Modal>
  )
}

export default withTranslation("reports")(SaveReportModal)
