import React, { Component } from "react"
// eslint-disable-next-line
import { getSubdomain } from "../components/shared/StylesheetInjector"
import PrivacyPolicyFooter from "../views/sessions/PrivacyPolicyFooter"
import { connect } from "react-redux"
import { setupSplitFlags } from "../components/config/SplitClient"
import { withTranslation } from "react-i18next"
import NoSessionLeftPanel from "./NoSessionLeftPanel"

class NoSessionLayout extends Component {
  constructor(props) {
    super(props)
    this.state = {}
  }

  async componentDidMount() {
    await setupSplitFlags.bind(this)({ sub_domain: getSubdomain() })
  }

  render() {
    return (
      <div className={"app"}>
        <div className={"no-session-container"}>
          <NoSessionLeftPanel />
          {this.props.children}
        </div>
        {this.state.showFooterComponent && this.state.showFooterComponent == getSubdomain() && (
          <PrivacyPolicyFooter footerComponentConfig={this.state.footerComponentConfig} />
        )}
      </div>
    )
  }
} // NoSessionLayout

const mapStateToProps = (state) => ({
  currentUser: state.users.currentUser
})

export default connect(mapStateToProps)(withTranslation("common")(NoSessionLayout))
