import React, { Component } from "react"
import PropTypes from "prop-types"

import Select from "react-select"

class MultiSelect extends Component {
  static propTypes = {
    onChange: PropTypes.func.isRequired,
    options: PropTypes.array.isRequired,
    placeholder: PropTypes.string.isRequired,
    className: PropTypes.string,
    disabled: PropTypes.bool,
    name: PropTypes.string,
    value: PropTypes.array.isRequired
  }

  static defaultProps = {
    className: "",
    disabled: false,
    name: "",
    value: []
  }

  render() {
    const {
      className,
      disabled,
      name,
      onChange,
      options,
      placeholder,
      value,
      md_hd_label_color,
      customFilterOption
    } = this.props

    const colourStyles = {
      option: (provided, state) => ({
        ...provided,
        color: state.value == null ? md_hd_label_color : ""
      })
    }

    return (
      <Select
        className={className}
        isDisabled={disabled}
        isMulti={true}
        name={name}
        onChange={onChange}
        options={options}
        placeholder={placeholder}
        value={options.filter((option) => value.includes(option.value))}
        styles={colourStyles}
        filterOption={customFilterOption}
      />
    )
  }
}

export default MultiSelect
