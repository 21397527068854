import { call, put, takeEvery } from "redux-saga/effects"

import api from "../../utilities/api"
import * as apiErrorHelpers from "../../helpers/apiErrorHelpers"
import * as sessionHelpers from "../../helpers/sessionHelpers"
import moment from "moment"
import { Date_Format } from "../../constants/years"
import { AUTHENTICATION_TOKEN, MONTH, YEARS } from "../../constants/application"
import { ERROR, FAILURE, SUCCESS } from "../../constants/notification"

const LOAD_VEHICLES = "/vehicles"
const ORDERS_AGGREGATE_URL = "/orders/aggregate"
const LOAD_ORDER_SUMMARY = "/fleets/orders_summary"

export function* loadDashboard(action) {
  try {
    yield put({ type: "SET_LOADING" })

    yield put({ type: "UNSET_LOADING_ERROR" })

    api.setAuthApi({
      Authorization: sessionHelpers.getApplicationCookie(AUTHENTICATION_TOKEN)
    })

    ///////////////////////
    //  LOAD USERS DATA  //
    ///////////////////////
    // yield put({ type: "USERS_LOAD_REQUESTED" })

    // const usersRequest = {
    //   url: "/fleet_users",
    //   body: {}
    // }
    //
    // const usersResponse = yield call(api.utility.get, api.path(usersRequest.url), {
    //   body: usersRequest.body
    // })
    //
    // apiErrorHelpers.catchErrors(usersResponse.body)
    // yield put({
    //   type: "USERS_LOAD_SUCCEEDED",
    //   payload: {
    //     users: usersResponse.body
    //   }
    // })
    ///////////////////////

    ////////////////////////
    // LOAD VEHICLES DATA //
    ////////////////////////
    yield put({ type: "VEHICLES_LOAD_REQUESTED" })

    const vehiclesRequest = {
      url: LOAD_VEHICLES,
      body: {
        request: "active_order"
      }
    }

    const vehiclesResponse = yield call(api.utility.get, api.path(vehiclesRequest.url), {
      body: vehiclesRequest.body
    })

    apiErrorHelpers.catchErrors(vehiclesResponse.body)

    yield put({
      type: "VEHICLES_LOAD_SUCCEEDED",
      payload: {
        vehicles: vehiclesResponse.body
      }
    })

    yield put({
      type: "SET_VEHICLES_DATA_TYPE",
      payload: { dataType: "extended" }
    })
    ///////////////////////

    ////////////////////////
    // LOAD SERVICE COSTS DATA //
    ////////////////////////
    yield put({ type: "SERVICE_COSTS_LOAD_REQUESTED" })

    const serviceCostsRequest = {
      url: ORDERS_AGGREGATE_URL,
      body: {
        start_date: moment().subtract(YEARS, 1).format(Date_Format.YYYY_MM_01),
        end_date: moment().endOf(MONTH).format(Date_Format.YYYY_MM_DD),
        grouping: MONTH
      }
    }

    const serviceCostsResponse = yield call(api.utility.get, api.path(serviceCostsRequest.url), {
      body: serviceCostsRequest.body
    })

    apiErrorHelpers.catchErrors(serviceCostsResponse.body)

    yield put({
      type: "SERVICE_COSTS_LOAD_SUCCEEDED",
      payload: {
        serviceCosts: serviceCostsResponse.body
      }
    })

    yield put({ type: "UNSET_LOADING" })

    /////////////////////////
    //  LOAD INTERVAL DATA ASYNC //
    /////////////////////////
    yield put({ type: "MAINTENANCE_INTERVAL_SAGA" })
  } catch (errors) {
    // eslint-disable-next-line
    errors = apiErrorHelpers.formatInternalErrors(errors)

    yield put({ type: "LOADING_FAILED" })
    yield put({ type: "SET_LOADING_ERROR" })
    yield put({ type: "UNSET_LOADING" })

    apiErrorHelpers.consoleLog(errors)
  }
} // function* loadDashboard

export function* loadOrderSummary(action) {
  try {
    api.setAuthApi({
      Authorization: sessionHelpers.getApplicationCookie(AUTHENTICATION_TOKEN)
    })

    yield put({ type: "ORDER_SUMMARY_LOAD" })

    const summaryRequest = {
      url: LOAD_ORDER_SUMMARY
    }

    const summaryResponse = yield call(api.utility.get, api.pathV6(summaryRequest.url), {
      body: summaryRequest.body
    })

    apiErrorHelpers.catchErrors(summaryResponse.body)

    yield put({
      type: "ORDER_SUMMARY_LOAD_SUCCEEDED",
      payload: summaryResponse.body
    })
  } catch (errors) {
    errors = apiErrorHelpers.formatInternalErrors(errors)

    yield put({ type: "ORDER_SUMMARY_LOAD_FAILED" })
    yield put({ type: "SET_LOADING_ERROR" })
    yield put({ type: "UNSET_LOADING" })

    if (action.callback) yield call(action.callback, FAILURE)

    apiErrorHelpers.consoleLog(errors)
  }
} // function loadOrderSummary

export default function* dashboardSaga() {
  yield takeEvery("DASHBOARD_LOAD_SAGA", loadDashboard)
  yield takeEvery("LOAD_ORDER_SUMMARY", loadOrderSummary)
}
