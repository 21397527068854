export const firstName = "firstName"
export const lastName = "lastName"
export const confirmBankAccountNumber = "confirmBankAccountNumber"
export const text = "text"
export const ownershipType = "ownershipType"
export const personal = "personal"
export const businessName = "businessName"
export const locality = "locality"
export const region = "region"
export const STRING = "string"
export const RADIO = "radio"
export const DEFAULT_COUNTRY = "US"
export const number = "number"
export const ACCOUNT_NUMBER = "accountNumber"

export const ONLY_NUMBER_REGEX = /^[0-9]*$/
export const ROUTING_NUMBER_REGEX = /^\d{9}$/
export const ACCOUNT_NUMBER_REGEX = /^\d{4,17}$/
export const ZIPCODE_REGEX = /^\d{5}$/

export const ACH_LABEL = "achLabel"
export const CREDIT_CARD_WITH_ACH_LABEL = "creditCardWithAchLabel"
export const CREDIT_CARD_LABEL = "creditCardLabel"

export const ACCOUNT_TYPE_OPTIONS = [
  {
    key: "1",
    value: "checking",
    text: "Checking"
  },
  {
    key: "2",
    value: "savings",
    text: "Savings"
  }
]

export const OWNERSHIP_TYPE_OPTIONS = [
  {
    key: "personal",
    value: "personal",
    text: "Personal"
  },
  {
    key: "business",
    value: "business",
    text: "Business"
  }
]
