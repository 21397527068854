import React from "react"
import * as vehicleHelpers from "../../../../helpers/vehicleHelpers"
import { Icon } from "semantic-ui-react"
import ButtonLink from "../../../shared/ButtonLink"
import { navigationPaths } from "../../../../constants/paths"
import SettingsIcon from "../../../../assets/images/settings_icon.svg"
import HistoryIcon from "../../../../assets/images/history_icon.svg"
import { isFleetAdvise } from "../../../../helpers/affiliationHelpers"

const PageTitle = (props) => {
  const {
    vehicle = {},
    onBackClick = () => {},
    showBack,
    showButtons = false,
    t = {},
    goToHistory = () => {}
  } = props

  return (
    <>
      <div className="maintenanceScheduleIndexActions">
        {showBack ? (
          <Icon.Group size="large" className="back-icon">
            <Icon name="arrow left" onClick={onBackClick} />
          </Icon.Group>
        ) : (
          <div />
        )}
        <div>
          <p className={`vehicle-name ${isFleetAdvise() ? "vehicle-name-text-color" : ""}`}>
            {" "}
            {vehicleHelpers.formattedName(vehicle)}{" "}
          </p>
          <p className="vehicle-vin">
            {t("vin")} {vehicle.vin}
          </p>
        </div>
      </div>
      {showButtons && showBack ? (
        <ButtonLink
          linkPath={"/"}
          icon={HistoryIcon}
          content={t("History")}
          onClickFunction={goToHistory}
        />
      ) : showButtons && !showBack ? (
        <ButtonLink
          linkPath={{
            pathname: navigationPaths.settingsIndex(),
            state: 2
          }}
          icon={SettingsIcon}
          content={t("Settings")}
        />
      ) : null}
    </>
  )
}

export default PageTitle
