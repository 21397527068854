import { constants } from "caradvise_shared_components"
const { INITIATED_BY_USER, ACTIVE, AWAITING_APPROVAL, COMPLETED, PAID, APPROVAL } = constants.orders
export const AWAITING_APPROVAL_LABEL = "Needs Approval"
export const INITIATED_LABEL = "Initiated"
export const APPROVED_LABEL = "Approved"
export const COMPLETED_LABEL = "Completed"

export function getOrderStatus(order) {
  if ([INITIATED_BY_USER, ACTIVE].includes(order.state)) return INITIATED_LABEL
  else if (order.state == AWAITING_APPROVAL) return AWAITING_APPROVAL_LABEL
  else if ([COMPLETED, PAID].includes(order.state)) return COMPLETED_LABEL
  else if ([APPROVAL].includes(order.state)) return APPROVED_LABEL
}
