import {
  REMOVE_INITIAL_ZERO_REGEX,
  TWO_DIGIT_DECIMAL_ALLOW_REGEX
} from "../constants/maintenanceHistoryForm"
import { MANUAL_MAINTENANCE_TYPE } from "../constants/services"
import { PAID, SERVICE_COMPLETED } from "../constants/statuses"

export function manualMaintenanceHistoriesWithVehicle(manualMaintenanceHistories, vehicles) {
  return manualMaintenanceHistories.map((history) => {
    const vehicle = (vehicles || []).find((vehicle) => vehicle.id === history.vehicle_id)
    history.vehicle = vehicle || {}
    return history
  })
}

export function preparedMaintenanceHistories(maintenanceHistories) {
  return maintenanceHistories.map((history) => {
    const orderServices = history.order_services || []
    const service = orderServices[0] || {}
    return {
      ...history,
      maintenance_type: MANUAL_MAINTENANCE_TYPE,
      status: PAID,
      miles: (service.odometer_config || {}).quantity,
      order_services: orderServices.map((service) => ({ ...service, status: SERVICE_COMPLETED }))
    }
  })
}

export const removeInitialZeros = (value) => {
  return value.toString().startsWith("0") && value.toString() !== "0"
    ? value.toString().replace(REMOVE_INITIAL_ZERO_REGEX, "")
    : value.toString()
}

export const IsNaN = (value) => {
  return isNaN(value)
}

export const roundUpTwoDecimals = (value) => {
  const regex = TWO_DIGIT_DECIMAL_ALLOW_REGEX // Regex to allow only up to two digits after the decimal point
  return regex.test(value)
}

export const convertTwoDigitDecimal = (value) => {
  return parseFloat(value).toFixed(2)
}
