import React, { Component } from "react"
import PropTypes from "prop-types"
import { KEYWORDS } from "../constants/common"

const getDefaultTime = (start, end, direction) => {
  const suppliedProps = typeof (start && end) !== undefined
  switch (direction) {
    case KEYWORDS.DESC:
      if (suppliedProps && start >= end) {
        return [start, end]
      } else if (suppliedProps && start < end) {
        return [start, start]
      }
      return [start || 60, end || 0]
    default:
      if (suppliedProps && start <= end) {
        return [start, end]
      } else if (suppliedProps && start > end) {
        return [start, start]
      }
      return [start || 0, end || 60]
  }
}

const handleTime = (time, direction, start, end, multiplier) => {
  let reverseCase = direction === KEYWORDS.DESC
  if (reverseCase) {
    multiplier =
      end + ((start - end) % multiplier) === time ? (start - end) % multiplier : multiplier
    return time - multiplier
  }
  multiplier = end - ((end - start) % multiplier) === time ? (end - start) % multiplier : multiplier
  return time + multiplier
}

class Timer extends Component {
  constructor(props) {
    super(props)
    const { startTime, endTime, direction } = this.props
    const [start, end] = getDefaultTime(startTime, endTime, direction)
    this.state = {
      start,
      end,
      resendTime: start,
      ticker: null
    }
  }

  componentDidMount() {
    const { direction, multiplier, timeOut } = this.props

    if (!this.state.ticker) {
      const newTicker = setInterval(() => {
        this.setState((prevState) => ({
          resendTime: handleTime(
            prevState.resendTime,
            direction,
            prevState.start,
            prevState.end,
            multiplier
          )
        }))
      }, timeOut)

      this.setState({ ticker: newTicker })
    }
  }

  componentWillUnmount() {
    clearInterval(this.state.ticker)
  }

  render() {
    const { resendTime } = this.state
    const { render, displayInMinutes } = this.props
    const isTimerFinished = resendTime === 0

    let formattedTime = resendTime

    if (displayInMinutes) {
      const minutes = Math.floor(resendTime / 60)
      const seconds = resendTime % 60

      const formattedMinutes = minutes < 10 ? `0${minutes}` : minutes
      const formattedSeconds = seconds < 10 ? `0${seconds}` : seconds

      formattedTime = `${formattedMinutes}:${formattedSeconds}`
    } else {
      formattedTime = resendTime < 10 ? `0${resendTime}` : resendTime
    }
    return <>{render({ resendTime: formattedTime, isTimerFinished })}</>
  }
}

Timer.propTypes = {
  startTime: PropTypes.number,
  endTime: PropTypes.number,
  direction: PropTypes.string,
  multiplier: PropTypes.number,
  timeOut: PropTypes.number,
  render: PropTypes.func.isRequired,
  displayInMinutes: PropTypes.bool
}

Timer.defaultProps = {
  startTime: 60,
  endTime: 0,
  direction: KEYWORDS.DESC, // Default to timer direction
  multiplier: 1,
  timeOut: 1000,
  displayInMinutes: false // Default to display in seconds only
}

export default Timer
