import React from "react"
import { ShimmerThumbnail } from "react-shimmer-effects"

function ApprovalsShowPaymentSectionShimmer() {
  return (
    <div className="vehicle-shimmer-parent">
      <ShimmerThumbnail rounded height={200} />
    </div>
  )
}

export default ApprovalsShowPaymentSectionShimmer
