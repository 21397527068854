import React, { Component } from "react"
import PropTypes from "prop-types"
import { connect } from "react-redux"
import { routerPaths } from "../../constants/paths"
import { Redirect } from "react-router"
import { isFMCUser } from "../../helpers/userRoleHelpers"

import Alert from "../../components/shared/Alert"
import ApplicationLayout from "../../layouts/ApplicationLayout"
import FleetForm from "../../components/fleets/FleetForm"
import FleetImpersonation from "../../components/fleets/FleetImpersonation"
import LoadingError from "../../components/shared/LoadingError"
import LoadingThrobber from "../../components/shared/LoadingThrobber"

import { navigationPaths } from "../../constants/paths"
import { FLEET, FMC_EDIT } from "../../constants/application"
import { withTranslation } from "react-i18next"
import AlertModal from "../../components/shared/AlertModal"
import { CURRENT_USER, CURRENT_FLEET } from "../../constants/localStorageData"
import { logFirebaseAnalyticsScreenName } from "../../helpers/googleAnalyticsHelpers"
import { getRouteNameFromUrl } from "../../helpers/segmentHelpers"

class FleetEdit extends Component {
  static propTypes = {
    fleet: PropTypes.object.isRequired,
    isLoading: PropTypes.bool.isRequired,
    isLoadingError: PropTypes.bool.isRequired,
    fleetPaymentTypes: PropTypes.array.isRequired
  }

  constructor(props) {
    super(props)

    // NOTE: locationStateData is instantiated as a variable here rather than referencing this.props.location.state.someObj directly because the state key may not be present when navigating to this page from a <Link> that does not explicitly set it.
    const locationStateData = this.props.location.state || {}
    this.state = {
      alertMessage: locationStateData.alertMessage || "",
      alertType: locationStateData.alertType || "default",
      fleetId: this.props.match.params.id,
      isSubmitting: false,
      openAlertModal: false,
      fleetIdTodelete: null
    }
  }

  componentDidMount() {
    const { fleetId } = this.state
    logFirebaseAnalyticsScreenName(getRouteNameFromUrl())

    this.props.dispatch({
      type: "FLEET_LOAD_SAGA",
      payload: {
        fleetId: fleetId,
        options: {
          includePaymentTypes: true
        }
      }
    })
  }

  afterDelete(status, data) {
    this.setState({ isSubmitting: false })

    if (status === "success") {
      this.onDeleteSuccess(data)
    } else {
      this.onRequestFailure(data)
    }
  }

  afterRequest(status, data) {
    this.setState({ isSubmitting: false })

    if (status === "success") {
      this.onRequestSuccess(data)
    } else {
      this.onRequestFailure(data)
    }
  }

  afterImpersonationRequest = async (status, data) => {
    this.setState({ isSubmitting: false })
    if (status === "success") {
      await this.props.dispatch({
        type: "FMC_FLIP_SAGA",
        payload: { mode: FLEET }
      })
      await this.redirectToDashboard(data)
    } else {
      this.onRequestFailure(data)
    }
  }

  redirectToDashboard = (data) => {
    this.props.history.push({
      pathname: navigationPaths.dashboard(),
      state: {
        alertMessage: data.alertMessage,
        alertType: data.alertType
      }
    })
  }

  afterQuickLinkRequest(status, data) {
    this.setState({ isSubmitting: false })

    const { fleetId } = this.state

    if (status === "success") {
      this.onRequestSuccess(data)

      // NOTE: Refreshes associated "Quick Link" data.
      this.props.dispatch({
        type: "FLEET_LOAD_SAGA",
        payload: {
          fleetId: fleetId
        }
      })
    } else {
      this.onRequestFailure(data)
    }
  }

  onDelete(fleetId) {
    this.setState({ openAlertModal: true, fleetIdTodelete: fleetId })
  }

  handleModalState = () => {
    this.setState({ openAlertModal: false })
  }

  onDeleteSuccess(data) {
    this.props.history.push({
      pathname: navigationPaths.fmcDashboard(),
      state: {
        alertMessage: data.alertMessage,
        alertType: data.alertType
      }
    })
  }

  onImpersonationSubmit(fleetId) {
    this.setState({ isSubmitting: true })

    this.props.dispatch({
      type: "FLEET_IMPERSONATION_SAGA",
      payload: { fleetId: fleetId },
      callback: this.afterImpersonationRequest.bind(this)
    })
  }

  onQuickLinkDelete(data) {
    this.setState({ isSubmitting: true })

    this.props.dispatch({
      type: "QUICK_LINK_DELETE_SAGA",
      payload: {
        fleetId: data["fleetId"],
        id: data["id"]
      },
      callback: this.afterQuickLinkRequest.bind(this)
    })
  }

  onQuickLinkSubmit(formData) {
    this.setState({ isSubmitting: true })

    let sagaType

    if (formData.id === null) {
      sagaType = "QUICK_LINK_CREATE_SAGA"
    } else {
      sagaType = "QUICK_LINK_UPDATE_SAGA"
    }

    this.props.dispatch({
      type: sagaType,
      payload: { formData: formData },
      callback: this.afterQuickLinkRequest.bind(this)
    })
  }

  onRequestFailure(data) {
    this.setState({
      alertMessage: data.alertMessage,
      alertType: data.alertType
    })
  }

  onRequestSuccess(data) {
    this.setState({
      alertMessage: data.alertMessage,
      alertType: data.alertType
    })
  }

  onSubmit(formData) {
    this.props.dispatch({
      type: "FLEET_UPDATE_SAGA",
      payload: { id: formData.id, formData: formData, t: this.props.t },
      callback: this.afterRequest.bind(this)
    })
  }

  shouldRenderContent() {
    const { isLoading, isLoadingError, fleet } = this.props

    // NOTE:  Prevents the component from attempting to render content body prior to API load.
    const fleetPresent = Object.keys(fleet).length !== 0

    return fleetPresent && !isLoading && !isLoadingError
  }

  renderContent() {
    const { fleetPaymentTypes, currentUser, fleet } = this.props
    const { isSubmitting } = this.state

    if (currentUser && !isFMCUser(currentUser)) {
      // TODO: extract this work to the Router itself.
      return <Redirect to={routerPaths.dashboard} />
    } else if (currentUser && this.shouldRenderContent()) {
      return (
        <div className={"form_container"}>
          <FleetImpersonation
            fleet={fleet}
            isSubmitting={isSubmitting}
            onSubmit={this.onImpersonationSubmit.bind(this)}
          />
          <FleetForm
            fleet={fleet}
            isSubmitting={isSubmitting}
            onDelete={this.onDelete.bind(this)}
            onSubmit={this.onSubmit.bind(this)}
            paymentTypes={fleetPaymentTypes}
          />
        </div>
      )
    } else {
      return null
    }
  }

  render() {
    const { isLoading, isLoadingError, t } = this.props

    const { alertMessage, alertType, openAlertModal, fleetIdTodelete } = this.state

    return (
      <ApplicationLayout>
        <Alert message={alertMessage} type={alertType} visible={alertMessage !== ""} />

        <LoadingThrobber visible={isLoading} />

        <LoadingError visible={!isLoading && isLoadingError} />

        {this.renderContent()}
        {openAlertModal ? (
          <AlertModal
            openAlertModal={openAlertModal}
            hideModal={this.handleModalState}
            idToDelete={fleetIdTodelete}
            afterDelete={this.afterDelete}
            that={this}
            modelFrom={FMC_EDIT}
            tFleetEdit={t}
          />
        ) : null}
      </ApplicationLayout>
    )
  }
} // class FleetEdit

const mapStateToProps = (state) => ({
  fleet: state.fleets.fleet,
  fleetPaymentTypes: state.fleets.paymentTypes,
  currentUser: state.application.userSessionData || state.users.currentUser,
  isLoading: state.application.isLoading,
  isLoadingError: state.application.isLoadingError
})

export default connect(mapStateToProps)(withTranslation("fleetImpersonation")(FleetEdit))
