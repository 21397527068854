import React from "react"
import CarAdviseBusinessLogo from "../../assets/images/CarAdvise_Business Logo.svg"
import { Image } from "semantic-ui-react"
import ReactSVG from "react-svg"

export default function FullPageLoader(props) {
  return (
    <div className="loader-content-full">
      {/* <Image src={CAIconLogo} className="loader-image" /> */}
      <ReactSVG src={CarAdviseBusinessLogo} className="fleetadvise-logo-img" />
    </div>
  )
}
