import React, { Component } from "react"
import PropTypes from "prop-types"
import { connect } from "react-redux"

import ApplicationLayout from "../../layouts/ApplicationLayout"
import LoadingError from "../../components/shared/LoadingError"
import LoadingThrobber from "../../components/shared/LoadingThrobber"
import UpcomingMaintenances from "../../components/dashboard/UpcomingMaintenances"
import { logFirebaseAnalyticsScreenName } from "../../helpers/googleAnalyticsHelpers"
import { getRouteNameFromUrl } from "../../helpers/segmentHelpers"

class UpcomingMaintenanceIndex extends Component {
  static propTypes = {
    isLoading: PropTypes.bool.isRequired,
    isLoadingError: PropTypes.bool.isRequired,
    vehicles: PropTypes.array.isRequired
  }

  async componentWillMount() {
    this.loading = true
    await this.fetchDashboardData()
    this.loading = false
  }

  componentDidMount() {
    logFirebaseAnalyticsScreenName(getRouteNameFromUrl())
  }

  fetchDashboardData() {
    this.props.dispatch({
      type: "DASHBOARD_LOAD_SAGA",
      payload: {
        users: ["id", "firstName", "lastName"],
        vehicles: [
          "id",
          "last_maintenance_miles",
          "license_plate_number",
          "license_plate_state",
          "make",
          "model",
          "year",
          "maintenance",
          "other_id"
        ]
      }
    })
  }

  shouldRenderContent() {
    const { isLoading, isLoadingError } = this.props

    return !isLoading && !isLoadingError && !this.loading
  }

  renderContent() {
    const { vehicles } = this.props

    if (this.shouldRenderContent()) {
      return (
        <div className={"dash-upcoming-container"}>
          <div className={"dash-hd"}>UPCOMING MAINTENANCE</div>

          <UpcomingMaintenances vehicles={vehicles} />
        </div>
      )
    } else {
      return null
    }
  }

  render() {
    const { isLoading, isLoadingError } = this.props

    return (
      <ApplicationLayout>
        <LoadingThrobber visible={isLoading} />

        <LoadingError visible={!isLoading && isLoadingError} />

        {this.renderContent()}
      </ApplicationLayout>
    )
  }
} // class UpcomingMaintenanceIndex

const mapStateToProps = (state) => ({
  isLoading: state.application.isLoading,
  isLoadingError: state.application.isLoadingError,
  vehicles: state.vehicles.vehicles
})

export default connect(mapStateToProps)(UpcomingMaintenanceIndex)
