export const PAGINATION_AMOUNT = 50

export function dynamicGridHeight({ items }) {
  const gridHeights = {
    header: 56,
    row: 48,
    bottomPadding: 15
  }

  let height

  if (items && items.length > 0) {
    let itemAmount = items.length > PAGINATION_AMOUNT ? PAGINATION_AMOUNT : items.length
    height = gridHeights.header + gridHeights.row * (itemAmount + 2)
  } else {
    height = gridHeights.header + 450
  }

  height = height + gridHeights.bottomPadding

  return `${height}px`
}

export const handleAgGridPagination = (pageApi, data) => {
  const { totalCount } = data
  const currentPage = pageApi.paginationGetCurrentPage()
  const pageSize = pageApi.paginationGetPageSize()
  const startRow = currentPage * pageSize + 1
  const endRow = Math.min((currentPage + 1) * pageSize, totalCount) // Ensure endRow never exceeds totalFleetCount

  // Update pagination text manually
  const paginationPanel = document.querySelector(".ag-paging-panel")
  if (paginationPanel) {
    const paginationText = `${startRow} to ${endRow} of ${totalCount}`
    paginationPanel.querySelector(".ag-paging-row-summary-panel").innerText = paginationText
  }
}
