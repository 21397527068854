import React from "react"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { isCarvana, isFleetcor, isFuelman } from "../../helpers/affiliationHelpers"
import { withTranslation } from "react-i18next"
import ReactSVG from "react-svg"

function NavbarItem({ item, activeColor, inactiveColor, transferVehicle = false, t }) {
  let extraStyles = {}
  if (isCarvana()) extraStyles = { backgroundColor: "white", padding: "9px 7px" }

  return item.content === t("Transfer_Vehicles") ? (
    <ReactSVG
      src={`/images/transfer_vehicles.svg`}
      svgStyle={{ ...extraStyles, width: 70, height: 70 }}
      className={
        item.activeFunc
          ? isFleetcor()
            ? "transfer_vehicles_active icon-hover"
            : "transfer_vehicles_active"
          : isFleetcor()
          ? "transfer_vehicles_inactive icon-hover"
          : "transfer_vehicles_inactive"
      }
    />
  ) : (
    <FontAwesomeIcon
      icon={item.icon}
      color={item.activeFunc ? activeColor : inactiveColor}
      size="3x"
      className={isFleetcor() || isFuelman() ? "icon-hover" : ""}
    />
  )
}

export default withTranslation("common")(NavbarItem)
