import React, { PureComponent } from "react"
import { connect } from "react-redux"
import { withTranslation } from "react-i18next"
import AddVanityNameModal from "../shared/AddVanityNameModal"
import PlusIcon from "../../assets/images/plus.svg"
import FleetcorPlusIcon from "../../assets/images/fleetcor_plus.svg"
import DiscounttirePlusIcon from "../../assets/images/discounttire_plus.svg"
import crossIcon from "../../assets/images/cross_icon.svg"
import ReactSVG from "react-svg"
import { getSubdomain } from "../shared/StylesheetInjector"
import { DISCOUNT_TIRE, FLEETCOR } from "../../constants/application"

class AddVanityLink extends PureComponent {
  onVanitySubmit = async (service) => {
    await this.props.onVanitySubmit(0, service, "")
  }
  render() {
    const { t, service, canEditVanity, onVanitySubmit, isVanityLabelSubmitting, index } = this.props
    let subdomain = getSubdomain()
    let svg_path
    switch (subdomain) {
      case DISCOUNT_TIRE:
        svg_path = DiscounttirePlusIcon
        break
      case FLEETCOR:
        svg_path = FleetcorPlusIcon
        break
      default:
        svg_path = PlusIcon
    }
    return (
      <React.Fragment>
        {canEditVanity ? (
          <>
            {service && service.vanity_label ? (
              <React.Fragment>
                <div className="vanity_edit_section">
                  <div className="show_exist_vanity_name">
                    <span className="text">{service.vanity_label}</span>
                    <div onClick={() => this.onVanitySubmit(service)}>
                      <ReactSVG src={crossIcon} svgClassName="cross_icon hand-icon" />
                    </div>
                  </div>
                  <AddVanityNameModal
                    onVanitySubmit={onVanitySubmit}
                    isVanityLabelSubmitting={isVanityLabelSubmitting}
                    service={service}
                    index={index}
                    t={t}
                  >
                    <div className="edit_link_section">
                      <span className="edit_text hand-icon">{t("services:editText")}</span>
                    </div>
                  </AddVanityNameModal>
                </div>
              </React.Fragment>
            ) : (
              <AddVanityNameModal
                onVanitySubmit={onVanitySubmit}
                isVanityLabelSubmitting={isVanityLabelSubmitting}
                service={service}
                index={index}
                t={t}
              >
                <div className="approvals3_form_field-service-info">
                  <div className="custom_service">
                    <ReactSVG src={svg_path} />
                    <span className="custom_service_name hand-icon">
                      {" "}
                      {t("services:customNameText")}
                    </span>
                  </div>
                </div>
              </AddVanityNameModal>
            )}
          </>
        ) : null}
      </React.Fragment>
    )
  }
}

export default connect()(withTranslation("services")(AddVanityLink))
