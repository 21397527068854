import React from "react"
import { ShimmerThumbnail } from "react-shimmer-effects"

function SettingsIndexShimmer() {
  const shimmerGrids = Array.from({ length: 5 }, (_, index) => {
    return <ShimmerThumbnail height={30} rounded />
  })
  return (
    <div className="vehicle-shimmer-parent">
      <ShimmerThumbnail rounded height={80} />
      {shimmerGrids}
    </div>
  )
}

export default SettingsIndexShimmer
