import React, { Component } from "react"
import { withTranslation } from "react-i18next"
import { Modal } from "semantic-ui-react"
import TextField from "../../elements/TextField"

class AddVanityNameModal extends Component {
  constructor(props) {
    super(props)

    this.state = {
      modalOpen: false,
      vanity_label: (this.props.service || {}).vanity_label || "",
      save_disabled: true
    }
  }

  handleOpen = async () => {
    this.setState({
      modalOpen: true
    })
  }

  handleClose = () => {
    this.setState({ modalOpen: false, vanity_label: (this.props.service || {}).vanity_label || "" })
  }

  onChange(e) {
    if (this.state.vanity_label != e.target.value) this.setState({ save_disabled: false })
    this.setState({ vanity_label: e.target.value })
    if (!e.target.value) this.setState({ save_disabled: true })
  }

  onVanitySubmit = async (index, service, vanity_label) => {
    this.setState({ save_disabled: true })
    await this.props.onVanitySubmit(index, service, vanity_label)
    await this.handleClose()
  }

  renderView = () => {
    const { service, index, t } = this.props
    const { vanity_label, save_disabled } = this.state
    return (
      <React.Fragment>
        <div className="add_model_section">
          <div className="header_section">
            <span className="header_section_label">{t("services:addCustomNameText")}</span>
            <span>{service && service.name}</span>
          </div>
          <div className="input_field_section">
            <TextField
              value={vanity_label}
              className="input_field"
              onChange={(event) => this.onChange(event)}
              placeholder={t("services:vanityTextPlaceholderText")}
            />
          </div>
          <div className="buttons_section">
            <div className="button_section hand-icon">
              <div className="button_cover">
                <div className="button">
                  <span className="button_text" onClick={this.handleClose}>
                    {t("services:cancelText")}
                  </span>
                </div>
              </div>
            </div>
            {save_disabled ? (
              <div className="disabled_save_button_section hand-icon">
                <div className="disable_button_cover">
                  <span className="save_text">{t("services:saveText")}</span>
                </div>
              </div>
            ) : (
              <div
                className="enabled_button_section hand-icon orange-button"
                onClick={() => this.onVanitySubmit(index, service, vanity_label)}
              >
                <div className="button_cover">
                  <div className="button">
                    <span className="button_text">{t("services:saveText")}</span>
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
      </React.Fragment>
    )
  }

  render() {
    const { children } = this.props
    return (
      <Modal
        size="mini"
        className="custom-modal"
        trigger={
          React.Children.map(children, (c) =>
            React.cloneElement(c, { onClick: this.handleOpen })
          )[0]
        }
        open={this.state.modalOpen}
        closeIcon
        onClose={this.handleClose}
      >
        <Modal.Content>{this.renderView()}</Modal.Content>
      </Modal>
    )
  }
} // class PaymentActivityModal

export default withTranslation("services")(AddVanityNameModal)
