import React, { Component } from "react"
import PropTypes from "prop-types"
import Button from "../../elements/Button"
import PasswordField from "../../elements/PasswordField"
import TextField from "../../elements/TextField"
import MobileStoreButton from "../../elements/MobileStoreButton"
import * as browserHelpers from "../../helpers/browserHelpers"
import { getSubdomain } from "../shared/StylesheetInjector"
import {
  isFleetAdvise,
  isFleetMaintenanceHub,
  isFleetMaintenanceHubCanada,
  isZubie
} from "../../helpers/affiliationHelpers"
import UserOrAdminSignIn from "./UserOrAdminSignIn"
import { elements } from "caradvise_shared_components"
import { withTranslation } from "react-i18next"
import * as sessionHelpers from "../../helpers/sessionHelpers"
import ReCAPTCHA from "react-google-recaptcha"
import { OFF, ON, setupSplitFlags } from "../config/SplitClient"
import { connect } from "react-redux"
import {
  REMEMBER_ME_COOKIE,
  EVENTS,
  ACCEPT_TERMS_AND_CONDITIONS
} from "../../constants/application"
import Checkbox from "../../elements/Checkbox"
import ErrorField from "../../elements/ErrorField"
import SessionPrivacyPolicyPage from "../../views/sessions/SessionPrivacyPolicyPage"
import { EMAIL_REGEX } from "../../utilities/stringHelpers"
import { Image } from "semantic-ui-react"
import { Link } from "react-router-dom"
import MagicIcon from "../../assets/images/magic_icon.svg"
import { routerPaths } from "../../constants/paths"
const recaptchaRef = React.createRef()
const { PrivacyPolicyAndTerms } = elements

class SignInForm extends Component {
  static propTypes = {
    onSubmit: PropTypes.func.isRequired,

    isSubmitting: PropTypes.bool
  }

  static defaultProps = {
    isSubmitting: false
  }

  constructor(props) {
    super(props)

    this.state = {
      formData: {
        email: "",
        password: "",
        acceptedTermsAndConditions: false
      },
      displayError: {
        email: false,
        password: false,
        checkboxChecked: false
      },
      isRecaptchaChecked: false,
      passwordInvisible: true
    }
  }

  async componentDidMount() {
    await setupSplitFlags.bind(this)({ sub_domain: getSubdomain(), app_name: getSubdomain() })
  }

  allowSubmit = () => {
    const { isSubmitting } = this.props

    return !isSubmitting
  }

  onKeyDown = (event) => {
    const { onSubmit } = this.props

    if (event.key === EVENTS.ENTER) {
      this.onClick()
    }
  }

  preSelectTermsAndConditionCheckBox = () => {
    let flag = false
    if (
      this.state.formData.email != "" &&
      sessionHelpers.getApplicationCookie(ACCEPT_TERMS_AND_CONDITIONS).toLowerCase() ===
        this.state.formData.email.toLowerCase()
    )
      flag = true
    this.setState((prevState) => ({
      formData: {
        ...prevState.formData,
        ["acceptedTermsAndConditions"]: flag
      }
    }))
  }

  onClick = () => {
    const { mfa, onSubmit, onSendOTP, t, isFleetCaptchaFlag } = this.props
    const { formData, isRecaptchaChecked } = this.state
    if (this.isFieldValueValid()) {
      if (
        this.state.showFooterComponent &&
        this.state.showFooterComponent !== `TAC-${getSubdomain()}`
      )
        delete formData["acceptedTermsAndConditions"]
      if (isRecaptchaChecked == false && isFleetCaptchaFlag) {
        alert(t("common:recaptchaCheck"))
        return false
      } else {
        if (mfa && sessionHelpers.getApplicationCookie(REMEMBER_ME_COOKIE) === formData.email) {
          onSubmit(formData)
        } else if (mfa) {
          onSendOTP(formData)
        } else {
          onSubmit(formData)
        }
      }
    }
  }

  onFieldChange = (event, field) => {
    let updatedFormData = { ...this.state.formData }
    updatedFormData[field] = event.target.value
    this.setState({ formData: updatedFormData }, () => {
      this.preSelectTermsAndConditionCheckBox()
    })
  }

  onEyeIconClick = () => {
    this.setState({ passwordInvisible: !this.state.passwordInvisible })
  }

  changedRecaptcha(val) {
    if (val.length > 0) {
      this.props.setState({ isRecaptchaChecked: true })
    } else {
      this.props.setState({ isRecaptchaChecked: false })
    }
  }

  isFieldValueValid = () => {
    const { formData } = this.state

    let isEmailValid = formData.email !== "" && this.validateEmail()
    let isPasswordValid = formData.password !== "" && this.passwordMinLength()
    let isTermsAccepted = true

    this.setDisplayError("email", !isEmailValid)
    this.setDisplayError("password", !isPasswordValid)

    if (
      this.state.showFooterComponent &&
      this.state.showFooterComponent === `TAC-${getSubdomain()}`
    ) {
      isTermsAccepted = formData.acceptedTermsAndConditions
      this.setDisplayError("checkboxChecked", !isTermsAccepted)
    }

    return isEmailValid && isPasswordValid && isTermsAccepted
  }

  setDisplayError = (field, booleanValue) => {
    this.setState((prevState) => ({
      displayError: {
        ...prevState.displayError,
        [field]: booleanValue
      }
    }))
  }

  validateEmail = () => {
    const email = this.state.formData.email
    return EMAIL_REGEX.test(email)
  }

  passwordMinLength = () => {
    const password = this.state.formData.password
    return password.length >= 6
  }

  usernameAndPassword = (t) => {
    const { email, password, acceptedTermsAndConditions } = this.state.formData
    const { displayError } = this.state
    const { i18n, navigateToForgotPassword, isFleetCaptchaFlag } = this.props
    let recaptchaDocument = document.querySelector('[title="reCAPTCHA"]')
    if (recaptchaDocument) {
      if (!recaptchaDocument.src.match(/(hl=)[^\&]+/)[0].includes(i18n.language))
        recaptchaDocument.src = recaptchaDocument.src.replace(/(hl=)[^\&]+/, `$1${i18n.language}`)
    }
    const fleetAdvise = isFleetAdvise()

    return (
      <div className={"sign-in-form"} onKeyDown={(event) => this.onKeyDown(event)}>
        <div className={"sign-in-email"}>
          {fleetAdvise ? <label>{t("common:emailLabel")}</label> : null}
          <TextField
            className={"sign-in-input bottom-margin"}
            onChange={(e) => this.onFieldChange(e, "email")}
            placeholder={t("common:emailLabel")}
            value={email}
            name="email"
          />
          <ErrorField
            displayError={displayError.email}
            className={"error-field"}
            label={t("common:emailValidateLabel")}
          />
        </div>
        <div className={"sign-in-password"}>
          {fleetAdvise ? <label>{t("common:passwordLabel")}</label> : null}
          <PasswordField
            className={"sign-in-input bottom-margin"}
            onChange={(e) => this.onFieldChange(e, "password")}
            placeholder={t("common:passwordLabel")}
            value={password}
            onIconClick={() => this.onEyeIconClick("password")}
            obfuscated={this.state.passwordInvisible}
          />
          <ErrorField
            displayError={displayError.password}
            className={"error-field"}
            label={t("common:passwordValidationLabel")}
          />
        </div>
        {this.state.showFooterComponent &&
        this.state.showFooterComponent === `TAC-${getSubdomain()}` ? (
          <div className={"checkbox-signupbutton margin-10"}>
            <div className={"terms-and-conditions"}>
              <Checkbox
                className={`checkbox t-and-c-checkbox ${
                  displayError.checkboxChecked ? "error-checkbox" : ""
                }`}
                onChange={this.onTermsCheckbox}
                checked={acceptedTermsAndConditions}
              />
              <SessionPrivacyPolicyPage t={t} configuration={this.state.footerComponentConfig} />
            </div>
          </div>
        ) : (
          ""
        )}
        {isFleetCaptchaFlag && (
          <ReCAPTCHA
            ref={recaptchaRef}
            className="g-recaptcha"
            sitekey={process.env.REACT_APP_CAPTCHA_SITE_KEY}
            onChange={this.changedRecaptcha}
            hl={i18n.language}
            style={{ padding: "1em" }}
            props={this}
          />
        )}
        <div className={"sign-in-submit"}>
          {
            <button className={"link"} onClick={navigateToForgotPassword}>
              {t("common:forgotPasswordLabel")}
            </button>
          }
          <Button
            className={`button--submit remove-margin ${isFleetAdvise() ? "signin-btn-color" : ""}`}
            disabled={!this.allowSubmit()}
            label={t("common:signInLabel")}
            onClick={() => this.onClick(t)}
            t={t}
          />
        </div>
        {this.state.isMagicLinkFeature && this.state.isMagicLinkFeature == ON && (
          <div className="magic-link-sign-in-container">
            <Link
              className="magic-link-button"
              to={{
                pathname: routerPaths.magicLink
              }}
            >
              <Image src={MagicIcon} />
              {t("magicLink:magicLinkLoginLabel")}
            </Link>
          </div>
        )}
      </div>
    )
  }

  mobileStoreButton = (store, link) => {
    return <MobileStoreButton store={store} url={link} linkProps={{ title: "iOS Store Button" }} />
  }

  whiteLabelMobileStoreButton() {
    const iosBaseUrl = "https://itunes.apple.com/us/app/"
    const androidBaseUrl = "https://play.google.com/store/apps/"
    const appPaths = {
      fleetadvise: {
        ios: "fleetamerica/id1361299536?ls=1&mt=8",
        android: "details?id=com.caradvise_fleet_mobile"
      },
      fleetamerica: {
        ios: "fleetamerica/id1361299536?ls=1&mt=8",
        android: "details?id=com.caradvise_fleet_mobile"
      },
      zubie: {
        ios: "zubie-smart-maintenance/id1456945093?ls=1&mt=8",
        android: "details?id=com.caradvise_zubie"
      }
    }

    const subDomain = getSubdomain()
    if (appPaths[subDomain] == null) return null

    const iosButton = () => this.mobileStoreButton("ios", iosBaseUrl + appPaths[subDomain]["ios"])
    const androidButton = () =>
      this.mobileStoreButton("android", androidBaseUrl + appPaths[subDomain]["android"])

    const mobileButton = browserHelpers.browserIsSafari() ? iosButton() : androidButton()

    return <div>{browserHelpers.isMobileBrowser() && mobileButton}</div>
  }

  onTermsCheckbox = (event) => {
    const { value, type, checked } = event.target
    const newValue = type === "checkbox" ? checked : value
    this.setState((prevState) => ({
      formData: {
        ...prevState.formData,
        ["acceptedTermsAndConditions"]: newValue
      }
    }))
  }

  render() {
    const { t } = this.props
    const isZubieOrFMH = isZubie() || isFleetMaintenanceHub() || isFleetMaintenanceHubCanada()
    return (
      <div style={{ display: "flex", flexDirection: "column", alignItems: "center" }}>
        {isZubieOrFMH && (
          <UserOrAdminSignIn usernameAndPassword={this.usernameAndPassword(t)} t={t} />
        )}
        {!isZubieOrFMH && this.usernameAndPassword(t)}
        {this.whiteLabelMobileStoreButton()}
      </div>
    )
  }
} // class SignInForm

const mapStateToProps = (state) => ({
  currentUser: state.application.userSessionData || state.users.currentUser
})

export default connect(mapStateToProps)(withTranslation("privacyPolicyAndTerms")(SignInForm))
