import { call, put, takeEvery, take } from "redux-saga/effects"
import api from "../../utilities/api"
import * as apiErrorHelpers from "../../helpers/apiErrorHelpers"
import * as sessionHelpers from "../../helpers/sessionHelpers"
import { loadManualMaitenanceHistories } from "./maintenanceHistorySaga"

import { constants } from "caradvise_shared_components"
import { FAILURE, NOTIFICATION_TYPE, SUCCESS } from "../../constants/notification"
import { AUTHENTICATION_TOKEN } from "../../constants/application"

const ORDERS_URL = "/orders"
const SEARCH_SERVICES_URL = "/services/search"

export function* loadShopOrders(action) {
  try {
    const payload = action.payload || {}
    const payloadOptions = payload.options || {}
    const skipUnsetLoading = payload.skipUnsetLoading
    const {
      AWAITING_APPROVAL,
      APPROVAL,
      INITIATED_BY_USER,
      ACTIVE,
      STATUS,
      IN_MANUAL_REVIEW,
      PAID_AT_SHOP,
      PAID
    } = constants.orders
    const ACTIVE_ORDER_SCHEDULED_STATUSES_FROM_RETAIL = [
      AWAITING_APPROVAL,
      APPROVAL,
      INITIATED_BY_USER,
      ACTIVE
    ].map((string) => STATUS[string])
    const PAST_ORDER_SCHEDULED_STATUSES_FROM_RETAIL = [IN_MANUAL_REVIEW, PAID_AT_SHOP, PAID].map(
      (string) => STATUS[string]
    )

    if (!skipUnsetLoading) yield put({ type: "SET_LOADING" })
    if (!skipUnsetLoading) yield put({ type: "UNSET_LOADING_ERROR" })
    if (!payloadOptions.captureActive) yield put({ type: "SET_PAST_ORDER_LOADING" })

    api.setAuthApi({
      Authorization: sessionHelpers.getApplicationCookie(AUTHENTICATION_TOKEN)
    })

    const url = `${ORDERS_URL}`
    let body = {}
    if (payloadOptions.captureActive) {
      body.state_id = ACTIVE_ORDER_SCHEDULED_STATUSES_FROM_RETAIL
    } else {
      body.state_id = PAST_ORDER_SCHEDULED_STATUSES_FROM_RETAIL
    }

    if (payloadOptions.startDate) {
      body.appointment_datetime_from = payloadOptions["startDate"]
      body.appointment_datetime_to = payloadOptions["endDate"]
    }

    const shopOrdersResponse = yield call(api.utility.get, api.pathV6(url), { body: body })

    apiErrorHelpers.catchErrors(shopOrdersResponse.body)

    if (payloadOptions.captureActive) {
      yield put({
        type: "ACTIVE_SHOP_ORDERS_LOAD_SUCCEEDED",
        payload: {
          activeShopOrders: shopOrdersResponse.body
        }
      })
    } else {
      yield put({
        type: "PAST_SHOP_ORDERS_LOAD_SUCCEEDED",
        payload: {
          shopOrders: shopOrdersResponse.body
        }
      })
    }

    if (payloadOptions.withMaintenanceHistory) {
      const { startDate, endDate } = payloadOptions
      yield call(loadManualMaitenanceHistories, { payload: { startDate, endDate } })
    }

    if (!skipUnsetLoading) yield put({ type: "UNSET_LOADING" })
    if (!payloadOptions.captureActive) yield put({ type: "UNSET_PAST_ORDER_LOADING" })

    if (action.callback) yield call(action.callback, SUCCESS, shopOrdersResponse.body)
  } catch (errors) {
    // eslint-disable-next-line
    errors = apiErrorHelpers.formatInternalErrors(errors)

    yield put({ type: "LOADING_FAILED" })
    yield put({ type: "SET_LOADING_ERROR" })
    yield put({ type: "UNSET_LOADING" })

    if (action.callback) yield call(action.callback, FAILURE, errors)

    apiErrorHelpers.consoleLog(errors)
  }
} // function* loadShopOrders

export function* loadShopOrder(action) {
  try {
    yield put({ type: "SET_LOADING" })
    yield put({ type: "UNSET_LOADING_ERROR" })

    api.setAuthApi({
      Authorization: sessionHelpers.getApplicationCookie(AUTHENTICATION_TOKEN)
    })
    const payload = action.payload || {}

    yield put({ type: "SHOP_ORDER_LOAD_REQUESTED" })

    const shopOrdersRequest = {
      url: `${ORDERS_URL}/${action.payload.shopOrderId}/preview`,
      body: {
        order: { order_services: action.payload.orderServices },
        price_as_approvals_logic: action.payload.priceAsApprovalsLogic,
        set_default_order_services_status: action.payload.set_default_order_services_status
      }
    }

    const shopOrdersResponse = yield call(api.utility.get, api.pathV6(shopOrdersRequest.url), {
      body: shopOrdersRequest.body
    })

    apiErrorHelpers.catchErrors(shopOrdersResponse.body)
    yield put({
      type: "SHOP_ORDER_LOAD_SUCCEEDED",
      payload: {
        shopOrder: shopOrdersResponse.body
      }
    })
    ////////////////////////////

    ////////////////////////////
    // LOAD CURRENT USER DATA //
    ////////////////////////////

    // yield put({ type: "CURRENT_USER_LOAD_PAYMENT_METHODS_SAGA" })

    ////////////////////////////

    if (payload.withPriceRanges) {
      const shopOrder = shopOrdersResponse.body || {}
      const services = shopOrder.order_services || []

      yield call(loadShopOrderPriceRanges, {
        payload: {
          skipLoadingCalls: true,
          serviceNames: services.map((s) => s.name),
          vehicleId: shopOrder.vehicle_id
        }
      })
    }

    yield put({ type: "UNSET_LOADING" })
    if (action.callback) yield call(action.callback, SUCCESS, shopOrdersResponse.body)
  } catch (errors) {
    // eslint-disable-next-line
    errors = apiErrorHelpers.formatInternalErrors(errors)

    yield put({ type: "LOADING_FAILED" })
    yield put({ type: "SET_LOADING_ERROR" })
    yield put({ type: "UNSET_LOADING" })

    apiErrorHelpers.consoleLog(errors)
  }
} // function* loadShopOrder

export function* loadShopOrderDetails(action) {
  try {
    yield put({ type: "SET_LOADING" })
    yield put({ type: "UNSET_LOADING_ERROR" })

    api.setAuthApi({
      Authorization: sessionHelpers.getApplicationCookie(AUTHENTICATION_TOKEN)
    })
    yield put({ type: "SHOP_ORDER_LOAD_REQUESTED" })

    const shopOrdersRequest = {
      url: `${ORDERS_URL}/${action.payload.shopOrderId}/preview_details`
    }

    const shopOrdersResponse = yield call(api.utility.get, api.pathV6(shopOrdersRequest.url), {})

    apiErrorHelpers.catchErrors(shopOrdersResponse.body)
    yield put({
      type: "SHOP_ORDER_LOAD_DETAILS_SUCCEEDED",
      payload: {
        shopOrder: shopOrdersResponse.body
      }
    })

    yield put({ type: "UNSET_LOADING" })
    if (action.callback) yield call(action.callback, SUCCESS, shopOrdersResponse.body)
  } catch (errors) {
    // eslint-disable-next-line
    errors = apiErrorHelpers.formatInternalErrors(errors)

    yield put({ type: "LOADING_FAILED" })
    yield put({ type: "SET_LOADING_ERROR" })
    yield put({ type: "UNSET_LOADING" })

    apiErrorHelpers.consoleLog(errors)
  }
} // function* loadShopOrder

export function* loadShopOrderPriceRanges(action) {
  try {
    const payload = action.payload || {}
    if (!payload.skipLoadingCalls) {
      yield put({ type: "SET_LOADING" })
      yield put({ type: "UNSET_LOADING_ERROR" })
    }

    api.setAuthApi({
      Authorization: sessionHelpers.getApplicationCookie(AUTHENTICATION_TOKEN)
    })

    /////////////////////////////////////
    // LOAD SHOP ORDER PRICE RANGES DATA/
    /////////////////////////////////////

    yield put({ type: "SHOP_ORDER_PRICE_RANGES_LOAD_REQUESTED" })

    const request = {
      url: SEARCH_SERVICES_URL,
      body: {
        query: {
          type: "name",
          where: payload.serviceNames
        },
        vehicle_id: payload.vehicleId
      }
    }

    const response = yield call(api.utility.post, api.path(request.url), { body: request.body })

    apiErrorHelpers.catchErrors(response.body)
    yield put({
      type: "SHOP_ORDER_PRICE_RANGES_LOAD_SUCCEEDED",
      payload: {
        priceRanges: response.body
      }
    })
    ////////////////////////////

    if (!payload.skipLoadingCalls) yield put({ type: "UNSET_LOADING" })
  } catch (errors) {
    // eslint-disable-next-line
    errors = apiErrorHelpers.formatInternalErrors(errors)

    yield put({ type: "LOADING_FAILED" })
    yield put({ type: "SET_LOADING_ERROR" })
    yield put({ type: "UNSET_LOADING" })

    apiErrorHelpers.consoleLog(errors)
  }
} // function* loadShopOrderPriceRanges

export function* downloadShopOrders(action) {
  const payload = action.payload || {}
  const { startDate, endDate, isActivePage } = payload
  const authToken = sessionHelpers.getApplicationCookie(AUTHENTICATION_TOKEN)
  const {
    AWAITING_APPROVAL,
    APPROVAL,
    INITIATED_BY_USER,
    ACTIVE,
    STATUS,
    IN_MANUAL_REVIEW,
    PAID_AT_SHOP,
    PAID
  } = constants.orders

  let url = ""
  if (isActivePage) {
    const ACTIVE_ORDER_SCHEDULED_STATUSES_FROM_RETAIL = [
      AWAITING_APPROVAL,
      APPROVAL,
      INITIATED_BY_USER,
      ACTIVE
    ].map((string) => STATUS[string])
    url = `${ORDERS_URL}.csv?auth_token=${authToken}&appointment_datetime_from=${startDate}&appointment_datetime_to=${endDate}&state_id=${ACTIVE_ORDER_SCHEDULED_STATUSES_FROM_RETAIL}`
  } else {
    const PAST_ORDER_SCHEDULED_STATUSES_FROM_RETAIL = [IN_MANUAL_REVIEW, PAID_AT_SHOP, PAID].map(
      (string) => STATUS[string]
    )
    url = `${ORDERS_URL}.csv?auth_token=${authToken}&completed=true&appointment_datetime_from=${startDate}&appointment_datetime_to=${endDate}&state_id=${PAST_ORDER_SCHEDULED_STATUSES_FROM_RETAIL}`
  }
  window.open(api.pathV6(url))
}

export function* loadOrderPreview(action) {
  yield put({ type: "SHOP_ORDER_LOAD_REQUESTED" })

  const shopOrdersRequest = {
    url: `${ORDERS_URL}/${action.payload.shopOrderId}/preview`,
    body: {
      order: { order_services: action.payload.orderServices },
      price_as_approvals_logic: action.payload.priceAsApprovalsLogic
    }
  }

  const shopOrdersResponse = yield call(api.utility.post, api.path(shopOrdersRequest.url), {
    body: shopOrdersRequest.body
  })

  apiErrorHelpers.catchErrors(shopOrdersResponse.body)
  yield put({
    type: "SHOP_ORDER_LOAD_SUCCEEDED",
    payload: {
      shopOrder: shopOrdersResponse.body
    }
  })
}

export function* createAndUpdateVanityLabel(action) {
  const { isNew, shop_order_id, index } = action.payload
  try {
    yield put({
      type: "VANITY_LABEL_CHANGE_REQUESTED"
    })

    api.setAuthApi({
      Authorization: sessionHelpers.getApplicationCookie(AUTHENTICATION_TOKEN)
    })

    const request = {
      url: `/orders/${shop_order_id}/vanity`,
      body: {
        ...action.payload
      }
    }

    const response = yield call(api.utility.post, api.path(request.url), { body: request.body })
    apiErrorHelpers.catchErrors(response.body)

    yield put({
      type: "VANITY_LABEL_CREATE_OR_UPDATE_SUCCEEDED",
      payload: { vanity: response.body.vanity }
    })

    const afterRequestData = {
      title: isNew ? action.payload.t("successLable") : action.payload.t("updateLabel"),
      message: isNew
        ? action.payload.t("vanityLabelAddedSuccess")
        : action.payload.t("vanityLabelUpdatedSuccess"),
      type: isNew ? NOTIFICATION_TYPE.SUCCESS : NOTIFICATION_TYPE.DEFAULT,
      index: index,
      response: response.body.vanity
    }

    yield call(action.callback, SUCCESS, afterRequestData)
  } catch (errors) {
    // eslint-disable-next-line
    errors = apiErrorHelpers.formatInternalErrors(errors)

    yield put({
      type: "VANITY_LABEL_CREATE_OR_UPDATE_FAILURE"
    })

    const afterRequestData = {
      title: action.payload.t("errorLabel"),
      message: apiErrorHelpers.toString(errors),
      type: NOTIFICATION_TYPE.DANGER,
      index: index
    }

    yield call(action.callback, FAILURE, afterRequestData)

    apiErrorHelpers.consoleLog(errors)
  }
} // createAndUpdateVanityLabel

export default function* shopOrdersSaga() {
  yield takeEvery("SHOP_ORDERS_LOAD_SAGA", loadShopOrders)
  yield takeEvery("SHOP_ORDERS_DOWNLOAD_SAGA", downloadShopOrders)
  yield takeEvery("SHOP_ORDER_LOAD_SAGA", loadShopOrder)
  yield takeEvery("SHOP_ORDER_LOAD_DETAILS_SAGA", loadShopOrderDetails)
  yield takeEvery("SHOP_ORDER_PRICE_RANGES_LOAD_SAGA", loadShopOrderPriceRanges)
  yield takeEvery("SHOP_ORDER_LOAD_PREVIEW", loadOrderPreview)
  yield takeEvery("CREATE_AND_UPDATE_VANITY_LABEL_SAGA", createAndUpdateVanityLabel)
}
