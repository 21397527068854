import React from "react"
export const EMAIL_REGEX = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/

export const ZIP_CODE_REGEX = /^\d{5}((-|\s)\d{4})?$/
export const POSTAL_CODE_REGEX = /^[ABCEGHJKLMNPRSTVXYabceghjklmnprstvxy]{1}\d{1}[A-Za-z]{1} \d{1}[A-Za-z]{1}\d{1}$/

export const PHONE_NUMBER_REGEX = /^\d{10}$/

export const OTP_REGEX = /^\d{6}$/
export const COUNTRY_CODE = "+1"

export const formatPhoneNumber = (phoneNumber) => {
  const cleaned = ("" + phoneNumber).replace(/\D/g, "")
  const isValidNumber = cleaned.length === 10

  if (isValidNumber) {
    const formatted = cleaned.replace(/(\d{3})(\d{3})(\d{4})/, "$1-$2-$3")
    return formatted
  } else {
    return phoneNumber
  }
}

export const formatSentence = (text, fontWeight = 900, textDecoration = "none") => {
  const parts = text.split("*")

  const elements = parts.map((part, index) => {
    return index % 2 === 0 ? (
      <span key={index}>{part}</span>
    ) : (
      <p
        style={{
          fontWeight: fontWeight,
          textDecoration: textDecoration,
          marginBlockStart: "0em",
          marginBlockEnd: "0em"
        }}
        key={index}
      >
        {part}
      </p>
    )
  })

  return elements
}
