import React, { Component } from "react"

import { navigationPaths } from "../../constants/paths"
import { connect } from "react-redux"

import { getSubdomain, getStyles } from "./StylesheetInjector"
import ReactSVG from "react-svg"
import { Grid, Menu } from "semantic-ui-react"
import NavItem from "./NavItem"

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import {
  faHome,
  faCar,
  faTools,
  faMapMarkerAlt,
  faCog,
  faUserFriends,
  faTachometer,
  faUserClock,
  faFileInvoiceDollar,
  faFilePlus
} from "@fortawesome/pro-light-svg-icons"
import { FLEET, FMC } from "../../constants/application"
import { isFmcPath } from "../../helpers/pathHelpers"
import { isFMCUser } from "../../helpers/userRoleHelpers"
import {
  isFleetMaintenanceHub,
  isCarvana,
  isFleetMaintenanceHubCanada,
  isFleetcor,
  isFuelman,
  isNTWTBC,
  isFleetAdvise
} from "../../helpers/affiliationHelpers"
import { withTranslation } from "react-i18next"
import NavbarContent from "./NavbarContent"
import { ON, setupSplitFlags } from "../config/SplitClient"
const styles = getStyles()

class Nav extends Component {
  constructor(props) {
    super(props)
    this.state = {}
  }

  async componentDidMount() {
    await setupSplitFlags.bind(this)({ sub_domain: getSubdomain() })
  }

  accountTabIsActive() {
    const pathName = this.pathName()

    if (/\/account/.test(pathName)) {
      return true
    }

    return false
  }

  dashboardTabIsActive() {
    const pathName = this.pathName()
    if (pathName === navigationPaths.dashboard()) {
      return true
    }

    return false
  }

  fmcDashboardTabIsActive() {
    const pathName = this.pathName()

    if (pathName === navigationPaths.fmcDashboard()) {
      return true
    }

    return false
  }

  fmcVehiclesTabIsActive() {
    const pathName = this.pathName()

    if (pathName === navigationPaths.fmcVehicles()) {
      return true
    }

    return false
  }

  fmcUsersTabIsActive() {
    const pathName = this.pathName()

    if (pathName === navigationPaths.fmcUsers()) {
      return true
    }

    return false
  }

  fmcOrdersTabIsActive() {
    const pathName = this.pathName()

    if (pathName === navigationPaths.fmcActiveOrders()) {
      return true
    }

    return false
  }

  transferVehiclesTabIsActive() {
    const pathName = this.pathName()

    if (pathName === navigationPaths.transferVehicles()) {
      return true
    }

    return false
  }

  fmcTransactionsTabIsActive() {
    const pathName = this.pathName()

    if (pathName === navigationPaths.fmcTransactions()) {
      return true
    }

    return false
  }

  fleetTransactionsTabIsActive() {
    const pathName = this.pathName()

    if (pathName === navigationPaths.fleetTransactions()) {
      return true
    }

    return false
  }

  fmcReportingTabIsActive() {
    const pathName = this.pathName()

    if (pathName === navigationPaths.fmcReporting()) {
      return true
    }

    return false
  }

  fleetReportingTabIsActive() {
    const pathName = this.pathName()
    return pathName === navigationPaths.fleetReporting()
  }

  pathName() {
    return window.location.pathname
  }

  shopsTabIsActive() {
    const pathName = this.pathName()

    if (/\/service_request/.test(pathName)) {
      return true
    }
    if (/\/shops/.test(pathName)) {
      return true
    }

    return false
  }

  servicesTabIsActive() {
    const pathName = this.pathName()

    if (/\/services/.test(pathName)) {
      return true
    }

    return false
  }

  settingsTabIsActive() {
    const pathName = this.pathName()

    if (/\/settings/.test(pathName)) {
      return true
    }

    return false
  }

  vehiclesTabIsActive() {
    const pathName = this.pathName()

    if (/\/vehicles/.test(pathName)) {
      return true
    }

    return false
  }

  fleetModeNavItems = () => {
    const { t } = this.props
    let fleetModeNavObj = [
      {
        content: t("Dashboard"),
        activeFunc: this.dashboardTabIsActive(),
        icon: faHome,
        linkPath: navigationPaths.dashboard()
      },
      {
        content: t("Vehicles"),
        activeFunc: this.vehiclesTabIsActive(),
        icon: faCar,
        linkPath: navigationPaths.vehicleIndex()
      },
      {
        content: t("Services"),
        activeFunc: this.servicesTabIsActive(),
        icon: faTools,
        linkPath: navigationPaths.servicesIndex()
      },
      {
        content: t("Shops"),
        activeFunc: this.shopsTabIsActive(),
        icon: faMapMarkerAlt,
        linkPath: navigationPaths.shopIndex()
      },
      {
        content: t("Settings"),
        activeFunc: this.settingsTabIsActive(),
        icon: faCog,
        linkPath: navigationPaths.settingsIndex()
      },
      {
        content: t("Transactions"),
        activeFunc: this.fleetTransactionsTabIsActive(),
        icon: faFileInvoiceDollar,
        linkPath: navigationPaths.fleetTransactions()
      }
    ]

    if (this.state && this.state.showReportingFlag && this.state.showReportingFlag === ON)
      fleetModeNavObj = [
        ...fleetModeNavObj,
        {
          content: t("reports:reportingLabel"),
          activeFunc: this.fleetReportingTabIsActive(),
          icon: faFilePlus,
          linkPath: navigationPaths.fleetReporting()
        }
      ]

    return fleetModeNavObj
  }

  fmcNavItems = () => {
    const { t } = this.props
    let fmcNavObj = [
      {
        content: t("Dashboard"),
        activeFunc: this.fmcDashboardTabIsActive(),
        icon: faTachometer,
        linkPath: navigationPaths.fmcDashboard()
      },
      {
        content: t("FMC_Vehicles"),
        activeFunc: this.fmcVehiclesTabIsActive(),
        icon: faCar,
        linkPath: navigationPaths.fmcVehicles()
      },
      {
        content: t("Users"),
        activeFunc: this.fmcUsersTabIsActive(),
        icon: faUserFriends,
        linkPath: navigationPaths.fmcUsers()
      },
      {
        content: t("FMC_Orders"),
        activeFunc: this.fmcOrdersTabIsActive(),
        icon: faUserClock,
        linkPath: navigationPaths.fmcActiveOrders()
      },
      {
        content: t("FMC_Transactions"),
        activeFunc: this.fmcTransactionsTabIsActive(),
        icon: faFileInvoiceDollar,
        linkPath: navigationPaths.fmcTransactions()
      },
      {
        content: t("Transfer_Vehicles"),
        activeFunc: this.transferVehiclesTabIsActive(),
        icon: "",
        linkPath: navigationPaths.transferVehicles()
      }
    ]

    if (this.state && this.state.showReportingFlag && this.state.showReportingFlag == ON)
      fmcNavObj = [
        ...fmcNavObj,
        {
          content: t("reports:reportingLabel"),
          activeFunc: this.fmcReportingTabIsActive(),
          icon: faFilePlus,
          linkPath: navigationPaths.fmcReporting()
        }
      ]

    return fmcNavObj
  }

  /* TAK affiliation id(DEV) = 222
   N.J. Malin and Associates, LLC affiliation id(PROD) = 1011
   Unity Point Health affiliation id(PROD) = 957
   Alsco affiliation id(PROD) = 720 */

  render() {
    const { isFMC, isFMCMode, user, account_fleets, currentUser, fleet, t } = this.props
    const activeColor = styles.iconColorActive
    const inactiveColor = styles.iconColorInactive
    let sub = getSubdomain()
    let size = isFleetAdvise() ? 48 : 86
    if (isFleetMaintenanceHub() || isFleetMaintenanceHubCanada()) size = 60
    if (isFleetcor()) size = 150
    if (isFuelman()) size = 90
    let extraStyles = {}
    // Add white background to the Carvana logo
    if (isCarvana()) extraStyles = { backgroundColor: "white", padding: "9px 7px" }
    const fleetModeNavItems = this.fleetModeNavItems()
    const fmcNavItems = this.fmcNavItems()

    return (
      <Grid.Column
        className={isFleetcor() ? "navbar fleetcor" : " navbar"}
        only="computer"
        centered
      >
        <Menu borderless vertical className="borderless-outline">
          <div style={{ backgroundColor: "white !important" }}>
            <Menu.Header className={isFleetcor() || isFuelman() || isNTWTBC() ? "fleetcor" : ""}>
              <ReactSVG
                src={`/images/${sub}_logo.svg`}
                svgStyle={{ ...extraStyles, width: size, height: size }}
              />
            </Menu.Header>
          </div>

          {this.props.isFleetMode && (
            <React.Fragment>
              {fleetModeNavItems.map((item) => {
                return (
                  <NavItem
                    active={item.activeFunc}
                    content={
                      <NavbarContent
                        item={item}
                        activeColor={activeColor}
                        inactiveColor={inactiveColor}
                      />
                    }
                    linkPath={item.linkPath}
                  />
                )
              })}
            </React.Fragment>
          )}

          {isFMC && isFMCMode && (
            <React.Fragment>
              {fmcNavItems.map((item) => {
                return (
                  <NavItem
                    active={item.activeFunc}
                    content={
                      <NavbarContent
                        item={item}
                        activeColor={activeColor}
                        inactiveColor={inactiveColor}
                      />
                    }
                    linkPath={item.linkPath}
                  />
                )
              })}
            </React.Fragment>
          )}
        </Menu>
      </Grid.Column>
    )
  }
} // class Nav

const mapStateToProps = (state) => {
  const user = state.application.userSessionData || (state.users && state.users.currentUser)
  const isFMC = (user && user.isFMC) || isFMCUser(user)
  const MembershipUrl = window.location.pathname.match(navigationPaths.membershipCard())
  const isPathFMC =
    isFmcPath() || (state.application.mode === FMC && MembershipUrl && MembershipUrl.length > 0)
  return {
    isFMC: isFMC,
    isFMCMode: state.application.mode === FMC && isFMC && isPathFMC,
    isFleetMode: state.application.mode === FLEET || (isFMC && !isPathFMC),
    fleet: state.application.fleetSessionData || state.fleets.fleet,
    account_fleets: state.users.account_fleets,
    currentUser: user
  }
}

export default connect(mapStateToProps)(withTranslation("common")(Nav))
