import React from "react"
import { ShimmerThumbnail } from "react-shimmer-effects"

function ApprovalsShowShimmer() {
  const shimmerGrids = Array.from({ length: 7 }, (_, index) => {
    return <ShimmerThumbnail height={30} rounded />
  })
  return (
    <div className="vehicle-shimmer-parent">
      <ShimmerThumbnail rounded height={200} />
      {shimmerGrids}
      <ShimmerThumbnail rounded height={150} />
    </div>
  )
}

export default ApprovalsShowShimmer
