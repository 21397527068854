import React, { Component } from "react"
import PropTypes from "prop-types"
import { connect } from "react-redux"

import { Segment, Menu, Header } from "semantic-ui-react"
import Alert from "../../components/shared/Alert"
import ApplicationLayout from "../../layouts/ApplicationLayout"
import ShopList from "../../components/shops/ShopList"
import { withTranslation } from "react-i18next"
import { isFleetAdvise } from "../../helpers/affiliationHelpers"
import { logFirebaseAnalyticsScreenName } from "../../helpers/googleAnalyticsHelpers"
import { getRouteNameFromUrl } from "../../helpers/segmentHelpers"

const PAGE_SEARCH = "search"
const PAGE_FAVORITES = "favorites"

class ShopIndex extends Component {
  static propTypes = {
    isLoading: PropTypes.bool.isRequired,
    isLoadingError: PropTypes.bool.isRequired,
    favoriteShops: PropTypes.array.isRequired,
    shops: PropTypes.array.isRequired
  }

  state = {
    alertMessage: "",
    alertType: "default",
    activePage: PAGE_SEARCH,
    pages: [
      {
        name: PAGE_SEARCH,
        label: "searchShopsLabel",
        props: () => {
          return { ...this.pagesProps(), searchMode: true, language: this.props.language }
        }
      },
      {
        name: PAGE_FAVORITES,
        label: "savedShopsLabel",
        props: () => {
          return { ...this.pagesProps(), shops: this.props.favoriteShops, searchMode: false }
        }
      }
    ]
  }

  pagesProps() {
    const {
      isLoading,
      isLoadingError,
      favoriteShops,
      shops,
      location,
      totalPages,
      pageCount,
      favoriteShopTotalPages,
      favoriteShopPageCount
    } = this.props

    const { activePage } = this.state

    return {
      favoriteShops: favoriteShops,
      shops: shops,
      isLoading: isLoading,
      isLoadingError: isLoadingError,
      onFavoriteSubmit: this.onFavoriteSubmit,
      previousFormData: location.state && location.state.previousFormData,
      totalPages: activePage === PAGE_SEARCH ? totalPages : favoriteShopTotalPages,
      pageCount: activePage === PAGE_SEARCH ? pageCount : favoriteShopPageCount,
      activePage
    }
  }

  handlePageChange = async (page) => {
    if (this.state.activePage === PAGE_SEARCH && page === PAGE_FAVORITES) {
      await this.props.dispatch({
        type: "SHOPS_LOAD_SAGA"
      })
    }
    await this.setState({ activePage: page })
  }

  componentWillMount() {
    if (this.state.activePage !== PAGE_SEARCH) {
      this.props.dispatch({
        type: "SHOPS_LOAD_SAGA"
      })
    }
  }

  componentDidMount() {
    logFirebaseAnalyticsScreenName(getRouteNameFromUrl())
  }

  afterRequest(data) {
    if (data.status === "success") {
      if (this.state.activePage === PAGE_FAVORITES)
        this.props.dispatch({
          type: "SHOPS_LOAD_SAGA",
          payload: { pageCount: 1 }
        })
      this.onRequestSuccess(data)
    } else {
      this.onRequestFailure(data)
    }
  }

  onFavoriteSubmit = (formData) => {
    this.props.dispatch({
      type: "SHOP_FAVORITE_SAGA",
      payload: { formData: formData },
      callback: this.afterRequest.bind(this)
    })
  }

  onRequestFailure(data) {
    this.setState({
      alertMessage: data.alertMessage,
      alertType: data.alertType
    })
  }

  onRequestSuccess(data) {
    // NOTE: Intentionally left blank.
  }

  componentWillUnmount() {
    const { dispatch } = this.props
    if (!window.location.href.includes("shops")) {
      dispatch({
        type: "RESET_SHOPS_FILTER_SAGA"
      })
      dispatch({
        type: "RESET_SHOP_LOCATION"
      })
    }
  }

  // NOTE: For this view, the LoadingThrobber and LoadingError components are rendered within the ShopList component. This way the filter element can trigger it as well, and still display itself while the filtered list is (re)loading. It also prevent the entered zip code from being reset after filtering.
  render() {
    const { t } = this.props
    const { alertMessage, alertType, pages, activePage } = this.state
    const pageData = pages.find((p) => p.name === activePage)

    return (
      <ApplicationLayout>
        <Alert message={alertMessage} type={alertType} visible={alertMessage !== ""} />

        <div>
          <span />
          <Header size="small" className={"dash-hd"}>
            {t("shopsLabel")}
          </Header>
          <Segment.Group>
            <Segment className="menu-container">
              <Menu pointing secondary>
                {pages.map((page) => {
                  return (
                    <Menu.Item
                      name={t(page.label)}
                      active={page.name === activePage}
                      onClick={() => this.handlePageChange(page.name)}
                      className={`${isFleetAdvise() ? "menu-theme-color font-weight" : ""}`}
                    />
                  )
                })}
              </Menu>
            </Segment>
            <Segment className="tab-container">{<ShopList {...pageData.props()} />}</Segment>
          </Segment.Group>
        </div>
      </ApplicationLayout>
    )
  }
} // class ShopIndex

const mapStateToProps = (state) => ({
  isLoading: state.application.isLoading,
  isLoadingError: state.application.isLoadingError,
  favoriteShops: state.shops.favoriteShops,
  shops: state.shops.shops,
  language: state.users.currentUser ? state.users.currentUser.language : "",
  totalPages: state.shops.totalPages,
  pageCount: state.shops.pageCount,
  favoriteShopTotalPages: state.shops.favoriteShopTotalPages,
  favoriteShopPageCount: state.shops.favoriteShopPageCount
})

export default connect(mapStateToProps)(withTranslation("shops")(ShopIndex))
