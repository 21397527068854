import React from "react"
import { Form, Image } from "semantic-ui-react"
import i18n from "../../utilities/i18n"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faFileAlt } from "@fortawesome/pro-light-svg-icons"
import { isFleetAdvise } from "../../helpers/affiliationHelpers"

export class DocumentUploader extends React.Component {
  constructor(props) {
    super(props)
    this.state = {}
  }

  handleFileChange = (e) => {
    this.props.handleFileChange(e.target.files)
  }

  render() {
    const { canUploadMultipleFiles, deleteSelectedFile, selectedFiles } = this.props

    return (
      <div className="document-uploader-container fileupload-view">
        <div>
          <div>
            <div className="card">
              <div className="card-body">
                <div className="data-box">
                  <Form>
                    <div className="file-upload">
                      <div className="file-upload-box">
                        <input
                          type="file"
                          id="fileupload"
                          className="file-upload-input"
                          onChange={(e) => this.handleFileChange(e)}
                          multiple={canUploadMultipleFiles}
                        />
                        <span>
                          {i18n.t("vehicle:dragAndDropLbl")}
                          <span className="link">
                            {canUploadMultipleFiles
                              ? i18n.t("vehicle:chooseFilesLbl")
                              : i18n.t("vehicle:chooseFileLbl")}
                          </span>
                        </span>
                      </div>
                    </div>
                    <div className="attach-box">
                      {selectedFiles.map((data, index) => {
                        const { fileName, fileType, fileImage, dateTime, fileSize } = data
                        return (
                          <div className="file-atc-box" key={index}>
                            {fileName.match(/.(jpg|jpeg|png|jfif)$/i) ? (
                              <div className="file-image">
                                <Image src={fileImage} alt="" />
                              </div>
                            ) : (
                              <div className="file-image">
                                <FontAwesomeIcon icon={faFileAlt} />
                              </div>
                            )}
                            <div className="file-detail">
                              <h6>{fileName}</h6>
                              {fileSize && (
                                <>
                                  <p></p>
                                  <p>
                                    <span>
                                      {i18n.t("vehicle:sizeLbl")} {fileSize}{" "}
                                    </span>
                                  </p>
                                </>
                              )}
                              <div className="file-actions">
                                <button
                                  type="button"
                                  className={`file-action-btn ${
                                    isFleetAdvise() ? "file-action-btn-background" : ""
                                  }`}
                                  onClick={() => deleteSelectedFile(index, data.id)}
                                >
                                  {i18n.t("vehicle:deleteLbl")}
                                </button>
                              </div>
                            </div>
                          </div>
                        )
                      })}
                    </div>
                  </Form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }
}
