import React, { Component } from "react"
import { useTranslation } from "react-i18next"
import { Label } from "semantic-ui-react"
import * as maintenanceHelpers from "../../helpers/maintenanceHelpers"

const DueStatusLabel = ({ status }) => {
  // const status = maintenanceHelpers.dueStatusForVehicle(vehicle, serviceSchedulesByVehicle)
  const statusColor = maintenanceHelpers.dueStatusColor(status)
  const { t } = useTranslation("dashboard")
  if (status)
    return (
      <Label
        size="tiny"
        style={{ borderColor: statusColor, color: statusColor }}
        basic
        padded
        attached="top right"
      >
        {t(status)}
      </Label>
    )
  else return null
}

export default DueStatusLabel
