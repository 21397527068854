export const initialState = {
  transactionReportData: [],
  reportSummaryData: [],
  filters: [],
  loadingTransactionRecords: false,
  loadingFilterRecords: false
}

export default function (state = initialState, action) {
  const { payload, type } = action

  switch (type) {
    case "SET_TRANSACTION_REPORT_LOADER":
      return {
        ...state,
        loadingTransactionRecords: true
      }

    case "SET_FILTER_REPORT_LOADER":
      return {
        ...state,
        loadingFilterRecords: true
      }

    case "TRANSACTIONS_REPORT_LOAD_SUCCEEDED":
      return {
        ...state,
        transactionReportData: payload.transactionReportData,
        loadingTransactionRecords: false
      }

    case "CONFIGURED_REPORT_FILTERS":
      return {
        ...state,
        configureFilters: payload.configureFilters
      }

    case "REPORT_SUMMARY_DATA":
      return {
        ...state,
        reportSummaryData: payload.reportSummaryData
      }

    case "FILTERS_LOAD_SUCCEEDED":
      return {
        ...state,
        filters: payload.filters,
        loadingFilterRecords: false
      }

    case "CREATE_UPDATE_FILTER_SUCCEEDED":
      if (payload.reportEditEnabled == true) {
        let updatedFilters = state.filters.map((filter) =>
          filter.filter_key == payload.filter_key ? payload.filter : filter
        )
        return {
          ...state,
          filters: updatedFilters
        }
      } else {
        return {
          ...state,
          filters: [payload.filter, ...state.filters]
        }
      }

    case "DELETE_FILTER_SUCCEEDED":
      return {
        ...state,
        filters: state.filters.filter((filter) => filter.filter_key !== payload.filter_key)
      }

    default:
      return state
  }
}
