import React from "react"
import { Grid } from "semantic-ui-react"
import { ShimmerThumbnail } from "react-shimmer-effects"

function ServiceIndexShimmer(props) {
  const shimmerGrids = Array.from({ length: 5 }, (_, index) => {
    return <ShimmerThumbnail height={30} rounded />
  })

  const gridColumns = Array.from({ length: 3 }, (_, index) => {
    return (
      <Grid.Column>
        <ShimmerThumbnail rounded height={200} />
      </Grid.Column>
    )
  })

  return (
    <div>
      {!props.only_grid ? (
        <>
          <ShimmerThumbnail rounded height={80} />
          <Grid columns="equal" stretched stackable>
            <Grid.Row>{gridColumns}</Grid.Row>
          </Grid>
          <br />
        </>
      ) : (
        <></>
      )}
      <div className="vehicle-shimmer-parent">
        <ShimmerThumbnail rounded height={80} />
        {shimmerGrids}
      </div>
    </div>
  )
}

export default ServiceIndexShimmer
