export const OPTIONS = Object.freeze({
  EMAIL: "email",
  TEXT: "cellPhone",
  PHONE: "phone"
})

export const KEYWORDS = Object.freeze({
  SUCCESS: "success",
  ERROR: "error",
  FAILURE: "failure",
  DESC: "descending"
})
