import React, { useState } from "react"
import { Modal } from "semantic-ui-react"
import { useTranslation, withTranslation } from "react-i18next"
import DeleteExclamation from "../../assets/images/delete_exclamation.svg"
import ReactSVG from "react-svg"
import CustomNotification from "./CustomNotification"
import { SUCCESS } from "../../constants/notification"
import store from "../../actions/configureStore"

const TrashReportModal = ({ modalOpen, service, handleClose, filter, clearFilters }) => {
  const { t } = useTranslation("reports")

  const handleDeleteReport = () => {
    store.dispatch({
      type: "DELETE_FILTER",
      payload: { filter_key: filter.filter_key, t: t },
      callback: afterFilterDelete
    })
  }

  const afterFilterDelete = (status, data) => {
    const { type, title, message } = data
    handleClose()
    clearFilters()
    return CustomNotification(type, title, message, t)
  }

  const renderView = () => (
    <React.Fragment>
      <div className="add_model_section">
        <div className="delete_icon_section">
          <ReactSVG src={DeleteExclamation} />
        </div>
        <div className="header_section">
          <span className="header_section_label">
            {t("deleteConfirmationLabel")} {filter && filter.filter_name} ?
          </span>
          <span>{service && service.name}</span>
        </div>
        <span className="header_sub_text">{t("deleteSubTextLabel")}</span>
        <div className="buttons_section">
          <div className="button_section hand-icon">
            <div className="button_cover">
              <div className="button">
                <span className="button_text" onClick={handleClose}>
                  {t("services:cancelText")}
                </span>
              </div>
            </div>
          </div>
          <div
            className="enabled_delete_button_section hand-icon"
            onClick={() => handleDeleteReport()}
          >
            <div className="button_cover">
              <div className="button">
                <span className="button_text">{t("deleteLabel")}</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  )

  return (
    <Modal size="mini" className="custom-modal" open={modalOpen} closeIcon onClose={handleClose}>
      <Modal.Content>{renderView()}</Modal.Content>
    </Modal>
  )
}

export default withTranslation("reports")(TrashReportModal)
