import React, { useState } from "react"
import { useTranslation } from "react-i18next"
import { Button, Checkbox, Divider, Modal } from "semantic-ui-react"
import { CUSTOMIZE_REPORT_OPTIONS, INCLUDE_ALL_COLUMNS } from "../../constants/reports"
import { isEmpty } from "lodash"
import { ANCHOR_BLANK, ANCHOR_FEATURES } from "../../constants/sfo"
import store from "../../actions/configureStore"

const CustomizeReportModal = ({
  modalOpen,
  handleClose,
  handleDownload,
  isReportDownloading,
  reportUrl,
  handleReportUrl
}) => {
  const { t } = useTranslation("reports")
  const [selectedColumns, setSelectedColumns] = useState(
    CUSTOMIZE_REPORT_OPTIONS.map((option) => option.value)
  )

  const downloadReports = () => {
    store.dispatch({
      type: "DOWNLOAD_TRANSACTIONS_REPORT",
      payload: {
        fileName: reportUrl
      }
    })
  }

  const handleCheckboxChange = ({ value, checked }) => {
    if (value === INCLUDE_ALL_COLUMNS) {
      if (checked) {
        // Add all values to selectedColumns
        setSelectedColumns(CUSTOMIZE_REPORT_OPTIONS.map((option) => option.value))
      } else {
        // Clear all values from selectedColumns
        setSelectedColumns([])
      }
    } else {
      if (checked) {
        setSelectedColumns((prevSelected) => [...prevSelected, value])
      } else {
        setSelectedColumns((prevSelected) =>
          prevSelected.filter((selectedValue) => selectedValue !== value)
        )
      }
    }
  }

  const isAllSelected = selectedColumns.length === CUSTOMIZE_REPORT_OPTIONS.length

  return (
    <Modal
      className="customize-report-modal"
      open={modalOpen}
      onClose={handleClose}
      dimmer="blurring"
    >
      <Modal.Content>
        <p className="report-modal-header">{t("selectColumn")}</p>

        <Checkbox
          onChange={(_, data) => handleCheckboxChange(data)}
          className="option-checkbox"
          label={INCLUDE_ALL_COLUMNS}
          value={INCLUDE_ALL_COLUMNS}
          checked={isAllSelected}
          disabled={reportUrl}
        />

        <Divider />

        <div className="customize-options">
          {CUSTOMIZE_REPORT_OPTIONS.map((option, index) => (
            <Checkbox
              onChange={(_, data) => handleCheckboxChange(data)}
              className="option-checkbox"
              label={option.label}
              value={option.value}
              disabled={reportUrl}
              checked={selectedColumns.includes(option.value)}
            />
          ))}
        </div>

        <div className="dialog-action-btn">
          <Button
            size="tiny"
            disabled={isReportDownloading}
            onClick={() => {
              handleReportUrl("")
              handleClose()
            }}
            className="cancel-btn"
          >
            {t("cancel")}
          </Button>
          <Button
            disabled={isReportDownloading || isEmpty(selectedColumns)}
            onClick={() => {
              if (reportUrl) {
                downloadReports()
                handleReportUrl("")
                handleClose()
              } else {
                handleDownload(selectedColumns)
              }
            }}
            size="tiny"
            className="download-btn"
          >
            {reportUrl
              ? t("download")
              : isReportDownloading
              ? t("generating")
              : t("generateReport")}
          </Button>
        </div>
      </Modal.Content>
    </Modal>
  )
}

export default CustomizeReportModal
