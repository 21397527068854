import React, { Component } from "react"
import { connect } from "react-redux"
import { withTranslation } from "react-i18next"
import { Modal, Header } from "semantic-ui-react"
import MaintenanceHistorySummary from "../../views/approvals/MaintnenaceHistorySummary"

class ShowManualManintenanceHistoryModal extends Component {
  constructor(props) {
    super(props)

    this.state = {
      modalOpen: false,
      save_disabled: true
    }
  }

  handleOpen = async () => {
    this.setState({
      modalOpen: true
    })
  }

  handleClose = () => {
    this.setState({ modalOpen: false })
  }

  renderView = () => {
    const { t } = this.props
    return (
      <React.Fragment>
        <div className="box-container maintenance-summary-section" id="maintenance-summary-section">
          <React.Fragment>
            <div className="inline-elements">
              <Header as="h3" className="title">
                {t("vehicle:maintenanceHistoryLabel")}
              </Header>
            </div>
            {true && (
              <MaintenanceHistorySummary
                afterDeleteMaintenanceHistory={this.afterDeleteMaintenanceHistory}
                {...this.props}
              />
            )}
          </React.Fragment>
        </div>
      </React.Fragment>
    )
  }

  render() {
    const { children } = this.props
    return (
      <Modal
        size="large"
        trigger={
          React.Children.map(children, (c) =>
            React.cloneElement(c, { onClick: this.handleOpen })
          )[0]
        }
        open={this.state.modalOpen}
        closeIcon
        onClose={this.handleClose}
      >
        <Modal.Content>{this.renderView()}</Modal.Content>
      </Modal>
    )
  }
}

export default withTranslation("services")(ShowManualManintenanceHistoryModal)
