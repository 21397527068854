import React, { Component } from "react"
import PropTypes from "prop-types"
import { connect } from "react-redux"
import { withTranslation } from "react-i18next"
import LoadingError from "../../components/shared/LoadingError"
import { Divider, Image } from "semantic-ui-react"
import ReactSVG from "react-svg"
import { isFleetAdvise } from "../../helpers/affiliationHelpers"
import MerchantAccountIcon from "../../assets/images/merchant_account.svg"
import ACHVerificationModal from "../../components/shared/ACHVerificationModal"
import BraintreeModal from "../../components/shared/BraintreeModal"
import { DRIVER, MANAGER } from "../../constants/roles"
import { NOTIFICATION_TYPE } from "../../constants/notification"
import { CREDIT_CARD_WITH_ACH_LABEL, ACH_LABEL } from "../../constants/achPayment"

const RenderPaymentsOptions = ({ pages, onChange, t, activePage, fromApproval, achPresent }) => {
  let svgStyle = { width: "50px", marginLeft: "25px" }
  return (
    <div className={fromApproval ? "payment-grid  approval-screen" : "payment-grid"}>
      {pages &&
        pages.map((page) => {
          return (
            <label
              key={page.name}
              className={fromApproval ? "payment-card  approval-screen" : "payment-card"}
              onClick={() => page.name !== activePage && onChange(page.name)}
            >
              <input
                name={`${t(page.label)}-payment`}
                className={`${fromApproval ? "payment-radio  approval-screen" : "payment-radio"} ${
                  isFleetAdvise() ? "payment-radio-fleetadvise" : ""
                }`}
                type="radio"
                checked={page.name === activePage}
                readOnly
              />
              <span
                className={fromApproval ? "payment-details  approval-screen" : "payment-details"}
              >
                <span>
                  {page.label === ACH_LABEL ? (
                    <ReactSVG
                      src={`/images/ach_logo.svg`}
                      svgStyle={fromApproval ? { height: "30px" } : {}}
                    />
                  ) : (
                    <ReactSVG
                      src={`/images/${page.name}-logo.svg`}
                      svgStyle={fromApproval ? svgStyle : {}}
                    />
                  )}
                </span>
                <span
                  className={`${fromApproval ? "payment-type approval-screen" : "payment-type"} ${
                    isFleetAdvise() ? "payment-color" : ""
                  }`}
                >
                  {t(page.label)}
                </span>
                {!achPresent &&
                !fromApproval &&
                (page.label === ACH_LABEL || page.label == CREDIT_CARD_WITH_ACH_LABEL) ? (
                  <p className="payment_ach__new">New</p>
                ) : (
                  <></>
                )}
              </span>
            </label>
          )
        })}
    </div>
  )
}

class PaymentsIndex extends Component {
  static propTypes = {
    isLoading: PropTypes.bool.isRequired,
    isLoadingError: PropTypes.bool.isRequired
  }

  constructor(props) {
    super(props)
    const { pages } = this.props
    this.braintreeModal = React.createRef()

    this.state = {
      activePage: pages && pages.length === 1 ? pages[0].name : "",
      isSubmitting: false,
      isACHModalOpen: false,
      isVerificationOpen: false
    }
  }

  handlePageChange = async (page) => {
    const { fromApproval } = this.props
    if (fromApproval) await this.props.onChange(page)
    await this.setState({ activePage: page })
  }

  addPaymentMethod = async (nonce, formData, ach = false, is_default = false) => {
    const { t, dispatch } = this.props
    await dispatch({
      type: "PAYMENT_METHOD_CREATE_SAGA",
      payload: { nonce: nonce, formData: formData, t: t, ach: ach, is_default: is_default },
      callback: this.afterRequest.bind(this)
    })
  }

  afterRequest = (status, data) => {
    if (status == NOTIFICATION_TYPE.SUCCESS) {
      this.handleACHModal()
    }
  }

  renderContent() {
    const {
      t,
      pages,
      onChange,
      fromApproval,
      clientToken,
      language,
      users,
      fleet,
      achPresent
    } = this.props
    const { activePage, isACHModalOpen, isVerificationOpen } = this.state
    const page = pages && pages.find((p) => p.name === activePage)

    return (
      <>
        <div className={fromApproval ? "payment-options approval-screen" : "payment-options"}>
          {pages && (
            <>
              <p>
                <strong>{t("payment:choosePaymentProviderLable")}</strong>
              </p>
              <RenderPaymentsOptions
                key="payment-option"
                onChange={this.handlePageChange}
                pages={pages}
                t={t}
                activePage={activePage}
                fromApproval={fromApproval}
                achPresent={achPresent}
              />
            </>
          )}
        </div>
        {!fromApproval && (
          <>
            <Divider />
            <div className="payment-options-content">
              {!fromApproval && pages && pages.length > 0 ? (
                activePage ? (
                  page.component()
                ) : (
                  <div className="no-content">{t("payment:noPaymentProviderSelected")}</div>
                )
              ) : (
                <div className="no-content">{t("payment:noPaymentProvidersAvailable")}</div>
              )}
            </div>
          </>
        )}
        <BraintreeModal
          ref={this.braintreeModal}
          clientToken={clientToken}
          modalOpen={isACHModalOpen}
          closeModal={this.handleACHModal}
          saveCallback={this.addPaymentMethod}
          users={users.filter(function (item) {
            if (
              (item.roles.length == 1 &&
                !item.roles.includes(DRIVER) &&
                !item.roles.includes(MANAGER)) ||
              (item.roles.length == 2 &&
                JSON.stringify(item.roles.sort()) !== JSON.stringify([DRIVER, MANAGER])) ||
              item.roles.length > 2
            )
              return item
          })}
          t={t}
          language={language}
          fleet={fleet}
        />
        <ACHVerificationModal
          isACHVerificationModalOpen={isVerificationOpen}
          closeModal={this.handleACHModal}
        />
      </>
    )
  }

  render() {
    const { currentUser, isLoading, isLoadingError } = this.props
    return (
      <>
        {!isLoading && isLoadingError ? <LoadingError visible /> : this.renderContent(currentUser)}
      </>
    )
  }
}

const mapStateToProps = (state) => ({
  isLoading: state.application.isLoading,
  isLoadingError: state.application.isLoadingError,
  users: state.users.users,
  fleet: state.application.fleetSessionData || state.fleets.fleet,
  currentUser: state.application.userSessionData || state.users.currentUser,
  language:
    (state.application.userSessionData && state.application.userSessionData.language) ||
    state.users.currentUser
      ? state.users.currentUser.language
      : "",
  fleetPaymentProviders: state.users.currentUser.fleet_payment_providers,
  clientToken: state.paymentMethods.clientToken,
  paymentMethods: state.paymentMethods.paymentMethods,
  achPresent: state.paymentMethods.achPresent
})

export default connect(mapStateToProps)(withTranslation(["settings", "payment"])(PaymentsIndex))
