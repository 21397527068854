import React, { Component } from "react"
import { connect } from "react-redux"
import Store from "./actions/configureStore"

class FlutterInit extends Component {
  constructor(props) {
    super(props)
  }

  componentDidMount() {
    initFlutter()
  }

  render() {
    return <></>
  }
}

const mapStateToProps = (state) => {
  const flutterApp = state.application.flutterApp
  return {
    flutterApp: flutterApp || null
  }
}

export default connect(mapStateToProps)(FlutterInit)

export function initFlutter() {
  if (window._flutter && window._flutter.loader) {
    loadFlutter()
  } else {
    document.addEventListener("DOMContentLoaded", (event) => {
      loadFlutter()
    })
  }
}

function loadFlutter() {
  window._flutter.loader.load({
    // serviceWorkerSettings: {
    //   serviceWorkerVersion: null
    // },
    onEntrypointLoaded: async function (engineInitializer) {
      const appRunner = await engineInitializer.initializeEngine({
        multiViewEnabled: true
      })
      let flutterApp = await appRunner.runApp()
      Store.dispatch({ type: "SET_FLUTTER_APP_SAGA", payload: flutterApp })
    }
  })
}
