import { faPlus } from "@fortawesome/pro-light-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import debounce from "debounce"
import { sortBy } from "lodash"
import PropTypes from "prop-types"
import React, { Component } from "react"
import { withTranslation } from "react-i18next"
import { withRouter } from "react-router-dom"
import { Button, Grid, Image, Input, Table, Checkbox } from "semantic-ui-react"
import DownloadIcon from "../../assets/images/DownloadIcon.png"
import { isOwnerManagerOrAdmin } from "../../helpers/activeMaintenanceHelpers"
import { isFleetAdvise } from "../../helpers/affiliationHelpers"
import * as arrayHelpers from "../../helpers/arrayHelpers"
import * as vehicleHelpers from "../../helpers/vehicleHelpers"
import { ON } from "../config/SplitClient"
import InfoLabel from "../shared/InfoLabel"
import UserListItem from "./UserListItem"
import UserModal from "./UserModal"
import { CSV_DEFAULT_LANG } from "../../constants/users"

class UserList extends Component {
  static propTypes = {
    users: PropTypes.array.isRequired,
    loadUsers: PropTypes.func.isRequired
  }

  constructor(props) {
    super(props)
    this.debouncedSetQuickFilterText = debounce(this.setQuickFilterText, 300)
  }

  state = {
    showInactiveUsers: false
  }

  setQuickFilterText = (event) => {
    const escapedText = event.target.value.match(/[^\\]+/)
    this.setState({ quickFilterText: (escapedText || [])[0] })
  }

  filteredUsers = (userList) => {
    const { quickFilterText } = this.state
    const regexp = new RegExp(quickFilterText, "i")
    const users = userList.filter((user) => {
      return this.state.showInactiveUsers ? !user.is_active : user.is_active
    })
    if (!quickFilterText) {
      return users
    }

    const filteredUsers = users.flatMap((user) => {
      const userMatches = Object.values(user || {}).some((value) => {
        return value && value.match && value.match(regexp)
      })

      const vehicleMatches =
        user &&
        user.vehicles &&
        user.vehicles.some(
          (vehicle) =>
            vehicle.fleetcor_enrolled &&
            vehicleHelpers.formattedName(vehicle) &&
            vehicleHelpers.formattedName(vehicle).match(regexp)
        )

      const roleMatches =
        user &&
        user.roles &&
        user.roles.some((role) => (role.name ? role.name.match(regexp) : role.match(regexp)))

      return userMatches || vehicleMatches || roleMatches ? [user] : []
    })

    return arrayHelpers.uniq(filteredUsers)
  }

  renderListItems() {
    const {
      users,
      loadUsers,
      onDelete,
      onDeactivate,
      currentUser,
      isFMC,
      userRoles,
      states,
      isGloveboxFeatureFlag
    } = this.props

    return sortBy(this.filteredUsers(users), "email").map((user, index) => {
      return (
        <UserListItem
          key={index}
          user={user}
          loadUsers={loadUsers}
          onDelete={onDelete}
          onDeactivate={onDeactivate}
          currentUser={currentUser}
          isFMC={isFMC}
          userRoles={userRoles}
          states={states}
          isGloveboxFeatureFlag={isGloveboxFeatureFlag}
        />
      )
    })
  }

  handleDownloadClick = async () => {
    const { dispatch, match, language } = this.props
    if (match.url.includes("/fmc_users")) {
      await dispatch({
        type: "FMC_USERS_LOAD_SAGA",
        payload: {
          download: true,
          language: language
        }
      })
    } else {
      await dispatch({
        type: "USERS_LOAD_SAGA",
        payload: {
          download: true,
          language: language || CSV_DEFAULT_LANG
        }
      })
    }
  }

  setShowInactiveUsers = (event, data) => {
    this.setState({ showInactiveUsers: data.checked })
  }

  render() {
    const { loadUsers, user, currentUser, isFMC, t, users = [], isGloveboxFeatureFlag } = this.props
    const fleetsLabel = t("fleetsLabel")
    // FMC user list doesn't list Vehicles but instead Fleets which the user can approve orders for
    const headers = [
      t("nameLabel"),
      t("rolesLabel"),
      t("emailLabel"),
      t("phoneLabel"),
      isFMC ? fleetsLabel : t("common:vehiclesLabel")
    ]
    const gloveboxHeaders = [
      !isFMC && t("driverLicenseLable"),
      !isFMC && t("vehicleInsuranceLable"),
      !isFMC && t("registrationLable"),
      !isFMC && t("extraDocumentsLable")
    ]

    return (
      <React.Fragment>
        <Grid columns={2}>
          <Grid.Column floated="left" mobile={5}>
            <div className="user-list-filter">
              <Input
                className="search"
                icon="search"
                onChange={this.setQuickFilterText}
                placeholder={t("searchUsersLabel")}
              />
              <Checkbox
                label={t("showInactiveUsers")}
                checked={this.state.showInactiveUsers}
                onChange={this.setShowInactiveUsers}
              />
            </div>
          </Grid.Column>
          <Grid.Column floated="right" mobile={8} tablet={8} computer={6}>
            {isOwnerManagerOrAdmin(currentUser) && (
              <UserModal
                completionCallback={loadUsers}
                user={user}
                isFMC={isFMC}
                currentUser={currentUser}
              >
                <Button tabIndex="1" floated="right" size="tiny">
                  {isFleetAdvise() ? (
                    <FontAwesomeIcon icon={faPlus} className="button-icon-color" />
                  ) : null}
                  {t("addNewUserLabel")}
                </Button>
              </UserModal>
            )}
            <Button
              tabIndex="0"
              floated="right"
              size="tiny"
              onClick={this.handleDownloadClick}
              disabled={users.length === 0}
              className={isFleetAdvise() ? "btn-margin-right" : ""}
            >
              {isFleetAdvise() ? <Image src={DownloadIcon} className="vehicle-icons" /> : null}
              {t("services:downloadLabel")}
            </Button>
          </Grid.Column>
        </Grid>
        <div className="user-list-content">
          <Table className="borderless">
            <Table.Header>
              <Table.Row>
                {headers.map((header) => (
                  <Table.HeaderCell singleLine>
                    {header}
                    {header === fleetsLabel && (
                      <InfoLabel
                        field={{
                          helpModal: <div>{t("userDescLabel")}</div>
                        }}
                      />
                    )}
                  </Table.HeaderCell>
                ))}
                {gloveboxHeaders.map(
                  (gloveboxHeader) =>
                    isGloveboxFeatureFlag === ON && (
                      <Table.HeaderCell textAlign="center" width={1}>
                        {gloveboxHeader}
                      </Table.HeaderCell>
                    )
                )}
                <Table.HeaderCell>{t("actionLablel")}</Table.HeaderCell>
              </Table.Row>
            </Table.Header>
            <Table.Body>{this.renderListItems()}</Table.Body>
          </Table>
        </div>
      </React.Fragment>
    )
  }
} // class UserList

export default withRouter(withTranslation("fmcUsers")(UserList))
