import React, { Component } from "react"
import { connect } from "react-redux"
import { withTranslation } from "react-i18next"
import { isEmpty } from "lodash"
import { isFleetAdvise } from "../../helpers/affiliationHelpers"

const COPYRIGHT = "copyRight"
const DO_NOT_SHARE_INFORMATION = "doNotShareInfo"
class PrivacyPolicyFooter extends Component {
  constructor(props) {
    super(props)
  }

  render() {
    const { footerComponentConfig, t } = this.props
    let isFirstKey = true
    return (
      <React.Fragment>
        {!isEmpty(footerComponentConfig) && (
          <div className="privacy-policy-footer">
            <footer>
              <div className="info">
                {footerComponentConfig.hasOwnProperty(COPYRIGHT) ? (
                  <div className="copyright">{t(footerComponentConfig.copyRight.label)}</div>
                ) : (
                  ""
                )}
                {Object.keys(footerComponentConfig).map((key, index) => {
                  const { url, label } = footerComponentConfig[key]
                  const prefix = !isFirstKey ? `|` : ""
                  if (key === COPYRIGHT) {
                    return
                  }
                  isFirstKey = false
                  return (
                    <div className="legal">
                      <>
                        {prefix}
                        <a
                          href={url}
                          target="_blank"
                          className={isFleetAdvise() ? "legal-text-color" : ""}
                        >
                          {" "}
                          {t(label)}{" "}
                        </a>
                      </>
                    </div>
                  )
                })}
              </div>
            </footer>
          </div>
        )}
      </React.Fragment>
    )
  }
} // class PrivacyPolicyFooter

export default connect()(withTranslation("common")(PrivacyPolicyFooter))
