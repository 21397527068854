export const segmentProps = {
  shopName: "shop name",
  orderId: "order id",
  cartId: "cart id",
  orderServices: "order services",
  shopAddress: "shop address",
  licensePlateNumber: "license plate number",
  licensePlateState: "license plate state",
  shopId: "shop id",
  walkIn: "walk in",
  bookingAppointmentDate: "booking appointment date and time",
  bookingAppointmentTime: "booking appointment time",
  firstNameWithSpace: "first name",
  lastNameWithSpace: "last name",
  email: "email",
  zip: "zip",
  phone: "phone",
  firstName: "firstName",
  lastName: "lastName",
  totalCostOfOwnership: "total cost of ownership",
  lifetimeSavings: "lifetime savings",
  ytdCostOfOwnership: "ytd cost of ownership",
  ytdSavings: "ytd savings",
  numberOfServices: "number of services",
  promoCode: "promo code",
  dateOfCompletion: "date of completion",
  miles: "miles",
  serviceName: "service name",
  cost: "cost",
  vehicleEngine: "vehicle engine",
  estimatedCurrentMileage: "estimated current mileage",
  lastRecordedMileage: "last recorded mileage",
  estimatedMilesPerMonth: "estimated miles per month",
  lastMaintenanceDate: "last maintenance date",
  nextMaintenanceDate: "next maintenance date",
  methodToAddVehicle: "method to add vehicle",
  vehicleImage: "vehicle image",
  signInMethod: "signin method",
  userType: "user type",
  affiliation: "affiliation",
  appleOrAndroid: "apple or android",
  source: "from where",
  beforeOrAfterSignup: "before or after sign up",
  paymentMode: "payment mode",
  searchTerm: "search term",
  bookingAppointmentDay: "booking appointment day",
  yourTotal: "booking total",
  buttonName: "button name",
  existingShopAddress: "existing shop address",
  existingShopCity: "existing shop city",
  existingDate: "existing booking appointment date",
  existingTime: "existing booking appointment time",
  bookingCreatedDate: "booking created date and time",
  bookingCreatedTime: "booking created time",
  servicesName: "services name",
  planType: "plan type",
  milesPerMonth: "miles per month",
  reminderTime: "reminder time",
  action: "action",
  shopCity: "shop city",
  upcomingMiles: "upcoming miles",
  numberOfOrderServices: "number of order services",

  itemName: "option",
  currentlyDueServices: "currently due services",
  pastDueServices: "past due services",
  upcomingServices: "upcoming services",
  isDefault: "is default",
  carsAdded: "cars added",
  numberOfServicesBooked: "number of services booked",
  name: "name",
  duringOnboarding: "during onboarding",
  screenNumber: "screen number",
  previousPlan: "previous plan",
  selectedPlan: "selected plan",
  didUserSignedFromNoVehicle: "did user logged in from no vehicle",
  walletType: "wallet type",
  approvedServices: "approved services",
  declinedServices: "declined services",
  serviceId: "service id",
  servicePrice: "service price",
  currency: "currency",
  revenue: "revenue",
  shopPhoneNumber: "shop phone number",
  eventSourceName: "event_source_name",

  servicesSelected: "services selected",
  vehicleId: "vehicle id",
  vin: "VIN",
  vehicleName: "vehicle name",
  vehicleYear: "vehicle year",
  vehicleMake: "vehicle make",
  vehicleModel: "vehicle model",
  vehicleSubModel: "vehicle sub model",
  shopName: "shop name",
  shopAddress: "shop address",
  appointmentDate: "booking appointment date",
  appointmentTime: "booking appointment time",
  device: "device"
}

export const SLASH_INITIAL_REMOVE_REGEX = /^\/+|\/+$/g
