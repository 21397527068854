import { initializeApp } from "firebase/app"
import { getAnalytics } from "firebase/analytics"

// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: process.env.REACT_APP_FIREBASE_GOOGLE_ANALYTICS_API_KEY,
  authDomain: process.env.REACT_APP_FIREBASE_GOOGLE_ANALYTICS_AUTH_DOMAIN,
  projectId: process.env.REACT_APP_FIREBASE_GOOGLE_ANALYTICS_PROJECT_ID,
  storageBucket: process.env.REACT_APP_FIREBASE_GOOGLE_ANALYTICS_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_FIREBASE_GOOGLE_ANALYTICS_MESSAGE_SENDER_ID,
  appId: process.env.REACT_APP_FIREBASE_GOOGLE_ANALYTICS_APP_ID,
  measurementId: process.env.REACT_APP_FIREBASE_GOOGLE_ANALYTICS_MEASUREMENT_ID
}

const initFirebase = initializeApp(firebaseConfig)
const firebaseAnalytics = getAnalytics(initFirebase)

export { initFirebase, firebaseAnalytics }
