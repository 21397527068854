import { preparedMaintenanceHistories } from "../../helpers/maintenanceHistoryHelpers"

export const initialState = {
  manualMaintenanceHistories: [],
  manualMaintenanceHistory: {}
}

export default function (state = initialState, action) {
  const { payload, type } = action

  switch (type) {
    case "MAINTENANCE_HISTORIES_LOAD_SUCCEEDED":
      return {
        ...state,
        manualMaintenanceHistories: preparedMaintenanceHistories(payload.maintenanceHistories)
      }
    case "MAINTENANCE_HISTORIES_UNLOAD_DATA":
      return {
        ...state,
        manualMaintenanceHistories: []
      }
    case "MAINTENANCE_HISTORY_LOAD_SUCCEEDED":
      return {
        ...state,
        manualMaintenanceHistory: payload.maintenanceHistory
      }
    default:
      return state
  }
}
