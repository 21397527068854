import React from "react"
import { SCHEDULED_STATUSES, AWAITING_APPROVAL, APPROVAL } from "../../constants/statuses"
import { Segment, Grid, Container, Header, Image, Divider, Button, Label } from "semantic-ui-react"
import { navigationPaths } from "../../constants/paths"
import { Link } from "react-router-dom"
import * as vehicleHelpers from "../../helpers/vehicleHelpers"
import * as maintenanceHelpers from "../../helpers/maintenanceHelpers"
import VehicleModal from "../../components/vehicles/VehicleModal"
import DueStatusLabel from "../../components/shared/DueStatusLabel"
import { useTranslation } from "react-i18next"
import { isFleetAdvise, isFleetcor } from "../../helpers/affiliationHelpers"

export default function VehiclesWidget({ vehicles, pastDueVehicleIds, dueSoonVehicleIds }) {
  const { t } = useTranslation("dashboard")
  const onlyDueSoonVehicles = dueSoonVehicleIds.filter((item) => !pastDueVehicleIds.includes(item))

  return (
    <React.Fragment>
      <span />
      <Header size="small" className={"dash-hd"}>
        {t("common:vehiclesLabel")}
      </Header>
      <Segment>
        <Divider hidden />
        {vehicles.length > 0 ? (
          <React.Fragment>
            <Grid style={{ flexDirection: "column" }} divided="vertically">
              {vehicles.map((v) => (
                <Grid.Row className="slim-row">
                  <Grid.Column width={4} verticalAlign="middle">
                    <Link to={navigationPaths.vehicleShow(v.id)}>
                      <Image src={v.image_url_full || "images/no_car.svg"} />
                    </Link>
                  </Grid.Column>
                  <Grid.Column width={9}>
                    <DueStatusLabel
                      status={
                        pastDueVehicleIds.includes(v.id)
                          ? "pastDueLabel"
                          : onlyDueSoonVehicles.includes(v.id)
                          ? "dueSoonLabel"
                          : ""
                      }
                    />
                    <p>{vehicleHelpers.formattedName(v)}</p>
                    <p>
                      {isFleetcor() && v.license_plate_number && v.license_plate_state ? (
                        <>
                          {" "}
                          {t("plateLabel")}: {vehicleHelpers.formattedLicensePlateNumber(v)}{" "}
                        </>
                      ) : (
                        <></>
                      )}
                    </p>
                    <p>
                      {!isFleetcor() ? (
                        <>
                          {" "}
                          {t("otherIdLabel")}: {v.other_id || "-"}{" "}
                        </>
                      ) : (
                        <>
                          {" "}
                          {t("vehicleNameLabel")}: {v.name_of_vehicle || "-"}{" "}
                        </>
                      )}
                    </p>
                  </Grid.Column>
                </Grid.Row>
              ))}
            </Grid>
            <Divider hidden />
            <Link to={navigationPaths.vehicleIndex()}>
              <Container textAlign="center">
                <span className={`link ${isFleetAdvise() ? "dashboard-link-color" : ""}`}>
                  {t("viewAllVehiclesLabel")}
                </span>
              </Container>
            </Link>
          </React.Fragment>
        ) : (
          <Container textAlign="center">
            <Header>
              {isFleetcor()
                ? vehicles.length == 0
                  ? t("noVehiclesAddedLabel")
                  : t("enrolledVehiclesNotPresent")
                : t("noVehiclesAddedLabel")}
            </Header>
            <Divider hidden />
            {!isFleetcor() ? (
              <VehicleModal isDashboard={true}>
                <Button>{t("addAVehicleLabel")}</Button>
              </VehicleModal>
            ) : (
              ""
            )}
            <Divider hidden />
          </Container>
        )}
      </Segment>
    </React.Fragment>
  )
}
