export const WALK_IN = "walk_in"
export const ACTIVE = "active"
export const CANCELED = "cancel"
export const PAID = "paid"
export const PAID_AT_SHOP = "paid_in_shop"
export const INITIATED_BY_USER = "initiated"
export const IN_MANUAL_REVIEW = "paid_deferred"
export const AWAITING_APPROVAL = "awaiting_approval"
export const APPROVAL = "approved"
export const COMPLETED = "complete"
export const SCHEDULED_STATUSES = [INITIATED_BY_USER, ACTIVE]
export const ACTIVE_STATUSES = [
  COMPLETED,
  ACTIVE,
  INITIATED_BY_USER,
  APPROVAL,
  WALK_IN,
  AWAITING_APPROVAL
]
export const PAID_STATUSES = [IN_MANUAL_REVIEW, PAID_AT_SHOP, PAID]
export const INACTIVE_STATUSES = [IN_MANUAL_REVIEW, PAID_AT_SHOP, PAID, CANCELED]
export const DISPLAY_STATUSES = {
  [WALK_IN]: "walkInLabel",
  [ACTIVE]: "activeLabel",
  [CANCELED]: "cancelledLabel",
  [PAID]: "paidLabel",
  [PAID_AT_SHOP]: "paidAtShopLabel",
  [INITIATED_BY_USER]: "initiatedLabel",
  [IN_MANUAL_REVIEW]: "inManualReviewLabel",
  [AWAITING_APPROVAL]: "awaitingApprovalLabel",
  [APPROVAL]: "approvedLabel",
  [COMPLETED]: "CompleteLabel"
}

export const SERVICE_COMPLETED = "completed"
export const SERVICE_APPROVED = "approved"
export const SERVICE_AWAITING_APPROVAL = "awaiting_approval"
export const SERVICE_INITIATED = "initiated"
export const SERVICE_REJECTED = "rejected"
export const SERVICE_DELETED = "deleted"
export const APPROVAL_STATUS = "approval"
