import React, { Component } from "react"
import { Modal, Button } from "semantic-ui-react"
import { withTranslation } from "react-i18next"
import { connect } from "react-redux"
import {
  FMC_EDIT,
  FMC_USER,
  MAINTENANCE_INDEX,
  PAYMENT,
  IS_DEFAULT,
  SETTINGS_USER,
  VEHICLE_INDEX,
  DEFAULT
} from "../../constants/application"

class AlertModal extends Component {
  constructor(props) {
    super(props)
    this.state = {
      open: props.openAlertModal
    }
  }

  componentDidUpdate(prevProps) {
    if (prevProps.openAlertModal !== this.props.openAlertModal) {
      this.setState({ open: true })
    }
  }

  handleClose = () => {
    this.setState({ open: false })
    this.props.hideModal()
  }

  handleYesDeactivateButton = () => {
    const { afterDelete, hideModal, dispatch, that, idToDeactivate } = this.props
    hideModal()
    dispatch({
      type: "USER_DEACTIVATE_SAGA",
      payload: {
        userId: idToDeactivate
      },
      callback: afterDelete.bind(that)
    })
  }
  handleYesButton = () => {
    const {
      afterDelete,
      hideModal,
      idToDelete,
      dispatch,
      that,
      modelFrom,
      onDelete,
      tFleetEdit,
      afterRequest,
      t = {},
      paymentMethod
    } = this.props
    this.setState({ open: false })
    hideModal()
    if (modelFrom === VEHICLE_INDEX) {
      dispatch({
        type: "VEHICLE_DELETE_SAGA",
        payload: {
          vehicleId: idToDelete
        },
        callback: afterDelete.bind(that)
      })
    } else if (modelFrom === MAINTENANCE_INDEX) {
      onDelete(idToDelete)
    } else if (modelFrom === FMC_USER) {
      dispatch({
        type: "USER_DELETE_SAGA",
        payload: {
          userId: idToDelete
        },
        callback: afterDelete.bind(that)
      })
    } else if (modelFrom === FMC_EDIT) {
      that.setState({ isSubmitting: true })
      dispatch({
        type: "FLEET_DELETE_SAGA",
        payload: {
          fleetId: idToDelete,
          t: tFleetEdit
        },
        callback: afterDelete.bind(that)
      })
    } else if (modelFrom === SETTINGS_USER) {
      dispatch({
        type: "USER_DELETE_SAGA",
        payload: {
          userId: idToDelete
        },
        callback: afterDelete.bind(that)
      })
    } else if (modelFrom === PAYMENT) {
      dispatch({
        type: "PAYMENT_METHOD_DELETE_SAGA",
        payload: { id: idToDelete, t: t },
        callback: afterRequest.bind(that)
      })
    } else if (modelFrom === IS_DEFAULT) {
      dispatch({
        type: "PAYMENT_METHOD_SET_IS_DEFAULT_SAGA",
        payload: { id: idToDelete, t: t },
        callback: afterRequest.bind(that)
      })
    } else if (modelFrom === DEFAULT) {
      dispatch({
        type: "PAYMENT_METHOD_SET_DEFAULT_SAGA",
        payload: { id: paymentMethod.token, t: t, default: true, paymentMethod: paymentMethod },
        callback: afterRequest.bind(that)
      })
    }
  }

  render() {
    const { t, idToDeactivate, userStatus, isDefaultAlert, user, defaultAlert } = this.props
    return (
      <Modal
        size="tiny"
        className="alertModal"
        open={this.state.open}
        onClose={this.handleClose}
        closeIcon
      >
        <Modal.Content>
          {idToDeactivate
            ? userStatus == true
              ? t("deactivateAlertLabel", {
                  customer_name: user ? `, ${user.name} (${user.email})` : ""
                })
              : t("activateAlertLabel", {
                  customer_name: user ? `, ${user.name} (${user.email})` : ""
                })
            : isDefaultAlert
            ? t("isDefaultSubscriptionAlertLabel")
            : defaultAlert
            ? t("defaultSubscriptionAlertLabel")
            : t("deleteAlertLabel", {
                customer_name: user ? `, ${user.name} (${user.email})` : ""
              })}
        </Modal.Content>
        <Modal.Actions>
          <Button size="medium" onClick={this.handleClose}>
            {t("noLabel")}
          </Button>
          <Button
            size="medium"
            onClick={idToDeactivate ? this.handleYesDeactivateButton : this.handleYesButton}
          >
            {t("yesLabel")}
          </Button>
        </Modal.Actions>
      </Modal>
    )
  }
} // class EditForm

export default connect(null, null)(withTranslation("common")(AlertModal))
