import React, { Component } from "react"
import PropTypes from "prop-types"
import ReactSVG from "react-svg"
import { PAYMENT_METHODS } from "../../constants/application"
import { constants } from "caradvise_shared_components"
const { ACH_TYPE } = constants.paymentProviders

class PaymentMethodIcon extends Component {
  render() {
    const { paymentMethod } = this.props
    const logo =
      paymentMethod.type === PAYMENT_METHODS.CREDIT_CARD
        ? paymentMethod.card_type
        : PAYMENT_METHODS.PAYPAL
    return paymentMethod.type === ACH_TYPE ? (
      <ReactSVG src={`/images/ach_logo.svg`} style={{ marginRight: "15px" }} />
    ) : (
      <ReactSVG src={`/images/${logo.replace(/\s+/g, "")}.svg`} svgClassName={"payment-icon"} />
    )
  }
} // PaymentMethodIcon

PaymentMethodIcon.propTypes = {
  paymentMethod: PropTypes.object.isRequired
}

export default PaymentMethodIcon
