import braintree from "braintree-web"
import i18n from "../utilities/i18n"

export const setupACHInstance = async (clientToken, formData) => {
  try {
    const clientInstance = await createBraintreeClient(clientToken)
    return await setupACHPaymentMethod(clientInstance, formData)
  } catch (error) {
    console.error(error)
  }
}

const createBraintreeClient = (clientToken) => {
  return new Promise((resolve, reject) => {
    braintree.client.create({ authorization: clientToken }, (error, clientInstance) => {
      error ? reject(error) : resolve(clientInstance)
    })
  })
}

const setupACHPaymentMethod = async (clientInstance, formData) => {
  try {
    const usBankAccountInstance = await createUsBankAccount(clientInstance)
    const achBankDetails = getACHBankDetails(formData)
    return await tokenizeBankAccount(usBankAccountInstance, achBankDetails)
  } catch (error) {
    console.error(error)
  }
}

const createUsBankAccount = (clientInstance) => {
  return new Promise((resolve, reject) => {
    braintree.usBankAccount.create({ client: clientInstance }, (error, usBankAccountInstance) => {
      error ? reject(error) : resolve(usBankAccountInstance)
    })
  })
}

export const getACHBankDetails = (formData) => {
  const {
    accountNumber,
    routingNumber,
    accountType,
    ownershipType,
    streetAddress,
    extendedAddress,
    locality,
    region,
    postalCode,
    firstName,
    lastName,
    businessName
  } = formData

  let bankDetails = {
    accountNumber: accountNumber,
    routingNumber: routingNumber,
    accountType: accountType,
    ownershipType: ownershipType,
    billingAddress: {
      streetAddress: streetAddress,
      extendedAddress: extendedAddress,
      locality: locality,
      region: region,
      postalCode: postalCode
    }
  }

  if (bankDetails.ownershipType === "personal") {
    bankDetails.firstName = firstName
    bankDetails.lastName = lastName
  } else {
    bankDetails.businessName = businessName
  }
  return bankDetails
}

export const tokenizeBankAccount = async (usBankAccountInstance, bankDetails) => {
  try {
    const tokenizedPayload = await usBankAccountInstance.tokenize({
      bankDetails: bankDetails,
      mandateText: i18n.t("settings:achMetadataLabel")
    })

    return { nonce: tokenizedPayload.nonce }
  } catch (error) {
    return { error: error.details.originalError.details.originalError[0].message }
  }
}
