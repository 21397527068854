import React from "react"
import { Icon } from "semantic-ui-react"
import arrowRight from "../../../assets/images/arrow-blue.png"
import { Link } from "react-router-dom"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faWrench } from "@fortawesome/free-solid-svg-icons"
import { connect } from "react-redux"
import { withTranslation } from "react-i18next"
import { CURRENTLY_DUE } from "../../../helpers/maintenanceHelpers"
import { navigationPaths } from "../../../constants/paths"
import { isFleetAdvise } from "../../../helpers/affiliationHelpers"

class MaintenanceServiceRow extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      loading: false,
      serviceToBeMarkedAsCompleted: [],
      isChecked: false
    }
  }

  handleCheckChange(intervalId, flag, services_definitions) {
    this.props.updateIntervalMarkAsComplete(intervalId, flag, services_definitions)
  }

  componentDidUpdate() {
    document.getElementById(CURRENTLY_DUE).scrollIntoView({
      behavior: "smooth"
    })
  }

  render() {
    const {
      vehicle,
      oemSchedules,
      title,
      noRecordMsg,
      showMarkAsCompleted,
      serviceCountBackground,
      mileStatusBackground,
      milesLable,
      intervalDifference,
      t,
      showBookNowLabel,
      showMaintenanceServiceDetails
    } = this.props

    return (
      <section id={title} className="maintenanceScheduleSection">
        <span className="dueStatusTitle">{title}</span>
        <div>
          {oemSchedules &&
            oemSchedules.map((interval) => {
              return (
                <div id={interval.interval_id} className="maintenanceRow">
                  {" "}
                  <Link
                    to="/"
                    onClick={(e) => showMaintenanceServiceDetails(e, interval.interval_id)}
                    style={{ cursor: "pointer" }}
                    className="maintenanceRowLink"
                  >
                    {/* <a className="maintenanceRowLink"> */}
                    <div className="allServicesName" style={{ paddingTop: 13 }}>
                      {t("mileageLabel")}: {interval.interval.toLocaleString("en-US")}
                      {t("miLabel")}
                    </div>

                    <div className="allServicesArrow">
                      <img src={arrowRight} className="arrowRight" />
                    </div>
                    <div className="allServicesCountAndStatusContainer">
                      <div
                        className="servicesCount"
                        style={{ backgroundColor: serviceCountBackground }}
                      >
                        <FontAwesomeIcon icon={faWrench} />
                        <span style={{ padding: "4px" }}>{interval.services.length + "+"}</span>
                      </div>

                      <div
                        style={{
                          display: "inline",
                          margin: "10px",
                          background: mileStatusBackground,
                          borderRadius: "42px"
                        }}
                      >
                        <Icon name="clock outline" style={{ margin: "0px !important" }} />

                        <span>
                          {" "}
                          {intervalDifference
                            ? vehicle.predicted_current_miles - interval.interval < 0
                              ? t("calculatinglbl")
                              : (
                                  vehicle.predicted_current_miles - interval.interval
                                ).toLocaleString("en-US")
                            : interval.interval - vehicle.predicted_current_miles < 0
                            ? t("calculatinglbl")
                            : (interval.interval - vehicle.predicted_current_miles).toLocaleString(
                                "en-US"
                              )}
                          {t("miLabel")} {milesLable}{" "}
                        </span>
                      </div>

                      {showBookNowLabel && (
                        <span
                          className={`bookNowLabel ${isFleetAdvise() ? "bookNowLabel-color" : ""}`}
                        >
                          {t("bookNow")}
                        </span>
                      )}
                    </div>
                  </Link>
                  {showMarkAsCompleted && (
                    <div style={{ flexDirection: "row" }} className="markAllCompletedRadio">
                      <Icon.Group>
                        <Icon
                          name={interval.is_completed ? "check circle" : "circle outline"}
                          className={isFleetAdvise() ? "checkbox-color" : ""}
                          onClick={() => {
                            const services_definitions = interval.services.map((service) => {
                              return service.service_id
                            })

                            this.handleCheckChange(
                              interval.interval_id,
                              !interval.is_completed,
                              services_definitions
                            )
                          }}
                        />
                      </Icon.Group>
                      <span>
                        {interval.is_completed
                          ? t("intervalCompletedLabel")
                          : t("intervalMarkAllAsCompletedLabel")}
                      </span>
                    </div>
                  )}
                </div>
              )
            })}
          {oemSchedules && oemSchedules.length < 1 && (
            <div className=" maintenanceRowEmpty">
              <div className="maintenanceRowLink" style={{ cursor: "default" }}>
                <div
                  style={{
                    display: "inline",
                    margin: "10px",
                    borderRadius: "42px"
                  }}
                >
                  <Icon name="clock outline" style={{ margin: "0px !important" }} />
                  <span style={{ padding: "4px" }}>{noRecordMsg}</span>
                </div>
              </div>
            </div>
          )}
          {!oemSchedules && (
            <div className=" maintenanceRowEmpty">
              <div className="maintenanceRowLink" style={{ cursor: "default" }}>
                <div
                  style={{
                    display: "inline",
                    margin: "10px",
                    borderRadius: "42px"
                  }}
                >
                  <Icon name="clock outline" style={{ margin: "0px !important" }} />
                  <span style={{ padding: "4px" }}>{noRecordMsg}</span>
                </div>
              </div>
            </div>
          )}
        </div>
      </section>
    )
  }
}

export default connect()(withTranslation("maintenanceSchedule")(MaintenanceServiceRow))
