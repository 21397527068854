import React from "react"
import { connect } from "react-redux"
import { Grid, Image, Segment } from "semantic-ui-react"
import VehicleDetails from "./vehicleDetails"
import MaintenanceServiceRow from "./MaintenanceServiceRow"
import { CURRENTLY_DUE, PAST_DUE, UPCOMING } from "../../../helpers/maintenanceHelpers"
import { useTranslation, withTranslation } from "react-i18next"
import { DEFAULT_IMG_URL } from "../../../constants/application"
import { ProgressHUD } from "../../shared/ProgressHUD"
import MaintenanceScheduleHistoryDetails from "./MaintenanceScheduleHistoryDetails"
import MaintenanceDetails from "./MaintenanceDetails"
import CustomNotification from "../../shared/CustomNotification"
import PageTitle from "./components/PageTitle"
import { navigationPaths } from "../../../constants/paths"

const RenderMaintenanceServiceRow = ({
  vehicle,
  oemSchedules,
  title,
  updateIntervalMarkAsComplete,
  showBookNowLabel = false,
  showMaintenanceServiceDetails
}) => {
  const { t } = useTranslation("maintenanceSchedule")
  const iconBgColor = title && title.match(PAST_DUE) ? "#B91C1C" : "#282828"
  const mileBgColor = title && title.match(PAST_DUE) ? "#FEF2F2" : "#F4F7F5"
  const showMarkAsCompleted = title && title.match(PAST_DUE) ? true : false
  const milesLable = title && title.match(PAST_DUE) ? "past due" : "to go"
  const negavtiveIntervalDifference = title && title.match(PAST_DUE) ? true : false
  const noRecordMsg =
    title && title.match(PAST_DUE)
      ? t("noPastDues")
      : title.match(CURRENTLY_DUE)
      ? t("noCurrentDues")
      : t("noUpcomingDues")

  return (
    <MaintenanceServiceRow
      oemSchedules={oemSchedules}
      vehicle={vehicle}
      title={title}
      noRecordMsg={noRecordMsg}
      showMarkAsCompleted={showMarkAsCompleted}
      serviceCountBackground={iconBgColor}
      mileStatusBackground={mileBgColor}
      milesLable={milesLable}
      showBookNowLabel={showBookNowLabel}
      intervalDifference={negavtiveIntervalDifference}
      updateIntervalMarkAsComplete={(intervalId, flag, services_definitions) =>
        updateIntervalMarkAsComplete(intervalId, flag, services_definitions)
      }
      showMaintenanceServiceDetails={(e, intervalId) =>
        showMaintenanceServiceDetails(e, intervalId)
      }
    />
  )
}

class MaintenanceScheduleIndex extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      loading: false,
      showMaintenanceDetails: false,
      intervalId: null,
      showMaintenanceHistory: false,
      windowInnerWidth: window.innerWidth,
      windowOuterWidth: window.outerWidth,
      setUpActiveComponent: false
    }
  }

  handleResize = (innerWidth, outerWidth) =>
    this.setState({
      windowInnerWidth: innerWidth,
      windowOuterWidth: outerWidth
    })

  componentDidMount = () => {
    this.pullOemMaintenanceSchedules()
  }

  pullOemMaintenanceSchedules = () => {
    const { dispatch, vehicle } = this.props
    this.setState({ loading: true })
    dispatch({
      type: "PULL_OEM_MAINTENANCE_SCHEDULES",
      payload: {
        vehicle
      },
      callback: this.afterUpdateOemData
    })
  }

  afterUpdateOemData = (status) => {
    if (status == "success") {
      this.setState({ loading: false })
      switch (this.props.history.location.pathname) {
        case navigationPaths.vehicleShowMaintenanceScheduleDetails(
          this.props.match.params.id,
          this.props.match.params.interval
        ):
          this.setState({
            showMaintenanceDetails: true,
            intervalId: this.props.match.params.interval
          })
          break
      }
    }
  }

  showMaintenanceServiceDetails = (e, intervalId) => {
    e.preventDefault()
    const { vehicle } = this.props
    if (intervalId == null) {
      var pathname = navigationPaths.vehicleShowMaintenanceSchedule(vehicle.id)
      this.props.history.replace(pathname)
      this.setState({ showMaintenanceDetails: false, showMaintenanceHistory: false })
    } else {
      var pathname = navigationPaths.vehicleShowMaintenanceScheduleDetails(vehicle.id, intervalId)
      this.props.history.replace(pathname)
      this.setState({
        showMaintenanceDetails: !this.state.showMaintenanceDetails,
        showMaintenanceHistory: false,
        intervalId: intervalId
      })
    }
  }

  showMaintenanceHistoryScreen = (e) => {
    e.preventDefault()
    const { vehicle } = this.props
    var pathname = navigationPaths.vehicleShowMaintenanceScheduleHistory(vehicle.id)
    this.props.history.replace(pathname)
    this.setState({
      showMaintenanceHistory: !this.state.showMaintenanceHistory,
      showMaintenanceDetails: false,
      intervalId: null
    })
  }

  fetchAllUpcomingSchedules(oemSchedules) {
    const result = []
    const upcomingIndex = oemSchedules.findIndex((schedule) => {
      return schedule.tag && schedule.tag.match(UPCOMING)
    })
    if (upcomingIndex !== -1) {
      for (let i = upcomingIndex + 1; i < oemSchedules.length; i++) {
        result.push(oemSchedules[i])
      }
    }
    return result
  }

  updateIntervalMarkAsComplete = (intervalId, flag, services_definitions) => {
    const { currentUser, vehicle } = this.props
    this.setState({ loading: true })
    const data = {
      is_completed: flag,
      vehicle_id: vehicle.id,
      interval_id: intervalId,
      customer_id: currentUser.id,
      service_definition_ids: services_definitions
    }
    this.props.dispatch({
      type: "UPDATE_INTERVAL_MARK_AS_COMPLETED",
      payload: { data: data },
      callback: this.afterUpdateInterval
    })
  }

  afterUpdateInterval = (status, data) => {
    const { t } = this.props
    if (status == "success") {
      this.pullOemMaintenanceSchedules()
      CustomNotification(
        "success",
        t("notificationSuccessTitle"),
        t("maintenanceSchedule:successMarkAllCompletedMsg")
      )
    } else {
      CustomNotification("danger", t("notificationErrorTitle"), data)
    }
  }

  render() {
    const { vehicle, oemSchedules, t } = this.props
    const {
      loading,
      showMaintenanceDetails,
      intervalId,
      showMaintenanceHistory,
      windowInnerWidth,
      windowOuterWidth
    } = this.state
    const mm = vehicle && vehicle.make + " " + vehicle.model
    const vehicleYear = vehicle && vehicle.year
    const oemPastSchedules =
      oemSchedules &&
      oemSchedules.filter((schedule) => schedule.tag && schedule.tag.match(PAST_DUE))
    const oemCurrentlySchedules =
      oemSchedules &&
      oemSchedules.filter((schedule) => schedule.tag && schedule.tag.match(CURRENTLY_DUE))
    const oemUpcomingSchedules =
      oemSchedules &&
      oemSchedules
        .filter((schedule) => schedule.tag && schedule.tag.match(UPCOMING))
        .concat(this.fetchAllUpcomingSchedules(oemSchedules))

    return (
      <React.Fragment>
        <div className="maintenance-schedules-container">
          {loading && <ProgressHUD />}
          <Grid columns={2} stackable>
            <Grid.Row>
              <Grid.Column mobile={4} tablet={8} computer={8}>
                {showMaintenanceDetails ? (
                  <MaintenanceScheduleHistoryDetails
                    intervalId={intervalId}
                    showMaintenanceHistoryScreen={this.showMaintenanceHistoryScreen}
                    showMaintenanceServiceDetails={this.showMaintenanceServiceDetails}
                    showMaintenanceDetails={showMaintenanceDetails}
                    handleResize={this.handleResize}
                    {...this.props}
                  />
                ) : showMaintenanceHistory ? (
                  <MaintenanceDetails
                    id={intervalId}
                    showMaintenanceServiceDetails={this.showMaintenanceServiceDetails}
                    showMaintenanceHistory={showMaintenanceHistory}
                    handleResize={this.handleResize}
                  />
                ) : (
                  <>
                    <Grid.Row>
                      <PageTitle vehicle={vehicle} t={t} showButtons={true} />
                    </Grid.Row>
                    <VehicleDetails className="vehicle-details" {...this.props} />
                    <br />
                    <Segment
                      className="scrolling scrollableSegment"
                      style={{ marginTop: "10% !important" }}
                    >
                      <Grid.Row>
                        <RenderMaintenanceServiceRow
                          oemSchedules={oemPastSchedules}
                          vehicle={vehicle}
                          title={PAST_DUE}
                          updateIntervalMarkAsComplete={this.updateIntervalMarkAsComplete}
                          showMaintenanceServiceDetails={this.showMaintenanceServiceDetails}
                        />
                      </Grid.Row>
                      <Grid.Row>
                        <RenderMaintenanceServiceRow
                          oemSchedules={oemCurrentlySchedules}
                          vehicle={vehicle}
                          title={CURRENTLY_DUE}
                          updateIntervalMarkAsComplete={this.updateIntervalMarkAsComplete}
                          showBookNowLabel={true}
                          noRecordMsg={t("noCurrentDues")}
                          showMaintenanceServiceDetails={this.showMaintenanceServiceDetails}
                        />
                      </Grid.Row>
                      <Grid.Row>
                        <RenderMaintenanceServiceRow
                          oemSchedules={oemUpcomingSchedules}
                          vehicle={vehicle}
                          title={UPCOMING}
                          updateIntervalMarkAsComplete={this.updateIntervalMarkAsComplete}
                          noRecordMsg={t("noUpcomingDues")}
                          showMaintenanceServiceDetails={this.showMaintenanceServiceDetails}
                        />
                      </Grid.Row>
                    </Segment>
                  </>
                )}
              </Grid.Column>
              <Grid.Column
                mobile={8}
                tablet={8}
                computer={6}
                style={{
                  display:
                    (showMaintenanceDetails || showMaintenanceHistory) &&
                    (windowInnerWidth <= 550 || windowOuterWidth <= 540)
                      ? "none"
                      : ""
                }}
              >
                <div className="mr-schedule-vehicle-container">
                  <Image
                    size="large"
                    className={vehicle && vehicle.dashboard_url ? "hide-bg" : "default_image"}
                    src={vehicle && vehicle.dashboard_url ? vehicle.dashboard_url : DEFAULT_IMG_URL}
                  />
                </div>
              </Grid.Column>
            </Grid.Row>
          </Grid>
        </div>
      </React.Fragment>
    )
  }
}

function mapStateToProps(state, props) {
  let currentUser = state.application.userSessionData || state.users.currentUser
  let vehicle = state.vehicles.vehicle || {}
  return {
    vehicle: vehicle || {},
    oemSchedules: vehicle.oemServices,
    currentUser
  }
}

export default connect(mapStateToProps)(
  withTranslation("maintenanceSchedule")(MaintenanceScheduleIndex)
)
