import { constants } from "caradvise_shared_components"
import * as arrayHelpers from "../../helpers/arrayHelpers"
const { ACH_TYPE } = constants.paymentProviders

export const initialState = {
  paymentMethods: [],
  achPresent: false
}

export default function (state = initialState, action) {
  const { payload, type } = action

  switch (type) {
    case "PAYMENT_METHODS_LOAD_SUCCEEDED":
      return {
        ...state,
        paymentMethods: action.payload.paymentMethods,
        achPresent: action.payload.paymentMethods.some((payment) => payment.type === ACH_TYPE)
      }
    case "PAYMENT_METHOD_CREATE_SUCCEEDED":
      return {
        ...state,
        paymentMethods: [action.payload.paymentMethod].concat(state.paymentMethods)
      }
    case "PAYMENT_METHOD_UPDATE_SUCCEEDED":
      const paymentMethod = action.payload.paymentMethod

      return {
        ...state,
        paymentMethods: state.paymentMethods.map((pm) => {
          return pm.id === paymentMethod.id ? paymentMethod : pm
        })
      }
    case "DEFAULT_PAYMENT_METHOD_UPDATE_SUCCEEDED":
      let updatedPaymentMethods
      if (action.payload.is_default) {
        const defaultPayment = action.payload.paymentMethod
        updatedPaymentMethods = state.paymentMethods.map((pm) =>
          pm.id !== defaultPayment.id ? { ...pm, is_default: false } : { ...pm, is_default: true }
        )
      } else {
        updatedPaymentMethods = state.paymentMethods.map((payment) => {
          const response_object = action.payload.paymentMethod.find((obj) => obj.id === payment.id)
          if (response_object) {
            return response_object
          }
          return payment
        })
      }
      return {
        ...state,
        paymentMethods: updatedPaymentMethods
      }
    case "PAYMENT_METHOD_DELETE_SUCCEEDED":
      const id = action.payload.paymentMethodId
      return {
        ...state,
        paymentMethods: arrayHelpers.removeObjectById(state.paymentMethods, id)
      }
    case "BRAINTREE_CLIENT_TOKEN_LOAD_SUCCEEDED":
      return {
        ...state,
        clientToken: action.payload.clientToken
      }
    case "SET_PAYMENT_METHODS_LOADING":
      return {
        ...state,
        isLoading: true
      }
    case "UNSET_PAYMENT_METHODS_LOADING":
      return {
        ...state,
        isLoading: false
      }
    case "SET_WEX_PAYMENT_METHODS_LOADING":
      return {
        ...state,
        isWEXLoading: true
      }
    case "UNSET_WEX_PAYMENT_METHODS_LOADING":
      return {
        ...state,
        isWEXLoading: false
      }
    case "WEX_ACCOUNT_NUMBER_LOAD_SUCCEEDED":
      return {
        ...state,
        wexPaymentMethod: action.payload.paymentMethods
      }
    case "WEX_ACCOUNT_NUMBER_UPDATE_SUCCEEDED":
      return {
        ...state,
        wexPaymentMethod: action.payload.wexPaymentMethod
      }
    default:
      return state
  }
}
