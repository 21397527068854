import React, { Component } from "react"
import { Form, Input } from "semantic-ui-react"
import Select from "../../elements/Select"
import { sortByAttr } from "../../helpers/arrayHelpers"
import { selectOptions } from "../../helpers/serviceHelpers"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faTrashAlt } from "@fortawesome/pro-light-svg-icons"
import { withTranslation } from "react-i18next"
import HistoryAccordion from "../shared/HistoryAccordionSection"
import ReactSVG from "react-svg"
import { removeInitialZeros, roundUpTwoDecimals } from "../../helpers/maintenanceHistoryHelpers"
import { UNIT_PRICE } from "../../constants/maintenanceHistoryForm"

class AddServiceRow extends Component {
  availableServices() {
    const { services, serviceRows, serviceRow } = this.props

    const selected_service_ids = serviceRows.map((selected_service) => {
      if (selected_service.rowIndex !== serviceRow.rowIndex) {
        return selected_service.service_id
      }
    })

    return services.filter((service) => !selected_service_ids.includes(service.id))
  }

  positionOptions(positions) {
    let options = []
    positions.map((position) => options.push({ id: position, name: position }))
    return options
  }

  render() {
    let { serviceRow, serviceRows, t, maintenance_history_id } = this.props
    const { rowIndex } = serviceRow

    return (
      <div className="mmh-service-section">
        <div className="mmh-service-content">
          <div className="mmh-vehicle-info-fields">
            <Form.Field required style={{ width: "99%" }}>
              <label>
                {t("serviceLable")} {maintenance_history_id ? rowIndex : rowIndex + 1}
              </label>
              <Select
                onChange={(event) => this.props.onServiceChange(event.target.value, rowIndex)}
                options={selectOptions(sortByAttr(this.availableServices(), "name"))}
                placeholder={t("services:selectServicesLabel")}
                defaultValue={serviceRow.service_id}
                value={serviceRow.service_id}
                handleAddition={this.handleAddition}
                className={serviceRow.service_error ? "error-message" : ""}
                allowAdditions={true}
              />
            </Form.Field>
          </div>
          {serviceRow.service && serviceRow.service.positions.length > 0 && (
            <div className="mmh-vehicle-info-fields">
              <Form.Field style={{ width: "99%" }}>
                <label>{t("positionLable")}</label>
                <Select
                  onChange={(event) =>
                    this.props.handleCurrentChange("position", event.target.value, rowIndex)
                  }
                  options={selectOptions(this.positionOptions(serviceRow.service.positions))}
                  placeholder={t("services:selectPositionLabel")}
                  defaultValue={serviceRow.position}
                  value={serviceRow.position}
                />
              </Form.Field>
            </div>
          )}
          <div className="mmh-vehicle-info-fields">
            <Form.Field required style={{ width: "100%" }}>
              <label>{t("unitPriceLable")}</label>
              <Input
                onChange={(event) => {
                  if (roundUpTwoDecimals(event.target.value)) {
                    this.props.handleCurrentChange(UNIT_PRICE, event.target.value, rowIndex)
                  }
                }}
                placeholder="0.0"
                value={removeInitialZeros(serviceRow.unit_price)}
                type="number"
                icon="dollar"
                iconPosition="left"
                min="0"
                className={serviceRow.price_error ? "error-message" : ""}
              />
            </Form.Field>
          </div>
        </div>
        <div className="horizontal-divider" />
        <HistoryAccordion
          rowIndex={rowIndex}
          t={t}
          serviceRow={serviceRow}
          serviceRows={serviceRows}
          handleCurrentChange={this.props.handleCurrentChange}
        />

        {serviceRows.length > 1 && (
          <div className="mmh-remove-button" onClick={() => this.props.handleRemove(rowIndex)}>
            <ReactSVG src={`/images/delete_icon.svg`} />
            {t("removeServiceLabel")}
          </div>
        )}
      </div>
    )
  }
}

export default withTranslation("maintenanceHistory")(AddServiceRow)
