import React, { useEffect, useState } from "react"
import FullPageLoader from "./FullPageLoader"
import { useTranslation } from "react-i18next"

export function Loader2({ allowText = false, titleText }) {
  const { t } = useTranslation("magicLink")
  const dynamicTexts = [t("saveUptoLabel"), t("useOfMembershipCardLabel")]
  return (
    <div className="loaderContainer">
      <div className={allowText ? "loaderText" : "loaderText-image-only"}>
        <FullPageLoader />
        {allowText && (
          <div className="loader-info-text-container">
            <div className="loader-text-title">{titleText ? titleText : t("didYouKnowLabel")}</div>
            <div className="loader-text-subtext">
              <TextAnimation dynamicTexts={dynamicTexts} />
            </div>
          </div>
        )}
      </div>
    </div>
  )
}

const TextAnimation = ({ dynamicTexts }) => {
  const [currentIndex, setCurrentIndex] = useState(0)
  const [displayText, setDisplayText] = useState(dynamicTexts[0])

  useEffect(() => {
    if (Array.isArray(dynamicTexts) && dynamicTexts.length > 1) {
      const interval = setInterval(() => {
        setCurrentIndex((prevIndex) => {
          return (prevIndex + 1) % dynamicTexts.length
        })
      }, 2000)
      return () => clearInterval(interval)
    } else if (typeof dynamicTexts === "string") {
      setDisplayText(dynamicTexts)
    }
  }, [dynamicTexts])

  useEffect(() => {
    if (Array.isArray(dynamicTexts)) {
      setDisplayText(dynamicTexts[currentIndex])
    }
  }, [currentIndex, dynamicTexts])

  return <span className="subtext-item text-animation">{displayText}</span>
}
