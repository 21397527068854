import React, { Component } from "react"
import { connect } from "react-redux"
import { withTranslation } from "react-i18next"
import { isFleetAdvise } from "../../helpers/affiliationHelpers"

class SessionPrivacyPolicyPage extends Component {
  constructor(props) {
    super(props)
  }

  render() {
    const { t, configuration } = this.props
    const { terms, privacyPolicy } = configuration
    return (
      <React.Fragment>
        <div
          style={{ fontSize: "0.8em", position: "relative", top: "-3px" }}
          className={isFleetAdvise() ? "privacy-policy-link-color" : ""}
        >
          {t("common:readAcceptTermLabel")}
          <a
            style={{ textDecoration: "underline", color: "unset" }}
            href={terms.url}
            rel="noopener noreferrer"
            target="_blank"
          >
            {t(terms.label)}
          </a>
          {t("common:andLabel")}
          <a
            style={{ textDecoration: "underline", color: "unset" }}
            href={privacyPolicy.url}
            rel="noopener noreferrer"
            target="_blank"
          >
            {t(privacyPolicy.label)}
          </a>
        </div>
      </React.Fragment>
    )
  }
} // class SessionPrivacyPolicyPage

export default connect()(withTranslation("common")(SessionPrivacyPolicyPage))
