import React, { Component } from "react"
import PropTypes from "prop-types"
import PaymentMethodDetails from "./PaymentMethodDetails"
import PaymentMethodIcon from "./PaymentMethodIcon"
import { withTranslation } from "react-i18next"
import ReactSVG from "react-svg"
import { Image } from "semantic-ui-react"
import { APPROVED_LABEL, COMPLETED_LABEL } from "../../helpers/orderHelpers"
import { constants } from "caradvise_shared_components"
import CheckMarkCircle from "../../assets/images/checkmark-circle-green.png"
const { BRAINTREE, BRAINTREE_ACH, WEX, FLEETCOR } = constants.paymentProviders

class PaymentMethod extends Component {
  render() {
    const {
      pm,
      onSelectPayment,
      selectedPaymentMethod,
      providerType,
      selectedPaymentProvider
    } = this.props

    const getSVGUrl = (providerType) => {
      if (providerType === WEX) {
        return `/images/wex-logo.svg`
      } else if (providerType === FLEETCOR) {
        return `/images/fleetcor-logo.svg`
      }
    }

    const PaymentMethodSection = ({ selected, pm, providerType, onSelectPayment }) => {
      const handleClick = () => {
        if (providerType === WEX || providerType === FLEETCOR) {
          onSelectPayment(null, providerType)
        } else {
          onSelectPayment(pm, providerType)
        }
      }

      const { t, orderStatus, wex_account_number } = this.props

      const section_class_name = selected ? "selected-payment-section" : "payment-details-section"
      const show_hover =
        orderStatus == APPROVED_LABEL || orderStatus == COMPLETED_LABEL
          ? section_class_name
          : `${section_class_name} awaiting-aproval`
      return (
        <div className={show_hover} onClick={handleClick}>
          {orderStatus == APPROVED_LABEL || orderStatus == COMPLETED_LABEL ? (
            <Image src={CheckMarkCircle} className="completed-image" />
          ) : (
            <div>
              <ReactSVG
                src={selected ? `/images/selected_checkbox.svg` : `/images/checkbox_base.svg`}
              />
            </div>
          )}
          <div className="payment-method-label-section">
            {providerType === WEX && (
              <>
                <div className="payment-details-label fleetcor-wex-text">
                  {wex_account_number
                    ? `${t("payment:WexCardText")} (XXXX-${wex_account_number.substr(-4)})`
                    : t("payment:blankWexPaymentText")}
                </div>
                <ReactSVG src={getSVGUrl(providerType)} svgClassName={"payment-icon"} />
              </>
            )}
            {providerType === FLEETCOR && (
              <>
                <div className="payment-details-label  fleetcor-wex-text">
                  {" "}
                  {t("payment:fuelmanCardText")}
                </div>
                <ReactSVG src={getSVGUrl(providerType)} svgClassName={"payment-icon"} />
              </>
            )}
            {providerType === BRAINTREE && (
              <>
                <PaymentMethodDetails paymentMethod={pm} />
                <PaymentMethodIcon paymentMethod={pm} />
              </>
            )}
          </div>
        </div>
      )
    }

    return (
      <>
        <PaymentMethodSection
          selected={
            providerType == BRAINTREE || providerType == BRAINTREE_ACH
              ? selectedPaymentMethod && pm && selectedPaymentMethod.token === pm.token
              : providerType == selectedPaymentProvider
          }
          pm={pm}
          providerType={providerType}
          onSelectPayment={onSelectPayment}
        />
      </>
    )
  }
} // PaymentMethod

PaymentMethod.propTypes = {
  onDelete: PropTypes.func.isRequired,
  onSetDefault: PropTypes.func.isRequired,
  pm: PropTypes.object.isRequired,
  hasPaidMembership: PropTypes.bool
}

export default withTranslation("paymentMethod")(PaymentMethod)
