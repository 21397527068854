import * as numberHelpers from "./numberHelpers"
import moment from "moment"
import { Date_Format } from "../constants/years"
import { FLEET, CUSTOMER, NA } from "../constants/vehicles"
import { isFleetMaintenanceHubCanada, isFleetcor } from "./affiliationHelpers"

export function getDriverNames(users, vehicleId) {
  let driverNames = []

  users.forEach(function (driver) {
    const vehicleIds = (driver.vehicles || []).map((vehicle) => vehicle.id)

    if (vehicleIds.includes(vehicleId)) {
      driverNames.push(driver.name)
    }
  })

  return driverNames
}

export function getActiveOrderServicesTotal(orderServices) {
  const serviceCosts = orderServices.map((orderService) => {
    if (orderService.total && orderService.status !== "rejected") {
      return parseFloat(orderService.total)
    } else {
      return 0.0
    }
  })

  return serviceCosts.reduce(function (a, b) {
    return a + b
  }, 0)
}

export function formattedCostPerMile(vehicle) {
  const { cost_per_mile } = vehicle

  if (cost_per_mile) {
    return parseFloat(cost_per_mile).toFixed(2)
  } else {
    return "-"
  }
}

export function formattedLastMaintenanceDate(vehicle, language) {
  if (vehicle == null) return NA

  const { last_maintenance_date, last_maintenance_date_pretty } = vehicle

  if (last_maintenance_date_pretty) {
    return isFleetMaintenanceHubCanada()
      ? moment.utc(last_maintenance_date_pretty).format(Date_Format.YYYY_MM_DD)
      : last_maintenance_date_pretty
  } else if (last_maintenance_date) {
    return isFleetMaintenanceHubCanada()
      ? moment.utc(last_maintenance_date).format(Date_Format.YYYY_MM_DD)
      : moment.utc(last_maintenance_date).format(Date_Format.M_D_YY)
  } else {
    return NA
  }
}

export function formattedDriverNames(names, language = {}) {
  // const names = getDriverNames(users, vehicle.id)
  if (!Array.isArray(names)) {
    const namesArr = []
    namesArr.push(names)
    names = namesArr
  }

  if (names && names.length > 0) {
    return names.join(", ")
  } else {
    return language == "fr" ? "NON ATTRIBUÉ" : "UnAssigned"
  }
}

export function formattedMiles(vehicle) {
  const { miles, kilometers } = vehicle

  return kilometers && isFleetMaintenanceHubCanada()
    ? numberHelpers.formatWithCommas(kilometers)
    : miles
    ? numberHelpers.formatWithCommas(miles)
    : "-"
}

export function formattedLastMaintenanceMiles(vehicle) {
  const { last_maintenance_miles } = vehicle

  if (last_maintenance_miles) {
    return numberHelpers.formatWithCommas(last_maintenance_miles)
  } else {
    return "-"
  }
}

// TODO: (MPH) Handle timezones as expected.
export function formattedLicensePlateExpiration(vehicle, language) {
  const { license_plate_expiration } = vehicle

  if (license_plate_expiration) {
    return isFleetMaintenanceHubCanada()
      ? moment(license_plate_expiration).format(Date_Format.YYYY_MM_DD)
      : moment(license_plate_expiration).format(Date_Format.MM_DD_YYYY)
  } else {
    return "-"
  }
}

export function formattedLicensePlateNumber(vehicle, withState = false) {
  const { license_plate_number, license_plate_state } = vehicle

  let formattingArray = [license_plate_number]

  if (withState) {
    formattingArray.unshift(license_plate_state)
  }

  const formattedString = formattingArray.join(" ").trim()

  if (formattedString.length > 0) {
    return formattedString
  } else {
    return "-"
  }
}

export function formattedName(vehicle, options = {}) {
  if (!vehicle) return ""

  const {
    year,
    make,
    model,
    vin,
    license_plate_state,
    license_plate_number,
    other_id,
    name_of_vehicle
  } = vehicle
  let name = year && make && model ? `${year} ${make} ${model}` : vin

  if (options.displayLicensePlateNumber && license_plate_number) {
    if (license_plate_state) {
      name += ` [${license_plate_state}: ${license_plate_number}]`
    } else {
      name += ` [${license_plate_number}]`
    }
  }

  if (isFleetcor()) {
    if (name_of_vehicle) name += ` (${name_of_vehicle})`
  } else if (other_id) {
    name += ` (${other_id})`
  }

  return name
}

export function formattedNameWithId(vehicle, options = {}) {
  if (!vehicle) return ""

  const { year, make, model, vin, license_plate_state, license_plate_number, id } = vehicle
  let name = year && make && model ? `${year} ${make} ${model}` : vin

  if (options.displayLicensePlateNumber && license_plate_number) {
    if (license_plate_state) {
      name += ` [${license_plate_state}: ${license_plate_number}]`
    } else {
      name += ` [${license_plate_number}]`
    }
  }

  if (id) {
    name += ` ${id}`
  }

  return name
}

export function formattedNames(vehicles, options = {}) {
  let names = vehicles.map((vehicle, index) => formattedName(vehicle, options))

  return names.join(", ")
}

export function displayText(vehicle) {
  const { vin, other_id, department_number } = vehicle
  let name = ""
  if (other_id) {
    name += "(" + other_id + ")"
  }
  if (vin) {
    name += "(" + vin + ")"
  }
  if (department_number) {
    name += "(" + department_number + ")"
  }
  return name
}

export function selectOptions(vehicles, t, options = {}) {
  let vehicleOptions = []

  if (options.semantic)
    vehicles.forEach((vehicle) => {
      vehicleOptions.push({
        value: vehicle.id,
        text: t(vehicle.label) || formattedName(vehicle, options),
        key: `vehicle-${vehicle.id}`
      })
    })
  else
    vehicles.forEach((vehicle) => {
      if (vehicle.is_active && vehicle.fleetcor_enrolled) {
        vehicleOptions.push({
          text: formattedName(vehicle, options),
          value: vehicle.id,
          key: `vehicle-${vehicle.id}`
        })
      }
    })

  return vehicleOptions
}

export function selectBasicOptions(vehicles) {
  let vehicleOptions = []
  vehicles.forEach((vehicle) => {
    vehicleOptions.push({
      value: vehicle.id,
      text: displayText(vehicle),
      key: `vehicle-${vehicle.id}`
    })
  })
  return vehicleOptions
}

export function GaragingAddressOptions(addresses, vehicle) {
  let garaging_addresses = []

  addresses.forEach((address) => {
    let garaging_address_object = {}
    let isNullish = false
    garaging_address_object = {
      address: address["address"],
      state: address["state"],
      city: address["city"],
      zip: address["zip"]
    }

    isNullish = Object.values(garaging_address_object).every((value) => {
      if (value === null) {
        return true
      }
      if (value === "") {
        return true
      }
      return false
    })

    if (address && !isNullish) {
      garaging_addresses.push({
        value: address.id,
        text: `${address.address && address.address != null ? address.address + "," : ""} ${
          address.city && address.city != null ? address.city + "," : ""
        } ${address.state && address.state != null ? address.state + "," : ""} ${
          address.zip && address.zip != null ? address.zip : ""
        } (${
          address.addressable_type == CUSTOMER
            ? vehicle.owner_roles && vehicle.owner_roles.join(",")
            : FLEET
        }) `,
        key: address.id
      })
    }
  })
  return garaging_addresses
}

export const getVehicleClassfiedData = (vehicles) => {
  const selectedVehicles =
    vehicles && vehicles.length > 0 && vehicles.filter((vehicle) => vehicle.checked)

  const activeVehicle =
    selectedVehicles && selectedVehicles.length > 0
      ? selectedVehicles.filter((vehicle) => vehicle.is_active)
      : []

  const deactiveVehicle =
    selectedVehicles && selectedVehicles.length > 0
      ? selectedVehicles.filter((vehicle) => !vehicle.is_active)
      : []

  return { selectedVehicles, activeVehicle, deactiveVehicle }
}
