import React, { Component } from "react"
import PropTypes from "prop-types"
import { withTranslation } from "react-i18next"
import { constants } from "caradvise_shared_components"
import { PAYPAL } from "../../constants/paymentActivity"
const { ACH_TYPE, CREDIT_CARD_TYPE, PAY_PAL_ACCOUNT } = constants.paymentProviders
class PaymentMethodDetails extends Component {
  renderTypeSpecificDetails() {
    const { paymentMethod } = this.props
    const { t } = this.props

    if (paymentMethod.type === CREDIT_CARD_TYPE || paymentMethod.type === ACH_TYPE) {
      const { expiration_date, last_4 } = paymentMethod

      return (
        <React.Fragment>
          {paymentMethod.cardholder_name && (
            <>
              <strong>{paymentMethod.cardholder_name}</strong>
              <br />
            </>
          )}
          {t("payment:endingInLbl")} <strong>xxxx-{last_4}</strong>
          <br />
          {paymentMethod.type != ACH_TYPE && (
            <>
              {" "}
              {t("expLbl")} <strong>{expiration_date}</strong>
            </>
          )}
        </React.Fragment>
      )
    } else if (paymentMethod.type === PAYPAL || paymentMethod.type === PAY_PAL_ACCOUNT) {
      const { email } = paymentMethod
      return (
        <div className="paypal-info">
          <strong>{email}</strong>
          <br />
          {t("paypalLbl")}
        </div>
      )
    }
  }

  render() {
    return <div className="payment-details-label">{this.renderTypeSpecificDetails()}</div>
  }
} // PaymentMethodDetails

PaymentMethodDetails.propTypes = {
  paymentMethod: PropTypes.object.isRequired
}

export default withTranslation("payment")(PaymentMethodDetails)
