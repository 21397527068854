import React, { Component } from "react"
import PropTypes from "prop-types"
import { connect } from "react-redux"
import { StateNamesAndAbbreviations, ProvinceNamesAndAbbreviations } from "../../constants/states"
import EditModal from "../shared/EditModal"
import { withTranslation } from "react-i18next"
import { isFleetMaintenanceHubCanada, isFleetcor } from "../../helpers/affiliationHelpers"
import { GaragingAddressOptions } from "../../helpers/vehicleHelpers"
import { EDIT, VEHICLE_EDIT_OBJECT_NAME, YEARS } from "../../constants/application"

class VehicleModal extends Component {
  vinField = () => [
    {
      placeholder: this.props.t("vinLabel"),
      optional: false,
      fieldName: "vin",
      onEnterCreateCall: true
    }
  ]

  licensePlateFields = () => {
    const { t } = this.props
    const licenseFields = [
      {
        placeholder: t("licensePlateNumberLabel"),
        optional: true,
        fieldName: "license_plate_number"
      },
      {
        placeholder: isFleetMaintenanceHubCanada()
          ? t("licensePlateProvinceLabel")
          : t("licensePlateStateLabel"),
        optional: true,
        fieldName: "license_plate_state",
        valueSet: "states"
      }
    ]

    const expirationFields = [
      {
        placeholder: t("licensePlateExpirationLabel"),
        optional: true,
        fieldName: "license_plate_expiration",
        date: true
      }
    ]

    return isFleetMaintenanceHubCanada()
      ? [...licenseFields]
      : [...licenseFields, ...expirationFields]
  }

  milesField = () => [
    {
      placeholder: isFleetMaintenanceHubCanada()
        ? this.props.t("dashboard:kilometersLabel")
        : "Miles",
      optional: true,
      fieldName: "miles"
    }
  ]

  otherIdField = () => [
    { placeholder: this.props.t("otherIDLabel"), optional: true, fieldName: "other_id" }
  ]

  department_number = () => [
    {
      placeholder: this.props.t("departmentNumber"),
      optional: true,
      fieldName: "department_number"
    }
  ]

  vehicle_name = () => [
    {
      placeholder: this.props.t("vehicleNameLabel"),
      optional: true,
      fieldName: "vehicle_name"
    }
  ]

  garaging_address = () => [
    {
      placeholder: this.props.t("common:garagingAddressLabel"),
      optional: true,
      fieldName: "address_id",
      valueSet: "garaging_address_options"
    }
  ]

  ymmFields = () => {
    const { t } = this.props

    return [
      {
        placeholder: t("yearLabel"),
        optional: false,
        fieldName: "year",
        valueSet: YEARS,
        callback: this.callYearsSaga
      },
      {
        placeholder: t("makeLabel"),
        optional: false,
        fieldName: "motorMakeID",
        valueSet: "makes",
        dependent: ["year"],
        callback: this.callMakesSaga
      },
      {
        placeholder: t("modelLabel"),
        optional: false,
        fieldName: "motorModelID",
        valueSet: "models",
        dependent: ["year", "motorMakeID"],
        callback: this.callModelsSaga
      },
      {
        placeholder: t("subModelLabel"),
        optional: false,
        fieldName: "motor_sub_model_id",
        valueSet: "subModels",
        dependent: ["year", "motorMakeID", "motorModelID"],
        callback: this.callSubModelsSaga,
        filterOn: { motorModelID: "model_id" }
      },
      {
        placeholder: t("engineLabel"),
        optional: false,
        fieldName: "motorEngineID",
        valueSet: "engines",
        dependent: ["year", "motorMakeID", "motorModelID", "motor_sub_model_id"],
        callback: this.callEnginesSaga
      }
    ]
  }

  editFields = () => ({
    1: [
      ...this.vinField(),
      ...this.milesField(),
      ...this.otherIdField(),
      ...this.department_number(),
      ...this.licensePlateFields(),
      ...this.garaging_address()
    ]
  })

  fleetcoreditFields = () => ({
    1: [
      ...this.vinField(),
      ...this.milesField(),
      ...this.department_number(),
      ...this.vehicle_name(),
      ...this.licensePlateFields(),
      ...this.garaging_address()
    ]
  })

  yearMakeModelEditFields = () => ({
    1: [...this.vinField(), ...this.ymmFields()]
  })

  activateVehicleFields = () => ({
    1: [...this.vinField(), ...this.licensePlateFields()]
  })

  allFields = () => {
    let vin = this.vinField()
    vin[0].hint = this.props.t("searchVinValidationLabel")

    return {
      1: [...vin, ...this.ymmFields(), ...this.garaging_address()],
      2: [
        ...this.milesField(),
        ...this.otherIdField(),
        ...this.department_number(),
        ...this.licensePlateFields()
      ]
    }
  }

  static propTypes = {
    vehicle: PropTypes.object.isRequired,
    completionCallback: PropTypes.func.isRequired
  }

  static defaultProps = {
    vehicle: {},
    completionCallback: () => {}
  }

  callYearsSaga = async () => {
    await this.props.dispatch({
      type: "YEARS_LOAD_SAGA",
      payload: {}
    })
  }

  callMakesSaga = async (year) => {
    if (!year) return
    await this.props.dispatch({
      type: "MAKES_LOAD_SAGA",
      payload: {
        yearId: year
      }
    })
  }

  callModelsSaga = async (year, make) => {
    if (!year || !make) return
    await this.props.dispatch({
      type: "MODELS_LOAD_SAGA",
      payload: {
        yearId: year,
        makeId: make
      }
    })
  }

  callSubModelsSaga = async (year, make, model) => {
    if (!year || !make || !model) return
    await this.props.dispatch({
      type: "SUB_MODELS_LOAD_SAGA",
      payload: {
        yearId: year,
        makeId: make,
        modelId: model
      }
    })
  }

  callEnginesSaga = async (year, make, model, subModel) => {
    if (!year || !make || !model || !subModel) return
    await this.props.dispatch({
      type: "ENGINES_LOAD_SAGA",
      payload: {
        yearId: year,
        makeId: make,
        modelId: model,
        subModelId: subModel
      }
    })
  }

  getNewFormState = (vehicle) => {
    return {
      id: vehicle.id,
      license_plate_number: vehicle.license_plate_number,
      license_plate_state: vehicle.license_plate_state,
      license_plate_expiration: vehicle.license_plate_expiration,
      motorMakeID: vehicle.motorMakeID,
      motorModelID: vehicle.motorModelID,
      motorEngineID: vehicle.motorEngineID,
      other_id: vehicle.other_id,
      department_number: vehicle.department_number,
      vehicle_name: vehicle.name_of_vehicle,
      motor_sub_model_id: vehicle.motor_sub_model_id,
      year: vehicle.year,
      vin: vehicle.vin,
      miles: vehicle.miles,
      kilometers: vehicle.kilometers,
      odometer: isFleetMaintenanceHubCanada() ? vehicle.kilometers : vehicle.miles,
      odometer_units: isFleetMaintenanceHubCanada() ? "kilometers" : "miles",
      address_id: vehicle.address_id
    }
  }

  loadVehiclesStateSummary = () => {
    this.props.dispatch({
      type: "LOAD_VEHICLE_STATE_SUMMARY"
    })
  }

  render() {
    const { vehicle, editYmmOnly, editActiveVehicleFields, currentUser, isDashboard } = this.props
    return (
      <EditModal
        objectName={VEHICLE_EDIT_OBJECT_NAME}
        object={vehicle}
        getNewFormState={this.getNewFormState}
        allFields={this.allFields()}
        editFields={
          editYmmOnly
            ? this.yearMakeModelEditFields()
            : editActiveVehicleFields
            ? this.activateVehicleFields()
            : isFleetcor()
            ? this.fleetcoreditFields()
            : this.editFields()
        }
        saga={"VEHICLE_CREATE_OR_UPDATE_SAGA"}
        flag={EDIT}
        currentUser={currentUser}
        editSaga={"GET_VEHICLE_DATA"}
        isDashboard={isDashboard}
        loadVehiclesStateSummary={this.loadVehiclesStateSummary}
        {...this.props}
      ></EditModal>
    )
  }
} // class VehicleModal

const mapStateToProps = (state, ownProps) => {
  const { years = [], makes = [], models = [], subModels = [], engines = [] } = state.vehicles
  let { garaging_address } = (ownProps && ownProps.vehicle) || []
  if (garaging_address == null) {
    garaging_address = []
    if (state.application.fleetSessionData && state.application.fleetSessionData.address)
      garaging_address.push(state.application.fleetSessionData.address)
  }
  let reducedModels = models.reduce(
    (x, y) => (x.find((v) => v.model_id === y.model_id) ? x : [...x, y]),
    []
  )

  return {
    years:
      years
        .map((y) => parseInt(y))
        .sort()
        .reverse()
        .map((year) => ({ key: year, value: year, text: year })) || [],
    makes: makes.map((make) => ({ key: make.make_id, value: make.make_id, text: make.make })) || [],
    models:
      reducedModels.map((model) => ({
        key: model.model_id,
        value: model.model_id,
        text: model.model
      })) || [],
    // Yes this below is real. models come as an array of model/submodel combos for some reason.
    subModels:
      models.map((subModel) => ({
        model_id: subModel.model_id,
        key: subModel.sub_model_id,
        value: subModel.sub_model_id,
        text: subModel.sub_model
      })) || [],
    engines: engines.map((engine) => ({
      key: engine.engine_id,
      value: engine.engine_id,
      text: engine.description
    })),
    states: (isFleetMaintenanceHubCanada()
      ? ProvinceNamesAndAbbreviations
      : StateNamesAndAbbreviations
    ).map((s) => ({
      key: s.abbreviation,
      value: s.abbreviation,
      text: s.name
    })),
    garaging_address_options:
      garaging_address &&
      garaging_address.length > 0 &&
      GaragingAddressOptions(garaging_address, ownProps.vehicle),
    currentUser: state.users.currentUser
  }
}

export default connect(mapStateToProps)(withTranslation("vehicle")(VehicleModal))
