import React, { Component } from "react"
import { connect } from "react-redux"
import Logo from "../../../assets/images/CarAdvise-Logo.svg"
import RenderOptions from "./RenderOptions"
import { withTranslation } from "react-i18next"
import EmailLogo from "../../../assets/images/icon_email.svg"
import PhoneLogo from "../../../assets/images/icon_phone.svg"
import { EMAIL_REGEX } from "../../../utilities/stringHelpers"
import { KEYWORDS, OPTIONS } from "../../../constants/common"
import { routerPaths } from "../../../constants/paths"
import { Redirect } from "react-router"
import SuccessRedirectComponent from "./SuccessRedirectComponent"
import ErrorRedirectComponent from "./ErrorRedirectComponent"
import ReactSVG from "react-svg"
import MagicIcon from "../../../assets/images/magic_icon.svg"
import { Button } from "semantic-ui-react"
import { Link } from "react-router-dom"
import FailureLoginViaMagicLink from "./FailureLoginViaMagicLink"
import { Loader2 } from "../Loader2"
import NoSessionLeftPanel from "../../../layouts/NoSessionLeftPanel"
import { SUCCESS } from "../../../constants/notification"
import { FMC, FMC_FLEET_ADMIN, FMC_FLEET_MANAGER } from "../../../constants/roles"
import { navigationPaths } from "../../../constants/paths"
import CustomNotification from "../CustomNotification"
import { getSubdomain } from "../../../components/shared/StylesheetInjector"
import { OFF, setupSplitFlags } from "../../config/SplitClient"
import { FLEET } from "../../../constants/application"
import { isFMCUser } from "../../../helpers/userRoleHelpers"
import { isFmcPath } from "../../../helpers/pathHelpers"
import { logFirebaseAnalyticsScreenName } from "../../../helpers/googleAnalyticsHelpers"
import { getRouteNameFromUrl } from "../../../helpers/segmentHelpers"

class MagicLinkIndex extends Component {
  constructor(props) {
    super(props)
    this.state = {
      fields: {
        email: {
          name: OPTIONS.EMAIL,
          value: "",
          invalid: false,
          validators: ["_isPresent", "_isEmailValid"]
        },
        cellPhone: {
          name: OPTIONS.TEXT,
          value: "",
          invalid: false,
          formattedValue: "",
          validators: ["_isPresent", "_isValidCellPhone"]
        }
      },
      activeOption: OPTIONS.TEXT,
      successRedirect: false,
      errorRedirect: false,
      redirectType: null,
      redirectionType: null,
      redirectionValue: null,
      redirectError: null,
      isSubmitting: false,
      isLoading: false
    }
  }

  async componentDidMount() {
    logFirebaseAnalyticsScreenName(getRouteNameFromUrl())
    this.setState({ isLoading: true })
    await setupSplitFlags.bind(this)({ sub_domain: getSubdomain(), app_name: getSubdomain() })
    const { t, dispatch, isLoggedIn, currentUser } = this.props
    if (this.props.location && this.props.location.state) var key = this.props.location.state.key
    if (key && !isLoggedIn) {
      await dispatch({
        type: "SIGN_OUT_SAGA",
        payload: { currentUser: currentUser },
        callback: this.onSignOutSuccess(key, t),
        t: t
      })
    } else {
      this.setState({ isLoading: false })
    }
  }

  onSignOutSuccess = async (key, t) => {
    var formData = { key: key }
    await this.props.dispatch({
      type: "SIGN_IN_SAGA",
      payload: { formData, error_type: "magic_link", subdomain: getSubdomain() },
      callback: this.afterFormSubmit.bind(this, t)
    })
  }

  async afterFormSubmit(t, status, data) {
    if (status == SUCCESS) {
      await this.props.dispatch({
        type: "CURRENT_USER_LOAD_SAGA",
        callback: this.onFormSubmitSuccess.bind(this)
      })
    } else {
      this.setState({
        redirectType: KEYWORDS.FAILURE,
        redirectError: data.alertMessage || t("somethingWentWrongLabel")
      })
      this.setState({ isLoading: false })
    }
  }

  async onFormSubmitSuccess(status, data) {
    await this.props.dispatch({
      type: "FLEET_LOAD_SAGA",
      payload: { fleetId: this.props.currentUser.fleet_id }
    })
    if ([FMC, FMC_FLEET_ADMIN, FMC_FLEET_MANAGER].some((role) => data.roles.includes(role))) {
      if (this.props.isFleetMode) {
        await this.props.dispatch({
          type: "FMC_FLIP_SAGA",
          callback: this.props.history.push({ pathname: navigationPaths.fmcDashboard() })
        })
      }
    } else {
      if (!this.props.isFleetMode) {
        await this.props.dispatch({
          type: "FMC_FLIP_SAGA",
          callback: this.props.history.push({ pathname: navigationPaths.dashboard() })
        })
      }
    }
    this.setState({ isSubmitting: false })
    this.setState({ isLoading: false })
  }

  handleOptionChange = (option) => {
    const { activeOption } = this.state
    if (activeOption !== option) {
      this.setState({ activeOption: option }, () => {
        this._resetFields()
      })
    }
  }

  _onNumberChange = (value) => {
    const formattedValue = value
      .replace(/[^\d]/g, "")
      .slice(0, 10)
      .replace(/(\d{0,3})(\d{0,3})(\d{0,4})/, (_, p1, p2, p3) => {
        let formatted = ""
        if (p1) formatted += `${p1}`
        if (p2) formatted += `-${p2}`
        if (p3) formatted += `-${p3}`
        return formatted
      })

    const rawNumericValue = formattedValue.replace(/[^\d]/g, "")

    const updatedCellPhone = {
      ...this.state.fields.cellPhone,
      formattedValue: formattedValue,
      value: rawNumericValue,
      invalid: !this._isValidCellPhone(rawNumericValue)
    }

    this.setState((prevState) => ({
      fields: {
        ...prevState.fields,
        cellPhone: updatedCellPhone
      }
    }))
  }

  _onFieldChange(key, value) {
    const updatedFields = {
      ...this.state.fields,
      [key]: {
        ...this.state.fields[key],
        value: value,
        invalid: !this._isEmailValid(value)
      }
    }
    this.setState({ fields: updatedFields })
  }

  textMagicLinkForm = (isError) => {
    const { t } = this.props
    const { fields } = this.state
    const displayValue = `+1 ${fields.cellPhone.formattedValue}`
    return (
      <div className="option-content">
        {isError && this.magicLinkButtons(this._onSubmit)}
        {!isError && <span className="option-helper-text">{t("textHelperLabel")}</span>}
        <div className="input-container">
          <span className="plus-one">+1</span>
          <input
            className="inputFld"
            placeholder={t("textPlaceholderLabel")}
            value={fields.cellPhone.formattedValue}
            onChange={(value) => this._onNumberChange(value.target.value)}
          />
        </div>
      </div>
    )
  }

  emailMagicLinkForm = (isError) => {
    const { t } = this.props
    const { fields } = this.state
    return (
      <div className="option-content">
        {isError && this.magicLinkButtons(this._onSubmit)}
        {!isError && <span className="option-helper-text">{t("emailHelperText")}</span>}
        <div className="input-container">
          <input
            className="inputFld"
            value={fields.email.value}
            placeholder={t("emailPlaceholderLabel")}
            onChange={(e) => this._onFieldChange(OPTIONS.EMAIL, e.target.value)}
          />
        </div>
      </div>
    )
  }

  defaultOptions() {
    const { t } = this.props
    return [
      {
        name: OPTIONS.TEXT,
        label: t("textMagicLinkLabel"),
        icon: PhoneLogo,
        component: () => this.textMagicLinkForm()
      },
      {
        name: OPTIONS.EMAIL,
        label: t("emailMagicLinkLabel"),
        icon: EmailLogo,
        component: () => this.emailMagicLinkForm()
      }
    ]
  }

  magicLinkButtons = (
    onSubmit = this._onSubmit,
    opts = { showMagicLink: true, showSignInButton: true }
  ) => {
    const { t } = this.props
    const { isSubmitting } = this.state
    return (
      <div className="magic-link-buttons">
        {opts && opts.showMagicLink && (
          <Button className="magic-link-button" onClick={() => onSubmit()}>
            {!isSubmitting && <ReactSVG src={MagicIcon} className="magic-link-button-icon" />}
            {t("sendMagicLinkLabel")}
          </Button>
        )}
        {opts && opts.showSignInButton && (
          <Link
            className="white-link-button"
            to={{
              pathname: routerPaths.signIn
            }}
          >
            {t("goToSignInLabel")}
          </Link>
        )}
      </div>
    )
  }

  _onSubmit = () => {
    this._validateFields(async () => {
      this.setState({ isSubmitting: true })
      const { fields, activeOption } = this.state

      const areFieldsValid = Object.values(fields).every((field) => !field.invalid)

      if (areFieldsValid) {
        const data = {}

        Object.keys(fields).forEach((key) => {
          if (!fields[key].invalid && fields[key].value.trim() !== "") {
            // data[key] = fields[key].value
            data.value = fields[key].value.toLowerCase()
          }
          if (activeOption === OPTIONS.EMAIL) {
            data.type = OPTIONS.EMAIL
          } else {
            data.type = OPTIONS.PHONE
          }
        })
        if (data.value && data.value.length > 0) {
          this.props.dispatch({
            type: "SEND_MAGIC_LINK",
            payload: { data: data },
            callback: this.afterSendMagicLink.bind(this, data)
          })
        }
      }
    })
  }

  afterSendMagicLink = (data, status, response) => {
    if (
      response &&
      !Array.isArray(response) &&
      !response.hasOwnProperty("error") &&
      !response.hasOwnProperty("errors")
    ) {
      this.setState({
        redirectType: KEYWORDS.SUCCESS,
        redirectionType: data.type,
        redirectionValue: data.value
      })
    } else {
      this.setState({
        redirectType: KEYWORDS.ERROR,
        redirectionType: data.type,
        redirectionValue: data.value,
        redirectError: response[0]
      })
    }
    this.setState({ isSubmitting: false })
  }

  _resetFields = () => {
    const { activeOption } = this.state
    const { fields } = this.state

    const updatedFields = { ...fields }

    if (activeOption === OPTIONS.EMAIL) {
      updatedFields.cellPhone.value = ""
      updatedFields.cellPhone.invalid = false
    } else if (activeOption === OPTIONS.TEXT) {
      updatedFields.email.value = ""
      updatedFields.email.invalid = false
    }
    this.setState({ fields: updatedFields })
  }

  generatePageView = () => {
    const {
      redirectType,
      redirectionType,
      redirectionValue,
      redirectError,
      isSubmitting
    } = this.state
    const options = this.defaultOptions()
    if (redirectType === KEYWORDS.FAILURE) {
      return (
        <FailureLoginViaMagicLink
          redirectType={redirectType}
          redirectError={redirectError}
          buttonsContainer={this.magicLinkButtons}
          handleTryAgain={() => this.setState({ redirectType: null })}
        />
      )
    }
    const getRedirectComponent = () => {
      switch (redirectType) {
        case KEYWORDS.SUCCESS:
          return (
            <>
              <SuccessRedirectComponent
                redirectType={redirectType}
                redirectionType={redirectionType}
                redirectionValue={redirectionValue}
              />
            </>
          )
        case KEYWORDS.ERROR:
          const isError = true
          const contentToRender =
            redirectionType === OPTIONS.EMAIL
              ? this.emailMagicLinkForm(isError)
              : this.textMagicLinkForm(isError)
          return (
            <ErrorRedirectComponent
              redirectType={redirectType}
              redirectionType={redirectionType}
              redirectionValue={redirectionValue}
              redirectError={redirectError}
              contentComponent={contentToRender}
            />
          )
        default:
          return (
            <RenderOptions
              options={options}
              onOptionChange={this.handleOptionChange}
              onSubmit={this._onSubmit}
              isLoading={isSubmitting}
              buttonsContainer={this.magicLinkButtons}
            />
          )
      }
    }

    return (
      <div className={"app"}>
        <div className={"magic-link-container no-session-container"}>
          <div className="magic-link-left-container responsive-large-container">
            <NoSessionLeftPanel />
          </div>
          <div className="magic-link-right-container">
            <div className="responsive-small-magic-link">
              <div className="header-logo">
                <img src={Logo} />
              </div>
            </div>
            <div className="contentCenter">{getRedirectComponent()}</div>
          </div>
        </div>
      </div>
    )
  }

  renderOptionsPage = () => {
    const options = this.defaultOptions()
    const { isSubmitting } = this.state
    return this.generatePageView(
      <RenderOptions
        options={options}
        onOptionChange={this.handleOptionChange}
        onSubmit={this._onSubmit}
        isLoading={isSubmitting}
      />
    )
  }

  render() {
    const { isLoggedIn, user } = this.props
    const { isLoading } = this.state
    if (this.state.isMagicLinkFeature && this.state.isMagicLinkFeature === OFF) {
      return <Redirect to={routerPaths.signIn} />
    }
    if (isLoggedIn && user && user.id) {
      return <Redirect to={routerPaths.dashboard} />
    }
    return (
      <React.Fragment>
        {isLoading && <Loader2 allowText={true} />}

        {!isLoading && this.generatePageView()}
      </React.Fragment>
    )
  }

  //Validators
  _isPresent(value) {
    return !!value.trim()
  }

  _isEmailValid(value) {
    return EMAIL_REGEX.test(value)
  }

  _isValidCellPhone = (value) => {
    return value && value.length == 10 ? true : false
  }

  _validateEmail = () => {
    const { fields } = this.state
    const email = fields.email.value
    const { t } = this.props
    if (email && email.length > 0) {
      if (!this._isEmailValid(email)) {
        this.showError(t("magicLink:invalidEmailMessage"))
        return false
      }
    } else {
      this.showError(`${t("magicLink:alertMessage")} ${t(`magicLink:emailFieldKey`)}`)
    }
    return true
  }

  _validateCellPhone = () => {
    const { fields } = this.state
    const { t } = this.props
    const cellPhone = fields.cellPhone.value
    if (cellPhone && cellPhone.length > 0) {
      if (!this._isValidCellPhone(cellPhone)) {
        this.showError(t("magicLink:invalidCellPhoneMessage"))
        return false
      }
    } else {
      this.showError(`${t("magicLink:alertMessage")} ${t(`magicLink:cellPhoneFieldKey`)}`)
    }
    return true
  }

  showError = (error) => {
    const { t } = this.props
    CustomNotification("danger", t("errorTitle"), error)
  }

  _setAndValidateField(key, value) {
    let field = this.state.fields[key]
    let validators = field.validators || []
    let invalid = validators.some((validator) => !this[validator](value))

    return { ...field, value, invalid }
  }

  _validateFields(callback) {
    const { t } = this.props
    const { activeOption, fields } = this.state
    if (activeOption === OPTIONS.EMAIL) {
      if (!this._validateEmail()) return
    } else if (activeOption === OPTIONS.TEXT) {
      if (!this._validateCellPhone()) return
    }
    let firstInvalidKey = null
    const updatedFields = {}

    Object.keys(fields).forEach((key) => {
      const fieldValue = fields[key].value.trim()
      if (fieldValue !== "") {
        const updatedField = this._setAndValidateField(key, fieldValue)
        updatedFields[key] = updatedField
        if (!firstInvalidKey && updatedField.invalid) {
          firstInvalidKey = key
        }
      }
    })

    if (Object.keys(updatedFields).length > 0) {
      this.setState({ fields: { ...fields, ...updatedFields } }, () => {
        if (firstInvalidKey) {
          CustomNotification(
            "danger",
            t("errorTitle"),
            `${t("alertMessage")} ${t(`accountDetails:${firstInvalidKey}FieldKey`)}`
          )
        } else if (callback) {
          callback()
        }
      })
    } else if (callback) {
      callback()
    }
  }
}

function mapStateToProps(state) {
  let user = state.application.userSessionData || state.users.currentUser || {}
  const isFMC =
    (state.application.userSessionData && state.application.userSessionData.isFMC) ||
    isFMCUser(user)
  const isPathFMC = isFmcPath()
  return {
    isFleetMode: state.application.mode === FLEET || (isFMC && !isPathFMC),
    currentUser: user,
    user,
    isLoggedIn: !!user.authentication_token,
    isLoading: !!user.loading
  }
}

export default connect(mapStateToProps)(withTranslation("magicLink")(MagicLinkIndex))
