import React, { PureComponent } from "react"
import { Segment, Container, Header } from "semantic-ui-react"
import { navigationPaths } from "../../constants/paths"
import { Link } from "react-router-dom"
import { BarChart, Bar, XAxis, Tooltip } from "recharts"
import moment from "moment"
import { getStyles } from "../shared/StylesheetInjector"
import { withTranslation } from "react-i18next"
import { Date_Format } from "../../constants/years"
import { isFleetAdvise, isFleetMaintenanceHubCanada } from "../../helpers/affiliationHelpers"
import { formatCurrency } from "../../utilities/currency"

const MONTH_TO_LETTER = {
  0: "J",
  1: "F",
  2: "M",
  3: "A",
  4: "M",
  5: "J",
  6: "J",
  7: "A",
  8: "S",
  9: "O",
  10: "N",
  11: "D"
}

const CustomTooltip = ({ active, payload, t }) => {
  if (active && payload && payload.length) {
    const data = payload[0].payload
    // Customize the tooltip's content and formatting
    return (
      <div className="custom-tooltip">
        <p>{formatValue(data.date)}</p>
        <p className="text">Total : {formatCurrency(data.Total)}</p>
        <p>{t("tooltipLabel")}</p>
      </div>
    )
  }
  return null
}

// Function to format the value
const formatValue = (date) => {
  // Format the date as desired
  return moment
    .utc(date, Date_Format.DEFAULT_UTC_DATE_TIME)
    .format(isFleetMaintenanceHubCanada() ? Date_Format.YYYY_MM_DD : Date_Format.MMMM_YYYY)
}

class ServiceCostsWidget extends PureComponent {
  state = {}

  componentDidMount() {
    window.addEventListener("resize", this.resize)
    this.resize()
  }

  resize = () => {
    if (document.getElementById("service-costs"))
      this.setState({ width: document.getElementById("service-costs").clientWidth - 28 * 2 })
  }

  render() {
    const styles = getStyles()

    const { costs, navigateToServicesByMonth, language, t } = this.props

    //Weird bug, cant pass in moment Dates to XAxis elements. Still need for sorting tho.
    let data = Object.keys(costs)
      .map((date) => ({
        date: date,
        momentDate: moment.utc(date, Date_Format.DEFAULT_UTC_DATE_TIME),
        Total: parseFloat(costs[date]).toFixed(2)
      }))
      .sort((a, b) => (a.momentDate.isAfter(b.momentDate) ? 1 : -1))

    let dummy = !!!data.find((d) => parseFloat(d.Total) > 0)
    if (dummy) {
      data = data.map((d) => {
        d.Total = parseFloat((Math.random() * 1000).toFixed(2))
        return d
      })
    }

    return (
      <React.Fragment>
        <span />
        <Header size="small" className={"dash-hd hide-margin-on-computer-up"}>
          {t("serviceCostsLabel")}
        </Header>
        <Segment id="service-costs">
          {this.state.width && (
            <BarChart width={this.state.width} height={183} data={data}>
              <XAxis
                dataKey="date"
                tickLine={false}
                axisLine={!dummy}
                tickFormatter={(date) =>
                  data.length < 6
                    ? moment.utc(date, Date_Format.DEFAULT_UTC_DATE_TIME).format("MMM")
                    : MONTH_TO_LETTER[moment.utc(date, Date_Format.DEFAULT_UTC_DATE_TIME).month()]
                }
              />
              {!dummy && <Tooltip content={<CustomTooltip t={t} />} />}
              <Bar
                dataKey="Total"
                onClick={dummy ? "" : navigateToServicesByMonth}
                fill={dummy ? styles.rechartsNeutralColor : styles.rechartsSecondaryColor}
                style={{ cursor: dummy ? "" : "pointer" }}
              />
            </BarChart>
          )}
          <Link
            to={{
              pathname: navigationPaths.servicesIndex(),
              state: {
                startingPage: "past"
              }
            }}
          >
            <Container textAlign="center">
              <span className={`link ${isFleetAdvise() ? "dashboard-link-color" : ""}`}>
                {t("viewAllPastOrdersLabel")}
              </span>
            </Container>
          </Link>
        </Segment>
      </React.Fragment>
    )
  }
}

export default withTranslation("dashboard")(ServiceCostsWidget)
