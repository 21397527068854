import React, { Component } from "react"
import PropTypes from "prop-types"
import { Modal, Button, Header, Container, Icon, Select, Image } from "semantic-ui-react"
import BraintreeWebDropIn from "braintree-web-drop-in"
import { isAch, isBraintree, selectOptions } from "../../helpers/userHelpers"
import {
  BRAINTREE_EN_US_LOCALE,
  BRAINTREE_FR_CANADA_LOCALE,
  FR_LOCALE
} from "../../constants/users"
import MerchantAccountIcon from "../../assets/images/merchant_account.svg"
import ACHForm from "./ACHForm"
import UserList from "../payments/UserList"
import AchModalCloseIcon from "../../assets/images/achModalClose.svg"
import { setupACHInstance } from "../../utilities/setupACHBraintree"

const BRAINTREE_CONTAINER = "payment-form"

// TODO: this class can be extracted as a common component, if it is refactored to accept props
// for the "Drivers" dropdown data.  this.props.users and the formData attr name would become props.
// Otherwise, it's a generic overlay modal that is reusable.
export default class BraintreeModal extends Component {
  static propTypes = {
    modalOpen: PropTypes.bool.isRequired,
    closeModal: PropTypes.func.isRequired,
    saveCallback: PropTypes.func.isRequired,
    users: PropTypes.array.isRequired,
    clientToken: PropTypes.string
  }

  state = {
    formData: {
      customer_ids: this.props.users.map((u) => u.id)
    },
    isACHPayment: false,
    isACHFormValid: false,
    achFormData: {},
    achErrorMessage: "",
    isConsent: true
  }

  onChange(field, value) {
    let updatedFormData = { ...this.state.formData }
    updatedFormData[field] = value
    this.setState({ formData: updatedFormData })
  }

  handleACHPayment = () => {
    this.setState({ isACHPayment: !this.state.isACHPayment, achErrorMessage: "" })
  }

  handleACHValidation = (isValid, formValues) => {
    this.setState({ isACHFormValid: isValid, achFormData: formValues })
  }

  createBraintreeDropIn() {
    return new Promise((resolve, reject) => {
      return BraintreeWebDropIn.create(
        {
          authorization: this.props.clientToken,
          container: `#${BRAINTREE_CONTAINER}`,
          paypal: { flow: "vault" },
          locale:
            this.props.language == FR_LOCALE ? BRAINTREE_FR_CANADA_LOCALE : BRAINTREE_EN_US_LOCALE,
          card: {
            cardholderName: {
              required: true
            }
          }
        },
        (error, instance) => {
          if (error) console.log(error)
          resolve(instance)
        }
      )
    })
  }

  async setupBraintree() {
    const btInstance = await this.createBraintreeDropIn()
    if (btInstance) await this.setState({ btInstance: btInstance })
  }

  async componentWillUnmount() {
    const { btInstance } = this.state
    if (btInstance) {
      await btInstance.teardown()
    }
  }

  handleSave = async () => {
    if (!this.state.btInstance) {
      alert(this.props.t("common:pleaseSelectPaymentMethod"))
      return
    } else {
      return new Promise((resolve, reject) => {
        this.state.btInstance.requestPaymentMethod(async (error, payload) => {
          if (error && error.message) {
            window.alert(`${this.props.t("payment:braintreePaymentErrorLabel")}`)
            resolve(false)
          } else {
            await this.props.saveCallback(payload.nonce, this.state.formData)
            this.setState({ formData: { customer_ids: [] } })
            resolve(true)
          }
        })
      })
    }
  }

  submitACHForm = async () => {
    if (!this.state.isACHPayment) {
      this.handleSave()
    } else {
      const result = await setupACHInstance(this.props.clientToken, this.state.achFormData)
      if (!result.error) {
        await this.props.saveCallback(result.nonce, this.state.formData, true, this.state.isConsent)
        this.setState({
          formData: { customer_ids: [] },
          isConsent: true,
          isACHPayment: false,
          isACHFormValid: false
        })
      } else {
        const splitErrorMessage = result.error.split(";")
        this.setState({
          achErrorMessage: splitErrorMessage.length > 1 ? splitErrorMessage[1].trim() : ""
        })
      }
    }
  }

  achFormFields = (achFormFields) => {
    this.setState({ achFormData: achFormFields })
  }

  closeModal = () => {
    const { closeModal } = this.props
    this.setState({ isACHPayment: false })
    closeModal()
  }

  setIsConsent = (value) => {
    this.setState({ isConsent: value })
  }

  render() {
    const { modalOpen, closeModal, users, t, fleet } = this.props
    const { btInstance, formData, isACHPayment, user, isACHFormValid, achErrorMessage } = this.state
    const saveEnabled = formData && formData.customer_ids && formData.customer_ids.length > 0
    return (
      <Modal className="ach_modal" size="tiny" open={modalOpen}>
        <Modal.Content>
          <div className="ach_modal__header">
            <div className="ach_modal__payment">
              <p className="payment_header">
                1. <span>{t("achPayment:addPayment")}</span>
              </p>

              <p className="payment_subheader">{t("achPayment:addPaymentMethod")}</p>
            </div>
            <Image className="closeLogo" onClick={this.closeModal} src={AchModalCloseIcon} />
          </div>
          {isBraintree(fleet) ? (
            <div className={isACHPayment ? "braintree__dropin" : ""} id={BRAINTREE_CONTAINER}></div>
          ) : (
            <></>
          )}
          {isAch(fleet) && !isACHPayment && (
            <div className="payment_ach__ref" onClick={() => this.handleACHPayment()}>
              <Image src={MerchantAccountIcon} />
              <p className="payment_ach__title">{t("achPayment:addACHMethod")}</p>
            </div>
          )}
          {isACHPayment && (
            <ACHForm
              handleACHValidation={this.handleACHValidation}
              achFormFields={this.achFormFields}
              achErrorMessage={achErrorMessage}
              setIsConsent={this.setIsConsent}
            />
          )}
          {isACHPayment && (
            <div className="choose_different__method" onClick={this.handleACHPayment}>
              {t("achPayment:chooseDifferentMethod")}
            </div>
          )}
          <div className="userlist_container">
            <UserList
              onChange={(_, data) => this.onChange("customer_ids", data.value)}
              options={selectOptions(users, { semantic: true })}
              value={this.state.formData.customer_ids}
            />
            <Button
              disabled={isACHPayment ? !(isACHFormValid && saveEnabled) : !saveEnabled}
              onClick={this.submitACHForm}
              size="tiny"
              className={
                (isACHPayment ? isACHFormValid && saveEnabled : saveEnabled)
                  ? "ach_modal__button"
                  : "ach_modal__disabled"
              }
            >
              {t("achPayment:savePayments")}
            </Button>
          </div>
        </Modal.Content>
      </Modal>
    )
  }
} // class BraintreeModal
