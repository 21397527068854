import { call, put, takeEvery, takeLatest } from "redux-saga/effects"

import api from "../../utilities/api"
import * as apiErrorHelpers from "../../helpers/apiErrorHelpers"
import * as sessionHelpers from "../../helpers/sessionHelpers"
import * as shopHelpers from "../../helpers/shopHelpers"
import { AUTHENTICATION_TOKEN } from "../../constants/application"
import { ERROR, SUCCESS } from "../../constants/notification"
import i18n from "../../utilities/i18n"

const SELECT_SHOPS = "/shops/select_shops?"
const FAVORITE_SHOPS_URL = "/fleet/shops/favorites"
const FAVORITE_SHOPS_URL_V4 = "/shops/favorites"

export function* favoriteShop(action) {
  try {
    api.setAuthApi({
      Authorization: sessionHelpers.getApplicationCookie(AUTHENTICATION_TOKEN)
    })

    yield put({ type: "SHOP_FAVORITE_REQUESTED" })

    const shopId = action.payload.formData.id

    const request = {
      url: `/shops/${shopId}/toggle_favorite`,
      body: {
        ...action.payload.formData
      }
    }

    const response = yield call(api.utility.post, api.path(request.url), { body: request.body })

    apiErrorHelpers.catchErrors(response.body)

    yield put({
      type: "SHOP_FAVORITE_SUCCEEDED",
      payload: {
        shop: response.body.v4_shop
      }
    })

    const afterRequestData = {
      alertMessage: i18n.t("common:preferenceUpdated"),
      alertType: SUCCESS,
      status: SUCCESS
    }

    yield call(action.callback, afterRequestData)
  } catch (errors) {
    // eslint-disable-next-line
    errors = apiErrorHelpers.formatInternalErrors(errors)

    yield put({
      type: "SHOP_FAVORITE_FAILED"
    })

    const afterRequestData = {
      alertMessage: apiErrorHelpers.toString(errors),
      alertType: ERROR,
      status: ERROR
    }

    yield call(action.callback, afterRequestData)

    apiErrorHelpers.consoleLog(errors)
  }
} // function* favoriteShop

export function* filterShops(action) {
  const { asSubForm } = action.payload
  try {
    if (asSubForm) {
      yield put({ type: "SET_INNER_LOADING" })
    } else {
      yield put({ type: "SET_LOADING" })
    }

    yield put({ type: "UNSET_LOADING_ERROR" })

    api.setAuthApi({
      Authorization: sessionHelpers.getApplicationCookie(AUTHENTICATION_TOKEN)
    })

    yield put({ type: "SHOPS_FILTER_REQUESTED" })

    const zipCode = action.payload.filters.zip_code
    const latitude = action.payload.filters.searchLocation.latitude
    const longitude = action.payload.filters.searchLocation.longitude
    const zip = action.payload.filters.searchLocation.zip
    const pageCount = action.payload.pageCount
    const fromMaintananceHistory = action.payload.fromMaintananceHistory
    const params = {
      zip: zipCode,
      latitude: latitude,
      longitude: longitude,
      page: pageCount,
      zip: zip
    }
    var vehicle_type_id
    if (asSubForm) {
      const { filterShopsParams } = action.payload
      const vehicle_id = filterShopsParams.vehicle_id
      vehicle_type_id = filterShopsParams.vehicle_type_id
      const services_requested = JSON.stringify(filterShopsParams.services_requested)
      params.vehicle_id = vehicle_id
      if (!fromMaintananceHistory) params.services_requested = services_requested
    }

    let url = api.constructURLWithParams(SELECT_SHOPS, params)

    const request = {
      url: url,
      body: {}
    }

    const response = yield call(api.utility.get, api.path(request.url), { body: request.body })

    apiErrorHelpers.catchErrors(response.body)
    const shops = response.body

    yield put({
      type: "SHOPS_FILTER_SUCCEEDED",
      payload: {
        shops: shopHelpers.withPricing(shops.shops),
        totalPages: response.body ? response.body.total_pages : 1,
        pageCount: pageCount
      }
    })

    if (asSubForm) {
      yield put({ type: "UNSET_INNER_LOADING" })
    } else {
      yield put({ type: "UNSET_LOADING" })
    }
    if (action.callback) yield call(action.callback, SUCCESS, shops)
  } catch (errors) {
    // eslint-disable-next-line
    errors = apiErrorHelpers.formatInternalErrors(errors)

    yield put({
      type: "SHOPS_FILTER_FAILED"
    })

    yield put({ type: "SET_LOADING_ERROR" })

    if (asSubForm) {
      yield put({ type: "UNSET_INNER_LOADING" })
    } else {
      yield put({ type: "UNSET_LOADING" })
    }

    apiErrorHelpers.consoleLog(errors)
    if (action.callback) yield call(action.callback, ERROR, errors)
  }
} // function* filterShops

export function* loadShops(action) {
  const pageCount = action.payload && action.payload.pageCount ? action.payload.pageCount : 1
  const isV4 = action.payload && action.payload.isV4

  try {
    yield put({ type: "SET_LOADING" })
    yield put({ type: "UNSET_LOADING_ERROR" })

    api.setAuthApi({
      Authorization: sessionHelpers.getApplicationCookie(AUTHENTICATION_TOKEN)
    })

    ///////////////////////
    //  LOAD SHOPS DATA  //
    ///////////////////////
    yield put({ type: "SHOPS_LOAD_REQUESTED" })

    const shopsRequest = {
      url: isV4 ? FAVORITE_SHOPS_URL_V4 : `${FAVORITE_SHOPS_URL}?page=${pageCount}&per_page=25`,
      body: {}
    }

    const response = yield call(
      api.utility.get,
      isV4 ? api.path(shopsRequest.url) : api.pathV6(shopsRequest.url),
      {
        body: shopsRequest.body
      }
    )

    apiErrorHelpers.catchErrors(response.body)

    if (isV4) {
      yield put({
        type: "SHOPS_V4_LOAD_SUCCEEDED",
        payload: {
          shops: response.body
        }
      })
    } else {
      yield put({
        type: "SHOPS_LOAD_SUCCEEDED",
        payload: {
          favoriteShops: response && response.body && response.body.shops,
          favoriteShopTotalPages:
            response && response.body && response.body.total_pages ? response.body.total_pages : 1,
          favoriteShopPageCount:
            response && response.body && response.body.current_page ? response.body.current_page : 1
        }
      })
    }

    ///////////////////////

    yield put({ type: "UNSET_LOADING" })
  } catch (errors) {
    // eslint-disable-next-line
    errors = apiErrorHelpers.formatInternalErrors(errors)

    yield put({ type: "LOADING_FAILED" })
    yield put({ type: "SET_LOADING_ERROR" })
    yield put({ type: "UNSET_LOADING" })

    apiErrorHelpers.consoleLog(errors)
  }
} // function* loadShops

export default function* shopSaga() {
  yield takeEvery("SHOP_FAVORITE_SAGA", favoriteShop)
  yield takeLatest("SHOPS_FILTER_SAGA", filterShops)
  yield takeEvery("SHOPS_LOAD_SAGA", loadShops)
}
