import React, { useEffect, useRef } from "react"
import { connect } from "react-redux"

const TreadDepthModal = ({ flutterApp }) => {
  const hostElementRef = useRef(null)

  useEffect(() => {
    if (flutterApp && hostElementRef.current) {
      flutterApp.addView({
        hostElement: hostElementRef.current,
        viewConstraints: {
          minHeight: 0,
          maxHeight: Infinity
        },
        initialData: {
          widget: "tread_depth_dialog"
        }
      })
    }
  }, [])

  return <div ref={hostElementRef} />
}

const mapStateToProps = (state) => {
  const { flutterApp } = state.application

  return {
    flutterApp: flutterApp
  }
}

export default connect(mapStateToProps)(TreadDepthModal)
