import React from "react"
import { withTranslation } from "react-i18next"
import { Dropdown, Label } from "semantic-ui-react"

const UserList = ({ t, options, onChange, value, isError }) => {
  return (
    <div className="ach_modal">
      <div className="ach_modal__header">
        <div className="ach_modal__payment">
          <p className="payment_header">
            2. <span>{t("addUser")}</span>
          </p>
          <p className="payment_subheader">{t("typesInUser")}</p>
        </div>
      </div>
      <Dropdown
        onChange={onChange}
        placeholder={t("common:selectAssigneeLabel")}
        fluid
        multiple
        selection
        value={value}
        options={options}
      />
      {isError && (
        <Label className="ach_error" pointing>
          {t("userListError")}
        </Label>
      )}
    </div>
  )
}

export default withTranslation("achPayment")(UserList)
