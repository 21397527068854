import { initFirebase, firebaseAnalytics } from "../config/firebaseConfig"
import gaJSON from "../config/googleAnalytics.json"
import { getAnalytics, logEvent, setUserId } from "firebase/analytics"
import { getDeviceProperties } from "./userHelpers"
import { getShopAddress } from "./shopHelpers"
import { formatCurrency } from "../utilities/currency"
import { capitalCase } from "./stringHelpers"
import { getVehicleName } from "./segmentHelpers"
import { getBookingAppointmentDayWithoutISO } from "../constants/years"
import { isNull, isUndefined } from "lodash"
import { getServiceNameForMap } from "./serviceHelpers"
import store from "../actions/configureStore"
import moment from "moment"
import { ORDER_STATUSES } from "../constants/orders"

const trackFirebaseAnalytics = ({ event, params = undefined }) => {
  const additionalProps = getDeviceProperties()
  params = {
    ...params,
    // Add additional parameters as needed for your analytics events
    ...additionalProps
  }
  try {
    logEvent(getAnalytics(), event, params)
  } catch (e) {
    console.error("Google Analytics Error- ", e)
  }
}

const setFirebaseAnalyticsUserId = (userId) => {
  try {
    setUserId(getAnalytics(), userId.toString())
  } catch (e) {
    console.error("Google Analytics Error- ", e)
  }
}

const logFirebaseAnalyticsScreenName = (screenName) => {
  if (screenName !== "Loading") {
    try {
      logEvent(getAnalytics(), "screen_view", {
        firebase_screen: screenName,
        firebase_screen_class: screenName
      })
    } catch (e) {
      console.error("Google Analytics Error- ", e)
    }
  }
}

const logPurchase = (currency, value) => {
  try {
    logEvent(getAnalytics(), "purchase", {
      currency: currency,
      value: value
    })
  } catch (e) {
    console.error("Google Analytics Error- ", e)
  }
}

const logClickEvent = (location, interactionType) => {
  logEvent(getAnalytics(), "button_click", {
    buttonType: gaJSON.button_type,
    location: location || window.location.pathname,
    interactionType: interactionType || gaJSON.single_click
  })
}

const initializeFirebaseAnalytics = () => {
  // Perform any necessary initialization or cleanup related to analytics
  // For example, initializing Firebase analytics on mount or performing setup
}

const getShopData = (shop) => {
  return {
    [gaJSON.shop_id]: shop && shop.id,
    [gaJSON.shop_name]: shop && shop.name,
    [gaJSON.shop_rating]: shop && shop.average_rating,
    [gaJSON.shop_address]: shop && getShopAddress(shop),
    [gaJSON.shop_city]: shop && shop.city
  }
}

const getUserData = () => {
  const user = store.getState().application.userSessionData

  return {
    [gaJSON.approver_name]: user && user.name,
    [gaJSON.approver_email]: user && user.email
  }
}

const getVehicleData = (vehicle) => {
  return {
    [gaJSON.vehicle_id]: vehicle && vehicle.id,
    [gaJSON.vehicle_name]: vehicle
      ? getVehicleName(vehicle) && getVehicleName(vehicle).toUpperCase()
      : null
  }
}

const getOrderService = (order_services) => {
  return {
    [gaJSON.number_of_services]: order_services && order_services.length,
    [gaJSON.order_services]:
      order_services && order_services.length > 0 && order_services.map(getServiceNameForMap),
    [gaJSON.cost]:
      order_services &&
      order_services.length > 0 &&
      order_services
        .map((service) => service.override_total || service.total || service.servicePrice || 0)
        .join(",")
  }
}

const getOrderServiceWithoutCost = (order_services) => {
  return {
    [gaJSON.number_of_services]: order_services && order_services.length,
    [gaJSON.order_services]:
      order_services && order_services.length > 0 && order_services.map(getServiceNameForMap)
  }
}

const getAppointmentDateTimeData = (appointment_datetime, booking_appointment_time) => {
  return {
    [gaJSON.booking_appointment_date]: appointment_datetime
      ? new Date(appointment_datetime).toISOString()
      : null,
    [gaJSON.booking_appointment_day]:
      appointment_datetime && getBookingAppointmentDayWithoutISO(appointment_datetime),
    [gaJSON.booking_appointment_time]: booking_appointment_time || null
  }
}

const serviceSelectedEvents = ({ serviceDefinition, vehicle }) => {
  trackFirebaseAnalytics({
    event: gaJSON.service_selected,
    params: {
      [gaJSON.services]: serviceDefinition,
      [gaJSON.from_where]: window.location.pathname,
      ...getVehicleData(vehicle)
    }
  })
}

const shopSelectedEvents = ({ order_services, shop, vehicle }) => {
  trackFirebaseAnalytics({
    event: gaJSON.shop_selected,
    params: {
      ...getShopData(shop),
      ...getVehicleData(vehicle),
      ...getOrderServiceWithoutCost(order_services)
    }
  })
}

const appointmentDateTimeSelectedEvents = (serviceData) => {
  const {
    shop,
    vehicle,
    order_services,
    appointment_datetime,
    booking_appointment_time
  } = serviceData

  trackFirebaseAnalytics({
    event: gaJSON.appointment_date_time_selected,
    params: {
      ...getShopData(shop),
      ...getVehicleData(vehicle),
      ...getOrderService(order_services),
      ...getAppointmentDateTimeData(appointment_datetime, booking_appointment_time)
    }
  })
}

const orderCancelledEvents = (orderCancelledEventsObj) => {
  const {
    vehicle,
    shop,
    appointment_datetime,
    order_services,
    promotions,
    payment_type,
    id,
    appointment_time_pretty,
    currency
  } = orderCancelledEventsObj
  trackFirebaseAnalytics({
    event: gaJSON.order_cancelled,
    params: {
      [gaJSON.order_id]: id && parseInt(id),
      ...getShopData(shop),
      ...getVehicleData(vehicle),
      ...getOrderService(order_services),
      ...getAppointmentDateTimeData(appointment_datetime, appointment_time_pretty),
      [gaJSON.currency]: currency && currency.toUpperCase(),
      [gaJSON.payment_type]: payment_type && capitalCase(payment_type),
      [gaJSON.promo_code]: promotions && promotions.length > 0 ? promotions[0].name : ""
    }
  })
}

const orderBookedEvents = (orderBookedEventsObj) => {
  const {
    vehicle,
    shop,
    appointment_datetime,
    order_services,
    promotions,
    payment_type,
    appointment_time_pretty,
    id,
    currency,
    fleet_name
  } = orderBookedEventsObj

  trackFirebaseAnalytics({
    event: gaJSON.order_booked,
    params: {
      [gaJSON.order_id]: parseInt(id),
      ...getShopData(shop),
      ...getVehicleData(vehicle),
      ...getOrderService(order_services),
      ...getAppointmentDateTimeData(appointment_datetime, appointment_time_pretty),
      [gaJSON.currency]: currency && currency.toUpperCase(),
      [gaJSON.payment_type]: payment_type && capitalCase(payment_type),
      [gaJSON.promo_code]: promotions && promotions.length > 0 ? promotions[0].name : "",
      [gaJSON.fleet_name]: fleet_name
    }
  })
}

const orderApprovedEvent = (orderApprovedEventObj) => {
  const { order_services, id } = orderApprovedEventObj

  const approvedServices =
    order_services && order_services.length > 0
      ? order_services
          .filter((service) => service.status === ORDER_STATUSES.approved)
          .map(getServiceNameForMap)
      : []

  const declineServices =
    order_services && order_services.length > 0
      ? order_services
          .filter((service) => service.status === ORDER_STATUSES.saved)
          .map(getServiceNameForMap)
      : []

  trackFirebaseAnalytics({
    event: gaJSON.order_approved,
    params: {
      [gaJSON.order_id]: parseInt(id),
      ...getUserData(),
      [gaJSON.approved_date]: moment().format(),
      [gaJSON.approved_services]: JSON.stringify(approvedServices),
      [gaJSON.declined_services]: JSON.stringify(declineServices)
    }
  })
}

export {
  trackFirebaseAnalytics,
  setFirebaseAnalyticsUserId,
  logFirebaseAnalyticsScreenName,
  initializeFirebaseAnalytics,
  gaJSON,
  logPurchase,
  logClickEvent,
  appointmentDateTimeSelectedEvents,
  serviceSelectedEvents,
  shopSelectedEvents,
  orderBookedEvents,
  orderCancelledEvents,
  orderApprovedEvent
}
