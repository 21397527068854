import React, { Component } from "react"
import { connect } from "react-redux"
import { withRouter } from "react-router-dom"
import ApplicationLayout from "../../layouts/ApplicationLayout"
import { Header, Segment, Input, Button, Checkbox as SematicCheckbox } from "semantic-ui-react"
import { withTranslation } from "react-i18next"
import { EVENTS, FILTER_OPTIONS, VIN } from "../../constants/application"
import CustomNotification from "../../components/shared/CustomNotification"
import { NOTIFICATION_TYPE } from "../../constants/notification"
import { agGridFMCColumnDefs, agGridRowData } from "../../constants/vehicles"
import { isFleetAdvise, isFleetcor } from "../../helpers/affiliationHelpers"
import { AgGridReact } from "ag-grid-react"
import CustomNoRowsOverlay from "../../components/shared/CustomNoRowsOverlay"
import { FR_LOCALE } from "../../constants/users"
import frGrid from "../../locales/fr-ca/grid.json"
import enGrid from "../../locales/en/grid.json"
import * as agGridHelpers from "../../helpers/agGridHelpers"
import debounce from "debounce"
import FMCTransactionsShimmer from "./FMCTransactionsShimmer"
import { navigationPaths } from "../../constants/paths"
import { FLEET } from "../../constants/application"
import { logFirebaseAnalyticsScreenName } from "../../helpers/googleAnalyticsHelpers"
import { getRouteNameFromUrl } from "../../helpers/segmentHelpers"
import ReactSVG from "react-svg"
import ReactTooltip from "react-tooltip"

class FMCVehicles extends Component {
  constructor(props) {
    super(props)
    this.state = {
      search_text: "",
      showDropdown: false,
      selectedOption: VIN,
      sizeToFit: false,
      prevBottomDisplayedRowIndex: 0,
      page: 1,
      showGrid: true
    }
    this.wrapperRef = React.createRef()
    this.handleClickOutside = this.handleClickOutside.bind(this)
    this.debouncedOnSizeToFitChange = debounce(this.onSizeToFitChange, 100)
    this.handleKeyDown = this.handleKeyDown.bind(this)
    this.redirectToVehicleShow = this.redirectToVehicleShow.bind(this)
  }

  handleInputClick = (e) => {
    this.setState({ showDropdown: true })
  }

  componentDidMount() {
    logFirebaseAnalyticsScreenName(getRouteNameFromUrl())

    this.loadAllVehicles({ fmc: true, page: 1 })
    document.addEventListener(EVENTS.MOUSEDOWN, this.handleClickOutside)
  }

  loadAllVehicles = async (payload) => {
    await this.props.dispatch({
      type: "VEHICLES_LOAD_SAGA",
      payload: payload,
      callback: this.afterLoadVehicles
    })
  }

  componentWillUnmount() {
    document.removeEventListener(EVENTS.MOUSEDOWN, this.handleClickOutside)
  }

  handleClickOutside(event) {
    if (
      this.state.showDropdown &&
      this.wrapperRef &&
      !this.wrapperRef.current.contains(event.target)
    ) {
      this.setState({ showDropdown: false })
    }
  }

  onTextChange = (event) => {
    const { dispatch } = this.props
    this.setState({ search_text: event.target.value })

    if (event.target.value == "") {
      this.api.showLoadingOverlay()
      this.setState({ search_text: "", showDropdown: false })
      this.loadAllVehicles({ fmc: true, page: 1 })
    }
  }

  handleKeyDown(event) {
    if (event.key === EVENTS.ENTER) {
      this.onSearch()
    }
  }

  onSearch = () => {
    const { t } = this.props
    const { search_text, selectedOption, showDropdown } = this.state
    if (search_text !== "" && search_text.length >= 3) {
      this.api.showLoadingOverlay()
      this.loadAllVehicles({ page: 1, query: search_text, search_type: selectedOption, fmc: true })
    } else {
      return CustomNotification(NOTIFICATION_TYPE.DANGER, "", t("invalidSearchValue"), t)
    }
  }

  handleOptionChange = (e) => {
    this.setState({ selectedOption: e.target.value })
  }

  redirectToVehicleShow = (e) => {
    e.preventDefault()
  }

  defaultCellRenderer(cellParams) {
    const isActiveVehicle =
      cellParams.data.vehicle.is_active === false ||
      (isFleetcor() && cellParams.data.vehicle.fleetcor_enrolled === false)
    return (
      <div
        className={`ag-grid-link-column ${isActiveVehicle ? "deactivated" : ""} ${
          cellParams.colDef.field === "vin" ? "column-vin" : ""
        }`}
      >
        <div>{cellParams.value}</div>
        {cellParams.colDef.field === "vin" && cellParams.value && (
          <>
            <ReactTooltip
              id={"vin-copy-" + cellParams.value}
              effect={"solid"}
              type={"dark"}
              place={"right"}
              class={"vin-copy-tooltip"}
              afterShow={(e) => {
                e.preventDefault()
                navigator.clipboard.writeText(cellParams.value)
              }}
            >
              <p style={{ color: "white" }}>Copied!</p>
            </ReactTooltip>
            <div
              data-for={"vin-copy-" + cellParams.value}
              data-tip
              data-event="click"
              data-event-off={"mouseout"}
            >
              <ReactSVG src={"images/copy-icon.svg"} className="vin-copy" />
            </div>
          </>
        )}
      </div>
    )
  }

  onGridReady = (params) => {
    const { sizeToFit } = this.state
    this.api = params.api
    this.columnApi = params.columnApi
    if (sizeToFit) params.api.sizeColumnsToFit()
    window.addEventListener("resize", () => sizeToFit && this.api.sizeColumnsToFit())
  }

  setFilteredItems = () =>
    this.setState({ filteredItems: this.api.getModel().rowsToDisplay.map((r) => r.data) })

  onSizeToFitChange = (event, syntheticEvent) => {
    this.setState({ sizeToFit: syntheticEvent.checked })
    if (syntheticEvent.checked) this.api.sizeColumnsToFit()
    else this.redrawGrid()
  }

  redrawGrid() {
    this.setState({ showGrid: false })
    setTimeout(() => this.setState({ showGrid: true }), 500)
  }

  handlePageChange = async ({ api: pageApi, newPage }) => {
    const { page, prevBottomDisplayedRowIndex, search_text, selectedOption } = this.state

    const bottomDisplayedRowIndex = pageApi.paginationProxy.bottomDisplayedRowIndex / 50
    if (newPage && bottomDisplayedRowIndex > prevBottomDisplayedRowIndex) {
      this.api.showLoadingOverlay()
      this.setState({ page: page + 1, prevBottomDisplayedRowIndex: bottomDisplayedRowIndex })
      if (search_text) {
        this.loadAllVehicles({
          page: page + 1,
          query: search_text,
          fmc: true,
          filter: { filterType: "search_type", filterValue: selectedOption }
        })
      } else {
        this.loadAllVehicles({ fmc: true, page: page + 1 })
      }
    }
  }

  afterLoadVehicles = (message) => {
    this.api.hideOverlay()
  }

  handleRowClick = (event) => {
    if (event.event.defaultPrevented) return
    const rowData = event.data
    this.props.dispatch({
      type: "FLEET_IMPERSONATION_SAGA",
      payload: { fleetId: rowData.fleet_id },
      callback: this.afterImpersonationRequest.bind(this, rowData.id)
    })
  }

  afterImpersonationRequest(vehicle_id, status) {
    if (status === "success") {
      sessionStorage.removeItem("current_user")
      sessionStorage.removeItem("current_fleet")
      this.props.dispatch({
        type: "FMC_FLIP_SAGA",
        payload: { mode: FLEET }
      })
      this.props.history.push({
        pathname: navigationPaths.vehicleShow(vehicle_id)
      })
      window.location.reload()
    }
  }

  render() {
    const { t, vehicles, language } = this.props
    const {
      sizeToFit,
      search_text,
      selectedOption,
      showDropdown,
      quickFilterText,
      showGrid
    } = this.state
    const getRowStyle = (params) => {
      if (
        params.data.vehicle.is_active === false ||
        (isFleetcor() && params.data.vehicle.fleetcor_enrolled === false)
      ) {
        return { background: "#f7f7f7" }
      }
    }
    return (
      <ApplicationLayout>
        <div className="settings-container">
          <span />
          <Header size="small" className={"dash-hd"}>
            {t("vehiclesLabel")}
          </Header>
          <Segment
            className={"ag-theme-material"}
            style={{
              height: agGridHelpers.dynamicGridHeight({ items: vehicles })
            }}
          >
            <div className="search-input-parent">
              <div className="search-input-box">
                <Input
                  className="search search-fleet"
                  icon="search"
                  onClick={this.handleInputClick}
                  onChange={this.onTextChange}
                  placeholder={t("SearchVehicleLabel")}
                  onKeyDown={this.handleKeyDown}
                />
                {showDropdown && (
                  <div className="filter-options-parent" ref={this.wrapperRef}>
                    <p>{t("iAmLookingForLabel")}</p>
                    <div className="filter-options">
                      {FILTER_OPTIONS.map((option) => (
                        <label key={option.value}>
                          <input
                            type="radio"
                            value={option.value}
                            checked={option.value === selectedOption}
                            onChange={this.handleOptionChange}
                            className={isFleetAdvise() ? "radio-btn-color" : ""}
                          />
                          {option.key}
                          <br />
                        </label>
                      ))}
                    </div>
                  </div>
                )}
              </div>
              <Button disabled={!(search_text.length >= 1)} onClick={() => this.onSearch()}>
                {t("search")}
              </Button>
              <SematicCheckbox
                style={{ paddingLeft: "65px", paddingTop: "10px" }}
                label={t("sizeToFitLabel")}
                onChange={this.debouncedOnSizeToFitChange}
                checked={sizeToFit}
                className={isFleetAdvise() ? "size-to-fit" : ""}
              />
            </div>
            {showGrid && vehicles && vehicles.length > 0 ? (
              <AgGridReact
                columnDefs={agGridFMCColumnDefs(this.defaultCellRenderer, t, vehicles, true)}
                onPaginationChanged={this.handlePageChange}
                defaultColDef={{
                  sortable: true,
                  filter: true
                }}
                getRowNodeId={(data) => data.id} // Provide a unique identifier for each row
                onRowClicked={this.handleRowClick} // Attach the row click event handler
                rowData={agGridRowData(vehicles)}
                rowSelection={"single"}
                onGridReady={this.onGridReady}
                gridOptions={{
                  onFilterChanged: this.setFilteredItems
                }}
                frameworkComponents={{
                  customNoRowsOverlay: CustomNoRowsOverlay
                }}
                noRowsOverlayComponent={"customNoRowsOverlay"}
                noRowsOverlayComponentParams={{
                  translationFile: "vehicleIndex",
                  label: "noVehiclesInFleetLabel"
                }}
                localeText={language === FR_LOCALE ? frGrid : enGrid}
                quickFilterText={quickFilterText}
                cacheQuickFilter={true}
                pagination={true}
                paginationPageSize={agGridHelpers.PAGINATION_AMOUNT}
                suppressMenuHide={true}
                suppressMovableColumns={false}
                suppressCellSelection={true}
                getRowStyle={getRowStyle}
              ></AgGridReact>
            ) : (
              <FMCTransactionsShimmer />
            )}
          </Segment>
        </div>
      </ApplicationLayout>
    )
  }
} // class FMCVehicleSearch

const mapStateToProps = (state) => ({
  vehicles: state.vehicles.vehicles,
  currentUser: state.application.userSessionData || state.users.currentUser,
  language:
    (state.application.userSessionData && state.application.userSessionData.language) ||
    state.users.currentUser
      ? state.users.currentUser.language
      : ""
})

export default connect(mapStateToProps)(withTranslation("vehicleIndex")(withRouter(FMCVehicles)))
