import React, { Component } from "react"
import { Label, Popup, Icon } from "semantic-ui-react"
import { isFleetAdvise } from "../../helpers/affiliationHelpers"

const InfoLabel = ({ field }) => {
  const styleLabel = {
    backgroundColor: "#fff",
    padding: 0,
    paddingBottom: 5,
    color: isFleetAdvise() ? "#292F36" : ""
  }
  const { placeholder } = field
  return (
    <Label style={styleLabel} size="large">
      {placeholder && `${placeholder}:`}
      {field.helpModal && (
        <Popup
          flowing
          inverted
          style={{ backgroundColor: "#5382b4" }}
          on="click"
          trigger={<Icon style={{ marginLeft: 5 }} name="question circle outline" />}
          content={field.helpModal}
        />
      )}
    </Label>
  )
}

export default InfoLabel
