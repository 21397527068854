import { browserName } from "react-device-detect"
import moment from "moment"
import store from "../actions/configureStore"
import { Date_Format, formatDateWithUTC } from "../constants/years"
import { isNil, isObject, isNull, omitBy, isUndefined } from "lodash"
import { SLASH_INITIAL_REMOVE_REGEX } from "../constants/segmentProps"
import { isFleetcor, isFuelman } from "./affiliationHelpers"

export const getDateFormat = () => "MM-DD-YYYY"

export const getEventNameByKey = (key) => {
  const state = store.getState()

  if (state && state.segment && state.segment.eventNames && state.segment.eventNames[key]) {
    return state.segment.eventNames[key]
  }
  return null
}
export const getDeviceProp = () => ({ device: `Web - ${browserName.toLowerCase()}` })

export const getBookingAppointmentDay = (appointmentDateTime) => {
  if (appointmentDateTime)
    moment(appointmentDateTime, "YYYY-MM-DD HH:mm:ss").format("dddd").toISOString()
}

export const getAppointmentDateTime = (appointmentDateTime, timeString) => {
  if (appointmentDateTime) {
    const date = moment(appointmentDateTime).format("YYYY-MM-DD") + " " + timeString
    return moment(date).format("YYYY-MM-DD HH:mm:ss")
  }
}
export const getBookingAppointmentDateTime = (appointmentDateTime) => {
  if (appointmentDateTime) {
    return appointmentDateTime.replace("T", " ") + ":00"
  }
}
export const getBookedAppointmentDateTime = (appointmentDateTime) => {
  if (appointmentDateTime) {
    return appointmentDateTime.slice(0, 19).replace("T", " ")
  }
}
export const getBookingAppointmentTime = (appointmentDateTime) => {
  if (appointmentDateTime) {
    const formattedDateTime = moment(appointmentDateTime, "YYYY-MM-DD HH:mm:ss")
    const appointmentTime = formattedDateTime.format("HH:mm")
    return appointmentTime
  }
}

export const getVehicleName = (vehicle = {}) => {
  const { year, make, model } = vehicle
  if (!isObject(vehicle) || (!year && !make && !model)) {
    return null
  }

  const nameParts = [year, make, model].filter((part) => !!part)
  return nameParts.join(" ")
}

export const getVehicleProps = (
  vehicle = {},
  additionalInfo = { shouldAddCarsAdded: true, shouldRemoveNullKeys: false, omitIsDefault: false }
) => {
  let vehicleProps = {}

  if (additionalInfo.shouldAddCarsAdded) vehicleProps.carsAdded = 1

  if (isObject(vehicle) && !isNull(vehicle) && !isUndefined(vehicle)) {
    vehicleProps.vehicleYear = vehicle.year || null
    vehicleProps.vehicleModel = vehicle.model || null
    vehicleProps.vehicleSubModel = vehicle.sub_model || null
    vehicleProps.vehicleMake = vehicle.make || null
    vehicleProps.vin = vehicle.vin || null
    vehicleProps.vehicleEngine = vehicle.engine_description || null
    vehicleProps.vehicleImage = vehicle.dashboard_mobile_url || vehicle.dashboard_url || null
    vehicleProps.miles =
      parseInt(vehicle.odometer_config && vehicle.odometer_config.quantity) || null
    vehicleProps.estimatedCurrentMileage =
      (vehicle.predicted_current_odometer_config &&
        parseInt(vehicle.predicted_current_odometer_config.quantity)) ||
      null
    vehicleProps.lastMaintenanceDate =
      (vehicle.last_maintenance_date &&
        formatDateWithUTC(vehicle.last_maintenance_date, Date_Format.MM_DD_YYYY)) ||
      null
    vehicleProps.nextMaintenanceDate =
      (vehicle.next_maintenance_date &&
        formatDateWithUTC(vehicle.next_maintenance_date, Date_Format.MM_DD_YYYY)) ||
      null
    vehicleProps.estimatedMilesPerMonth =
      (vehicle.odometer_per_month_config && parseInt(vehicle.odometer_per_month_config.quantity)) ||
      null
    vehicleProps.licensePlateNumber = vehicle.license_plate_number || null
    vehicleProps.licensePlateState = vehicle.license_plate_state || null
    vehicleProps.vehicleId = (vehicle.id && parseInt(vehicle.id)) || null
    vehicleProps.lastRecordedMileage =
      (vehicle.odometer_config && parseInt(vehicle.odometer_config.quantity)) || null
    vehicleProps.vehicleName = isFleetcor()
      ? vehicle.name_of_vehicle || getVehicleName(vehicle)
      : getVehicleName(vehicle) || null
  }

  // if we want to remove null or undefined keys
  if (additionalInfo.shouldRemoveNullKeys) {
    vehicleProps = omitBy(vehicleProps, isNil)
  }

  return vehicleProps
}

export const getRouteNameFromUrl = (url = window.location.pathname) => {
  url = url.trim().replace(SLASH_INITIAL_REMOVE_REGEX, "")
  const segments = url.split("/").filter((segment) => isNaN(segment))
  let routeName = segments.join("-")
  return routeName
}
