import React, { useEffect, useState } from "react"

import { Checkbox, Divider, Icon, Image } from "semantic-ui-react"
import RecommendedServiceModal from "../RecommendedServiceModal"
import { isFleetAdvise } from "../../../../helpers/affiliationHelpers"
import { EVENT_LISTENERS, MD_HD_VEHICLE_TYPE_ID } from "../../../../constants/application"
import { formatCurrency } from "../../../../utilities/currency"
import { isEmpty } from "lodash"
import { useTranslation } from "react-i18next"

const Services = (props) => {
  const {
    service,
    selectedServices,
    completed,
    showMarkCompletedCheckBox = true,
    isLast = false,
    addorRemoveServices,
    onMarkasCompletedCheckboxClick,
    markAllCompleted,
    serviceToBeMarkedAsCompleted,
    isAdded = false,
    activeVehicle
  } = props
  const [open, setOpen] = useState(false)
  const [windowWidth, setWindowWidth] = useState(window.innerWidth)
  const [show, setShow] = useState(true)

  let isChecked = false
  const vehicleTypeId = activeVehicle && activeVehicle.vehicle_type_id

  const { t } = useTranslation("common")

  useEffect(() => {
    if (!isChecked) {
      setShow(true)
    }
  }, [isChecked])

  const updateWindowWidth = () => {
    setWindowWidth(window.innerWidth)
  }
  useEffect(() => {
    window.addEventListener(EVENT_LISTENERS.RESIZE, updateWindowWidth)
    return () => {
      window.removeEventListener(EVENT_LISTENERS.RESIZE, updateWindowWidth)
    }
  }, [])

  if (markAllCompleted) {
    const serviceIndex = serviceToBeMarkedAsCompleted.findIndex(
      (s) => s.service_id === service.service_id
    )
    isChecked = serviceIndex == -1 ? false : true
  } else {
    const serviceIndex = selectedServices.findIndex((s) => s.service_id === service.service_id)
    isChecked = serviceIndex == -1 ? false : true
  }
  const openModal = () => {
    setOpen(true)
  }
  const closeModal = (data) => {
    setOpen(false)
  }

  const onSave = (data) => {
    if (data) {
      addorRemoveServices(service, data)
    } else {
      addorRemoveServices(service)
      isChecked = false
    }
    closeModal()
  }
  return (
    <>
      <div className="manufacturer-title">
        {markAllCompleted && (
          <div style={{ flexDirection: "row" }}>
            <Icon.Group size="large" className="back-icon">
              <Icon
                name={
                  completed
                    ? "check circle"
                    : isChecked
                    ? isFleetAdvise()
                      ? "check circle checkbox-color"
                      : "check circle"
                    : "circle outline"
                }
                disabled={completed}
                onClick={() => !completed && onMarkasCompletedCheckboxClick(service)}
              />
            </Icon.Group>
            <span>{service.service_name}</span>
          </div>
        )}
        {!markAllCompleted && <span>{service.service_name}</span>}
        {completed && (
          <Image src="../../../../images/completedIcon.png" className="completed-image" />
        )}

        {!completed && (
          <div className="maintenance-schedule-price-section">
            {windowWidth > 500 && (
              <div className="service-price-info-section">
                <div className="service-price-info">
                  {!isAdded && (!service.price_estimate || !service.high_price_estimate) ? (
                    vehicleTypeId && vehicleTypeId === MD_HD_VEHICLE_TYPE_ID ? (
                      <p className="price-text">{t("mdHdpricedAtShopLbl")}</p>
                    ) : service.high_price_estimate &&
                      service.low_price_estimate &&
                      service.high_price_estimate.shop_price_estimate !==
                        service.low_price_estimate.shop_price_estimate ? (
                      <p className="price-range">
                        {`${t("fromLabel")} ${formatCurrency(
                          service.low_price_estimate.shop_price_estimate
                        )} - ${formatCurrency(service.high_price_estimate.shop_price_estimate)}`}
                      </p>
                    ) : service.high_price_estimate ? (
                      `${formatCurrency(service.high_price_estimate.shop_price_estimate)}`
                    ) : service.price_range_from && service.price_range_to ? (
                      <p className="price-range">
                        {`${t("fromLabel")} ${formatCurrency(
                          service.price_range_from
                        )} - ${formatCurrency(service.price_range_to)}`}
                      </p>
                    ) : isEmpty(activeVehicle) ? (
                      <p className="price-text">{t("addTheVehicleLabel")}</p>
                    ) : (
                      <p className="price-text">{t("discountPriceAtShop")}</p>
                    )
                  ) : null}
                </div>
              </div>
            )}
            {markAllCompleted == false && !completed && (
              <Checkbox
                checked={isChecked}
                onChange={(e) => {
                  // e.preventDefault()
                  if (service.positions && service.positions.length > 1) {
                    openModal()
                  }

                  if (!service.positions || service.positions.length < 1) {
                    addorRemoveServices(service)
                  }
                }}
                onClick={() => {
                  if (isChecked && service.positions && service.positions.length > 1) {
                    addorRemoveServices(service)
                    isChecked = true
                    setShow(false)
                  }
                  if (!isChecked && service.positions && service.positions.length > 1) {
                    // addorRemoveServices(service)
                    isChecked = true
                    setShow(true)
                  }
                }}
              />
            )}
          </div>
        )}
        {show && service.positions && service.positions.length > 1 && (
          <RecommendedServiceModal
            serviceOptions={service.positions}
            isOpenModel={open}
            serviceName={service.service_name}
            closeModal={closeModal}
            openModal={openModal}
            onSave={onSave}
          />
        )}
      </div>
      <Divider />
    </>
  )
}

export default Services
