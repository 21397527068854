import React, { PureComponent } from "react"
import PropTypes from "prop-types"
import { Link } from "react-router-dom"
import VehicleModal from "./VehicleModal"
import { agGridColumnDefs, agGridRowData } from "../../constants/vehicles"
import * as agGridHelpers from "../../helpers/agGridHelpers"
import { navigationPaths } from "../../constants/paths"
import LoadingThrobber from "../../components/shared/LoadingThrobber"
import { AgGridReact } from "ag-grid-react"
import { Header, Segment, Button, Checkbox as SematicCheckbox, Portal } from "semantic-ui-react"
import { withRouter } from "react-router-dom"
import debounce from "debounce"
import VehicleSearch from "./VehicleSearch"
import { withTranslation } from "react-i18next"
import frGrid from "../../locales/fr-ca/grid.json"
import enGrid from "../../locales/en/grid.json"
import { FR_LOCALE } from "../../constants/users"
import CustomNoRowsOverlay from "../shared/CustomNoRowsOverlay"
import { isFleetcor } from "../../helpers/affiliationHelpers"
import BulkActivateDeactivate from "./BulkActivateDeactivate"
import vehicles from "../../actions/reducers/vehicles"
import ReactSVG from "react-svg"
import ReactTooltip from "react-tooltip"

class NewVehicleList extends PureComponent {
  constructor(props) {
    super(props)
    this.state = {
      sizeToFit: false,
      showGrid: true,
      openBulkPortal: false
    }
    this.debouncedOnFilterChange = debounce(this.onFilterChange, 300)
    this.debouncedOnSizeToFitChange = debounce(this.onSizeToFitChange, 100)
    this.handleBulkCheckBox = this.handleBulkCheckBox.bind(this)
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (prevProps.showInactiveVehicles !== this.props.showInactiveVehicles) {
      this.setState({ openBulkPortal: false })
    }
  }

  editCellRenderer = (cellParams) => {
    const vehicle = cellParams.value
    const { loadVehicles, t } = this.props
    if (vehicle.fleetcor_enrolled == true) {
      return (
        <VehicleModal completionCallback={loadVehicles} vehicle={vehicle}>
          <span className="link">{t("common:editLabel")}</span>
        </VehicleModal>
      )
    } else return <></>
  }

  deleteCellRenderer = (cellParams) => {
    const vehicle = cellParams.value
    const { t } = this.props
    return (
      <span
        className="link"
        onClick={(event) => {
          event.preventDefault()
          this.props.onDeleteVehicle(vehicle.id, t)
        }}
      >
        {t("deleteLabel")}
      </span>
    )
  }

  deactivateCellRenderer = (cellParams) => {
    const vehicle = cellParams.value
    const { loadVehicles, t, currentUser } = this.props
    return isFleetcor() ? (
      vehicle.fleetcor_enrolled == false ? (
        <VehicleModal
          deactivate={true}
          editActiveVehicleFields={true}
          completionCallback={() => this.redrawGrid()}
          vehicle={vehicle}
        >
          <Button className="white-button">Enroll Now</Button>
        </VehicleModal>
      ) : (
        <Button className="white-button" disabled>
          Enrolled
        </Button>
      )
    ) : (
      <div
        role="presentation"
        onClick={() => this.props.onDeactivateVehicle(vehicle.is_active, vehicle.id, t)}
      >
        <SematicCheckbox toggle checked={vehicle.is_active} />
      </div>
    )
  }

  checkboxBulkRender = (cellParams) => {
    const { value } = cellParams

    return (
      <SematicCheckbox
        checked={value.checked}
        disabled={isFleetcor() && !value.fleetcor_enrolled}
        onChange={(_, { checked }) => {
          const isRootChecked = this.props.handleCellCheckboxChange(value.index, checked)
          this.setState({ openBulkPortal: isRootChecked || checked })
        }}
        onClick={(event) => {
          event.preventDefault()
        }}
      />
    )
  }

  handlePortal = () => {
    this.setState({ openBulkPortal: !this.state.openBulkPortal })
  }

  handleBulkCheckBox(isChecked) {
    this.setState({ openBulkPortal: isChecked })
    this.props.handleVehicleActivateRootCheckbox(isChecked)
  }

  callToActionRenderer = (cellParams) => {
    const vehicle = cellParams.data.vehicle
    const vehicleId = cellParams.data.id
    const ctaValsPresent = vehicle.year && vehicle.make && vehicle.model
    const presentValsString = `${vehicle.year} ${vehicle.make} ${vehicle.model}`
    const isActiveVehicle =
      cellParams.data.vehicle.is_active === false ||
      (isFleetcor() && cellParams.data.vehicle.fleetcor_enrolled === false)

    const vehicleDetailsLink = (
      <Link
        className={`ag-grid-link-column ${isActiveVehicle ? "deactivated" : ""}`}
        to={navigationPaths.vehicleShow(vehicleId)}
      >
        <div>{presentValsString}</div>
      </Link>
    )
    const { t, loadVehicles } = this.props
    const ctaValsModal = isFleetcor() ? <> - </> : <></>

    return <div>{ctaValsPresent ? vehicleDetailsLink : ctaValsModal}</div>
  }

  // NOTE: This is used over linking from ag-grid's onRowSelected, because clicking a column button (i.e. Edit), will
  // fire both the row and column click handlers. This will take you to the expected edit page, but polutes the
  // navigation stack, and breaks expected back button functionality.
  // NOTE: This is not needed without linking, as it just wraps the ag-grid default render with a link.
  defaultCellRenderer(cellParams) {
    const isActiveVehicle =
      cellParams.data.vehicle.is_active === false ||
      (isFleetcor() && cellParams.data.vehicle.fleetcor_enrolled === false)
    return (
      <div
        className={`ag-grid-link-column ${isActiveVehicle ? "deactivated" : ""} ${
          cellParams.colDef.field === "vin" ? "column-vin" : ""
        }`}
      >
        <div>{cellParams.value}</div>
        {cellParams.colDef.field === "vin" && cellParams.value && (
          <>
            <ReactTooltip
              id={"vin-copy-" + cellParams.value}
              effect={"solid"}
              type={"dark"}
              place={"right"}
              class={"vin-copy-tooltip"}
              afterShow={(e) => {
                e.preventDefault()
                navigator.clipboard.writeText(cellParams.value)
              }}
            >
              <p style={{ color: "white" }}>Copied!</p>
            </ReactTooltip>
            <div
              data-for={"vin-copy-" + cellParams.value}
              data-tip
              data-event="click"
              data-event-off={"mouseout"}
            >
              <ReactSVG src={"images/copy-icon.svg"} className="vin-copy" />
            </div>
          </>
        )}
      </div>
    )
  }

  onFilterChange = (_, event) => {
    this.setState({ quickFilterText: event.value })
  }

  onGridReady = (params) => {
    this.api = params.api
    this.columnApi = params.columnApi
    if (this.state.sizeToFit) params.api.sizeColumnsToFit()
    window.addEventListener("resize", () => this.state.sizeToFit && this.api.sizeColumnsToFit())
  }

  resetFiltering = () => {
    this.api.setFilterModel([])
    this.props.handleVehicleActivateRootCheckbox(false)
    this.handlePortal()
  }

  setFilteredItems = () =>
    this.setState({ filteredItems: this.api.getModel().rowsToDisplay.map((r) => r.data) })

  onSizeToFitChange = (event, syntheticEvent) => {
    this.setState({ sizeToFit: syntheticEvent.checked })
    if (syntheticEvent.checked) this.api.sizeColumnsToFit()
    else this.redrawGrid()
  }

  redrawGrid() {
    this.setState({ showGrid: false })
    setTimeout(() => this.setState({ showGrid: true }), 500)
  }

  handleRowSelect = (event) => {
    if (event.event.defaultPrevented) return
    const rowData = event.data
    this.props.history.push({
      pathname: navigationPaths.vehicleShow(rowData.id)
    })
  }

  render() {
    const {
      users,
      language,
      t,
      dispatch,
      vehicles,
      showInactiveVehicles,
      setShowInactiveVehicles
    } = this.props
    const { showGrid, sizeToFit } = this.state
    const getRowStyle = (params) => {
      if (
        params.data.vehicle.is_active === false ||
        (isFleetcor() && params.data.vehicle.fleetcor_enrolled === false)
      ) {
        return { background: "#f7f7f7" }
      }
    }

    return (
      <div>
        <span />
        <Header size="small" className={"dash-hd"}>
          {t("myVehiclesLabel")}
        </Header>
        <Segment
          className={"ag-theme-material"}
          style={{
            height: agGridHelpers.dynamicGridHeight({ items: vehicles })
          }}
        >
          <VehicleSearch
            debouncedOnFilterChange={this.debouncedOnFilterChange}
            resetFiltering={this.resetFiltering}
            loadVehicles={this.props.loadVehicles}
            sizeToFit={sizeToFit}
            debouncedOnSizeToFitChange={this.debouncedOnSizeToFitChange}
            dispatch={dispatch}
            language={language}
            vehicles={vehicles}
            showInactiveVehicles={showInactiveVehicles}
            setShowInactiveVehicles={setShowInactiveVehicles}
          />

          {showGrid ? (
            <AgGridReact
              columnDefs={agGridColumnDefs(
                this.defaultCellRenderer,
                this.editCellRenderer,
                this.deleteCellRenderer,
                this.deactivateCellRenderer,
                this.callToActionRenderer,
                t,
                isFleetcor(),
                vehicles,
                this.checkboxBulkRender,
                this.handleBulkCheckBox,
                this.props.activateRootCheckbox
              )}
              defaultColDef={{
                sortable: true,
                filter: true
              }}
              rowData={agGridRowData(vehicles, users, language, isFleetcor())}
              rowSelection={"single"}
              onGridReady={this.onGridReady}
              gridOptions={{
                onFilterChanged: this.setFilteredItems,
                suppressScrollOnNewData: true,
                immutableData: true,
                getRowNodeId: (data) => data.id
              }}
              frameworkComponents={{
                customNoRowsOverlay: CustomNoRowsOverlay
              }}
              noRowsOverlayComponent={"customNoRowsOverlay"}
              noRowsOverlayComponentParams={{
                translationFile: "vehicleIndex",
                label: "noVehiclesInFleetLabel"
              }}
              localeText={language === FR_LOCALE ? frGrid : enGrid}
              quickFilterText={this.state.quickFilterText}
              cacheQuickFilter={true}
              pagination={true}
              paginationPageSize={agGridHelpers.PAGINATION_AMOUNT}
              suppressMenuHide={true}
              suppressMovableColumns={false}
              suppressCellSelection={true}
              getRowStyle={getRowStyle}
              onRowClicked={this.handleRowSelect}
            ></AgGridReact>
          ) : (
            <LoadingThrobber visible />
          )}
        </Segment>
        <Portal open={this.state.openBulkPortal}>
          <div className="bulk-vehicle-portal">
            <BulkActivateDeactivate
              handleVehicleActivation={this.props.handleVehicleActivation}
              vehicles={vehicles}
              isBulkApiInProgress={this.props.isBulkApiInProgress}
            />
          </div>
        </Portal>
      </div>
    )
  }
} // class NewVehicleList

NewVehicleList.propTypes = {
  users: PropTypes.array.isRequired,
  vehicles: PropTypes.array.isRequired
}

export default withRouter(withTranslation("vehicleIndex")(NewVehicleList))
