// import { SET_EVENT_NAMES } from "../actions/segment"

import { SET_EVENT_NAMES } from "../sagas/segment"

//Initial state for the reducer
const initialState = {
  eventNames: [] // Initial state for event names, modify as per your requirements
}

// Reducer function
const segment = (state = initialState, action) => {
  switch (action.type) {
    case SET_EVENT_NAMES:
      // Update state with the event names from the action payload
      return {
        ...state,
        eventNames: action.payload
      }
    default:
      // Return the current state for any other action types
      return state
  }
}

export default segment
