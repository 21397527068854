import React from "react"
import { Link } from "react-router-dom"
import { navigationPaths } from "../../constants/paths"
import { Button } from "semantic-ui-react"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faCalendar } from "@fortawesome/pro-light-svg-icons"
import { useTranslation } from "react-i18next"
import { isFleetAdvise } from "../../helpers/affiliationHelpers"

export default function AddMaintenanceHistoryButton({ text, floated, secondary }) {
  const { t } = useTranslation("common")

  return (
    <Link
      to={{
        pathname: navigationPaths.MaintenanceHistoryNew()
      }}
    >
      <Button
        className={`${secondary && "white-button"} ${isFleetAdvise() ? "btn-margin-right" : ""}`}
        floated={floated}
      >
        {isFleetAdvise() ? (
          <FontAwesomeIcon icon={faCalendar} className="button-icon-color" />
        ) : null}
        &nbsp;
        {text ? text : t("addMaintenanceHistoryLabel")}
      </Button>
    </Link>
  )
}
