import React from "react"
import { Checkbox } from "semantic-ui-react"

const ActivatedHeaderCheckbox = ({ handleBulkCheckBox, checked }) => {
  return (
    <div className="flexCenter">
      <Checkbox
        checked={checked}
        onChange={(_, { checked }) => handleBulkCheckBox(checked)}
        Activated
      />
    </div>
  )
}

export default ActivatedHeaderCheckbox
