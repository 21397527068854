import { call, put, takeEvery } from "redux-saga/effects"

import api from "../../utilities/api"
import * as apiErrorHelpers from "../../helpers/apiErrorHelpers"
import * as sessionHelpers from "../../helpers/sessionHelpers"
import { FAILURE, NOTIFICATION_TYPE } from "../../constants/notification"
import { AUTHENTICATION_TOKEN } from "../../constants/application"
import i18n from "../../utilities/i18n"

const BRAINTREE_PAYMENT_METHODS_URL = "/braintree_payment_methods"
const BRAINTREE_TOKENS_NEW_URL = "/braintree_tokens/new"
const SET_DEFAULT_PAYMENT_METHOD_URL = "/payment_methods"
export function* loadPaymentMethods(action) {
  try {
    yield put({ type: "SET_LOADING" })
    yield put({ type: "SET_PAYMENT_METHODS_LOADING" })
    yield put({ type: "UNSET_LOADING_ERROR" })

    api.setAuthApi({
      Authorization: sessionHelpers.getApplicationCookie(AUTHENTICATION_TOKEN)
    })

    yield put({ type: "PAYMENT_METHODS_LOAD_REQUESTED" })

    const request = {
      url: BRAINTREE_PAYMENT_METHODS_URL,
      body: {}
    }

    const response = yield call(api.utility.get, api.path(request.url), { body: request.body })

    apiErrorHelpers.catchErrors(response.body)

    yield put({
      type: "PAYMENT_METHODS_LOAD_SUCCEEDED",
      payload: {
        paymentMethods: response.body
      }
    })

    yield put({ type: "UNSET_LOADING" })
    yield put({ type: "UNSET_PAYMENT_METHODS_LOADING" })
  } catch (errors) {
    // eslint-disable-next-line
    errors = apiErrorHelpers.formatInternalErrors(errors)

    yield put({ type: "LOADING_FAILED" })
    yield put({ type: "SET_LOADING_ERROR" })
    yield put({ type: "UNSET_LOADING" })
    yield put({ type: "UNSET_PAYMENT_METHODS_LOADING" })

    apiErrorHelpers.consoleLog(errors)
  }
} // function* loadPaymentMethods

export function* createPaymentMethod(action) {
  try {
    yield put({ type: "SET_LOADING" })
    yield put({ type: "SET_PAYMENT_METHODS_LOADING" })

    // create payment method by updating the user's payment_method_nonce   //
    const payload = action.payload || {}
    const formData = payload.formData || {}

    api.setAuthApi({ Authorization: sessionHelpers.getApplicationCookie(AUTHENTICATION_TOKEN) })

    yield put({ type: "PAYMENT_METHOD_CREATE_REQUESTED" })

    const request = {
      url: BRAINTREE_PAYMENT_METHODS_URL,
      body: {
        payment_method_nonce: payload.nonce,
        ach: payload.ach,
        default: payload.is_default,
        ...formData
      }
    }
    const response = yield call(api.utility.post, api.pathV6(request.url), { body: request.body })

    apiErrorHelpers.catchErrors(response.body)

    yield put({
      type: "PAYMENT_METHOD_CREATE_SUCCEEDED",
      payload: {
        paymentMethod: response.body.braintree_payment_methods[0]
      }
    })

    yield call(loadPaymentMethods)

    const afterRequestData = {
      title: action.payload.t("successLable"),
      message: action.payload.t("creditCardAddedSuccess"),
      type: NOTIFICATION_TYPE.SUCCESS
    }

    yield put({ type: "UNSET_LOADING" })
    yield put({ type: "UNSET_PAYMENT_METHODS_LOADING" })

    if (action.callback) yield call(action.callback, NOTIFICATION_TYPE.SUCCESS, afterRequestData)
  } catch (errors) {
    // eslint-disable-next-line
    errors = apiErrorHelpers.formatInternalErrors(errors)

    yield put({ type: "LOADING_FAILED" })
    yield put({ type: "UNSET_LOADING" })
    yield put({ type: "UNSET_PAYMENT_METHODS_LOADING" })

    const afterRequestData = {
      title: i18n.t("settings:errorLabel"),
      message: apiErrorHelpers.toString(errors),
      type: NOTIFICATION_TYPE.DANGER
    }
    yield call(action.callback, FAILURE, afterRequestData)
    apiErrorHelpers.consoleLog(errors)
  }
} // function* createPaymentMethod

export function* updatePaymentMethod(action) {
  try {
    yield put({ type: "SET_LOADING" })
    yield put({ type: "SET_PAYMENT_METHODS_LOADING" })

    const payload = action.payload || {}
    const formData = payload.formData || {}

    api.setAuthApi({ Authorization: sessionHelpers.getApplicationCookie(AUTHENTICATION_TOKEN) })

    yield put({ type: "PAYMENT_METHOD_UPDATE_REQUESTED" })

    const request = {
      url: `/braintree_payment_methods/${payload.id}`,
      body: { ...formData }
    }
    const response = yield call(api.utility.put, api.path(request.url), { body: request.body })

    apiErrorHelpers.catchErrors(response.body)

    yield put({
      type: "PAYMENT_METHOD_UPDATE_SUCCEEDED",
      payload: {
        paymentMethod: response.body
      }
    })

    const afterRequestData = {
      title: action.payload.t("updateLabel"),
      message: action.payload.t("creditCardUpdatedSuccess"),
      type: NOTIFICATION_TYPE.DEFAULT
    }

    yield put({ type: "UNSET_LOADING" })
    yield put({ type: "UNSET_PAYMENT_METHODS_LOADING" })
    if (action.callback) yield call(action.callback, NOTIFICATION_TYPE.SUCCESS, afterRequestData)
  } catch (errors) {
    // eslint-disable-next-line
    errors = apiErrorHelpers.formatInternalErrors(errors)

    yield put({ type: "LOADING_FAILED" })
    yield put({ type: "UNSET_LOADING" })
    yield put({ type: "UNSET_PAYMENT_METHODS_LOADING" })

    const afterRequestData = {
      title: i18n.t("settings:errorLabel"),
      message: apiErrorHelpers.toString(errors),
      type: NOTIFICATION_TYPE.DANGER
    }
    yield call(action.callback, FAILURE, afterRequestData)

    apiErrorHelpers.consoleLog(errors)
  }
} // function* updatePaymentMethod

export function* deletePaymentMethod(action) {
  try {
    yield put({ type: "SET_LOADING" })
    yield put({ type: "SET_PAYMENT_METHODS_LOADING" })

    api.setAuthApi({
      Authorization: sessionHelpers.getApplicationCookie(AUTHENTICATION_TOKEN)
    })

    yield put({ type: "PAYMENT_METHOD_DELETE_REQUESTED" })

    const payload = action.payload || {}
    const id = payload.id
    const request = {
      url: `/braintree_payment_methods/${id}`,
      body: {}
    }

    const response = yield call(api.utility.del, api.path(request.url), { body: request.body })

    apiErrorHelpers.catchErrors(response.body)
    ///////////////////////////

    ///////////////////////////
    //   LOAD CLIENT TOKEN   //
    ///////////////////////////

    yield call(loadClientToken)

    ///////////////////////////
    yield put({
      type: "PAYMENT_METHOD_DELETE_SUCCEEDED",
      payload: {
        paymentMethodId: id
      }
    })

    const afterRequestData = {
      title: action.payload.t("deleteLable"),
      message: action.payload.t("creditCardDeletedSuccess"),
      type: NOTIFICATION_TYPE.DANGER
    }

    yield put({ type: "UNSET_LOADING" })
    yield put({ type: "UNSET_PAYMENT_METHODS_LOADING" })
    if (action.callback) yield call(action.callback, NOTIFICATION_TYPE.SUCCESS, afterRequestData)
  } catch (errors) {
    // eslint-disable-next-line
    errors = apiErrorHelpers.formatInternalErrors(errors)

    yield put({ type: "LOADING_FAILED" })
    yield put({ type: "UNSET_LOADING" })
    yield put({ type: "UNSET_PAYMENT_METHODS_LOADING" })

    const afterRequestData = {
      title: i18n.t("settings:errorLabel"),
      message: apiErrorHelpers.toString(errors),
      type: NOTIFICATION_TYPE.DANGER
    }
    yield call(action.callback, FAILURE, afterRequestData)

    apiErrorHelpers.consoleLog(errors)
  }
} // function* deletePaymentMethod

export function* setIsDefaultPaymentMethod(action) {
  try {
    yield put({ type: "SET_LOADING" })
    yield put({ type: "SET_PAYMENT_METHODS_LOADING" })

    api.setAuthApi({
      Authorization: sessionHelpers.getApplicationCookie(AUTHENTICATION_TOKEN)
    })

    yield put({ type: "PAYMENT_METHOD_SET_DEFAULT_REQUESTED" })

    const payload = action.payload || {}
    const id = payload.id
    const request = {
      url: `/braintree_payment_methods/${id}/set_default`,
      body: {}
    }

    const response = yield call(api.utility.put, api.path(request.url), { body: request.body })

    apiErrorHelpers.catchErrors(response.body)

    yield put({
      type: "DEFAULT_PAYMENT_METHOD_UPDATE_SUCCEEDED",
      payload: {
        paymentMethod: response.body,
        is_default: true
      }
    })

    const afterRequestData = {
      title: action.payload.t("successLable"),
      message: action.payload.t("isDefaultPaymentSuccess"),
      type: NOTIFICATION_TYPE.DEFAULT
    }

    yield call(action.callback, NOTIFICATION_TYPE.SUCCESS, afterRequestData)
    yield put({ type: "UNSET_LOADING" })
    yield put({ type: "UNSET_PAYMENT_METHODS_LOADING" })
  } catch (errors) {
    // eslint-disable-next-line
    errors = apiErrorHelpers.formatInternalErrors(errors)
    const afterRequestData = {
      title: i18n.t("settings:errorLable"),
      message: apiErrorHelpers.toString(errors),
      type: NOTIFICATION_TYPE.DANGER
    }
    yield put({ type: "LOADING_FAILED" })
    yield put({ type: "UNSET_LOADING" })
    yield put({ type: "UNSET_PAYMENT_METHODS_LOADING" })
    yield call(action.callback, FAILURE, afterRequestData)

    apiErrorHelpers.consoleLog(errors)
  }
} // function* setIsDefaultPaymentMethod

export function* setDefaultPaymentMethod(action) {
  try {
    yield put({ type: "SET_LOADING" })
    yield put({ type: "SET_PAYMENT_METHODS_LOADING" })

    api.setAuthApi({
      Authorization: sessionHelpers.getApplicationCookie(AUTHENTICATION_TOKEN)
    })

    yield put({ type: "PAYMENT_METHOD_DEFAULT_REQUESTED" })

    const payload = action.payload || {}
    const id = payload.id
    const customer_id = payload.paymentMethod.owner_id
    const request = {
      url: `${SET_DEFAULT_PAYMENT_METHOD_URL}/${id}`,
      body: { payment_method: { default: true }, customer_id: customer_id }
    }

    const response = yield call(api.utility.put, api.path(request.url), { body: request.body })

    apiErrorHelpers.catchErrors(response.body)

    yield put({
      type: "DEFAULT_PAYMENT_METHOD_UPDATE_SUCCEEDED",
      payload: {
        paymentMethod: response.body,
        default: true,
        payment_token: id
      }
    })

    const afterRequestData = {
      title: action.payload.t("successLable"),
      message: action.payload.t("defaultPaymentSuccess"),
      type: NOTIFICATION_TYPE.DEFAULT
    }

    yield call(action.callback, NOTIFICATION_TYPE.SUCCESS, afterRequestData)
    yield put({ type: "UNSET_LOADING" })
    yield put({ type: "UNSET_PAYMENT_METHODS_LOADING" })
  } catch (errors) {
    // eslint-disable-next-line
    errors = apiErrorHelpers.formatInternalErrors(errors)
    const afterRequestData = {
      title: i18n.t("settings:errorLable"),
      message: apiErrorHelpers.toString(errors),
      type: NOTIFICATION_TYPE.DANGER
    }
    yield put({ type: "LOADING_FAILED" })
    yield put({ type: "UNSET_LOADING" })
    yield put({ type: "UNSET_PAYMENT_METHODS_LOADING" })
    yield call(action.callback, FAILURE, afterRequestData)

    apiErrorHelpers.consoleLog(errors)
  }
} // function* setDefaultPaymentMethod

export function* loadClientToken(action) {
  try {
    api.setAuthApi({
      Authorization: sessionHelpers.getApplicationCookie(AUTHENTICATION_TOKEN)
    })

    yield put({ type: "BRAINTREE_CLIENT_TOKEN_LOAD_REQUESTED" })

    const request = {
      url: BRAINTREE_TOKENS_NEW_URL,
      body: {}
    }

    const response = yield call(api.utility.get, api.path(request.url), { body: request.body })

    apiErrorHelpers.catchErrors(response.body)

    yield put({
      type: "BRAINTREE_CLIENT_TOKEN_LOAD_SUCCEEDED",
      payload: {
        clientToken: response.body.client_token
      }
    })
    if (action.callback)
      yield call(action.callback, NOTIFICATION_TYPE.SUCCESS, response.body.client_token)
  } catch (errors) {
    // eslint-disable-next-line
    errors = apiErrorHelpers.formatInternalErrors(errors)

    yield put({ type: "LOADING_FAILED" })
    yield put({ type: "UNSET_LOADING" })

    apiErrorHelpers.consoleLog(errors)
  }
} // function* loadClientToken

export function* loadWexAccountNumber(action) {
  const { fleetId } = action.payload
  try {
    yield put({ type: "SET_WEX_PAYMENT_METHODS_LOADING" })
    yield put({ type: "UNSET_LOADING_ERROR" })

    api.setAuthApi({
      Authorization: sessionHelpers.getApplicationCookie(AUTHENTICATION_TOKEN)
    })

    yield put({ type: "WEX_ACCOUNT_NUMBER_LOAD_REQUESTED" })

    const request = {
      url: `/fleets/${fleetId}/fleet_payment_mapping`,
      body: {}
    }

    const response = yield call(api.utility.get, api.path(request.url), { body: request.body })

    apiErrorHelpers.catchErrors(response.body)

    yield put({
      type: "WEX_ACCOUNT_NUMBER_LOAD_SUCCEEDED",
      payload: {
        paymentMethods: response.body
      }
    })

    yield put({ type: "UNSET_WEX_PAYMENT_METHODS_LOADING" })
  } catch (errors) {
    // eslint-disable-next-line
    errors = apiErrorHelpers.formatInternalErrors(errors)

    yield put({ type: "LOADING_FAILED" })
    yield put({ type: "SET_LOADING_ERROR" })
    yield put({ type: "UNSET_WEX_PAYMENT_METHODS_LOADING" })

    apiErrorHelpers.consoleLog(errors)
  }
} // function* loadWexAccountNumber

export function* updateWexAccountNumber(action) {
  const { formData, fleetId, isNew } = action.payload
  const { wexAccountNumber } = formData
  try {
    yield put({ type: "SET_WEX_PAYMENT_METHODS_LOADING" })

    yield put({
      type: "WEX_ACCOUNT_NUMBER_UPDATE_REQUESTED"
    })

    const request = {
      url: `/fleets/${fleetId}/fleet_payment_mapping?fleet_payment_mapping[wex_account_number]=${wexAccountNumber.value}`,
      body: {}
    }

    const response = yield call(api.utility.put, api.path(request.url), { body: request.body })

    apiErrorHelpers.catchErrors(response.body)
    yield put({
      type: "WEX_ACCOUNT_NUMBER_UPDATE_SUCCEEDED",
      payload: {
        wexPaymentMethod: response.body
      }
    })
    const afterRequestData = {
      title: isNew ? action.payload.t("successLable") : action.payload.t("updateLabel"),
      message: isNew
        ? action.payload.t("wexAccountNumberAddedSuccess")
        : action.payload.t("wexAccountNumberUpdatedSuccess"),
      type: isNew ? NOTIFICATION_TYPE.SUCCESS : NOTIFICATION_TYPE.DEFAULT
    }
    yield put({ type: "UNSET_WEX_PAYMENT_METHODS_LOADING" })
    yield call(action.callback, NOTIFICATION_TYPE.SUCCESS, afterRequestData)
  } catch (errors) {
    // eslint-disable-next-line
    errors = apiErrorHelpers.formatInternalErrors(errors)

    yield put({ type: "WEX_ACCOUNT_NUMBER_UPDATE_FAILED" })
    yield put({ type: "UNSET_WEX_PAYMENT_METHODS_LOADING" })
    const afterRequestData = {
      title: action.payload.t("errorLabel"),
      message: apiErrorHelpers.toString(errors),
      type: NOTIFICATION_TYPE.DANGER
    }
    yield call(action.callback, FAILURE, afterRequestData)

    apiErrorHelpers.consoleLog(errors)
  }
} // function* updateWexAccountNumber

export default function* paymentMethodSaga() {
  yield takeEvery("PAYMENT_METHODS_LOAD_SAGA", loadPaymentMethods)
  yield takeEvery("PAYMENT_METHOD_CREATE_SAGA", createPaymentMethod)
  yield takeEvery("PAYMENT_METHOD_UPDATE_SAGA", updatePaymentMethod)
  yield takeEvery("PAYMENT_METHOD_DELETE_SAGA", deletePaymentMethod)
  yield takeEvery("PAYMENT_METHOD_SET_IS_DEFAULT_SAGA", setIsDefaultPaymentMethod)
  yield takeEvery("PAYMENT_METHOD_SET_DEFAULT_SAGA", setDefaultPaymentMethod)
  yield takeEvery("BRAINTREE_CLIENT_TOKEN_LOAD_SAGA", loadClientToken)
  yield takeEvery("WEX_ACCOUNT_NUMBER_LOAD_SAGA", loadWexAccountNumber)
  yield takeEvery("WEX_ACCOUNT_NUMBER_UPDATE_SAGA", updateWexAccountNumber)
}
