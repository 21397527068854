import React, { Component } from "react"
// eslint-disable-next-line
import ReactSVG from "react-svg"
import { getSubdomain } from "../components/shared/StylesheetInjector"
import {
  isFleetAdvise,
  isFleetMaintenanceHub,
  isFleetMaintenanceHubCanada,
  isFleetcor,
  isFuelman,
  isNTWTBC
} from "../helpers/affiliationHelpers"
import SignupPageDesign from "./SignupPageDesign"
import { connect } from "react-redux"
import SigninFleetAdviseImg from "../assets/images/Signin_img.svg"
import CarAdviseBusinessLogo from "../assets/images/CarAdvise_Business Logo.svg"
import { withTranslation } from "react-i18next"
import { PAGES } from "../constants/application"

class NoSessionLeftPanel extends Component {
  logoSize(subdomain) {
    return (
      {
        azuga: 250,
        zubie: 385,
        pepboys: 450,
        carvana: 450,
        ntw: 500,
        discounttire: 500
      }[subdomain] || 208
    )
  }

  logoTagLine(subdomain) {
    return (
      {
        azuga: "MAINTENANCE MANAGER",
        zubie: "SMART MAINTENANCE"
      }[subdomain] || ""
    )
  }

  render() {
    const { page, t } = this.props
    const sub = getSubdomain()
    const tagline = this.logoTagLine(sub)

    let svgStyle = this.logoSize(sub) ? { width: this.logoSize(sub) } : {}
    let headerLogo = `${sub}_logo.svg`
    let logoClass = "no-session-logo"
    if (isFleetMaintenanceHub() || isFleetMaintenanceHubCanada()) {
      svgStyle = { width: "80%", height: "100%" }
      headerLogo = "shell_no_session_logo.svg"
      logoClass = "no-session-logo-no-tagline"
    }

    if (isFleetcor() || isFuelman())
      svgStyle = { backgroundColor: "#fff", padding: "20px 40px", height: "135px", width: "300px" }

    return (
      <div
        className={`${
          page === PAGES.SIGNIN || page === PAGES.FORGOT_PASSWORD
            ? "no-session-header-signin"
            : `${isFleetAdvise() ? "fleetadvise-signup" : ""} no-session-header-signup`
        }`}
      >
        {isFleetAdvise() ? (
          <div className="fleetadvise-signin-parent">
            <ReactSVG src={CarAdviseBusinessLogo} className="fleetadvise-logo-img" />
            <img src={SigninFleetAdviseImg} className="fleetadvise-signin-img" />
            <div>
              <p className="fleetadvise-signin-header">{t("fleetadviseSigninHeading")}</p>
              <p className="fleetadvise-signin-content">{t("fleetadviseSigninDesc")}</p>
            </div>
          </div>
        ) : (
          <>
            <div
              className={`${
                headerLogo === "fleetamerica_logo.svg"
                  ? "fleetamerica-logo"
                  : headerLogo === "shell_logo.svg" || headerLogo === "shell_no_session_logo.svg"
                  ? "shell-logo"
                  : headerLogo === "discounttire_logo.svg"
                  ? "discount-tire-logo"
                  : "main-logo"
              }`}
            >
              <ReactSVG
                src={`images/${headerLogo}`}
                className={`${
                  headerLogo === "fleetadvise_logo.svg" ? "fleetadvise-logo" : logoClass
                }`}
                svgStyle={svgStyle}
              />
            </div>
            {tagline && <div className="no-session-logo-tag-line">{tagline}</div>}
            {/* FIXME: removing this messes up positioning for logos that have taglines */}
            {/* We should get rid of the tagline by using an SVG that already has it */}
            {tagline && <div className="no-session-cta"></div>}
            <div className="display-fleet-design">
              <div
                className={`${
                  headerLogo === "fleetadvise_logo.svg"
                    ? "display-shortinfo-text-container"
                    : "display-nothing"
                }`}
              >
                <p
                  className={`${
                    page === PAGES.SIGNIN || page === PAGES.FORGOT_PASSWORD
                      ? "short-info-signin-page"
                      : "short-info"
                  }`}
                >
                  <b>The ultimate, all-in-one fleet management tool</b>
                </p>
              </div>
              <div
                className={`${
                  (headerLogo === "fleetadvise_logo.svg" && page === PAGES.SIGNIN) ||
                  page === PAGES.FORGOT_PASSWORD
                    ? "display-visit-text-container"
                    : "display-nothing"
                }`}
              >
                <p
                  className={`${
                    page === PAGES.SIGNIN || page === PAGES.FORGOT_PASSWORD
                      ? "display-visit-fleetadvise-info"
                      : "display-nothing"
                  }`}
                >
                  Visit FleetAdvise.com
                </p>
              </div>

              {page === PAGES.SIGNUP ? (
                <SignupPageDesign headerLogo={headerLogo} subdomain={sub} />
              ) : null}
            </div>
          </>
        )}
      </div>
    )
  }
} // NoSessionLeftPanel

const mapStateToProps = (state) => ({
  currentUser: state.users.currentUser
})

export default connect(mapStateToProps)(withTranslation("common")(NoSessionLeftPanel))
