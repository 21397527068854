import {
  DRIVER,
  DRIVER_FR,
  FMC,
  FMC_FLEET_ADMIN,
  FMC_FLEET_ADMIN_FR,
  FMC_FLEET_MANAGER,
  FMC_FLEET_MANAGER_FR,
  FMC_FR
} from "../constants/roles"

export function selectOptions(userRoles) {
  let options = []

  userRoles.forEach((role) => {
    options.push({
      label: role.name,
      value: role.name
    })
  })

  return options
}

export function isFMCUser(user) {
  return (
    user &&
    user.roles &&
    user.roles.find(
      (role) =>
        role === FMC ||
        role.name === FMC ||
        role === FMC_FLEET_ADMIN ||
        role.name === FMC_FLEET_ADMIN ||
        role === FMC_FLEET_MANAGER ||
        role.name === FMC_FLEET_MANAGER
    )
  )
}

export function isFMC(user) {
  return user && user.roles && user.roles.find((role) => role === FMC || role.name === FMC)
}

export function isDriver(user) {
  return user && user.roles && user.roles.includes(DRIVER, DRIVER_FR)
}

export const EXCLUDED_ROLES = [
  FMC,
  FMC_FLEET_MANAGER,
  FMC_FLEET_ADMIN,
  FMC_FLEET_ADMIN_FR,
  FMC_FLEET_MANAGER_FR,
  FMC_FR
]
