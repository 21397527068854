import React, { Component } from "react"
import PropTypes from "prop-types"
import { connect } from "react-redux"

import { navigationPaths } from "../../constants/paths"

import Alert from "../../components/shared/Alert"
import ApplicationLayout from "../../layouts/ApplicationLayout"
import ServiceRequestGoBackButton from "../../components/service_requests/ServiceRequestGoBackButton"
import ServiceRequestScheduleForm from "../../components/service_requests/ServiceRequestScheduleForm"
import ServiceRequestSummary from "../../components/service_requests/ServiceRequestSummary"
import { Segment, Grid, Divider, Header } from "semantic-ui-react"
import { isWex, isFleetcor, isBraintree, isAch } from "../../helpers/userHelpers"
import { withTranslation } from "react-i18next"
import PromotionalCodeLink from "../../components/services/PromotionalCodeLink"
import { setupSplitFlags } from "../../components/config/SplitClient"
import { getSubdomain } from "../../components/shared/StylesheetInjector"
import { logFirebaseAnalyticsScreenName } from "../../helpers/googleAnalyticsHelpers"
import { getRouteNameFromUrl } from "../../helpers/segmentHelpers"

class ServiceRequestSchedule extends Component {
  constructor(props) {
    super(props)

    const locationStateData = this.props.location.state || {}

    this.state = {
      error: "",
      isSubmitting: false,
      previousFormData: locationStateData.previousFormData
    }
  }

  async componentDidMount() {
    logFirebaseAnalyticsScreenName(getRouteNameFromUrl())

    this.props.dispatch({ type: "CURRENT_USER_LOAD_PAYMENT_METHODS_SAGA" })
    await setupSplitFlags.bind(this)({ sub_domain: getSubdomain() })
  }

  afterFormSubmit(status, data) {
    this.setState({ isSubmitting: false })

    if (status === "success") {
      this.onFormSubmitSuccess(data)
    } else {
      this.onFormSubmitFailure(data)
    }
  }

  onFormSubmit(formData) {
    const { currentUser, fleet } = this.props
    const { has_payment_method, pays_through_platform, fleet_payment_providers } = currentUser
    if (
      !pays_through_platform ||
      isWex(fleet) ||
      isFleetcor(fleet) ||
      isBraintree(fleet) ||
      isAch(fleet)
    ) {
      this.setState({ isSubmitting: true })

      this.props.dispatch({
        type: "SERVICE_REQUEST_CREATE_SAGA",
        payload: { formData: formData, t: this.props.t },
        callback: this.afterFormSubmit.bind(this)
      })
    } else {
      this.setState({
        error: this.props.t("addPaymentHelper")
      })
    }
  }

  onFormSubmitFailure = (data) => {
    this.setState({ error: data.error })
  }

  onFormSubmitSuccess(data) {
    this.props.history.push({
      pathname: navigationPaths.dashboard(),
      state: {
        alert: data.alert,
        alertType: data.alertType
      }
    })
  }

  render() {
    const { t } = this.props
    const { error, isSubmitting, previousFormData, isGeotabSectionFlag } = this.state

    return (
      <ApplicationLayout>
        <Alert message={error} type={"error"} visible={error !== ""} />

        <div>
          <Segment padded raised>
            <Grid stackable columns={2}>
              <Grid.Row>
                <Grid.Column width={7}>
                  <Header as="h3">{t("appointmentInformationLabel")}</Header>
                  <Divider />
                  <ServiceRequestSummary previousFormData={previousFormData} />
                  <Divider hidden />
                  <Divider hidden />
                  <ServiceRequestGoBackButton previousFormData={previousFormData} />
                </Grid.Column>

                <Grid.Column floated="right" width={7}>
                  <Segment padded="very" raised>
                    <ServiceRequestScheduleForm
                      isSubmitting={isSubmitting}
                      onSubmit={this.onFormSubmit.bind(this)}
                      displayError={this.onFormSubmitFailure}
                      previousFormData={previousFormData}
                      isGeotabSectionFlag={isGeotabSectionFlag}
                    />
                  </Segment>
                </Grid.Column>
              </Grid.Row>
            </Grid>
          </Segment>
        </div>
      </ApplicationLayout>
    )
  }
} // class ServiceRequestSchedule

const mapStateToProps = (state) => ({
  services: state.services.services,
  shops: state.shops.shops,
  vehicles: state.vehicles.vehicles,
  currentUser: state.users.currentUser || state.application.userSessionData,
  fleet: state.application.fleetSessionData || state.fleets.fleet,
  basicUsers: state.users.basicUsers
})

export default connect(mapStateToProps)(withTranslation("scheduleRequest")(ServiceRequestSchedule))
