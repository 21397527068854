import PepBoysLogo from "../assets/images/pepboys.svg"
import FirestoneLogo from "../assets/images/firestone.svg"
import JiffyLubeLogo from "../assets/images/jiffylube.svg"
import DiscountTireLogo from "../assets/images/discount-tire.svg"
import ValvolineLogo from "../assets/images/valvoline.svg"
import MrTireLogo from "../assets/images/mr-tire.svg"
import MidasLogo from "../assets/images/midas.svg"
import TirechoiceLogo from "../assets/images/tire-choice.svg"
import AmericanTireLogo from "../assets/images/american-tire.svg"
import TirePlusLogo from "../assets/images/tires-plus.svg"
import MonroLogo from "../assets/images/monro.svg"
import StallantisLogo from "../assets/images/stell.svg"
import MrLubeLogo from "../assets/images/mr-lube.svg"
import CarXlogo from "../assets/images/CarX.svg"
import Aamcologo from "../assets/images/aamco.svg"
import CandianTireLogo from "../assets/images/canadian-tire.svg"
import MeinekeLogo from "../assets/images/Meineke.svg"
import Take5Logo from "../assets/images/take5.svg"
import WheelWorksLogo from "../assets/images/wheel-works.svg"
import GreaseMonkeyLogo from "../assets/images/grease-monkey.svg"
import KwikKarLogo from "../assets/images/kwilkar.svg"
import SpeedeLogo from "../assets/images/speedee.svg"
import GoodYearLogo from "../assets/images/googyear.svg"
import CarpLogo from "../assets/images/corp.svg"
import BelleTireLogo from "../assets/images/belle-tire.svg"
import NTBLogo from "../assets/images/NTB.svg"
import AmericasTireLogo from "../assets/images/americas-tire-logo.svg"
import { getSubdomain } from "../components/shared/StylesheetInjector"

export const PROJECT_NAME = "FleetAdvise"
export const FLEET_ACCOUNT = "CarAdvise National Fleet Account"
export const AUTO_INTEGRATE = "Auto Integrate"
export const PHONE = "(312) 779-1549"
export const HELP_CENTER_NAME = "CarAdvise"
export const WORLDWIDE_STORES = "30,000"
export const FLEETCOR_WORLDWIDE_STORES = "25,000"

export const PDF_CONSTANTS = {
  imgType: "image/png",
  JPEG: "JPEG",
  colorCode: "#999999"
}

export const FLEET_ADVISE = "fleetadvise"
export const PAGE_ORIANTATION = "@page { size: portrait; }"
export const TEXT_CSS = "text/css"
export const PRINT = "print"

export const CARADVISE_FLEET = "CarAdvise for Business"

export const BRAND_LOGO = [
  PepBoysLogo,
  FirestoneLogo,
  JiffyLubeLogo,
  DiscountTireLogo,
  ValvolineLogo,
  MrTireLogo,
  MidasLogo,
  TirechoiceLogo,
  AmericanTireLogo,
  TirePlusLogo,
  MonroLogo,
  StallantisLogo,
  MrLubeLogo,
  CarXlogo,
  Aamcologo,
  CandianTireLogo,
  MeinekeLogo,
  Take5Logo,
  WheelWorksLogo,
  GreaseMonkeyLogo,
  KwikKarLogo,
  SpeedeLogo,
  GoodYearLogo,
  CarpLogo,
  BelleTireLogo,
  NTBLogo
]

export const DISCOUNT_TIRE_BRAND_LOGO = [
  DiscountTireLogo,
  AmericasTireLogo,
  JiffyLubeLogo,
  ValvolineLogo,
  MrTireLogo,
  MidasLogo,
  TirechoiceLogo,
  AmericanTireLogo,
  TirePlusLogo,
  MonroLogo,
  StallantisLogo,
  MrLubeLogo,
  CarXlogo,
  Aamcologo,
  CandianTireLogo,
  MeinekeLogo,
  Take5Logo,
  WheelWorksLogo,
  GreaseMonkeyLogo,
  KwikKarLogo,
  SpeedeLogo,
  CarpLogo,
  BelleTireLogo,
  NTBLogo
]

const fleetServices = {
  fleetadvise: "CarAdvise for Business",
  fleetcor: "Fuelman Maintenance",
  ntw: "NTW Fleet Service",
  discounttire: "Discount Tire Fleet Services",
  fuelman: "Fuelman Maintenance",
  zubie: "Zubie Smart Maintenance"
}

export const getCompanyName = () => {
  const subdomain = getSubdomain()
  return fleetServices[subdomain] || CARADVISE_FLEET
}
